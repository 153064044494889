import { MAPY_POIAGG } from "~/const";
import { DEFAULT_TYPE_ID } from "~/lib/net/const";
import { ICoords, IFRPCResponse } from "~/lib/net/interfaces";
import { STATUS_OK } from "~/lib/net/request";
import { call } from '~/lib/net/rpc';

export interface IPOIGeometry {
	data: Array<string>;
	style: {
		opacity?: string;
		width?: string;
		color: string;
		outlineOpacity?: string;
		outlineWidth?: string;
		outlineColor?: string;
		offsetWidth?: string;
		endCap?: 'round' | 'square' | 'butt',
	},
	type: 'linestring' | 'polygon' | 'multilinestring' | 'multipolygon';
}

export interface IPOI {
	geom?: IPOIGeometry;
	source: string;
	id: number | string;
	title: string;
	mark: {
		lat: number;
		lon: number;
	};
	multiTitle?: Array<string>;
	typeName?: string;
	typeId?: number;
	titleVars: {
		locationMain1: string;
		locationMain2: Array<string>;
		locationMain3: Array<string>;
		locationSub1: string;
		locationSub2: Array<string>;
		locationSub3: Array<string>;
		specification: string;
		titleMain: string;
		titleMapMain: string;
		titleMapSub: string;
		titleSmartMain: string;
		titleSmartSub: string;
		titleSub: string;
	}
}

export interface IPOIIcon {
	height: number;
	id: number;
	width: number;
}

export interface IPOIType {
	// eslint-disable-next-line camelcase
	icon_id: number;
	id: number;
}

export interface IPOIIconResponse extends IFRPCResponse {
	poiicon: Array<IPOIIcon>;
	poitype: Array<IPOIType>;
}

export interface ILookupPOI {
	source: string;
	id: number | string;
	geomInLookup: boolean;
	mark: {
		lat: number;
		lon: number;
	};
	icon?: {
		[key: number]: string;
	};
	multiMark?: {
		data: Array<string>;
		type: 'multipoint';
	};
	paid?: number;
	title: string;
	typeId: number;
	zoom: number;
	zoomMax: number;
}

export interface ILookupOptions {
	zoom?: number;
	mapsetId?: number;
	pixelSize?: number;
	lang?: Array<string>;
}

export interface ILookupResponse extends IFRPCResponse {
	poi: Array<ILookupPOI>;
}

export interface IDetailResponse extends IFRPCResponse {
	poi: IPOI;
}

export function getDetail(source: string, id: string | number, options: any): Promise<IDetailResponse> {
	return call('detail', [source, id, options], {
		url: MAPY_POIAGG,
	}) as Promise<IDetailResponse>;
}

export interface IDescriptionData {
	[key: string]: Array<number>;
}

export function poisToDescriptionOptions(pois: Array<Partial<IPOI>>): IDescriptionData {
	const options: IDescriptionData = {};

	pois.forEach(poi => {
		if (!(poi.source in options)) {
			options[poi.source] = [];
		}
		options[poi.source].push(poi.id as number);
	});

	// eslint-disable-next-line guard-for-in
	for (const source in options) {
		options[source] = [...Array.from(new Set(options[source]))];
	}

	return options;
}

export interface IDescriptionResponse extends IFRPCResponse {
	poi: Array<IPOI>;
}

export interface IDescriptionOptions {
	lang?: Array<string>;
}

export function getDescription(data: IDescriptionData, options: IDescriptionOptions = { lang: ["cs"] }): Promise<IDescriptionResponse> {
	return call('description', [data, options], {
		url: MAPY_POIAGG,
	}) as Promise<IDescriptionResponse>;
}

export interface ILookupMediaPoisOptions {
	zoom?: number;
	count?: number;
	lang?: Array<string>;
}

export function lookupMediaPois(lat: number, lon: number, options: ILookupMediaPoisOptions): Promise<ILookupResponse> {
	const params = [
		lat,
		lon,
		{
			zoom: 16,
			count: 20,
			...options,
		},
	];

	const hints = {
		0: 'float',
		1: 'float',
	};

	return call('lookupMediaPois', params, {
		url: MAPY_POIAGG,
		hints,
	}) as Promise<ILookupResponse>;
}

export function getPOIIcons(): Promise<IPOIIconResponse> {
	return call('poiicons', [], {
		url: MAPY_POIAGG,
	}) as Promise<IPOIIconResponse>;
}

export interface IPOITypes {
	[key: number]: IPOIIcon;
}

export const POITYPES: IPOITypes = {};

export async function initPOIIcons(): Promise<void> {
	try {
		if (Object.keys(POITYPES).length === 0) {
			const response = await getPOIIcons();

			if (response.status === STATUS_OK) {
				response.poitype.forEach(poiType => {
					const icon = response.poiicon.find(poiIcon => poiIcon.id === poiType.icon_id);

					if (icon) {
						POITYPES[poiType.id] = icon;
					}
				});
			}

			Object.freeze(POITYPES);
		}
	} catch (exc) {
		//
	}
}

export function typeToIcon(typeId: number, poiTypes: IPOITypes = POITYPES): number {
	return typeId in poiTypes ? poiTypes[typeId].id : DEFAULT_TYPE_ID;
}

export function getLookup(bounds: [ICoords, ICoords], options: ILookupOptions = {}): Promise<ILookupResponse> {
	const { longitude: lbx, latitude: lby } = bounds[0];
	const { longitude: rtx, latitude: rty } = bounds[bounds.length - 1];
	const params = [
		lbx,
		lby,
		rtx,
		rty,
		{
			zoom: 0,
			mapsetId: 0,
			pixelSize: 1,
			lang: ["cs"],
			...options,
		},
	];

	const hints = {
		'0': 'float',
		'1': 'float',
		'2': 'float',
		'3': 'float',
		'4.pixelSize': 'float',
	};

	return call('lookupbox', params, {
		url: MAPY_POIAGG,
		hints,
	}) as Promise<ILookupResponse>;
}
