import { getDataPath } from "~/providers/php-api";
import { IDatabaseCategory, IDatabaseItem, TDatabaseCategory } from "./interfaces";
import { COMMON_ITEMS } from "./database/common";
import { MEDICAL_ITEMS } from "./database/medical";
import { TOYS_ITEMS } from "./database/toys";
import { DUPLO_ITEMS } from "./database/duplo";
import { ALCOHOL_ITEMS } from "./database/alcohol";

const DEF_IMG_PATH = "veci";

export const ALL_DATABASE_ITEMS_CATEGORY: TDatabaseCategory = "vse";

const database: { [key in TDatabaseCategory]: Array<IDatabaseItem>; } = {
	leky: [],
	duplo: [],
	hracky: [],
	ostatni: [],
	piticka: [],
	vse: [],
};
let wasSet = false;

export const DATABASE_CATEGORIES: Array<IDatabaseCategory> = [
	{
		title: "Vše",
		seo: "vse",
	},
	{
		title: "Léky",
		seo: "leky",
	},
	{
		title: "Hračky",
		seo: "hracky",
	},
	{
		title: "Duplo",
		seo: "duplo",
	},
	{
		title: "Pitíčka",
		seo: "piticka",
	},
	{
		title: "Ostatní",
		seo: "ostatni",
	},
];

function applyPhotos(category: TDatabaseCategory, items: Array<IDatabaseItem>, type: string): Array<IDatabaseItem> {
	for (const item of items) {
		if (item.images) {
			for (const image of item.images) {
				image.src = getDataPath("fotky", `${DEF_IMG_PATH}/${category}/${image.src}`, type);
				image.large = getDataPath("fotky", `${DEF_IMG_PATH}/${category}/${image.large}`, type);
			}
		}
	}

	return items;
}

export function databaseItemsInit(type: string) {
	if (!wasSet) {
		database.leky = applyPhotos("leky", MEDICAL_ITEMS, type);
		database.hracky = applyPhotos("hracky", TOYS_ITEMS, type);
		database.duplo = applyPhotos("duplo", DUPLO_ITEMS, type);
		database.piticka = applyPhotos("piticka", ALCOHOL_ITEMS, type);
		database.ostatni = applyPhotos("ostatni", COMMON_ITEMS, type);
		database.vse = [
			...database.leky,
			...database.hracky,
			...database.duplo,
			...database.piticka,
			...database.ostatni,
		];
		wasSet = true;
	}
}

export function getDatabaseItems(category: TDatabaseCategory) {
	if (category in database) {
		return database[category];
	}

	return database[ALL_DATABASE_ITEMS_CATEGORY];
}

export function searchItems(query: string) {
	const items = getDatabaseItems(ALL_DATABASE_ITEMS_CATEGORY);

	return items.filter(item => {
		const title = item.title.toLocaleLowerCase();
		const desc = (item.desc || "").toLocaleLowerCase();

		return title.indexOf(query) !== -1 || desc.indexOf(query) !== -1;
	});
}
