/* eslint-disable no-magic-numbers */
import { useEffect, useRef, PointerEvent } from "react";
import { IProfileData } from "~/providers/altitude";
import { getHeightProfileData } from "~/utils/height-profile";
import { getClassName } from "~/utils/utils";
import { TCoords } from "~/interfaces";

import "./style.less";

interface IHeightProfileColors {
	circleFill?: string;
	minMaxLine?: string;
	topLine?: string;
	measure?: string;
	filling?: string;
	gradientFill?: [string, string];
}

const COLORS: IHeightProfileColors = {
	circleFill: "#ffffff",
	minMaxLine: "#cccccc",
	// puvodne #b0b0b0
	topLine: "#828282",
	measure: "#ebebeb",
	filling: "#f4f4f4",
	gradientFill: ["#fafafa", "#e6e6e6"],
};

export interface IHeightProfile {
	width?: number;
	height?: number;
	padding?: number;
	data: IProfileData;
	measureSections?: number;
	circleRadius?: number;
	// meritka
	xUnits?: {
		[key: string]: string;
	};
	colors?: IHeightProfileColors;
	onEnter?: (coords: TCoords, altitude: number) => void;
	onMove?: (coords: TCoords, altitude: number) => void;
	onLeave?: () => void;
}

export default function HeightProfile({
	width = 320,
	height = 200,
	// top + bottom
	padding = 50,
	// altitude data
	data,
	// pocet dilku na meritku; 0 vypina renderovani meritka
	measureSections = 3,
	circleRadius = 3,
	// meritka
	xUnits = {
		1: "m",
		1000: "km",
	},
	colors = {},
	onEnter = () => {},
	onMove = () => {},
	onLeave = () => {},
}: IHeightProfile) {
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const opts: IHeightProfile = {
		width,
		height,
		padding,
		data,
		measureSections,
		circleRadius,
		xUnits,
		colors: {
			...COLORS,
			...colors,
		},
	};
	const profileData = getHeightProfileData(opts);

	function redraw(linePosX = -1) {
		const ctx = canvasRef.current.getContext("2d");
		const { items, measureItems, minItem, maxItem } = profileData;

		ctx.clearRect(0, 0, opts.width, opts.height);
		ctx.lineWidth = 1;

		// vypln
		const gradient = ctx.createLinearGradient(0, opts.height - 1, 0, 0);

		gradient.addColorStop(0, opts.colors.gradientFill[0]);
		gradient.addColorStop(1, opts.colors.gradientFill[1]);

		ctx.strokeStyle = gradient;
		ctx.fillStyle = gradient;

		ctx.beginPath();
		ctx.lineCap = "square";
		ctx.moveTo(0, opts.height - 1);
		items.forEach(item => {
			ctx.lineTo(item.x, item.y);
		});
		ctx.lineTo(opts.width, opts.height - 1);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();

		ctx.strokeStyle = opts.colors.filling;
		ctx.fillStyle = opts.colors.filling;

		// spodni cast a meritko
		ctx.strokeStyle = opts.colors.measure;
		ctx.fillStyle = opts.colors.measure;
		ctx.beginPath();
		ctx.moveTo(0, opts.height - 1);
		ctx.lineTo(opts.width - 1, opts.height - 1);
		ctx.stroke();

		measureItems.forEach(item => {
			ctx.beginPath();
			ctx.moveTo(item.x, item.y);
			ctx.lineCap = "square";
			ctx.lineTo(item.x, opts.height - 1);
			ctx.stroke();
		});

		// horni cast
		ctx.beginPath();
		ctx.lineJoin = "round";
		ctx.lineCap = "square";
		ctx.strokeStyle = opts.colors.topLine;
		ctx.fillStyle = opts.colors.topLine;

		items.forEach(item => {
			ctx.lineTo(item.x, item.y);
		});
		ctx.stroke();

		// max a min
		ctx.strokeStyle = opts.colors.minMaxLine;
		ctx.fillStyle = opts.colors.circleFill;

		[minItem, maxItem].forEach(item => {
			ctx.beginPath();
			ctx.arc(item.x, item.y, opts.circleRadius, 0, 2 * Math.PI, true);
			ctx.fill();
			ctx.stroke();

			ctx.beginPath();
			ctx.moveTo(item.x, item.y - opts.circleRadius);
			ctx.lineTo(item.x, item.y2);
			ctx.stroke();
		});

		if (linePosX >= 0) {
			ctx.strokeStyle = opts.colors.minMaxLine;
			ctx.fillStyle = opts.colors.minMaxLine;

			ctx.beginPath();
			ctx.moveTo(linePosX, items[linePosX].y);
			ctx.lineTo(linePosX, opts.height - 1);
			ctx.stroke();
		}
	}

	function getPosX(event: PointerEvent) {
		const target = (event.target as HTMLElement).closest(".height-profile__canvas-cover");
		const bcr = target.getBoundingClientRect();
		const xPos = event.clientX - bcr.left >>> 0;

		return xPos;
	}

	function onPointerEnter(event: PointerEvent) {
		const xPos = getPosX(event);
		const item = profileData.items[xPos];

		onEnter(item.coords, item.altitude);
		redraw(xPos);
	}

	function onPointerLeave() {
		onLeave();
		redraw();
	}

	function onPointerMove(event: PointerEvent) {
		const xPos = getPosX(event);
		const item = profileData.items[xPos];

		onMove(item.coords, item.altitude);
		redraw(xPos);
	}

	useEffect(() => {
		if (canvasRef.current) {
			redraw();
		}
	}, [canvasRef]);

	/* eslint-disable react/no-array-index-key */
	return <div className="height-profile" style={{ width: `${opts.width}px` }}>
		<div className="height-profile__canvas-cover" onPointerEnter={onPointerEnter} onPointerLeave={onPointerLeave} onPointerMove={onPointerMove}>
			<canvas width={width} height={height} ref={canvasRef} />
			{ [profileData.minItem, profileData.maxItem].map(item => <span className={getClassName(["height-profile__minmax-item", item.position])} key={item.type} style={{ left: `${item.x}px`, top: `${item.top}px` }}>
				<span className="height-profile__minmax-item__value">
					{ item.altitude }
				</span>
				<span className="height-profile__minmax-item__unit">
					m. n. m.
				</span>
			</span>) }
			<div className="height-profile__measure-items" data-count={profileData.measureItems.length}>
				{ profileData.measureItems.map((item, ind) => <span className="height-profile__measure-item" key={`measure-item-${ind}`}>
					<span className="height-profile__measure-itemValue">
						{ item.value }
					</span>
					<span className="height-profile__measure-itemUnit">
						{ item.unit }
					</span>
				</span>)}
			</div>
		</div>
		<p className="height-profile__desc">
			<span className="height-profile__desc-cover">
				<span className="height-profile__ascdesc-item">
					<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" fill="#999" viewBox="0 0 24 24" className="icon icon-ascending" role="img" aria-label="stoupání">
						<polygon points="20 4 19.999 13.998 17 11 8.001 19.999 4 15.998 12.999 6.999 10.001 4" />
					</svg>
					<span className="height-profile__ascdesc-itemValue">
						{ data.altitude.gain >>> 0 } m
					</span>
				</span>
				<span className="height-profile__ascdesc-item">
					<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" fill="#999" viewBox="0 0 24 24" className="icon icon-descending" role="img" aria-label="klesání">
						<polygon points="20 20.001 10.001 20 13 17.001 4 8.001 8.001 4 17.001 13 20 10.001" />
					</svg>
					<span className="height-profile__ascdesc-itemValue">
						{ data.altitude.loss >>> 0 } m
					</span>
				</span>
			</span>
		</p>
	</div>;
}
