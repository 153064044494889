import maplibregl from "maplibre-gl";
import { useEffect, useRef } from "react";

import { BESTFIT_PADDING, DEFAULT_ZOOM } from "~/const";
import { copyToClipboard, formatDistance, getClassName, getDistanceWGS84, getRandomHexHash, getTrackColorByType, numberToDec } from "~/utils/utils";
import { myUseState } from "~/hooks/myUseState";
import { coordsToString, mergeGeometries, stringToCoords } from "~/utils/mirek";
import { ITrackItem, TCoords, IMyGeoJSON, IDataEventGalleryData, TRouteTypes, IEventDayItem, IDataTrackGeometryFull, TTrackType } from "~/interfaces";
import { IMapLoad, useMap } from "~/hooks/useMap";
import { coordsToBounds, copyDebug, createContextMenu, createContextMenuItem, createImageMarker, createTextMarker, createTracks, getGeoJson, getGeoJsonFromSource, getLineStringGeoJson, getMapData, getMapyczUrl, getPointGeoJson,
	getRouteJSON, openMapyczDetail } from "~/utils/map";
import SetCoordsDialog from "../SetCoordsDialog";
import HeightProfileDialog from "../HeightProfileDialog";
import SetTracksDialog from "../SetTracksDialog";
import SelectTrackDialog from "../SelectTrackDialog";
import { IMapItem, IMapPosition, IMapTodoItem, IMarkerItem, ISearchItem, IRouteMarker, TRouteItemType, ISavedTrackItem } from "~/map-interfaces";
import MapControlsLeft, { TMapControlsLeftContent } from "../MapControlsLeft";
import MapControlsRight, { TMapControlsRightContent } from "../MapControlsRight";
import MapDays from "../MapDays";
import MapSearch from "../MapSearch";
import MapAddMarkers, { IAddMarker } from "../MapAddMarkers";
import MapDebug, { TMapDebugType } from "../MapDebug";
import SimplifyTrackDialog from "../SimplifyTrackDialog";
import MapSearchApi from "../MapSearchApi";
import { savedStore } from "~/stores/saved";
import TrackInfoDialog from "../TrackInfoDialog";

import "./style.less";

interface IMapRefData {
	detailMarker: maplibregl.Marker;
	gpsMarker: maplibregl.Marker;
	gpsWatchId: number;
	mapData: IMapLoad;
	markers: Array<IMarkerItem>;
	showMarkerDates: boolean;
	routeGlobalType: TRouteItemType;
	lastAddCoords: TCoords;
}

interface IState {
	fullscreen: boolean;
	query: string;
	searchItems: null | Array<ISearchItem>;
	debug: Pick<IDataEventGalleryData, "items">;
	routeMarkers: Array<IRouteMarker>;
	trackMarkers: Array<maplibregl.Marker>;
	activeDate: string;
	showSetCoordsDialog: boolean;
	showSetTracksDialog: boolean;
	showSimplifyTracksDialog: boolean;
	// vyskovy profil - geometrie
	trackGeometry: string;
	editTracks: Array<ITrackItem>;
	infoTrack: ITrackItem;
	leftControlsActive: Array<TMapControlsLeftContent>;
	markerInd: number;
	addToLastPosition: boolean;
	savePosition: boolean;
}

interface IStateTracks {
	routeDist: number;
	geomCount: number;
	routeGeometry: string;
	routeType: TRouteTypes;
	routeGlobalType: TRouteItemType;
	trackType: TTrackType;
	savedTracks: Array<ISavedTrackItem>;
}

export interface IOnClickData {
	event?: MouseEvent;
	item: IMapItem;
	map: maplibregl.Map;
}

interface IMap {
	position: IMapPosition;
	items?: Array<IMapItem>;
	todoItems?: Array<IMapTodoItem>;
	mapDate?: string;
	selectItem?: string;
	hasClose?: boolean;
	showSearch?: boolean;
	showDebug?: boolean;
	hideDays?: boolean;
	days?: Array<IEventDayItem>;
	geoJsons?: Array<IMyGeoJSON>;
	customTracks?: null | Array<ITrackItem>;
	showDetailMarker?: boolean;
	onLoad?: (mapData: IMapLoad) => void;
	onClick?: (clickData: IOnClickData) => void;
	onClose?: () => void;
}

export default function Map({
	position,
	items = [],
	todoItems = [],
	mapDate = "",
	selectItem = "",
	hasClose = true,
	showSearch = false,
	showDebug = false,
	days = [],
	hideDays = false,
	geoJsons = [],
	customTracks = null,
	showDetailMarker = false,
	onLoad = () => {},
	onClick = () => {},
	onClose = () => {},
}: IMap) {
	const mapRef = useRef<HTMLDivElement>(null);
	const mapRefData = useRef<IMapRefData>({
		detailMarker: null,
		gpsMarker: null,
		gpsWatchId: 0,
		mapData: null,
		markers: [],
		showMarkerDates: false,
		routeGlobalType: "route",
		lastAddCoords: null,
	});
	const { savedData } = savedStore(savedState => ({
		savedData: savedState.saved,
	}));
	const { state, setState, updateState, toggleState } = myUseState<IState>({
		fullscreen: false,
		query: "",
		searchItems: null,
		debug: {},
		routeMarkers: [],
		trackMarkers: [],
		activeDate: "",
		showSetCoordsDialog: false,
		showSetTracksDialog: false,
		showSimplifyTracksDialog: false,
		trackGeometry: "",
		editTracks: [],
		infoTrack: null,
		leftControlsActive: savedData.useRetina
			? ["retina"]
			: [],
		markerInd: 1,
		addToLastPosition: false,
		savePosition: false,
	});
	const { state: stateTracks, updateState: updateStateTracks } = myUseState<IStateTracks>({
		routeDist: 0,
		geomCount: 0,
		routeGeometry: "",
		routeType: "foot_fast",
		routeGlobalType: "route",
		trackType: "walk",
		savedTracks: [],
	});

	const parsedMapData = getMapData(items, days);

	function zoomToFeatures() {
		let zoomBounds: maplibregl.LngLatBounds = null;

		if (parsedMapData.tracksBounds) {
			zoomBounds = parsedMapData.tracksBounds;
		} else if (mapRefData.current.markers.length) {
			const coords: Array<maplibregl.LngLatLike> = mapRefData.current.markers.map(item => item.marker.getLngLat());

			zoomBounds = coordsToBounds(coords);
		}

		if (zoomBounds) {
			mapRefData.current.mapData.map.fitBounds(zoomBounds, {
				padding: BESTFIT_PADDING,
				duration: 0,
			});
		}
	}

	function selectMarkerByName(name: string) {
		let center: maplibregl.LngLatLike = [0, 0];

		mapRefData.current.markers.forEach(item => {
			item.marker.removeClassName("active");

			if (item.data.name === name) {
				const data = items.filter(markerItem => markerItem.name === name)[0];

				center = data.coords;
				item.marker.addClassName("active");
			}
		});

		return center;
	}

	function getMarkerDate(name: string): null | string {
		// jiz mame data - klik obrazek -> mapa, vybereme prislusny den nebo vycistime zadany
		if (name && mapRefData.current.mapData && days.length) {
			const filtered = mapRefData.current.markers.filter(item => item.data.name === name);

			return filtered.length && filtered[0].data.date
				? filtered[0].data.date
				: "";
		}

		return null;
	}


	function selectMarker(name: string) {
		if (name && mapRefData.current.mapData) {
			const center = selectMarkerByName(name);

			mapRefData.current.mapData.map.jumpTo({
				center,
				zoom: DEFAULT_ZOOM,
			});
		}
	}

	function onSearchClick(searchItem: ISearchItem) {
		mapRefData.current.mapData.map.jumpTo({
			center: searchItem.coords,
			zoom: DEFAULT_ZOOM,
		});
		selectMarker(searchItem.name);
	}

	function showTrack(track: ITrackItem) {
		const map = mapRefData.current.mapData.map;

		map.jumpTo(map.cameraForBounds(track.bbox, {
			padding: BESTFIT_PADDING,
		}));
	}

	function removeRouteById(id: string) {
		setState(prev => {
			const ind = prev.routeMarkers.findIndex(item => item.id === id);

			prev.routeMarkers[ind].marker.remove();

			return {
				...prev,
				routeMarkers: prev.routeMarkers.filter(item => item.id !== id),
			};
		});
	}

	function addMarker(addData: IAddMarker) {
		const { addItem, addToLastPosition, savePosition, date } = addData;
		const mapCenter = mapRefData.current.mapData.map.getCenter();
		let coords: TCoords = [mapCenter.lng, mapCenter.lat];

		if (addToLastPosition && mapRefData.current.lastAddCoords) {
			coords = mapRefData.current.lastAddCoords;
		}

		if (savePosition) {
			mapRefData.current.lastAddCoords = coords;
		}

		// nastavime
		mapRefData.current.lastAddCoords = coords;

		const data = {
			...addItem,
			coords,
		};
		const markerData = createImageMarker({
			data,
			onClick: event => onClick({
				event,
				item: data,
				map: mapRefData.current.mapData.map,
			}),
			onDragEnd: newCoords => {
				setState(prev => ({
					...prev,
					debug: {
						...prev.debug,
						[addItem.name]: {
							...prev.debug[addItem.name],
							coords: [
								numberToDec(newCoords[0]),
								numberToDec(newCoords[1]),
							],
						},
					},
				}));
			},
		});

		markerData.marker.setDraggable(true);
		markerData.showNameCaption(true);
		markerData.addToMap(mapRefData.current.mapData.map, mapRefData.current.mapData.popupData);
		mapRefData.current.markers.push({
			...markerData,
			coords,
			data,
		});
		setState(prev => ({
			...prev,
			addToLastPosition,
			savePosition,
			leftControlsActive: prev.leftControlsActive.filter(item => item !== "add"),
			debug: {
				...prev.debug,
				[addItem.name]: {
					coords: [
						numberToDec(coords[0]),
						numberToDec(coords[1]),
					],
					...date
						? {
							date,
						}
						: {},
				},
			},
		}));
	}

	function onAddMarkersOpenPhoto(marker: IMapTodoItem) {
		onClick({
			map: mapRefData.current.mapData.map,
			item: {
				coords: marker.coords,
				name: marker.name,
				src: marker.src,
				captionTitle: marker.captionTitle,
				popupTitle: marker.popupTitle,
				date: marker.date,
				title: marker.title,
			},
		});
	}

	function getTodoMarkers() {
		return todoItems.filter(todoItem => mapRefData.current.markers.filter(item => item.data.name === todoItem.name).length === 0);
	}

	async function showRoute(routeMarkers: Array<IRouteMarker>, routeType: TRouteTypes) {
		if (!mapRefData.current.mapData) {
			return;
		}

		const routeJSON = await getRouteJSON(routeMarkers, routeType, true);
		const routeGeometry = coordsToString(routeJSON.geometry.coordinates as Array<TCoords>);
		const routeDist = getDistanceWGS84(routeJSON.geometry.coordinates as Array<TCoords>);

		// @ts-ignore
		mapRefData.current.mapData.routeSource.setData(routeJSON);
		updateStateTracks({
			routeGeometry,
			routeDist,
			geomCount: routeJSON.geometry.coordinates.length,
		});
	}

	function setTracksGeoJson(newGeoJSON?: IMyGeoJSON) {
		const geoJSON = newGeoJSON || getGeoJson();

		// @ts-ignore
		mapRefData.current.mapData.tracksSource.setData(geoJSON);
	}

	function setTracks(mapTracks: Array<ITrackItem>, zoom = false, savedTracks: ISavedTrackItem[] = []) {
		const trackMarkers: Array<maplibregl.Marker> = [];
		let allTracksData: Array<ITrackItem> = [];

		// mame ulozene?
		if (savedTracks.length > 0) {
			for (const savedTrack of savedTracks) {
				allTracksData.push({
					bbox: [[0, 0], [0, 0]],
					date: "",
					distances: {
						all: 0,
						bike: 0,
						boat: 0,
						cable: 0,
						car: 0,
						pubt: 0,
						walk: 0,
					},
					geometries: [{
						...savedTrack,
						distance: 0,
					}],
					desc: "",
				});
			}
		}

		allTracksData = allTracksData.concat(mapTracks);

		const tracksData = createTracks(allTracksData);

		state.trackMarkers.forEach(marker => marker.remove());
		// @ts-ignore
		setTracksGeoJson(tracksData.geoJSON);
		tracksData.markers.forEach(marker => {
			marker.addToMap(mapRefData.current.mapData.map);
			trackMarkers.push(marker.marker);
		});

		// nemame itemy? Zoom na prvni track
		if (zoom && mapTracks.length) {
			showTrack(mapTracks[0]);
		}

		updateState({
			trackMarkers,
		});
	}

	function setDate({
		date = "",
		zoomTrack = null,
	}: {
		date?: string;
		zoomTrack?: ITrackItem;
	}) {
		const coords = [];
		const selDate = days.filter(day => day.date === date);
		const filteredTracks = selDate.length
			? selDate[0].tracks || []
			: [];
		let mapBounds: maplibregl.LngLatBounds = null;

		mapRefData.current.markers.forEach(marker => {
			if (!date || marker.data.date === date) {
				marker.marker.addTo(mapRefData.current.mapData.map);
				coords.push(marker.data.coords);
			} else {
				marker.marker.remove();
			}
		});

		// vse
		if (!date) {
			for (const day of days) {
				if (day.tracks) {
					for (const track of day.tracks) {
						filteredTracks.push(track);
					}
				}
			}
		}

		setTracks(filteredTracks);

		if (zoomTrack) {
			showTrack(zoomTrack);
		} else if (filteredTracks.length) {
			// tracky
			for (const track of filteredTracks) {
				if (mapBounds) {
					mapBounds.extend(track.bbox);
				} else {
					mapBounds = new maplibregl.LngLatBounds(track.bbox);
				}
			}
		} else {
			// markery
			mapBounds = coordsToBounds(coords);
		}

		if (mapBounds) {
			mapRefData.current.mapData.map.fitBounds(mapBounds, {
				padding: BESTFIT_PADDING,
				duration: 0,
			});
		}

		// update stavu
		updateState({
			activeDate: date,
		});
	}

	function onSearchClose() {
		updateState({
			query: "",
			searchItems: null,
		});
		setDate({});
		selectMarker("");
	}

	function onHeightProfileClick(trackItem: ITrackItem) {
		const allGeometries: Array<string> = [];

		for (const geometry of trackItem.geometries) {
			allGeometries.push(geometry.geometry);
		}

		updateState({
			trackGeometry: mergeGeometries(allGeometries),
		});
	}

	function onTrackClick(date: string, track: ITrackItem) {
		if (date === state.activeDate || !date) {
			showTrack(track);
		} else {
			setDate({
				date,
				zoomTrack: track,
			});
		}
	}

	function createDetailMarker(coords: TCoords, zoom = 0) {
		const map = mapRefData.current.mapData.map;
		const detailMarker = createTextMarker({
			text: "X",
			coords,
		});

		detailMarker.addToMap(map);

		if (zoom > 0) {
			map.setZoom(zoom);
		}

		map.setCenter(coords as maplibregl.LngLatLike);
		mapRefData.current.detailMarker = detailMarker.marker;
	}

	function onSetCoordsSave(coords: TCoords, zoom: number) {
		createDetailMarker(coords, zoom);
		updateState({
			showSetCoordsDialog: false,
		});
	}

	function clearControls(origItems: TMapControlsLeftContent[], activeContent: TMapControlsLeftContent) {
		const toRemove: TMapControlsLeftContent[] = ["days", "searchApi", "add", "debug"];

		for (const removeItem of toRemove) {
			if (removeItem === activeContent) {
				continue;
			}

			const ind = origItems.indexOf(removeItem);

			if (ind !== -1) {
				origItems.splice(ind, 1);
			}
		}
	}

	function controlsLeftClick(type: TMapControlsLeftContent) {
		const leftControlsActive = state.leftControlsActive.slice();
		const ind = leftControlsActive.indexOf(type);
		const newState: Partial<IState> = {};
		let isEnabled = false;

		if (ind === -1) {
			leftControlsActive.push(type);
			isEnabled = true;
		} else {
			leftControlsActive.splice(ind, 1);
		}

		switch (type) {
			case "captions":
				mapRefData.current.markers.forEach(markerItem => markerItem.showCaption(isEnabled));
				mapRefData.current.showMarkerDates = isEnabled;
				break;

			case "debug": {
				mapRefData.current.markers.forEach(markerItem => {
					markerItem.marker.setDraggable(isEnabled);
					markerItem.showNameCaption(isEnabled);
				});
				clearControls(leftControlsActive, type);
				break;
			}

			case "ophoto":
				mapRefData.current.mapData.showOphoto(isEnabled);
				break;

			case "retina":
				savedStore.getState().setRetina(isEnabled);
				location.reload();
				break;

			case "days": {
				clearControls(leftControlsActive, type);
				break;
			}

			case "gps": {
				if (isEnabled) {
					if (!mapRefData.current.gpsMarker) {
						const map = mapRefData.current.mapData.map;
						const center = mapRefData.current.mapData.map.getCenter();
						const gpsMarker = createTextMarker({
							text: "⌖",
							coords: center,
							className: "gps-marker",
						});

						gpsMarker.addToMap(map);
						map.setCenter(center);
						mapRefData.current.gpsMarker = gpsMarker.marker;

						let mapZoom = true;

						// geo lokace
						mapRefData.current.gpsWatchId = navigator.geolocation.watchPosition(location => {
							const coords = new maplibregl.LngLat(location.coords.longitude, location.coords.latitude);

							mapRefData.current.gpsMarker.setLngLat(coords);
							mapZoom && map.setCenter(coords);

							if (mapZoom) {
								mapZoom = false;
							}
						}, error => {
							/* eslint-disable-next-line */
							console.log(error);
						});
					}
				} else if (mapRefData.current.gpsMarker) {
					mapRefData.current.gpsMarker?.remove();
					mapRefData.current.gpsMarker = null;
					navigator.geolocation.clearWatch(mapRefData.current.gpsWatchId);
					mapRefData.current.gpsWatchId = 0;
				}

				break;
			}

			case "searchApi":
				clearControls(leftControlsActive, type);
				break;

			case "add":
				clearControls(leftControlsActive, type);
				break;

			default:
		}

		newState.leftControlsActive = leftControlsActive;
		updateState(newState);
	}

	function onSearch(query: string) {
		let searchItems: null | Array<ISearchItem> = null;

		if (query.length > 0) {
			const lwSearch = query.toLowerCase();
			const filtered = items.filter(item => item.title.toLowerCase().indexOf(lwSearch) !== -1);

			if (filtered.length) {
				searchItems = filtered.map(item => ({
					name: item.name,
					title: item.title,
					coords: item.coords,
				}));
			}
		}

		updateState({
			query,
			searchItems,
		});
	}

	function controlsRightClick(type: TMapControlsRightContent) {
		switch (type) {
			case "close":
				onClose();
				break;

			case "fullscreen":
				toggleState("fullscreen");
				break;

			case "zoom":
				zoomToFeatures();
				selectMarkerByName("");
				break;

			default:
		}
	}

	function onDebugClick(type: TMapDebugType) {
		switch (type) {
			case "clearAllRoute":
				state.routeMarkers.forEach(item => item.marker.remove());
				updateState({
					routeMarkers: [],
					markerInd: 1,
				});
				break;

			case "clearAllTracks":
				setTracks([]);
				break;

			case "clearRouteNotLast": {
				const routeMarkers = state.routeMarkers.slice(state.routeMarkers.length - 1);

				state.routeMarkers.slice(0, state.routeMarkers.length - 1).forEach(item => item.marker.remove());
				updateState({
					routeMarkers,
				});
				break;
			}

			case "debug":
				copyDebug([
					...stateTracks.savedTracks,
					...stateTracks.routeGeometry
						? [{
							geometry: stateTracks.routeGeometry,
							type: stateTracks.trackType,
						}]
						: [],
				], state.debug);
				break;

			case "saveTrack": {
				const savedTracks = stateTracks.savedTracks.slice();

				savedTracks.push({
					geometry: stateTracks.routeGeometry,
					type: stateTracks.trackType,
				});
				updateStateTracks({
					savedTracks,
					geomCount: 0,
					routeDist: 0,
					routeGeometry: "",
				});
				// smazeme
				state.routeMarkers.forEach(item => item.marker.remove());
				updateState({
					routeMarkers: [],
					markerInd: 1,
				});
				setTracks([], false, savedTracks);
				break;
			}

			default:
		}
	}

	function updateRouteMarkersCoord(id: string, newCoords: TCoords) {
		setState(prev => {
			const ind = prev.routeMarkers.findIndex(item => item.id === id);
			const routeMarkers = prev.routeMarkers.slice();

			routeMarkers[ind] = {
				...routeMarkers[ind],
				coords: newCoords,
			};

			return {
				...prev,
				routeMarkers,
			};
		});
	}

	function createRouteMarkers(arrayCoords: Array<TCoords>, type: IRouteMarker["type"], curMarkerInd: number) {
		const newMarkers: Array<IRouteMarker> = [];
		let markerInd = curMarkerInd;

		for (const coords of arrayCoords) {
			const id = getRandomHexHash();
			const markerData = createTextMarker({
				coords,
				text: markerInd.toString(),
				onClick: event => {
					// pro mazani
					if (event.altKey) {
						removeRouteById(id);
					}
				},
				onDragEnd: newCoords => {
					updateRouteMarkersCoord(id, newCoords);
				},
			});

			markerData.addToMap(mapRefData.current.mapData.map);
			markerData.marker.setDraggable(true);
			newMarkers.push({
				id,
				ind: markerInd,
				marker: markerData.marker,
				coords,
				type,
			});

			markerInd++;
		}

		return {
			markerInd,
			newMarkers,
		};
	}

	function addRouteMarkers(arrayCoords: Array<TCoords>, type: IRouteMarker["type"]) {
		setState(prev => {
			const routeMarkersData = createRouteMarkers(arrayCoords, type, prev.markerInd);
			const newMarkers: Array<IRouteMarker> = routeMarkersData.newMarkers;
			const markerInd = routeMarkersData.markerInd;
			const leftControlsActive = prev.leftControlsActive.slice();
			const daysInd = leftControlsActive.indexOf("days");

			if (daysInd !== -1) {
				leftControlsActive.splice(daysInd, 1);
			}

			// otevreme debug
			if (!leftControlsActive.includes("debug")) {
				leftControlsActive.push("debug");
			}

			return {
				...prev,
				leftControlsActive,
				markerInd,
				routeMarkers: [
					...prev.routeMarkers,
					...newMarkers,
				],
			};
		});
	}

	function createGeoJsons() {
		if (!mapRefData.current.mapData) {
			return;
		}

		mapRefData.current.mapData.addGeoJsons(geoJsons);
	}

	function createCustomTracks() {
		if (!mapRefData.current.mapData || !customTracks) {
			return;
		}

		// nastavime tracky
		setTracks(customTracks);
	}

	function createMarkers() {
		mapRefData.current.markers.forEach(marker => marker.marker.remove());

		if (!mapRefData.current.mapData) {
			return;
		}

		const markers = items.slice().reverse();

		mapRefData.current.markers = markers.map(item => {
			/* eslint-disable no-magic-numbers */
			const markerData = createImageMarker({
				data: item,
				active: item.name === selectItem,
				onClick: event => {
					onClick({
						event,
						item,
						map: mapRefData.current.mapData.map,
					});
				},
				onPopupAdd: popup => {
					popup.addTo(mapRefData.current.mapData.map);
				},
				onDragEnd: coords => {
					setState(prevDrag => ({
						...prevDrag,
						debug: {
							...prevDrag.debug,
							[item.name]: {
								coords: [
									numberToDec(coords[0]),
									numberToDec(coords[1]),
								],
							},
						},
					}));
				},
			});

			markerData.addToMap(mapRefData.current.mapData.map, mapRefData.current.mapData.popupData);
			mapRefData.current.showMarkerDates && markerData.showCaption(true);

			return {
				data: item,
				coords: item.coords,
				...markerData,
			};
		});

		// nejdrive zoom -> pak markery
		if (selectItem === "") {
			zoomToFeatures();
		} else {
			const markerDate = getMarkerDate(selectItem);

			if (markerDate !== null) {
				setDate({
					date: markerDate,
				});
			}

			selectMarker(selectItem);
		}
	}

	function onGlobalTypeChange(routeGlobalType: TRouteItemType) {
		updateStateTracks({
			routeGlobalType,
		});
		mapRefData.current.routeGlobalType = routeGlobalType;
	}

	function onSetTracksSave(geometry: string) {
		const allFeatures = getGeoJsonFromSource(mapRefData.current.mapData.tracksSource);
		const coordinates = stringToCoords(geometry);
		const feature = getLineStringGeoJson(coordinates, {
			color: getTrackColorByType("cable"),
		});
		const zoomBounds = coordsToBounds(coordinates);

		// @ts-ignore
		allFeatures.features.push(feature);
		setTracksGeoJson(allFeatures);
		mapRefData.current.mapData.map.fitBounds(zoomBounds, {
			padding: BESTFIT_PADDING,
			duration: 0,
		});
		updateState({
			showSetTracksDialog: false,
		});
	}

	function onSimplifyTracksSave(track: ITrackItem, geometryFull: IDataTrackGeometryFull, coordinates: Array<TCoords>) {
		const geoJson = getGeoJson();
		const origCoordinates = stringToCoords(geometryFull.geometry);
		const origFeature = getLineStringGeoJson(origCoordinates, {
			color: getTrackColorByType("boat"),
		});
		const newFeature = getLineStringGeoJson(coordinates, {
			color: getTrackColorByType("car"),
		});
		const zoomBounds = coordsToBounds(coordinates);

		//console.log([track.desc, origCoordinates.length, coordinates.length]);
		geoJson.features.push(origFeature);
		geoJson.features.push(newFeature);
		setTracksGeoJson(geoJson);

		mapRefData.current.mapData.map.fitBounds(zoomBounds, {
			padding: BESTFIT_PADDING,
			duration: 0,
		});
		updateState({
			showSimplifyTracksDialog: false,
		});
		copyToClipboard(`"${coordsToString(coordinates)}",`);
	}

	function editTrack(geometry: string) {
		const coordinates = stringToCoords(geometry);

		// smazeme soucasne tracky
		setTracksGeoJson();
		mapRefData.current.routeGlobalType = "user";
		addRouteMarkers(coordinates, "user");

		const zoomBounds = coordsToBounds(coordinates);

		mapRefData.current.mapData.map.fitBounds(zoomBounds, {
			padding: BESTFIT_PADDING,
			duration: 0,
		});
		updateState({
			editTracks: [],
		});
		updateStateTracks({
			routeGlobalType: "user",
		});
	}

	function addRouteOnId(id: string) {
		setState(prev => {
			const curRouteMarkers = prev.routeMarkers.slice();
			const ind = curRouteMarkers.findIndex(item => item.id === id);

			if (ind === curRouteMarkers.length - 1) {
				return prev;
			}

			const curMarker = curRouteMarkers[ind];
			const nextMarker = curRouteMarkers[ind + 1];
			const middleLon = (curMarker.coords[0] + nextMarker.coords[0]) / 2;
			const middleLat = (curMarker.coords[1] + nextMarker.coords[1]) / 2;
			const markersData = createRouteMarkers([[middleLon, middleLat]], stateTracks.routeGlobalType, prev.markerInd);
			const routeMarkers = prev.routeMarkers.slice();

			routeMarkers.splice(ind + 1, 0, ...markersData.newMarkers);

			return {
				...prev,
				routeMarkers,
				markerInd: markersData.markerInd,
			};
		});
	}

	function onTypeChange(type: IRouteMarker["type"], id: string) {
		const routeMarkers = state.routeMarkers.slice();

		routeMarkers.filter(item => item.id === id)[0].type = type;

		updateState({
			routeMarkers,
		});
	}

	function onContextMenu(event: maplibregl.MapMouseEvent) {
		const contextMenuData = createContextMenu();

		contextMenuData.node.append(
			createContextMenuItem(contextMenuData.popup, "Souřadnice", () => {
				const lon = numberToDec(event.lngLat.lng, 5);
				const lat = numberToDec(event.lngLat.lat, 5);

				copyToClipboard(`[${lon}, ${lat}]`);
			}),
			createContextMenuItem(contextMenuData.popup, "Jít na souřadnice", () => {
				updateState({
					showSetCoordsDialog: true,
				});
			}),
			...showDebug
				? [
					createContextMenuItem(contextMenuData.popup, "Nastavit střed", () => {
						const eventCoords = event.lngLat;

						mapRefData.current.mapData.map.setCenter(eventCoords);
					}),
					createContextMenuItem(contextMenuData.popup, "GeoJSON", () => {
						const data = getGeoJsonFromSource(mapRefData.current.mapData.tracksSource);
						let ind = 0;

						for (const track of parsedMapData.allTracks) {
							for (const geometry of track.geometries) {
								const feature = data.features[ind];

								feature.properties.stroke = feature.properties.color;
								feature.properties.date = track.date || "";
								feature.properties.distance = formatDistance(geometry.distance);
								feature.properties.desc = track.desc || "";
								feature.properties["stroke-width"] = 4;
								feature.properties["stroke-opacity"] = 0.8;
								delete feature.properties.color;
								ind++;
							}
						}

						items.forEach(item => {
							data.features.push(getPointGeoJson(item.coords, {
								"name": item.name,
								"title": item.popupTitle || "",
								"date": item.date || "",
								"marker-color": "#c01",
								"marker-size": "medium",
								"marker-symbol": "circle",
							}));
						});

						/* eslint-disable-next-line */
						console.log(data);
						copyToClipboard(JSON.stringify(data));
					}),
					createContextMenuItem(contextMenuData.popup, "Přidat stopu", () => {
						updateState({
							showSetTracksDialog: true,
						});
					}),
					createContextMenuItem(contextMenuData.popup, "Editovat stopu", () => {
						updateState({
							editTracks: parsedMapData.allTracks,
						});
					}),
					createContextMenuItem(contextMenuData.popup, "Zjednoduššit stopu", () => {
						updateState({
							showSimplifyTracksDialog: true,
						});
					}),
					createContextMenuItem(contextMenuData.popup, "Smazat stopy", () => {
						mapRefData.current.mapData.tracksSource.setData({
							type: "FeatureCollection",
							features: [],
						});
						setState(prev => {
							prev.trackMarkers.forEach(marker => marker.remove());

							return {
								...prev,
								trackMarkers: [],
							};
						});
					}),
				]
				: [],
			createContextMenuItem(contextMenuData.popup, "Mapy.cz - souřadnice", () => {
				const lon = numberToDec(event.lngLat.lng, 5);
				const lat = numberToDec(event.lngLat.lat, 5);

				window.open(getMapyczUrl(lon, lat, [
					{ name: "source", value: "coor" },
					{ name: "id", value: `${lon},${lat}` },
				]));
			}),
			createContextMenuItem(contextMenuData.popup, "Mapy.cz - panorama", () => {
				const lon = numberToDec(event.lngLat.lng, 5);
				const lat = numberToDec(event.lngLat.lat, 5);

				window.open(getMapyczUrl(lon, lat, [
					{ name: "pano", value: "1" },
					{ name: "newest", value: "1" },
					{ name: "pid", value: "0" },
					{ name: "ovl", value: "8" },
				]));
			}),
			createContextMenuItem(contextMenuData.popup, "Google maps - souřadnice", () => {
				const lon = numberToDec(event.lngLat.lng, 5);
				const lat = numberToDec(event.lngLat.lat, 5);
				const link = `https://www.google.com/maps/search/?api=1&query=${lat}%2C${lon}`;

				window.open(link);
			}),
			...hasClose
				? [createContextMenuItem(contextMenuData.popup, "Zavřít mapu", onClose)]
				: [],
		);
		contextMenuData.addToMap(mapRefData.current.mapData.map, event.lngLat);
	}

	useEffect(() => {
		const markerDate = getMarkerDate(selectItem);

		if (markerDate !== null) {
			setDate({
				date: markerDate,
			});
		}

		selectMarker(selectItem);
	}, [selectItem]);

	useEffect(() => {
		showRoute(state.routeMarkers, stateTracks.routeType);
	}, [state.routeMarkers, stateTracks.routeType]);

	useEffect(() => {
		createMarkers();
	}, [items]);

	useEffect(() => {
		createGeoJsons();
	}, [geoJsons]);

	useEffect(() => {
		createCustomTracks();
	}, [customTracks]);

	const mapData = useMap({
		containerRef: mapRef,
		x: position.x,
		y: position.y,
		z: position.z,
		useRetina: savedData.useRetina,
		onMapClick: ({ event, coords }) => {
			selectMarkerByName("");
			mapRefData.current.detailMarker?.remove();
			mapRefData.current.detailMarker = null;

			if (event.originalEvent.ctrlKey || event.originalEvent.metaKey) {
				addRouteMarkers([coords], mapRefData.current.routeGlobalType);
			}
		},
		onPoiClick: data => {
			openMapyczDetail(data);
		},
		onContextMenu,
	});

	useEffect(() => {
		if (mapData.map && !mapRefData.current.mapData) {
			mapRefData.current.mapData = mapData;

			onLoad(mapData);
			createMarkers();
			createGeoJsons();
			createCustomTracks();

			// tracky
			if (parsedMapData.allTracks.length > 0) {
				setTracks(parsedMapData.allTracks, items.length === 0);
			}

			if (showDetailMarker) {
				const center = mapData.map.getCenter();

				createDetailMarker([center.lng, center.lat]);
			}
		}
	}, [mapData]);

	const todoMarkers = getTodoMarkers();
	const debugIsActive = state.leftControlsActive.includes("debug");
	const daysIsActive = state.leftControlsActive.includes("days");
	const addMarkersIsActive = state.leftControlsActive.includes("add");
	const showSearchApi = state.leftControlsActive.includes("searchApi");

	return <div className={getClassName(["mapContainer", state.fullscreen ? "fullscreen" : ""])}>
		<MapControlsLeft active={state.leftControlsActive} search={showSearch} searchApi={!showSearch} days={days.length > 0 && !hideDays} captions={!showSearch && items.length > 0} debug={showDebug} add={showDebug && todoMarkers.length > 0}
			onClick={controlsLeftClick} onSearch={onSearch} query={state.query} />
		{ daysIsActive && <MapDays days={parsedMapData.datesItems} activeDate={state.activeDate} onDate={date => setDate({ date: date })} onHeightProfileClick={onHeightProfileClick} onTrackClick={onTrackClick}
			onTrackInfoClick={infoTrack => updateState({ infoTrack })} /> }
		{ addMarkersIsActive && <MapAddMarkers date={state.activeDate} addToLastPosition={state.addToLastPosition} savePosition={state.savePosition} mapDate={mapDate} items={todoMarkers} onAddMarker={addMarker}
			onOpenPhoto={onAddMarkersOpenPhoto} /> }
		<MapControlsRight zoom={items.length > 0} close={hasClose} onClick={controlsRightClick} />
		{ state.searchItems !== null && <MapSearch items={state.searchItems} onClick={onSearchClick} onClose={onSearchClose} /> }
		{ debugIsActive && <MapDebug debugCount={Object.keys(state.debug).length} geomCount={stateTracks.geomCount} distance={stateTracks.routeDist} items={state.routeMarkers} type={stateTracks.routeGlobalType} onClick={onDebugClick}
			onGlobalTypeChange={onGlobalTypeChange} onRemove={removeRouteById} onRouteType={routeType => updateStateTracks({ routeType })} routeType={stateTracks.routeType} onAdd={addRouteOnId} onTypeChange={onTypeChange}
			trackType={stateTracks.trackType} onTrackTypeChange={trackType => updateStateTracks({ trackType })} tracksCount={stateTracks.savedTracks.length} /> }
		{ state.trackGeometry && <HeightProfileDialog trackGeometry={state.trackGeometry} onClose={() => updateState({ trackGeometry: "" })} mapData={mapRefData.current.mapData} /> }
		{ state.showSetCoordsDialog && <SetCoordsDialog onSave={onSetCoordsSave} onClose={() => updateState({ showSetCoordsDialog: false })} showLocations={showDebug} /> }
		{ state.showSetTracksDialog && <SetTracksDialog onSave={onSetTracksSave} onClose={() => updateState({ showSetTracksDialog: false })} /> }
		{ state.showSimplifyTracksDialog && <SimplifyTrackDialog tracks={parsedMapData.allTracks} onSave={onSimplifyTracksSave} onClose={() => updateState({ showSimplifyTracksDialog: false })} /> }
		{ state.editTracks.length > 0 && <SelectTrackDialog tracks={state.editTracks} onSave={editTrack} onClose={() => updateState({ editTracks: [] })} /> }
		{ state.infoTrack && <TrackInfoDialog track={state.infoTrack} onClose={() => updateState({ infoTrack: null })} /> }
		{ showSearchApi && <MapSearchApi onCoords={createDetailMarker} /> }
		<div className="mapContainer__map" ref={mapRef} />
	</div>;
}
