import { ReactNode, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

import YearGalleryImageItem from "~/components/YearGalleryImageItem";
import { IGallery, IGalleryItem, TCoords } from "~/interfaces";
import MyGallery from "~/my/MyGallery";
import Map, { IOnClickData } from "~/components/Map";
import { myUseState } from "~/hooks/myUseState";
import { copyToClipboard, downloadImage, getCalendarDateLink, getClassName, getDateWithHours, getEventTitle, getFullDesc, getMapPositionFromYearGallery, getShortMonthDayDate, imgToArrayBuffer, parseCoords } from "~/utils/utils";
import { DAYJS_FORMAT } from "~/const";
import GalleryHeader from "~/components/GalleryHeader";
import YearGalleryVideoItem from "../YearGalleryVideoItem";
import MyButton from "~/my/MyButton";
import TagsGallery from "../TagsGallery";
import Loader from "../Loader";
import Description from "../Description";
import GalleryDay from "../GalleryDay";
import { getMapDays } from "~/utils/map";
import { IMapItem } from "~/map-interfaces";
import MyInputNumber from "~/my/MyInputNumber";
import VideoPlaylist from "../VideoPlaylist";
import SelectLocation from "../SelectLocation";

import "./style.less";

interface IState {
	hasMap: boolean;
	showMap: boolean;
	selectItem: string;
	isDownloadActive: boolean;
	selectPhoto: boolean;
	selectedPhotos: Array<string>;
	loading: boolean;
	mapItems: IMapItem[];
	photosRange: Array<number>;
	photosAttr: Array<string>;
	videos: Array<IGalleryItem>;
}

interface IYearGallery {
	gallery: IGallery;
	onClick?: (image: IGalleryItem) => void;
	onBack?: () => void;
	title?: string;
	noEventAndBack?: boolean;
	downloadMode?: boolean;
	hideDesc?: boolean;
	showDebug?: boolean;
	calendarDate?: string;
	beforeGallery?: ReactNode;
}

export default function YearGallery({
	gallery,
	onClick = () => {},
	onBack = () => {},
	title = "",
	noEventAndBack = false,
	downloadMode = false,
	hideDesc = false,
	showDebug = false,
	calendarDate = "",
	beforeGallery,
}: IYearGallery) {
	const navigate = useNavigate();
	const mapTitleRef = useRef<HTMLHeadingElement>(null);
	const mapTodoItems: Array<IMapItem> = gallery.items.filter(item => !item.coords || !item.date).map(item => ({
		name: item.name,
		src: item.isVideo
			? item.preview
			: item.src,
		coords: item.coords,
		date: item.date || "",
		...item.date
			? {
				captionTitle: getShortMonthDayDate(item.date),
			}
			: {},
	}));
	const { state, setState, updateState } = myUseState<IState>(() => ({
		hasMap: false,
		showMap: false,
		selectItem: "",
		isDownloadActive: false,
		selectPhoto: false,
		selectedPhotos: [],
		loading: false,
		mapItems: [],
		photosRange: [1, gallery.items.length],
		photosAttr: ["customDayName", `"hotel"`],
		videos: [],
	}));
	const galleryTitle = getEventTitle(gallery.event, title, true);
	const calendarLink = gallery.event.seo && !noEventAndBack
		? getCalendarDateLink(gallery.event.date)
		: "";
	const videoItems = gallery.items.filter(item => item.isVideo);

	function showMapClick() {
		const showMap = !state.showMap;

		updateState({
			showMap,
			...showMap
				? {}
				: {
					selectItem: "",
				},
		});
		mapTitleRef.current?.scrollIntoView();
	}

	function goToCalendar() {
		navigate(calendarLink);
	}

	function setDownloadMode() {
		const isDownloadActive = !state.isDownloadActive;

		updateState({
			isDownloadActive,
		});
	}

	function setSelectMode() {
		const selectPhoto = !state.selectPhoto;

		updateState({
			selectPhoto,
			...selectPhoto
				? {}
				: {
					selectedPhotos: [],
				},
		});
	}

	async function dowloadAll() {
		const { default: JSZip } = await import(/* webpackChunkName: "jszip" */"jszip");
		const zip = new JSZip();
		const zipFolder = zip.folder(gallery.event.seo);
		const allPromises = [];

		updateState({
			loading: true,
		});
		gallery.items.forEach(item => {
			if (!item.isVideo) {
				allPromises.push(new Promise(resolve => {
					imgToArrayBuffer(item.largeSrc).then(imgData => {
						zipFolder.file(item.name, imgData);
						resolve({});
					}, () => resolve({}));
				}));
			}
		});

		await Promise.all(allPromises);

		const zipData = await zip.generateAsync({ type: "blob" });
		const { default: saveAs } = await import("file-saver");

		saveAs(zipData, `${gallery.event.seo}.zip`);
		updateState({
			loading: false,
		});
	}

	function openCalendar() {
		navigate(getCalendarDateLink(calendarDate));
	}

	function openVideos() {
		updateState({
			videos: videoItems,
		});
	}

	const galleryBtns = [
		...state.hasMap || showDebug
			? [<MyButton text={state.showMap ? "Skrýt mapu" : state.hasMap ? "Zobrazit mapu" : "Prázdná mapa"} onClick={showMapClick} variant="filled" key="key01" />]
			: [],
		...videoItems.length > 0
			? [<MyButton text={<PlayCircleIcon />} onClick={openVideos} className="yearGallery__playBtn" variant="filled" key="key07" />]
			: [],
		...calendarLink
			? [<MyButton text="Kalendář" onClick={goToCalendar} variant="filled" key="key02" />]
			: [],
		...downloadMode
			? [
				<MyButton text={state.isDownloadActive ? "Zrušit stahování" : "Stahovat fotky"} onClick={setDownloadMode} variant="filled" key="key03" />,
				<MyButton text="Stáhnout vše" onClick={dowloadAll} variant="filled" key="key05" />,
			]
			: [],
		...showDebug
			? [<MyButton text={state.selectPhoto ? "Zrušit výběr" : "Vybrat fotky"} onClick={setSelectMode} variant="filled" key="key04" />]
			: [],
		...calendarDate === ""
			? []
			: [<MyButton text="Kalendář" onClick={openCalendar} variant="filled" key="key05" />],
	];

	function onItemClick(data: IGalleryItem) {
		if (state.isDownloadActive) {
			downloadImage(data.largeSrc, `${gallery.event.seo}-${data.name}`);
		} else if (state.selectPhoto) {
			const selectedPhotos = state.selectedPhotos.slice();
			const ind = selectedPhotos.indexOf(data.name);

			if (ind === -1) {
				selectedPhotos.push(data.name);
			} else {
				selectedPhotos.splice(ind, 1);
			}

			selectedPhotos.sort((aItem, bItem) => aItem.localeCompare(bItem));

			updateState({
				selectedPhotos,
			});
		} else if (!data.isVideo) {
			onClick(data);
		}
	}

	function onMapClick(data: IGalleryItem) {
		setState(prev => ({
			...prev,
			showMap: true,
			selectItem: data.name,
		}));
		setTimeout(() => {
			mapTitleRef.current?.scrollIntoView();
		}, 0);
	}

	function onMapClose() {
		setState(prev => ({
			...prev,
			showMap: false,
		}));
	}

	function onMapItemClick({ item: mapItem }: IOnClickData) {
		const filtered = gallery.items.filter(item => item.name === mapItem.name)[0];

		filtered && onClick(filtered);
	}

	function getHideDays() {
		return gallery.type === "month-item" || gallery.type === "reverse-by-date";
	}

	function clearSelectedPhotos() {
		updateState({
			selectedPhotos: [],
		});
	}

	function copySelectedPhotos(selectedPhotos: IState["selectedPhotos"] = state.selectedPhotos) {
		const items: Array<string> = [];
		const attrName = state.photosAttr[0];
		let attrValue = state.photosAttr[1];

		// zkusime OLC
		if (attrName === "coords" && attrValue.indexOf("[") === -1) {
			const parsed = parseCoords(attrValue);

			attrValue = `[${parsed.coords[0]}, ${parsed.coords[1]}]`;
		}

		items.push(`items: {`);

		for (const photo of selectedPhotos) {
			items.push(`\t"${photo}": {`);
			items.push(`\t\t${attrName}: ${attrValue},`);
			items.push(`\t},`);
		}

		items.push(`},`);
		copyToClipboard(`${items.join("\n")}`);
	}

	function getVideoData(item: IGalleryItem): IGalleryItem {
		return {
			...item,
			subTitle: item.date
				? getFullDesc(item.date)
				: "",
		};
	}

	function updateRange(newValue: number, isMax = false) {
		const photosRange = state.photosRange.slice();

		if (isMax) {
			photosRange[1] = newValue;
		} else {
			photosRange[0] = newValue;
		}

		updateState({
			photosRange,
		});
	}

	function updateAttr(newValue: string, isValue = false) {
		const photosAttr = state.photosAttr.slice();

		if (isValue) {
			photosAttr[1] = newValue;
		} else {
			photosAttr[0] = newValue;
		}

		updateState({
			photosAttr,
		});
	}

	function setCoords() {
		updateState({
			photosAttr: ["coords", ``],
		});
	}

	function setRange() {
		const photosRange = state.photosRange;

		if (photosRange[1] >= photosRange[0] && photosRange[0] > 0) {
			const selectedPhotos = gallery.items.filter(item => {
				const ind = parseFloat(item.name);

				return ind >= photosRange[0] && ind <= photosRange[1];
			}).map(item => item.name);

			updateState({
				selectedPhotos,
			});
			copySelectedPhotos(selectedPhotos);
		}
	}

	function onSelectLocation(coords: TCoords) {
		updateState({
			photosAttr: ["coords", `[${coords[0]}, ${coords[1]}]`],
		});
	}

	useEffect(() => {
		setState(prev => {
			const mapItems: IMapItem[] = gallery.items.filter(item => item.coords).map(item => ({
				name: item.name,
				src: item.isVideo
					? item.preview
					: item.src,
				coords: item.coords,
				isVideo: item.isVideo,
				...item.date
					? {
						date: dayjs(item.date).format(DAYJS_FORMAT),
						// prehled do mapy vcetne casu
						captionTitle: getDateWithHours(item.date),
					}
					: {},
			}));

			return {
				...prev,
				hasMap: mapItems.length > 0 || gallery.event.allTracks.length > 0,
				showMap: false,
				mapItems,
			};
		});

		const usp = new URLSearchParams(location.search);

		if (usp.get("map") === "1") {
			showMapClick();
		}
	}, [gallery]);

	/* eslint-disable react/no-array-index-key */
	return <div className="yearGalleryCont" key={gallery.event.seo}>
		<GalleryHeader title={galleryTitle} first={true} onBack={noEventAndBack ? null : onBack} buttons={galleryBtns} />
		{ (!hideDesc && !("days" in gallery.event)) && <Description event={gallery.event} /> }
		<div className="yearGallery__mapJump" ref={mapTitleRef} />
		{ state.selectPhoto && <div className="yearGallery__selectedPhotos">
			<div className="yearGallery__selectedPhotosList">
				Vybrané fotky: <strong>{ state.selectedPhotos.join(", ")}{ state.selectedPhotos.length === 0 ? "nic" : null }</strong>
			</div>
			{ showDebug && <>
				<div className="yearGallery__selectedRange">
					Od: <MyInputNumber value={state.photosRange[0]} onChange={newRange => updateRange(newRange)} />
					Do: <MyInputNumber value={state.photosRange[1]} onChange={newRange => updateRange(newRange, true)} />
					<MyButton text="Nastavit" variant="filled" onClick={setRange} />
				</div>
				<SelectLocation className="yearGallery__selectLocation" onSelect={onSelectLocation} />
			</> }
			<div className="yearGallery__selectedPhotosBtnArea">
				<div className="yearGallery__selectedPhotosBtnAreaLeft">
					<MyButton text="Smazat výběr" onClick={clearSelectedPhotos} variant="filled" />
					Atr. <input type="text" value={state.photosAttr[0]} onChange={event => updateAttr(event.target.value)} className="yearGallery__textInput" />
					Hod.: <input type="text" value={state.photosAttr[1]} onChange={event => updateAttr(event.target.value, true)} className="yearGallery__textInput" />
					<MyButton text="Coords" variant="filled" onClick={setCoords} />
				</div>
				<MyButton text="Zkopírovat fotky" onClick={copySelectedPhotos} variant="filled" />
			</div>
		</div> }
		{ beforeGallery }
		<div className={getClassName(["yearGallery", state.showMap ? "map-open" : ""])}>
			{ state.showMap && <Map items={state.mapItems} position={getMapPositionFromYearGallery(gallery)} onClick={onMapItemClick} selectItem={state.selectItem} onClose={onMapClose} showDebug={showDebug} days={getMapDays(gallery.event)}
				hideDays={getHideDays()} todoItems={mapTodoItems} mapDate={gallery.event.date} /> }
			{ !("days" in gallery.event) && <MyGallery>
				{gallery.items.map(item => item.isVideo
					? <YearGalleryVideoItem key={item.name} data={getVideoData(item)} seo={gallery.event.seo} onClick={() => onItemClick(item)} selected={state.selectedPhotos.indexOf(item.name) !== -1}
						selectMode={state.selectPhoto} onMapClick={onMapClick} />
					: <YearGalleryImageItem key={item.name} data={item} gallery={gallery} onClick={() => onItemClick(item)} onMapClick={onMapClick} seo={gallery.event.seo}
						downloadMode={state.isDownloadActive} selectMode={state.selectPhoto} selected={state.selectedPhotos.indexOf(item.name) !== -1} />)}
			</MyGallery> }
			{ "days" in gallery.event && <div className="yearGallery__daysFormat">
				{ gallery.event.days.map(day => <GalleryDay key={`${day.date || ""}${day.type}${day.customName || ""}`} day={day} gallery={gallery} downloadMode={state.isDownloadActive} selectMode={state.selectPhoto}
					selected={state.selectedPhotos} onClick={onItemClick} onMapClick={onMapClick} />)}
			</div> }
		</div>
		<TagsGallery gallery={gallery} alwaysVisible={true} />
		{ state.loading && <Loader className="yearGallery__loading" /> }
		{ state.videos.length > 0 && <VideoPlaylist items={state.videos} onClose={() => updateState({ videos: [] })} /> }
	</div>;
}
