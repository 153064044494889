import CloseBtn from "../CloseBtn";
import { ITrackItem, TTrackType } from "~/interfaces";

import "./style.less";

interface IItem {
	ind: number;
	title: string;
	type: TTrackType;
	geometry: string;
}

interface ISelectTrackDialog {
	tracks: Array<ITrackItem>;
	onSave?: (geometry: string) => void;
	onClose?: () => void;
}

export default function SelectTrackDialog({
	tracks,
	onSave = () => {},
	onClose = () => {},
}: ISelectTrackDialog) {
	function getItems() {
		const items: Array<IItem> = [];
		let ind = 0;

		for (const track of tracks) {
			const title = track.desc || "";

			for (const geometryItem of track.geometries) {
				items.push({
					ind,
					title,
					type: geometryItem.type,
					geometry: geometryItem.geometry,
				});
				ind++;
			}
		}

		return items;
	}

	const items = getItems();

	return <div className="selectTrackDialog map__dialog">
		<CloseBtn className="selectTrackDialog__btnClose" onClick={onClose} />
		<div className="selectTrackDialog__tracks">
			{ items.map(track => <ul key={track.ind} className="selectTrackDialog__tracksList">
				<li onClick={() => onSave(track.geometry)} className="selectTrackDialog__tracksItem">
					{ track.title } - { track.type }
				</li>
			</ul>) }
		</div>
	</div>;
}
