import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Page from "~/components/Page";
import { ROUTES } from "~/const";

import MainPage from "~/pages/MainPage";
import MessengerPage from "~/pages/MessengerPage";
import OverviewPage from "~/pages/OverviewPage";
import DancePage from "~/pages/DancePage";
import GalleryPage from "~/pages/GalleryPage";
import LoginPage from "~/pages/LoginPage";
import OthersPage from "~/pages/OthersPage";
import CalendarPage from "~/pages/CalendarPage";
import DetailPage from "~/pages/DetailPage";
import InThisDayPage from "~/pages/InThisDayPage";
import TracksPage from "./pages/TracksPage";
import WorldMapPage from "./pages/WorldMapPage";
import SearchPage from "./pages/SearchPage";
import UploadPage from "./pages/UploadPage";
import VisitsPage from "./pages/VisitsPage";
import SalaryPage from "./pages/SalaryPage";
import UploadedFilesPage from "./pages/UploadedFilesPage";
import StoryPage from "./pages/StoryPage";
import BirthdaysPage from "./pages/BirthdaysPage";
import SnakePage from "./pages/SnakePage";
import DataContentPage from "./pages/DataContentPage";
import PaymentPage from "./pages/PaymentPage";
import Lenka2024Page from "./pages/Lenka2024Page";
import DatabaseItemsPage from "./pages/DatabaseItemsPage";
import ImageTesterPage from "./pages/ImageTester";

import { setDefaultBackground } from "./utils/assets";
import { savedStoreInit } from "./stores/saved";

// css/less
import './index.less';

setDefaultBackground();
savedStoreInit("makuderovi");

const container = document.getElementById('app') || document.body;
const root = createRoot(container);

// render
root.render(
	<Router>
		<Routes>
			<Route element={<Page />}>
				<Route path={ROUTES.OVERVIEW} element={<OverviewPage />} />
				<Route path={ROUTES.MESSENGER} element={<MessengerPage />} />
				<Route path={ROUTES.DANCE} element={<DancePage />} />
				<Route path={ROUTES.GALLERY} element={<GalleryPage />} />
				<Route path={ROUTES.GALLERY_DETAIL} element={<GalleryPage />} />
				<Route path={ROUTES.LOGIN} element={<LoginPage />} />
				<Route path={ROUTES.OTHERS} element={<OthersPage />} />
				<Route path={ROUTES.CALENDAR} element={<CalendarPage />} />
				<Route path={ROUTES.TRACKS} element={<TracksPage />} />
				<Route path={ROUTES.WORLD_MAP} element={<WorldMapPage />} />
				<Route path={ROUTES.DETAIL} element={<DetailPage />} />
				<Route path={ROUTES.IN_THIS_DAY} element={<InThisDayPage />} />
				<Route path={ROUTES.SEARCH} element={<SearchPage />} />
				<Route path={ROUTES.UPLOAD} element={<UploadPage />} />
				<Route path={ROUTES.UPLOADED_FILES} element={<UploadedFilesPage />} />
				<Route path={ROUTES.VISITS} element={<VisitsPage />} />
				<Route path={ROUTES.SALARY} element={<SalaryPage />} />
				<Route path={ROUTES.STORY_DETAIL} element={<StoryPage />} />
				<Route path={ROUTES.STORY} element={<StoryPage />} />
				<Route path={ROUTES.BIRTHDAYS} element={<BirthdaysPage />} />
				<Route path={ROUTES.SNAKE} element={<SnakePage />} />
				<Route path={ROUTES.DATA_CONTENT} element={<DataContentPage />} />
				<Route path={ROUTES.PAYMENTS} element={<PaymentPage />} />
				<Route path={ROUTES.LENKA_2024} element={<Lenka2024Page />} />
				<Route path={ROUTES.DATABASE_ITEMS} element={<DatabaseItemsPage />} />
				<Route path={ROUTES.IMAGE_TESTER} element={<ImageTesterPage />} />
				<Route path="*" element={<MainPage />} />
			</Route>
		</Routes>
	</Router>,
);
