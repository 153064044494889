import { ReactNode } from "react";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import VideocamIcon from '@mui/icons-material/Videocam';
import RouteIcon from '@mui/icons-material/Route';
import { SxProps } from "@mui/material";

import { getClassName } from "~/utils/utils";

import "./style.less";

type TPosition = "top" | "bottom";

interface IMyGalleryItemBar {
	title: string;
	subtitle?: ReactNode;
	position?: TPosition;
	photosCount?: number;
	videosCount?: number;
	tracksCount?: number;
	duration?: string;
	disableMouse?: boolean;
	hide?: boolean;
}

export default function MyGalleryItemBar({
	title,
	subtitle = null,
	position = "bottom",
	photosCount = 0,
	videosCount = 0,
	tracksCount = 0,
	duration = "",
	disableMouse,
	hide = false,
}: IMyGalleryItemBar) {
	const hasNumbers = photosCount > 0 || videosCount > 0 || tracksCount > 0;
	const iconStyle: SxProps = {
		fill: "#fff",
		width: "16px",
		height: "16px",
	};

	return <div className={getClassName(["myGalleryItemBar", position, disableMouse ? "no-mouse" : "", hide ? "hide" : ""])}>
		<div className="myGalleryItemBar__title">
			{ title }
		</div>
		<div className="myGalleryItemBar__subtitleArea">
			{ subtitle && <div className="myGalleryItemBar__subtitle">
				{ subtitle }
			</div> }
			{ hasNumbers && <div className="myGalleryItemBar__numbersPart">
				{ photosCount > 0 && <div className="myGalleryItemBar__countIconLine">
					<PhotoCameraIcon sx={iconStyle} /> { photosCount}
				</div> }
				{ videosCount > 0 && <div className="myGalleryItemBar__countIconLine">
					<VideocamIcon sx={iconStyle} /> { videosCount}
				</div> }
				{ tracksCount > 0 && <div className="myGalleryItemBar__countIconLine">
					<RouteIcon sx={iconStyle} /> { tracksCount}
				</div> }
			</div> }
			{ duration && <div className="myGalleryItemBar__duration">
				{ duration }
			</div> }
		</div>
	</div>;
}
