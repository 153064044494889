import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";

import PageContent from "~/components/PageContent";
import HeaderTitle from "~/components/HeaderTitle";
import { getLink, getStory } from "~/providers/story";
import { STORIES } from "~/data/stories";
import { DAYJS_NICE_FORMAT_DATE } from "~/const";
import StoryItem from "~/components/StoryItem";
import Tags from "~/components/Tags";

import "./style.less";

function getMessageParts(message: string) {
	const parts: Array<string> = [];
	let wasC = false;

	for (let ind = 0, max = message.length; ind < max; ind++) {
		const sign = message[ind];

		if (sign === " ") {
			continue;
		}

		const signLc = sign.toLocaleLowerCase();

		if (wasC) {
			if (signLc === "h") {
				parts.push("ch");
			} else {
				parts.push("c");
				parts.push(sign);
			}

			wasC = false;
		} else if (signLc === "c") {
			wasC = true;

			continue;
		} else {
			parts.push(sign);
		}
	}

	return parts;
}

interface IParams {
	story: string;
	[key: string]: string;
}

export default function StoryPage() {
	const { story } = useParams<IParams>();
	const navigate = useNavigate();
	const storyData = getStory(story);
	const messageParts = storyData
		? getMessageParts(storyData.message)
		: [];

	function getTitle() {
		return storyData
			? `Příběh - ${storyData.title}`
			: "Příběh";
	}

	function onStoryClick(item: string) {
		const filtered = STORIES.filter(storyItem => storyItem.title === item);

		if (filtered.length === 1) {
			navigate(getLink(filtered[0].seo));
		}
	}

	function getSign(ind: number) {
		const len = messageParts.length - 1;
		const sign = storyData.reverse
			? messageParts[len - ind]
			: messageParts[ind];

		return sign.toLocaleUpperCase();
	}

	/* eslint-disable react/no-array-index-key */
	return <PageContent className="storyPage" adminContent={true}>
		<HeaderTitle title={getTitle()} />
		{ (storyData === null && story) && <p>
			Příběh nenalezen!
		</p> }
		{ story === undefined && <>
			<p>
				Dostupné příběhy:
			</p>
			<Tags items={STORIES.map(storyItem => storyItem.title)} onClick={onStoryClick} />
		</> }
		{ storyData && <>
			<p>
				Text zprávy: <strong>{ storyData.message }</strong><br />
				Datum: <strong>{ dayjs(storyData.date).format(DAYJS_NICE_FORMAT_DATE) }</strong>
			</p>
			<div className="storyPage__storiesCont">
				<div className="storyPage__stories">
					{ storyData.items.map((item, ind) => <StoryItem key={ind} data={item} sign={getSign(ind)} />) }
				</div>
			</div>
		</> }
	</PageContent>;
}
