import { getClassName } from "~/utils/utils";
import "./style.less";

interface IHeaderTitle {
	title: string;
	className?: string;
}

export default function HeaderTitle({
	title,
	className = "",
}: IHeaderTitle) {
	return <h1 className={getClassName(["headerTitle", className])}>
		{ title }
	</h1>;
}
