import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import UAParser from "ua-parser-js";

import { IVisitItem } from "~/interfaces";

import "./style.less";

const IPS = [{
	name: "Seznam Campus",
	ips: ["77.75.75.233", "77.75.75.235", "2a02:598:104:64:"],
}, {
	name: "Brno Oblá",
	ips: ["78.80.81.20"],
}, {
	name: "Taťka Bojanovice",
	ips: ["93.99.68.12"],
}];

export default function VisitsTable({
	visits,
	ip,
}: {
	visits: Array<IVisitItem>;
	ip: string;
}) {
	function getClient(client: string) {
		/* eslint-disable-next-line */
		return `${client.slice(0, 4)}...${client.slice(client.length - 4)}`;
	}

	function getIp(itemIp: string) {
		const filtered = IPS.filter(ipItem => ipItem.ips.indexOf(itemIp) !== -1);

		if (filtered.length > 0) {
			return filtered[0].name;
		}

		if (itemIp.match(/\d+\.\d+\.\d+\.\d+/u)) {
			return itemIp;
		}

		const parts = itemIp.split(":");

		return `${parts[0]}...${parts[parts.length - 1]}`;
	}

	function getLang(lang: string) {
		const parts = lang.split("-");

		return parts[0];
	}

	function getUA(userAgent: string) {
		const uaParser = new UAParser(userAgent);
		const browser = uaParser.getBrowser();

		return `${browser.name} ${browser.version}`;
	}

	return <TableContainer component={Paper}>
		<Table sx={{ minWidth: 650 }} aria-label="simple table">
			<TableHead>
				<TableRow>
					<TableCell sx={{ fontWeight: "bold" }}>datum</TableCell>
					<TableCell sx={{ fontWeight: "bold" }}>mobil</TableCell>
					<TableCell sx={{ fontWeight: "bold" }}>client</TableCell>
					<TableCell sx={{ fontWeight: "bold" }}>jazyk</TableCell>
					<TableCell sx={{ fontWeight: "bold" }}>obrazovka</TableCell>
					<TableCell sx={{ fontWeight: "bold" }}>browser</TableCell>
					<TableCell sx={{ fontWeight: "bold" }}>ip</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{ visits.map(row => (
					<TableRow
						key={row.id}
						sx={{ '&:last-child td, &:last-child th': { border: 0 }, ...row.sameClient ? { background: "#f7f7f7" } : {} }}
					>
						<TableCell>{row.date}</TableCell>
						<TableCell>{row.mobile ? "ano" : "ne"}</TableCell>
						<TableCell >
							{getClient(row.client)}
						</TableCell>
						<TableCell>
							{getLang(row.json.lang)}
						</TableCell>
						<TableCell>
							{`${row.json.screenWidth}x${row.json.screenHeight}`}
						</TableCell>
						<TableCell>
							{ getUA(row.json.agent)}
						</TableCell>
						<TableCell sx={{ fontWeight: row.ip === ip ? "bold" : "normal" }}>{getIp(row.ip)}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	</TableContainer>;
}
