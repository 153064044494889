import { TCoords } from "~/interfaces";
import { PLACES } from "~/const";
import MyButton from "~/my/MyButton";
import { getClassName } from "~/utils/utils";

import "./style.less";

interface IMapDebug {
	className?: string;
	onSelect?: (coords: TCoords) => void;
}

export default function SelectLocation({
	className = "",
	onSelect = () => {},
}: IMapDebug) {
	return <div className={getClassName(["selectLocation", className])}>
		{ PLACES.map(place => <MyButton text={place.title} onClick={() => onSelect(place.coords)} variant="filled" key={place.title} />)}
	</div>;
}
