import { create } from 'zustand';
import { IInfoLineItem } from "~/components/InfoLine";

import { TPixiMode } from "~/components/PixiBackground";
import { savedStore } from "./saved";
import { ILogin, TService } from "~/interfaces";

interface IGlobalStore {
	global: {
		logAuthLoading: boolean;
		loginData: ILogin | null;
		showMartinek: boolean;
		pixiMode: TPixiMode;
		hidePage: boolean;
		service: TService;
		christmas: boolean;
		infoLine: Array<IInfoLineItem>;
		messengerNews: boolean;
	};
	setLogin: (loginData: ILogin | null) => void;
	setShowMartinek: (showMartinek: boolean) => void;
	setPixiMode: (pixiMode: TPixiMode) => void;
	setHidePage: (hidePage: boolean) => void;
	setService: (service: TService) => void;
	setChristmas: (christmas: boolean) => void;
	setKokokyka: (kokokyka: number) => void;
	setInfoLine: (infoLine: Array<IInfoLineItem>) => void;
	setMessengerNews: (messengerNews: boolean) => void;
	setLogAuthLoading: (logAuthLoading: boolean) => void;
}

export const globalStore = create<IGlobalStore>(set => ({
	global: {
		logAuthLoading: false,
		loginData: null,
		showMartinek: false,
		pixiMode: "none",
		hidePage: false,
		service: "makuderovi",
		christmas: false,
		infoLine: [],
		messengerNews: false,
	},
	setLogin: loginData => {
		let messengerNews = false;

		if (loginData) {
			if (loginData.messengerInd !== 0 && loginData.isAdmin) {
				const lastId = savedStore.getState().saved.messengerLastId;

				messengerNews = lastId !== loginData.messengerInd;
			}
		}

		return set(state => ({
			global: {
				...state.global,
				loginData,
				messengerNews,
				logAuthLoading: false,
				infoLine: [],
			},
		}));
	},
	setShowMartinek: showMartinek => set(state => ({
		global: {
			...state.global,
			showMartinek,
		},
	})),
	setPixiMode: pixiMode => set(state => ({
		global: {
			...state.global,
			pixiMode,
		},
	})),
	setHidePage: hidePage => set(state => ({
		global: {
			...state.global,
			hidePage,
		},
	})),
	setService: service => set(state => ({
		global: {
			...state.global,
			service,
		},
	})),
	setChristmas: christmas => set(state => ({
		global: {
			...state.global,
			christmas,
		},
	})),
	setKokokyka: kokokyka => set(state => ({
		global: {
			...state.global,
			loginData: {
				...state.global.loginData,
				kokokyka,
			},
		},
	})),
	setInfoLine: infoLine => set(state => ({
		global: {
			...state.global,
			infoLine,
		},
	})),
	setMessengerNews: messengerNews => set(state => ({
		global: {
			...state.global,
			messengerNews,
		},
	})),
	setLogAuthLoading: logAuthLoading => set(state => ({
		global: {
			...state.global,
			logAuthLoading,
		},
	})),
}));
