import { KeyboardEvent, useEffect } from "react";
import { useParams } from "react-router-dom";

import PageContent from "~/components/PageContent";
import HeaderTitle from "~/components/HeaderTitle";
import { myUseState } from "~/hooks/myUseState";
import { getDatabaseItemsLink, isKeyEnter } from "~/utils/utils";
import Switcher from "~/components/Switcher";
import { IDatabaseItem, TDatabaseCategory } from "~/database-items/interfaces";
import { DATABASE_CATEGORIES, getDatabaseItems, ALL_DATABASE_ITEMS_CATEGORY, databaseItemsInit, searchItems } from "~/database-items/provider";
import { IGalleryItem } from "~/interfaces";
import FullscreenGallery from "~/components/FullscreenGallery";
import MyButton from "~/my/MyButton";
import { globalStore } from "~/stores/global";

import "./style.less";

interface IState {
	query: string;
	wasSearched: boolean;
	items: Array<IDatabaseItem>;
	galleryImg: null | IGalleryItem;
}

interface IParams {
	category: TDatabaseCategory;
	[key: string]: string;
}

const PERCENT = 100;

export default function DatabaseItemsPage() {
	const { state, updateState } = myUseState<IState>({
		query: "",
		wasSearched: false,
		items: [],
		galleryImg: null,
	});
	const { global } = globalStore(globalState => ({
		global: globalState.global,
	}));
	const { category: urlCategory } = useParams<IParams>();
	const switcherItems = DATABASE_CATEGORIES.map(item => item.title);

	function getLink(linkName: string) {
		const filtered = DATABASE_CATEGORIES.filter(item => item.title === linkName);

		if (filtered.length) {
			return getDatabaseItemsLink(filtered[0].seo);
		}

		return getDatabaseItemsLink();
	}

	function getActive() {
		if (state.wasSearched) {
			return DATABASE_CATEGORIES.filter(item => item.seo === ALL_DATABASE_ITEMS_CATEGORY)[0].title;
		}

		const filtered = DATABASE_CATEGORIES.filter(item => item.seo === urlCategory);

		return filtered.length
			? filtered[0].title
			: "";
	}

	function onClose() {
		updateState({
			galleryImg: null,
		});
	}

	function openImage(item: IDatabaseItem, largeSrc: string) {
		updateState({
			galleryImg: {
				name: item.title,
				title: item.title,
				subTitle: item.desc || "",
				src: largeSrc,
				largeSrc: largeSrc,
			},
		});
	}

	function search() {
		updateState({
			wasSearched: true,
			items: searchItems(state.query),
		});
	}

	function onKeyDown(event: KeyboardEvent) {
		if (isKeyEnter(event)) {
			search();
		}
	}

	function getPerc(count: number) {
		return Math.floor(PERCENT / count);
	}

	function cancelSearch() {
		updateState({
			wasSearched: false,
			query: "",
			items: getDatabaseItems(ALL_DATABASE_ITEMS_CATEGORY),
		});
	}

	useEffect(() => {
		if (global.loginData?.type) {
			databaseItemsInit(global.loginData.type);
		}
	}, [global.loginData]);

	useEffect(() => {
		if (urlCategory) {
			updateState({
				items: getDatabaseItems(urlCategory),
			});
		}
	}, [urlCategory]);

	return <PageContent className="databaseItems" adminContent={true}>
		<HeaderTitle title="Přehled věcí" />
		<Switcher items={switcherItems} getLink={getLink} active={getActive()} />
		<div className="databaseItems__search">
			Hledat: <input type="text" value={state.query} onChange={event => updateState({ query: event.target.value })} onKeyDown={onKeyDown} />
			<MyButton text="Hledat" onClick={search} variant="filled" />
			{ state.wasSearched && <MyButton text="Zrušit" variant="filled" onClick={cancelSearch} /> }
		</div>
		<div className="databaseItems__items">
			{ state.items.map(item => <div className="databaseItems__item" key={item.title}>
				<div className="databaseItems__itemHeader">
					<h3 className="databaseItems__itemTitle">{ item.title }</h3>
					{ item.desc && <p className="databaseItems__itemDesc">{ item.desc }</p> }
					{ item.price && <p className="databaseItems__itemPrice">{ item.price } Kč</p> }
				</div>
				{ (item.images && item.images.length > 0) && <div className="databaseItems__images">
					{ item.images.map(image => <div key={image.src} className="databaseItems__imageItem" style={{ flex: `0 1 calc(${getPerc(item.images.length)}% - 5px)` }} onClick={() => openImage(item, image.large)}>
						<img src={image.src} className="databaseItems__imageItemCover" alt="" />
					</div>) }
				</div> }
			</div>)}
		</div>
		{ state.galleryImg && <FullscreenGallery item={state.galleryImg} navigation={false} onClose={onClose} /> }
	</PageContent>;
}
