/* eslint-disable no-magic-numbers */
import maplibregl from 'maplibre-gl';
import { Feature } from 'geojson';

import { debounce } from "~/utils/utils";
import { POITYPES, getLookup, typeToIcon } from "./poiagg";
import { ICoords } from "~/lib/net/interfaces";
import { HTTP_STATUS_OK } from "~/lib/net/const";

// po jake dobe necinnosti po zmene se udela lookup
const DEBOUNCE_LOOKUP_TIMEOUT = 20;

export interface ILookupOptions {
	bounds: [ICoords, ICoords];
	zoom: number;
	mapsetId: number;
}

export interface ILookupFeatureProperties {
	source: string;
	id: number;
	title: string;
	typeId: number;
}

export interface ILookupFeaturePOIProperties extends ILookupFeatureProperties {
	longitude: number;
	latitude: number;
	icon: number;
}

export interface ILookupFeatures {
	pois: Array<Feature>;
}

async function lookup(options: ILookupOptions): Promise<ILookupFeatures> {
	const { bounds, zoom, mapsetId } = options;

	const features: ILookupFeatures = {
		pois: [],
	};

	const response = await getLookup(bounds, {
		zoom,
		mapsetId,
	});

	if (response.status === HTTP_STATUS_OK && Array.isArray(response.poi)) {
		for (let index = 0; index < response.poi.length; index++) {
			const poi = response.poi[index];

			if (poi.mark) {
				features.pois.push({
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: [poi.mark.lon, poi.mark.lat],
					},
					properties: {
						source: poi.source,
						id: poi.id,
						title: `<strong>${poi.title}</strong>`,
						typeId: poi.typeId,
						longitude: poi.mark.lon,
						latitude: poi.mark.lat,
						icon: typeToIcon(poi.typeId, POITYPES),
					} as ILookupFeaturePOIProperties,
				});
			}
		}
	}

	return features;
}

export function setMapLookup(map: maplibregl.Map, mapSource: string) {
	let isActive = true;
	const lookupDebounced = debounce(function lookupDebounce() {
		if (map) {
			const bounds = map.getBounds();
			let east = bounds.getEast();
			let west = bounds.getWest();
			let north = bounds.getNorth();
			let south = bounds.getSouth();

			east = east > 180
				? east - 360
				: east < -180
					? east + 360
					: east;
			west = west > 180
				? west - 360
				: west < -180
					? west + 360
					: west;
			north = north > 90
				? north - 180
				: north < -90
					? north + 180
					: north;
			south = south > 90
				? south - 180
				: south < -90
					? south + 180
					: south;

			lookup({
				bounds: [{
					latitude: Math.min(north, south),
					longitude: Math.min(east, west),
				}, {
					latitude: Math.max(north, south),
					longitude: Math.max(east, west),
				}],
				zoom: Math.floor(map.getZoom() + 1),
				mapsetId: 3,
			}).then(features => {
				if (isActive && map) {
					// bodove POIe
					const sourcePOI = map.getSource(mapSource) as maplibregl.GeoJSONSource;

					sourcePOI.setData({
						type: 'FeatureCollection',
						features: features.pois,
					});
				}
			});
		}
	}, DEBOUNCE_LOOKUP_TIMEOUT);

	map.on('zoomend', lookupDebounced);
	map.on('moveend', lookupDebounced);

	lookupDebounced();

	return () => {
		isActive = false;
		map.off('zoomend', lookupDebounced);
		map.off('moveend', lookupDebounced);
	};
}
