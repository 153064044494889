import dayjs from "dayjs";
import { useMemo } from "react";

import { IGalleryItem } from "~/interfaces";
import MyGallery from "~/my/MyGallery";
import YearGalleryVideoItem from "../YearGalleryVideoItem";
import YearGalleryImageItem from "../YearGalleryImageItem";
import GalleryHeader from "../GalleryHeader";
import FullscreenGallery from "~/components/FullscreenGallery";
import { myUseState } from "~/hooks/myUseState";
import { formatDuration, getPhotoByDirection, hasTimeHours, transYearsBack } from "~/utils/utils";
import ThisDateGalleryControls from "../ThisDateGalleryControls";

import "./style.less";

interface IState {
	galleryImg: null | IGalleryItem;
	showYears?: boolean;
}

interface IYearItem {
	year: number;
	title: string;
	items: Array<IGalleryItem>;
}

interface IThisDayGallery {
	date: string;
	martinekDate: string;
	items: Array<IGalleryItem>;
	onDateChange?: (date: dayjs.Dayjs) => void;
}

function getYearItems(items: Array<IGalleryItem>): Array<IYearItem> {
	const years: Array<number> = [];
	const yearsObj: { [key: number]: Array<IGalleryItem>; } = {};
	const curYear = dayjs().get("year");

	for (const item of items) {
		const dateObj = dayjs(item.date);
		const year = dateObj.get("year");
		const hasTime = hasTimeHours(item.date);
		const timeStr = hasTime
			? dateObj.format("H:mm")
			: "";

		if (!years.includes(year)) {
			years.push(year);
			yearsObj[year] = [];
		}

		yearsObj[year].push({
			...item,
			...item.isVideo
				? {
					subTitle: [timeStr, `délka ${formatDuration(item.duration)}`].filter(strItem => strItem).join(", "),
				}
				: {
					subTitle: timeStr,
				},
		});
	}

	years.sort((aItem, bItem) => bItem - aItem);

	return years.map(year => ({
		year,
		title: `${year} (${transYearsBack(curYear - year)})`,
		items: yearsObj[year],
	}));
}

export default function ThisDayGallery({
	date,
	martinekDate,
	items,
	onDateChange = () => {},
}: IThisDayGallery) {
	const curDate = dayjs(date);
	const title = `Vzpomínky na ${curDate.locale("cs").format("dddd D. MMMM")}`;
	const { state, updateState, toggleState } = myUseState<IState>({
		galleryImg: null,
		showYears: false,
	});
	const yearItems = useMemo(() => getYearItems(items), [items]);

	function showPhoto(data: IGalleryItem) {
		updateState({
			galleryImg: data,
		});
	}

	function openPhoto(direction: number) {
		const photo = getPhotoByDirection(state.galleryImg.name, items, direction);

		photo && showPhoto(photo);
	}

	function prevPhoto() {
		openPhoto(-1);
	}

	function nextPhoto() {
		openPhoto(1);
	}

	function onClose() {
		updateState({
			galleryImg: null,
		});
	}

	function onYearsClick() {
		toggleState("showYears");
	}

	/* eslint-disable react/no-array-index-key */
	return <div className="thisDayGallery">
		<GalleryHeader title={title} first={true} onBack={null} backChildren={<ThisDateGalleryControls date={date} years={state.showYears} martinekDate={martinekDate} onDateChange={onDateChange} onYearsClick={onYearsClick} />} />
		<div className="yearGallery" >
			{ items.length === 0 && <p className="thisDayGallery__emptyGallery">
				Galerie je prázdná
			</p> }
			{ state.showYears && <div className="thisDayGallery__yearsOverview">
				{ yearItems.map(yearItem => <div className="thisDayGallery__yearItem" key={yearItem.year}>
					<h3 className="thisDayGallery__yearItemTitle">{ yearItem.title }</h3>
					<MyGallery>
						{yearItem.items.map((item, ind) => item.isVideo
							? <YearGalleryVideoItem key={`${yearItem.year}-${date}-${ind}`} data={item} seo="" />
							: <YearGalleryImageItem key={`${yearItem.year}-${date}-${ind}`} data={item} onClick={() => showPhoto(item)} seo="" />)}
					</MyGallery>
				</div>)}
			</div> }
			{ !state.showYears && <MyGallery>
				{items.map((item, ind) => item.isVideo
					? <YearGalleryVideoItem key={`${date}-${ind}`} data={item} seo="" />
					: <YearGalleryImageItem key={`${date}-${ind}`} data={item} onClick={() => showPhoto(item)} seo="" />)}
			</MyGallery> }
		</div>
		{ state.galleryImg && <FullscreenGallery item={state.galleryImg} onClose={onClose} onPrev={prevPhoto} onNext={nextPhoto} />}
	</div>;
}
