import { IStoryItem } from "~/providers/story";

import "./style.less";

interface IStoryItemProps {
	data: IStoryItem;
	sign: string;
}

export default function StoryItem({
	data,
	sign,
}: IStoryItemProps) {
	const emoji = data.emoji || "myval7";

	return <div className="storyItem">
		<span className="storyItem__emoji">
			<img src={`/emoji/${emoji}.png`} alt="Emoji" />
		</span>
		<span className="storyItem__desc">
			<span className="storyItem__descCont" dangerouslySetInnerHTML={{ __html: data.text }} />
		</span>
		<span className="storyItem__sign">
			{ sign }
		</span>
	</div>;
}
