import { IFlat, IMortage, IYearPayment } from "~/interfaces";
import { formatNumberToThousands } from "./utils";

export function getFlatMonhtlyPrice(flat: IFlat) {
	const price = flat.electric + (flat.garbage || 0) + (flat.gas || 0) + flat.internet + flat.monthly
		+ (flat.others ? flat.others.reduce((acc, item) => acc + item, 0) : 0);

	return price;
}

export function getFlatYearPrice(flat: IFlat) {
	const monthly = getFlatMonhtlyPrice(flat);
	const months = flat.monthsRange[1] - flat.monthsRange[0] + 1;
	const perYear = (months * monthly)
		+ (flat.garbage || 0) + (flat.insurance || 0) + (flat.tax || 0);

	return {
		monthly,
		months,
		perYear,
	};
}

export function getMortageYearPrice(mortage: IMortage) {
	const monthly = mortage.monthly;
	const months = mortage.monthsRange[1] - mortage.monthsRange[0] + 1;
	const perYear = months * monthly;

	return {
		monthly,
		months,
		perYear,
	};
}

export function getYearData(yearItem: IYearPayment) {
	const joiner = " | ";
	const flats = yearItem.flat.map(flat => getFlatYearPrice(flat));
	const flatsMonthPrices = flats.map(flat => formatNumberToThousands(flat.monthly, true)).join(joiner);
	const flatsYearPrices = flats.reduce((acc, item) => acc + item.perYear, 0);
	const mortages = yearItem.mortgage.map(mortage => getMortageYearPrice(mortage));
	const mortageMonthPrices = yearItem.mortgage.map(mortage => formatNumberToThousands(mortage.monthly, true)).join(joiner);
	const mortagesYearPrices = mortages.reduce((acc, item) => acc + item.perYear, 0);
	const perMonthFlat = flats.map(flat => flat.monthly);
	const perMonthMortage = mortages.map(mortage => mortage.monthly);
	const perMonth = [];

	for (let ind = 0, max = perMonthFlat.length; ind < max; ind++) {
		perMonth.push(formatNumberToThousands(perMonthFlat[ind] + perMonthMortage[ind]));
	}

	return {
		year: yearItem.year,
		flatsMonthPrices,
		flatsYearPrices: formatNumberToThousands(flatsYearPrices, true),
		mortageMonthPrices,
		mortagesYearPrices: formatNumberToThousands(mortagesYearPrices, true),
		perMonth: perMonth.join(joiner),
		electric: yearItem.flat.map(flat => formatNumberToThousands(flat.electric, true)).join(joiner),
	};
}
