import { ReactNode } from "react";
import CSS from 'csstype';

import { getClassName } from "~/utils/utils";

import "./style.less";

interface IMyGalleryItem {
	children: ReactNode;
	seo: string;
	/* eslint-disable id-length */
	sx?: CSS.Properties;
	onClick?: () => void;
	className?: string | Array<string>;
	isVideo?: boolean;
}

export default function MyGalleryItem({
	children,
	seo,
	sx = {},
	onClick = () => {},
	className = "",
	isVideo = false,
}: IMyGalleryItem) {
	return <div className={getClassName(["myGalleryItem", isVideo ? "video-item" : "", ...Array.isArray(className) ? className : [className]])} style={ sx } onClick={onClick} data-seo={seo}>
		{ children }
	</div>;
}
