import { useEffect, MouseEvent } from 'react';
import { Link, useNavigate, Outlet, useLocation } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import { Button } from "@mui/material";

import Menu from "~/components/Menu";
import Christmas from "~/components/Christmas";
import { ANIM_DURATION, ROUTES } from "~/const";
import { globalStore } from "~/stores/global";
import { auth } from "~/providers/php-api";
import PixiBackground from "~/components/PixiBackground";
import { myUseState } from "~/hooks/myUseState";
import { useMedia } from "~/hooks/useMedia";
import { getClassName } from "~/utils/utils";
import MyButton from "~/my/MyButton";
import Kokokyka from "../Kokokyka";
import InfoLine from "../InfoLine";
import { getJWTFromCookies, setUserData } from "~/providers/user";
import Notifications from "../Notifications";
import { ASSETS } from "~/utils/assets";

import "./style.less";

interface IState {
	hamburgerVisible: boolean;
	menuVisible: boolean;
	pathname: string;
}

export default function Page() {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const isSmallDevice = useMedia("(max-width: 680px)");
	const { state, setState, updateState } = myUseState<IState>({
		hamburgerVisible: false,
		menuVisible: true,
		pathname,
	});
	const { global, setHidePage, setChristmas, setLogAuthLoading } = globalStore(globalState => ({
		global: globalState.global,
		setHidePage: globalState.setHidePage,
		setChristmas: globalState.setChristmas,
		setLogAuthLoading: globalState.setLogAuthLoading,
	}));

	function startAnimation(event: MouseEvent) {
		const elem = event.target as HTMLImageElement;

		elem.classList.add("animation");

		setTimeout(() => {
			elem.classList.remove("animation");
		}, ANIM_DURATION);
	}

	function onHamburgerClick() {
		setState(prev => ({
			...prev,
			menuVisible: !prev.menuVisible,
		}));
	}

	function onMenuClick() {
		isSmallDevice && updateState({
			menuVisible: false,
		});
	}

	function showPage() {
		setHidePage(false);
		setChristmas(false);
	}

	async function pageLoad() {
		const jwtCookie = getJWTFromCookies();

		if (jwtCookie) {
			setLogAuthLoading(true);

			const data = await auth();

			setUserData(data.data);
		}
	}

	useEffect(() => {
		pageLoad();
	}, []);

	useEffect(() => {
		if (pathname !== state.pathname && state.hamburgerVisible && state.menuVisible) {
			updateState({
				menuVisible: false,
			});
		}

		updateState({
			pathname,
		});
	}, [pathname, state.pathname, state.hamburgerVisible, state.menuVisible]);

	useEffect(() => {
		updateState({
			hamburgerVisible: isSmallDevice,
			menuVisible: !isSmallDevice,
		});
	}, [isSmallDevice]);

	return <>
		<div className={getClassName(["mainContent", global.hidePage ? "hide-page" : ""])}>
			<div className="mainContent__header" style={{ backgroundImage: `url(${ASSETS.HeaderBg})` }}>
				<Link to="/" className="mainContent__logoPart" onClick={() => navigate(ROUTES.ROOT)}>
					<img src={ASSETS.Logo} alt="Logo" className="mainContent__logo" />
					<div className="mainContent__headerNames">
						<span className="mainContent__leftName">Roman</span>
						<span className="mainContent__separator">,</span>
						<span className="mainContent__otherName">Lenka</span>
						<span className="mainContent__separator">,</span>
						<span className="mainContent__otherName">Martin</span>
						<span className="mainContent__separator">&</span>
						<span className="mainContent__otherName">Tomáš</span>
					</div>
				</Link>
				<p className="mainContent__anon">
					Makuderovi
					{ state.hamburgerVisible && <Button className="menu__hamburgetBtn" onClick={onHamburgerClick}>
						<MenuIcon sx={{ fill: "#000" }} />
					</Button> }
				</p>
				{ state.menuVisible && <Menu onClick={onMenuClick} mobileVersion={state.hamburgerVisible} /> }
				{ global.loginData?.isAdmin && <InfoLine items={global.infoLine} /> }
				<div className="mainContent__menuLinesCover">
					<img className="mainContent__menuLines" src={ASSETS.MenuLines} alt="" />
				</div>
				{ global.showMartinek && global.loginData && <>
					<img src={ASSETS.Martinek} alt="" className="mainContent__martinek" onClick={event => startAnimation(event)} />
					<img src={ASSETS.Tomi} alt="" className="mainContent__tomi" onClick={event => startAnimation(event)} />
				</> }
				{ (global.loginData?.isAdmin && !isSmallDevice) && <Kokokyka /> }
			</div>
			<div className="mainContent__cont">
				<div className="content">
					<Outlet />
				</div>
			</div>
		</div>
		<Notifications />
		{ global.pixiMode !== "none" && <PixiBackground mode={global.pixiMode} /> }
		{ global.christmas && <Christmas /> }
		{ global.hidePage && <div className="mainContent__showPage">
			<MyButton text="Zobrazit stránku" onClick={showPage} />
		</div>}
	</>;
}
