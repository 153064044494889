import { ReactNode, useEffect, useRef } from "react";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import { myUseState } from "~/hooks/myUseState";
import { formatTime, getClassName, timeToSeconds, debounce } from "~/utils/utils";
import { savedStore } from "~/stores/saved";
import MyVideoControls, { IMyVideoControlsClickData, TMyVideoControlsClickItem, TMyVideoControlsPosition, TMyVideoControlsVariant } from "../MyVideoControls";
import { videoStore } from "~/stores/video";

import "./style.less";

interface IState {
	isPlaying: boolean;
	isFullscreen: boolean;
	progress: number;
	sound: number;
	current: number;
	duration: number;
	showControls: number;
	activeLoop: boolean;
}

export interface IControlsData {
	type: TMyVideoControlsClickItem;
	loopEnabled?: boolean;
}

interface IMyVideo {
	src: string;
	cover: string;
	onControlsChange?: (isVisible: boolean) => void;
	onTimeUpdate?: (elem: HTMLVideoElement) => void;
	onVideoEnded?: () => void;
	hideControls?: number;
	position?: TMyVideoControlsPosition;
	time?: string;
	className?: string;
	children?: ReactNode;
	variant?: TMyVideoControlsVariant;
	autoHide?: boolean;
	onControlsClick?: (data: IControlsData) => void;
}

export default function MyVideo({
	src,
	cover,
	onControlsChange = () => {},
	onTimeUpdate = () => {},
	onVideoEnded = () => {},
	/* eslint-disable no-magic-numbers */
	hideControls = 3000,
	position = "bottom",
	time = "",
	className = "",
	children = null,
	variant = "gallery",
	autoHide = true,
	onControlsClick = () => {},
}: IMyVideo) {
	const { setAudioVolume, savedData } = savedStore(savedState => ({
		setAudioVolume: savedState.setAudioVolume,
		savedData: savedState.saved,
	}));
	const { videoPlaying, addPlayingVideo, removePlayingVideo } = videoStore(videoState => ({
		videoPlaying: videoState.video.playing,
		addPlayingVideo: videoState.addPlayingVideo,
		removePlayingVideo: videoState.removePlayingVideo,
	}));
	const { state, updateState, setState } = myUseState<IState>({
		isPlaying: false,
		isFullscreen: false,
		progress: 0,
		sound: savedData.audioVolume,
		current: 0,
		duration: 0,
		showControls: 0,
		activeLoop: false,
	});
	const videoRef = useRef<HTMLVideoElement>(null);
	const contRef = useRef<HTMLDivElement>(null);

	function showControls(customState?: Partial<IState>) {
		setState(prev => ({
			...prev,
			showControls: prev.showControls + 1,
			...customState,
		}));
	}

	const onPointerMove = debounce(() => {
		showControls();
		onControlsChange(true);
	}, 100);

	function onPlay() {
		showControls({
			isPlaying: true,
		});
	}

	function onFullScreen() {
		updateState({
			isFullscreen: !!document.fullscreenElement,
		});
	}

	function onPause() {
		showControls({
			isPlaying: false,
		});
	}

	function playPauseClick() {
		const video = videoRef.current;

		if (video) {
			if (video.paused || video.ended) {
				video.play();
				addPlayingVideo(src);
			} else {
				video.pause();
				removePlayingVideo(src);
			}
		}

		showControls();
		onControlsChange(true);
	}

	function fullscreenClick() {
		if (document.fullscreenElement) {
			document.exitFullscreen();
		} else if (videoRef.current) {
			contRef.current.requestFullscreen();
		}

		showControls();
		onControlsChange(true);
	}

	function onVideoTimeUpdate() {
		const video = videoRef.current;

		if (video) {
			/* eslint-disable no-magic-numbers */
			const progress = video.currentTime / video.duration * 100;
			const isVideoInit = state.duration === 0 && video.duration;
			const sound = isVideoInit
				? savedStore.getState().saved.audioVolume
				: 100;
			const isProgressDone = (progress >>> 0) === 100;

			if (state.duration === 0 || isProgressDone) {
				onControlsChange(true);
			}

			setState(prev => ({
				...prev,
				progress,
				current: video.currentTime,
				duration: video.duration,
				...prev.duration === 0 || isProgressDone
					? {
						showControls: prev.showControls + 1,
					}
					: {},
				...prev.duration === 0
					? {
						sound,
					}
					: {},
			}));
			onTimeUpdate(video);

			if (state.duration === 0 && video.duration) {
				videoRef.current.volume = sound / 100;
			}
		}
	}

	function onVideoProgressClick(perc: number) {
		const video = videoRef.current;

		if (video) {
			video.currentTime = perc * video.duration / 100;
			video.play();
			addPlayingVideo(src);
			showControls();
		}
	}

	function loopClick() {
		const activeLoop = !state.activeLoop;

		if (variant === "gallery") {
			videoRef.current.loop = activeLoop;
		} else {
			onControlsClick({
				type: "loop",
				loopEnabled: activeLoop,
			});
		}

		updateState({
			activeLoop,
		});
	}

	function updateSound(newSound: number) {
		updateState({
			sound: newSound,
		});
		setAudioVolume(newSound);
	}

	function onMyVideoControlsClick(type: TMyVideoControlsClickItem, data?: IMyVideoControlsClickData) {
		switch (type) {
			case "fullscreen":
				if (variant === "playlist") {
					onControlsClick({
						type: "fullscreen",
					});
				} else {
					fullscreenClick();
				}
				break;

			case "loop":
				loopClick();
				break;

			case "play":
			case "pause":
				playPauseClick();
				break;

			case "progress":
				onVideoProgressClick(data.progress);
				break;

			case "sound":
				updateSound(data.sound);
				break;

			case "prev":
				onControlsClick({
					type: "prev",
				});
				break;

			case "next":
				onControlsClick({
					type: "next",
				});
				break;

			case "close":
				onControlsClick({
					type: "close",
				});
				break;

			default:
		}

		showControls();
	}

	function onVideoElemEnded() {
		removePlayingVideo(src);
		onVideoEnded();
	}

	useEffect(() => {
		if (!autoHide || state.showControls === 0) {
			return () => {};
		}

		const hideTo = setTimeout(() => {
			updateState({
				showControls: 0,
			});
			onControlsChange(false);
		}, hideControls);

		return () => {
			clearTimeout(hideTo);
		};
	}, [state.showControls]);

	useEffect(() => {
		if (contRef.current && state.duration > 0) {
			const contElem = contRef.current;

			contElem.addEventListener("pointermove", onPointerMove);
			contElem.addEventListener("fullscreenchange", onFullScreen);

			return () => {
				contElem.removeEventListener("pointermove", onPointerMove);
				contElem.removeEventListener("fullscreenchange", onFullScreen);
			};
		}

		return () => {};
	}, [state.duration]);

	useEffect(() => {
		if (time && videoRef.current) {
			videoRef.current.pause();
			videoRef.current.currentTime = timeToSeconds(time);
		}
	}, [time, videoRef]);

	useEffect(() => {
		if (videoRef.current) {
			videoRef.current.volume = state.sound / 100;
		}
	}, [videoRef, state.sound]);

	useEffect(() => {
		if (!videoRef.current) {
			return;
		}

		const isPlaying = !videoRef.current.paused && !videoRef.current.ended;
		const lastVideo = videoPlaying[videoPlaying.length - 1];

		if (isPlaying && lastVideo !== src) {
			videoRef.current.pause();
		}
	}, [videoPlaying, videoRef]);

	return <div className={getClassName(["myVideo", className, state.isFullscreen ? "fullscreen" : ""])} ref={contRef}>
		{ !state.isPlaying && <div className="myVideo__playBtn" onClick={playPauseClick}>
			<PlayCircleOutlineIcon sx={{ width: "64px", height: "64px", fill: "#fff" }} />
		</div> }
		<video
			src={src}
			preload="none"
			poster={cover}
			controls={false}
			autoPlay={variant === "playlist"}
			ref={videoRef}
			onPlay={onPlay}
			onPause={onPause}
			onTimeUpdate={onVideoTimeUpdate}
			onEnded={onVideoElemEnded}
			onClick={playPauseClick}
		/>
		<MyVideoControls hide={state.showControls === 0} position={position} sound={state.sound} isFullscreen={state.isFullscreen} isPlaying={state.isPlaying} isActiveLoop={state.activeLoop} percent={state.progress}
			text={`${formatTime(state.current)} / ${formatTime(state.duration)}`} onClick={onMyVideoControlsClick} variant={variant} />
		{ children }
	</div>;
}
