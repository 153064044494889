import CloseIcon from '@mui/icons-material/Close';

import MyButton from "~/my/MyButton";
import { ISearchItem } from "~/map-interfaces";

import "./style.less";

interface IMapSearch {
	items: Array<ISearchItem>;
	onClick?: (item: ISearchItem) => void;
	onClose?: () => void;
}

export default function MapSearch({
	items = [],
	onClick = () => {},
	onClose = () => {},
}: IMapSearch) {
	/* eslint-disable react/no-array-index-key */
	return <div className="mapContainer__searchResults map__dialog">
		<MyButton text={<CloseIcon />} onClick={onClose} className="mapContainer__btn mapContainer__searchResultsCloseBtn" title="Zavřít" />
		<ul className="mapContainer__searchResultsList">
			{ items.map((searchItem, ind) => <li key={`result-${ind}`} onClick={() => onClick(searchItem)}>
				{ searchItem.title }
			</li>) }
		</ul>
	</div>;
}
