import Background from "~/assets/background.png";
import BackgroundFishes from "~/assets/background-fishes.jpg";
import Christmas from "~/assets/stromecek.jpg";
import Zvonecek from "~/assets/zvonecek.png";
import KokokykaBlack from "~/assets/kokokyka/black.png";
import KokokykaWhite from "~/assets/kokokyka/white.png";
import PlaceholderImg from "~/assets/placeholder.png";
import HeaderBg from "~/assets/svg/headerBg.svg";
import MenuLines from "~/assets/svg/menuLines.svg";
import Logo from "~/assets/logo.png";
import Martinek from "~/assets/tatrovka-01.png";
import Tomi from "~/assets/tomi.png";

export const ASSETS = {
	Zvonecek,
	KokokykaBlack,
	KokokykaWhite,
	PlaceholderImg,
	HeaderBg,
	MenuLines,
	Logo,
	Martinek,
	Tomi,
};

function clearBgClasses() {
	document.body.classList.remove("fish-bg");
	document.body.classList.remove("christmas");
}

export function setDefaultBackground() {
	clearBgClasses();
	document.body.style.background = `url(${Background}) repeat center top fixed`;
}

export function setFishBackground() {
	clearBgClasses();
	document.body.classList.add("fish-bg");
	document.body.style.background = `url(${BackgroundFishes}) center center no-repeat`;
}

export function setChristmasBackgound() {
	clearBgClasses();
	document.body.classList.add("christmas");
	document.body.style.background = `url(${Christmas}) center center no-repeat`;
}
