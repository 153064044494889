import { getClassName } from "~/utils/utils";

import "./style.less";

interface IVideoPlaylistItem {
	active?: boolean;
	title?: string;
	duration?: string;
	date?: string;
	position?: string;
	className?: string;
	onClick?: null | (() => void);
}

export default function VideoPlaylistItem({
	active = false,
	title = "",
	duration = "",
	date = "",
	position = "",
	className = "",
	onClick,
}: IVideoPlaylistItem) {
	function onItemClick() {
		if (onClick) {
			onClick();
		}
	}

	return <div className={getClassName(["videoPlaylistItem", className, onClick ? "" : "empty", active ? "active" : ""])} onClick={onItemClick}>
		<span className="videoPlaylistItem__title">
			{ title }
		</span>
		<span className="videoPlaylistItem__subtitle">
			<span className="videoPlaylistItem__duration">
				{ duration }
			</span>
			<span className="videoPlaylistItem__date">
				{ date }
			</span>
			<span className="videoPlaylistItem__position">
				{ position }
			</span>
		</span>
	</div>;
}
