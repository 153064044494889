import { ReactNode, MouseEvent } from "react";
import CSS from 'csstype';

import { getClassName } from "~/utils/utils";

interface IMyGalleryItemLink {
	children: ReactNode;
	link: string;
	seo: string;
	/* eslint-disable id-length */
	sx?: CSS.Properties;
	onClick?: () => void;
	className?: string | Array<string>;
}

export default function MyGalleryItemLink({
	children,
	link,
	seo,
	sx = {},
	onClick = () => {},
	className = "",
}: IMyGalleryItemLink) {
	function onLinkClick(event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();
		onClick();
	}

	return <a href={link} className={getClassName(["myGalleryItem", ...Array.isArray(className) ? className : [className]])} style={ sx } onClick={onLinkClick} data-seo={seo}>
		{ children }
	</a>;
}
