import simplify from 'simplify-geojson';

import CloseBtn from "../CloseBtn";
import { IDataTrackGeometryFull, ITrackItem, TCoords } from "~/interfaces";
import { myUseState } from "~/hooks/myUseState";
import { SIMPLIFY_PRECISION } from "~/const";
import MyButton from "~/my/MyButton";
import { getClassName, getTrackColorByType } from "~/utils/utils";
import { getLineStringGeoJson } from "~/utils/map";
import { stringToCoords } from "~/utils/mirek";

import "./style.less";

interface IItem {
	ind: number;
	title: string;
	track: ITrackItem;
	geometryItem: IDataTrackGeometryFull;
}

interface IState {
	active: IItem;
	precision: string;
	origLen: number;
	newLen: number;
	coordinates: Array<TCoords>;
}

interface ISimplifyTrackDialog {
	tracks: Array<ITrackItem>;
	onSave?: (track: ITrackItem, geometryFull: IDataTrackGeometryFull, coordinates: Array<TCoords>) => void;
	onClose?: () => void;
}

// nova: 0.002
/* eslint-disable-next-line */
const PRECISIONS = [0.1, 0.01, 0.001, 0.002, SIMPLIFY_PRECISION];

export default function SimplifyTrackDialog({
	tracks,
	onSave = () => {},
	onClose = () => {},
}: ISimplifyTrackDialog) {
	const { state, updateState, setState } = myUseState<IState>({
		active: null,
		precision: SIMPLIFY_PRECISION.toString(),
		origLen: 0,
		newLen: 0,
		coordinates: [],
	});

	function getItems() {
		const items: Array<IItem> = [];
		let ind = 0;

		for (const track of tracks) {
			const title = track.desc || "";

			for (const geometryItem of track.geometries) {
				const coordinates = stringToCoords(geometryItem.geometry);

				items.push({
					ind,
					title: `${title} ${coordinates.length}`,
					track,
					geometryItem,
				});
				ind++;
			}
		}

		return items;
	}

	function getGeometriesData(active: IItem, precision: string): Pick<IState, "origLen" | "newLen" | "coordinates"> {
		const coordinates = stringToCoords(active.geometryItem.geometry);
		const feature = getLineStringGeoJson(coordinates, {
			color: getTrackColorByType(active.geometryItem.type),
		});
		const simplified = simplify(feature, parseFloat(precision));

		return {
			origLen: coordinates.length,
			newLen: simplified.geometry.coordinates.length,
			coordinates: simplified.geometry.coordinates,
		};
	}

	function updatePrecision(precision: string) {
		setState(prev => ({
			...prev,
			precision,
			...prev.active
				? getGeometriesData(prev.active, precision)
				: {},
		}));
	}

	function setTrackItem(active: IItem) {
		updateState({
			active,
			...getGeometriesData(active, state.precision),
		});
	}

	const items = getItems();

	return <div className="simplifyTrackDialog map__dialog">
		<CloseBtn className="simplifyTrackDialog__btnClose" onClick={onClose} />
		<div className="simplifyTrackDialog__tracks">
			{ items.map(item => <ul key={item.ind} className="simplifyTrackDialog__tracksList">
				<li onClick={() => setTrackItem(item)} className={getClassName(["simplifyTrackDialog__tracksItem", item.ind === state.active?.ind ? "active" : ""])}>
					{ item.title } - { item.geometryItem.type }
				</li>
			</ul>) }
		</div>
		<div className="simplifyTrackDialog__btnsLine alignRight">
			Precision: <input type="text" value={state.precision} onChange={event => updatePrecision(event.target.value)} />
		</div>
		<div className="simplifyTrackDialog__btnsLine alignRight">
			{ PRECISIONS.map(precision => <MyButton key={precision} text={precision} onClick={() => updatePrecision(precision.toString())} variant="filled" />)}
		</div>
		{ state.active && <div className="simplifyTrackDialog__btnsLine spaceBetween">
			<span>
				{ state.origLen } / { state.newLen }
			</span>
			<MyButton text="Nastavit" onClick={() => onSave(state.active.track, state.active.geometryItem, state.coordinates)} variant="filled" />
		</div> }
	</div>;
}
