/* eslint-disable */
import { useEffect, useRef } from 'react';
import { Stage, Sprite, useTick, TilingSprite, Container } from '@pixi/react';
import '@pixi/events';

import { myUseState } from "~/hooks/myUseState";
import { debounce } from "~/utils/utils";
import PageContent from "~/components/PageContent";
import { KEYS } from "~/const";

import "./style.less";

type TPosition = [number, number];

interface IState {
	width: number;
	height: number;
	loaded: boolean;
	xTiles: number;
	yTiles: number;
}

const SIZE = [64, 64];
const DIRS = {
	left: [-1, 0],
	right: [1, 0],
	top: [0, -1],
	bottom: [0, 1],
};

export default function SnakePage() {
	const { state, updateState } = myUseState<IState>({
		width: 100,
		height: 100,
		loaded: false,
		xTiles: 0,
		yTiles: 0,
	});
	const pixiCoverElem = useRef<HTMLDivElement>(null);

	function setDimension() {
		const xTiles = pixiCoverElem.current.offsetWidth / SIZE[0] >>> 0;
		const yTiles = pixiCoverElem.current.offsetHeight / SIZE[1] >>> 0;

		updateState({
			xTiles,
			yTiles,
			width: xTiles * SIZE[0],
			height: yTiles * SIZE[1],
		});
	}

	const SnakeHead = ({ pos } : { pos: TPosition; }) => {
		return <Sprite image={`/snake/head.png`} anchor={0} width={SIZE[0]} height={SIZE[1]} x={pos[0] * SIZE[0]} y={pos[1] * SIZE[1]} />;
	};

	const MainCont = ({ width, height }: { width: number; height: number; }) => {
		const { state: stateCont, setState: setStateCont } = myUseState<{
			head: TPosition;
			lastDelta: number;
			dir: Array<number>;
		}>({
			head: [state.xTiles / 2 >>> 0, state.yTiles / 2 >>> 0],
			lastDelta: Date.now(),
			dir: DIRS.left,
		});

		useEffect(() => {
			function onKeyPress(event: KeyboardEvent) {
				if (event.code === KEYS.LEFT) {
					setStateCont(prev => ({
						...prev,
						dir: DIRS.left,
					}));
				} else if (event.code === KEYS.RIGHT) {
					setStateCont(prev => ({
						...prev,
						dir: DIRS.right,
					}));
				} else if (event.code === KEYS.UP) {
					setStateCont(prev => ({
						...prev,
						dir: DIRS.top,
					}));
				} else if (event.code === KEYS.DOWN) {
					setStateCont(prev => ({
						...prev,
						dir: DIRS.bottom,
					}));
				}
			}

			document.addEventListener("keydown", onKeyPress);

			return () => {
				document.removeEventListener("keydown", onKeyPress);
			};
		}, []);

		useTick(() => {
			const diff = Date.now() - stateCont.lastDelta;

			if (diff >= 500) {
				// game cycle
				setStateCont(prev => ({
					...prev,
					lastDelta: Date.now(),
					head: [
						prev.head[0] + prev.dir[0],
						prev.head[1] + prev.dir[1],
					],
				}));
			}
		});

		return <Container width={width} height={height}>
			<SnakeHead pos={stateCont.head} />
		</Container>;
	};

	// resize canvasu
	useEffect(() => {
		if (pixiCoverElem.current) {
			setDimension();

			const resize = debounce(() => {
				setDimension();
			}, 200);

			window.addEventListener("resize", resize);

			return () => {
				window.removeEventListener("resize", resize);
			};
		}

		return () => {};
	}, [pixiCoverElem.current]);

	return <PageContent className="snakePage" adminContent={true}>
		<div className="snakePage__cont" ref={pixiCoverElem} style={{ visibility: state.loaded ? "visible" : "hidden" }}>
			<Stage width={state.width} height={state.height} options={{ backgroundAlpha: 0 }} onMount={() => updateState({ loaded: true })}>
				<TilingSprite
					image={'/snake/bg.png'}
					width={state.width}
					height={state.height}
					tilePosition={{ x: 0, y: 0 }}
				/>
				<MainCont width={state.width} height={state.height} />
			</Stage>
		</div>
	</PageContent>;
}
