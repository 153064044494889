import { DependencyList, ReactNode, useEffect } from "react";

import { globalStore } from "~/stores/global";
import ScrollToTop from "~/components/ScrollToTop";
import PasswordWarn from "~/components/PasswordWarn";
import Password from "~/components/Password";
import Loader from "../Loader";
import { ILogin } from "~/interfaces";

interface IPageContent {
	className: string;
	children: ReactNode;
	disableScrollTop?: boolean;
	loggedContent?: boolean;
	adminContent?: boolean;
	showMartinek?: boolean;
	onLoad?: (loginData: ILogin) => void;
	dependencies?: DependencyList;
}

export default function PageContent({
	className,
	children,
	disableScrollTop,
	loggedContent = false,
	adminContent = false,
	showMartinek = false,
	onLoad = () => {},
	dependencies = [],
}: IPageContent) {
	const { setShowMartinek, global } = globalStore(globalState => ({
		setShowMartinek: globalState.setShowMartinek,
		global: globalState.global,
	}));
	const showProtected = (!loggedContent && !adminContent)
		|| (loggedContent && global.loginData)
		|| (adminContent && global.loginData?.isAdmin);

	useEffect(() => {
		setShowMartinek(showMartinek);
	}, []);

	useEffect(() => {
		showProtected && onLoad(global.loginData);
	}, [global.loginData, ...dependencies]);

	return <div className={className}>
		{ !disableScrollTop && <ScrollToTop /> }
		{ (!showProtected && !global.logAuthLoading && global.loginData) && <>
			<p>Nemáte právo k zobrazení stránky!</p>
		</> }
		{ (!showProtected && !global.logAuthLoading && !global.loginData) && <>
			<Password />
			<PasswordWarn />
		</> }
		{ showProtected && children }
		{ global.logAuthLoading && <Loader /> }
	</div>;
}
