import { ReactNode } from "react";

import "./style.less";

interface IMyGallery {
	children: ReactNode;
}

export default function MyGallery({
	children,
}: IMyGallery) {
	return <div className="myGallery">
		{ children }
	</div>;
}
