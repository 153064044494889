import { formatSize, getClassName } from "~/utils/utils";
import { ASSETS } from "~/utils/assets";

type TBackground = "white" | "red" | "green";

interface IImageTester {
	imgSrc?: string;
	title?: string;
	subTitle?: string;
	coords?: string;
	size?: number;
	background?: TBackground;
}

export default function ImageTesterItem({
	imgSrc = ASSETS.PlaceholderImg,
	title = "",
	subTitle = "",
	size = 0,
	coords = "",
	background = "white",
}: IImageTester) {
	return <div className={getClassName(["uploadItem", `bg-${background}`])}>
		<div className="uploadItem__cover">
			<img className="uploadItem__img" src={imgSrc} alt="" />
		</div>
		<div className="uploadItem__title">
			{ title }
		</div>
		<div className="uploadItem__title">
			{ subTitle }
		</div>
		{ coords && <div className="uploadItem__title">
			{ coords }
		</div> }
		<div className={getClassName(["uploadItem__title", "size"])}>
			{ formatSize(size) }
		</div>
	</div>;
}
