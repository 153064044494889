/* eslint-disable no-magic-numbers */
import { TCoords } from "~/interfaces";

/**
 * Abeceda pro sifrovani souradnic.
 */
const ALPHABET = "0ABCD2EFGH4IJKLMN6OPQRST8UVWXYZ-1abcd3efgh5ijklmn7opqrst9uvwxyz.";

/**
 * Serializace cisla.
 */
function serializeNumber(delta: number, orig: number) {
	let code = "";

	if (delta >= -1024 && delta < 1024) {
		code += ALPHABET.charAt((delta + 1024) >> 6);
		code += ALPHABET.charAt((delta + 1024) & 63);
	} else if (delta >= -32768 && delta < 32768) {
		const value = 0x20000 | (delta + 32768);

		code += ALPHABET.charAt((value >> 12) & 63);
		code += ALPHABET.charAt((value >> 6) & 63);
		code += ALPHABET.charAt(value & 63);
	} else {
		const value = 0x30000000 | (orig & 0xFFFFFFF);

		code += ALPHABET.charAt((value >> 24) & 63);
		code += ALPHABET.charAt((value >> 18) & 63);
		code += ALPHABET.charAt((value >> 12) & 63);
		code += ALPHABET.charAt((value >> 6) & 63);
		code += ALPHABET.charAt(value & 63);
	}

	return code;
}

/**
 * Parsovani cisla.
 */
function parseNumber(arr: Array<string>, count: number) {
	let result = 0;
	let ind = count;

	while (ind) {
		if (!arr.length) {
			throw new Error("No data!");
		}

		const ch = arr.pop();
		const index = ALPHABET.indexOf(ch);

		if (index === -1) {
			continue;
		}

		result <<= 6;
		result += index;
		ind--;
	}

	return result;
}

/**
 * Zakodovani souradnic do retezce.
 */
function coordsToString(coordsArg: Array<TCoords> | TCoords): string {
	let ox = 0;
	let oy = 0;
	let result = "";

	const coords = Array.isArray(coordsArg) ? coordsArg as Array<TCoords> : [coordsArg] as Array<TCoords>;

	coords.forEach(item => {
		const xValue = Math.round((item[0] + 180) * (1 << 28) / 360);
		const yValue = Math.round((item[1] + 90) * (1 << 28) / 180);

		const dx = xValue - ox;
		const dy = yValue - oy;
		/*if (!dx && !dy) { continue; }*/

		result += serializeNumber(dx, xValue);
		result += serializeNumber(dy, yValue);

		ox = xValue;
		oy = yValue;
	});

	return result;
}

/**
 * Reverzni proces pro desifrovani retezce na pole souradnic.
 */
function stringToCoords(str: string) {
	const FIVE_CHARS = (1+2) << 4;
	const THREE_CHARS = 1 << 5;

	const results: Array<TCoords> = [];
	const coords = [0, 0];
	let coordIndex = 0;
	const arr = str.trim().split("").reverse();

	while (arr.length) {
		let num = parseNumber(arr, 1); /* podle prvnich dvou bitu poznam, o co jde */

		if ((num & FIVE_CHARS) === FIVE_CHARS) { /* cela souradnice */
			num -= FIVE_CHARS;
			num = ((num & 0xF) << 24) + parseNumber(arr, 4);
			coords[coordIndex] = num;
		} else if ((num & THREE_CHARS) === THREE_CHARS) { /* velky posun - 16 bitu */
			num = ((num & 0xF) << 12) + parseNumber(arr, 2);
			num -= 1 << 15;
			coords[coordIndex] += num;
		} else { /* maly posun - 11 bitu */
			num = ((num & 0x1F) << 6) + parseNumber(arr, 1);
			num -= 1 << 10;
			coords[coordIndex] += num;
		}

		if (coordIndex) { /* mame dvojici souradnic - sup s nima do floatu */
			const lon = ((coords[0] * 360) / (1 << 28)) - 180;
			const lat = ((coords[1] * 180) / (1 << 28)) - 90;

			results.push([lon, lat]);
		}

		coordIndex = (coordIndex+1) % 2;
	}

	return results;
}

function isSame(coord1: TCoords, coord2: TCoords) {
	return coord1[0] === coord2[0] && coord1[1] === coord2[1];
}

function mergeGeometries(geoms: Array<string>): string {
	let allCoords = [];

	geoms.forEach(geom => {
		const geomCoords = stringToCoords(geom);

		if (allCoords.length > 0 && geomCoords.length > 0 && isSame(allCoords[allCoords.length - 1], geomCoords[geomCoords.length - 1])) {
			geomCoords.shift();
		}

		allCoords = allCoords.concat(geomCoords);
	});

	return coordsToString(allCoords);
}

function decNonZeroDelta(cVal: number, mVal: number) {
	return cVal - mVal + (cVal >= mVal ? 1 : 0);
}

function stringToAltitude(str: string): Array<number> {
	/* eslint-disable id-length */
	const results: Array<number> = [];
	let e0 = 0;
	const arr = str.trim().split("").reverse();
	let c1 = 0;
	let c2 = 0;
	let c3 = 0;
	let d1 = 0;
	let d2 = 0;
	let d3 = 0;

	while (arr.length) {
		c1 = parseNumber(arr, 1); /* podle prvnich dvou bitu poznam, o co jde */

		if ((c1 & 0x20) === 0x00) {
			const n = 1 + ((c1 & 0x18) >> 3);

			d1 = (c1 & 0x07) - 3;

			for (let i = 0; i < n; i++) {
				results.push(e0);
			}

			if (d1 !== 4) {
				e0 += d1;
				results.push(e0);
			}
		} else if ((c1 & 0x30) === 0x20) {
			d1 = decNonZeroDelta(c1 & 0x0F, 8);
			e0 += d1;
			results.push(e0);
		} else if ((c1 & 0x38) === 0x30) {
			c2 = parseNumber(arr, 1);
			d1 = decNonZeroDelta(c1 & 0x07, 4);
			d2 = decNonZeroDelta(c2 >> 3, 4);
			d3 = (c2 & 0x07) - (d2 < 0 ? 4 : 3);
			e0 += d1;
			results.push(e0);
			e0 += d2;
			results.push(e0);
			e0 += d3;
			results.push(e0);
		} else if ((c1 & 0x3C) === 0x38) {
			c2 = parseNumber(arr, 1);
			d1 = (c1 & 0x03) << 6;
			d2 = (c2 & 0x3F) - 128;
			e0 += d1 + d2;
			results.push(e0);
		} else {
			c2 = parseNumber(arr, 1);
			c3 = parseNumber(arr, 1);
			d1 = (c1 & 0x03) << 12;
			d2 = (c2 & 0x3F) << 6;
			d3 = (c3 & 0x3F) - 8192;
			e0 += d1 + d2 + d3;
			results.push(e0);
		}
	}

	return results;
}

export { coordsToString, stringToCoords, mergeGeometries, stringToAltitude };
