import dayjs from "dayjs";
import { Link } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { DAYJS_NICE_FORMAT_DATE } from "~/const";
import { IEvent } from "~/interfaces";
import { getEventDetailLink, getGalleryLink } from "~/utils/utils";

import "./style.less";

interface ISearchResultsTable {
	items: Array<IEvent>;
}

export default function SearchResultsTable({
	items,
}: ISearchResultsTable) {
	/* eslint-disable-next-line */
	function getDesc(event: IEvent) {
		if (event.search.desc.length === 0) {
			return <span>-</span>;
		}

		const desc = `${event.search.desc[0].toLocaleUpperCase()}${event.search.desc.slice(1)}`;

		if (desc.match(/[<]\w+/ug)) {
			return <span className="searchResultsTable__desc" dangerouslySetInnerHTML={{ __html: desc }} />;
		}

		return <span className="searchResultsTable__desc">
			{desc}
		</span>;
	}

	return <TableContainer component={Paper}>
		<Table sx={{ minWidth: 650 }} aria-label="simple table">
			<TableHead>
				<TableRow>
					<TableCell className="searchResultsTable__tableHead">Název</TableCell>
					<TableCell className="searchResultsTable__tableHead">Popis</TableCell>
					<TableCell className="searchResultsTable__tableHead center">Datum</TableCell>
					<TableCell className="searchResultsTable__tableHead center">Tracků</TableCell>
					<TableCell className="searchResultsTable__tableHead center">Kalendář</TableCell>
					<TableCell className="searchResultsTable__tableHead center">Galerie</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{ items.map(item => <TableRow key={item.seo} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} className="searchResultsTable__tableTr">
					<TableCell component="th" scope="row">
						{ item.title }
					</TableCell>
					<TableCell component="th" scope="row">
						{ getDesc(item) }
					</TableCell>
					<TableCell className="searchResultsTable__tableTdCenter searchResultsTable__tableTdPre">
						{ dayjs(item.date).format(DAYJS_NICE_FORMAT_DATE) }
					</TableCell>
					<TableCell className="searchResultsTable__tableTdCenter">
						{ item.allTracks.length }
					</TableCell>
					<TableCell className="searchResultsTable__tableTdCenter">
						<Link to={getEventDetailLink(item.seo, item.date, item.toDate)}>
							Událost
						</Link>
					</TableCell>
					<TableCell className="searchResultsTable__tableTdCenter">
						{ item.gallery && <Link to={getGalleryLink(item.seo, item.date, item.toDate)}>
							Galerie
						</Link> }
						{ !item.gallery && <span>-</span> }
					</TableCell>
				</TableRow>) }
			</TableBody>
		</Table>
	</TableContainer>;
}
