import { IDatabaseItem } from "../interfaces";

export const MEDICAL_ITEMS: Array<IDatabaseItem> = [
	{
		title: "Dr. Max Vitamin C Imuno Akut 700 mg 30 kapslí",
		price: 169,
		images: [{
			src: "01.png",
			large: "01.png",
		}],
	},
	{
		title: "Rowachol - žlučník",
		images: [{
			src: "02.jpg",
			large: "02.jpg",
		}],
	},
];
