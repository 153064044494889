import { getJSON } from "./php-api";
import { IPersonItem } from "~/interfaces";

let persons: Array<IPersonItem> = [];

export async function loadPersons(isAdmin: boolean) {
	if (isAdmin) {
		const data = await getJSON("persons");

		if (data.data) {
			persons = data.data;
		}
	}

	return Promise.resolve({});
}

export function getPersons() {
	return persons;
}
