import { useNavigate, useParams } from "react-router-dom";
import { ReactNode, useEffect } from "react";

import PageContent from "~/components/PageContent";
import { getGalleryDataByEvent, getEventBySeo } from "~/providers/events";
import { getCalendarDateLink, getEventTitle, getMapPositionFromEvent, getPhotoByDirection, updateFullscreenGalleryItem, valueSplitByDem } from "~/utils/utils";
import { globalStore } from "~/stores/global";
import GalleryHeader from "~/components/GalleryHeader";
import YearGallery from "~/components/YearGallery";
import { IEvent, IGallery, IGalleryItem } from "~/interfaces";
import { myUseState } from "~/hooks/myUseState";
import FullscreenGallery from "~/components/FullscreenGallery";
import Scroller from "~/components/Scroller";
import { useScrollToTop } from "~/hooks/useScrollToTop";
import Map from "~/components/Map";
import MyButton from "~/my/MyButton";
import Description from "~/components/Description";
import { getMapDays } from "~/utils/map";
import MonthData from "~/components/MonthData";

import "./style.less";

interface IParams {
	folderSeo: string;
	nameSeo: string;
	[key: string]: string;
}

interface IState {
	event: IEvent;
	gallery: IGallery;
	galleryImg: null | IGalleryItem;
	showMap: boolean;
	buttons: Array<ReactNode>;
	hasDays: boolean;
	desc: boolean;
	loaded: boolean;
	showDetailMarker: boolean;
}

export default function DetailPage() {
	const { folderSeo, nameSeo } = useParams<IParams>();
	const folder = valueSplitByDem(folderSeo).leftPart;
	const seo = `${folder}-${nameSeo}`;
	const showScroller = useScrollToTop();
	const { state, updateState, toggleState } = myUseState<IState>({
		event: null,
		gallery: null,
		galleryImg: null,
		showMap: false,
		buttons: [],
		hasDays: false,
		desc: false,
		loaded: false,
		showDetailMarker: false,
	});
	const { global } = globalStore(globalState => ({
		global: globalState.global,
	}));
	const navigate = useNavigate();

	function onBack() {
		const link = getCalendarDateLink(state.event.date);

		navigate(link);
	}

	function showPhoto(data: IGalleryItem) {
		updateState({
			galleryImg: updateFullscreenGalleryItem(data),
		});
	}

	function openPhoto(direction: number) {
		const photo = getPhotoByDirection(state.galleryImg.name, state.gallery.items, direction);

		photo && showPhoto(photo);
	}

	function prevPhoto() {
		openPhoto(-1);
	}

	function nextPhoto() {
		openPhoto(1);
	}

	function onClose() {
		updateState({
			galleryImg: null,
		});
	}

	function onPageLoad() {
		updateState({
			loaded: true,
		});
	}

	useEffect(() => {
		if (!state.loaded) {
			return;
		}

		const event = getEventBySeo(seo);
		const gallery = getGalleryDataByEvent(event);
		const hasCoordsAndNoGalleryTracks = event && event.coords && !event.gallery && !event.allTracks.length;
		const showMapButton = (event && event.allTracks.length > 0 && !event.gallery) || hasCoordsAndNoGalleryTracks;

		const buttons = [
			...showMapButton || global.loginData?.isAdmin
				? [<MyButton text={showMapButton ? "Mapa" : "Prázdná mapa"} onClick={() => toggleState("showMap")} variant="filled" key="key01" />]
				: [],
		];

		updateState({
			...event
				? {
					event,
					desc: event.desc.length > 0 || !!event.days,
					hasDays: !!event.days,
				}
				: {},
			gallery,
			buttons,
			showDetailMarker: hasCoordsAndNoGalleryTracks,
		});
	}, [seo, state.loaded]);

	const noDaysAndTitle = !state.hasDays && state.event && (state.event.desc.length > 0 || state.event.privateDesc);

	return <PageContent className="detailPage" loggedContent={true} onLoad={onPageLoad}>
		{ state.event && <>
			{ noDaysAndTitle && <>
				<GalleryHeader title={getEventTitle(state.event, "", true)} backTitle="Kalendář" first={true} onBack={state.gallery ? null : onBack} buttons={state.buttons} />
				<Description event={state.event} />
			</> }
			{ state.showMap && <div className="detailPage__trackList">
				<GalleryHeader title="Mapa" />
				<Map days={getMapDays(state.event)} position={getMapPositionFromEvent(state.event)} onClose={() => toggleState("showMap")} showDetailMarker={state.showDetailMarker} />
			</div> }
			{ state.gallery && <>
				<YearGallery gallery={state.gallery} title={state.hasDays || !noDaysAndTitle ? state.event.title : "Galerie události"} noEventAndBack={true} onClick={item => showPhoto(item)} downloadMode={true} hideDesc={true}
					showDebug={global.loginData?.isAdmin} calendarDate={state.event.date} beforeGallery={state.event.monthData ? <Description event={state.event} monthType="detail" /> : null} />
				<div className="detailPage__aboveScroller"></div>
				<Scroller visible={showScroller} className="galleryPage__sticky">
					{ state.event.monthData && <MonthData event={state.event} type="detail" /> }
				</Scroller>
			</> }
		</> }
		{ !state.event && <>
			<p>Událost nebyla nalezena!</p>
		</> }
		{ state.galleryImg && <FullscreenGallery item={state.galleryImg} onClose={onClose} onPrev={prevPhoto} onNext={nextPhoto} />}
	</PageContent>;
}
