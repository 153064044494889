import dayjs from "dayjs";
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';

import JumpToDate from "../JumpToDate";
import MyButton from "~/my/MyButton";

import "./style.less";

interface IThisDateGalleryControls {
	date: string;
	martinekDate: string;
	years: boolean;
	onDateChange?: (date: dayjs.Dayjs) => void;
	onYearsClick?: () => void;
}

export default function ThisDateGalleryControls({
	date,
	martinekDate,
	years = false,
	onDateChange = () => {},
	onYearsClick = () => {},
}: IThisDateGalleryControls) {
	function changeDate(direction: number) {
		let newDateObj = dayjs(date);

		if (direction < 0) {
			newDateObj = newDateObj.subtract(1, "days");
		} else {
			newDateObj = newDateObj.add(1, "days");
		}

		onDateChange(newDateObj);
	}

	return <div className="thisDateGalleryControls">
		<MyButton text={<SkipPreviousIcon />} onClick={() => changeDate(-1)} variant="filled" />
		<MyButton text="Roky" onClick={onYearsClick} variant="filled" active={years} />
		{ martinekDate && <MyButton text="Martínek" onClick={() => onDateChange(dayjs(martinekDate))} variant="filled" /> }
		<MyButton text="Dneska" onClick={() => onDateChange(dayjs())} variant="filled" />
		<JumpToDate className="thisDayGallery__jumpToDate" value={dayjs(date)} onChange={onDateChange} views={["day"]} format="DD. MM. YYYY" />
		<MyButton text={<SkipNextIcon />} onClick={() => changeDate(1)} variant="filled" />
	</div>;
}
