import { useEffect } from "react";

import { debounce } from "~/utils/utils";
import { myUseState } from "./myUseState";

interface IState {
	isVisible: boolean;
}

export function useScrollToTop() {
	const { state, updateState } = myUseState<IState>({
		isVisible: false,
	});

	useEffect(() => {
		/* eslint-disable no-magic-numbers */
		const elem = document.getElementById("app");
		const onScroll = debounce(() => {
			updateState({
				isVisible: elem.scrollTop > 25,
			});
		}, 200);

		elem.addEventListener("scroll", onScroll);

		return () => {
			elem.removeEventListener("scroll", onScroll);
		};
	}, []);

	return state.isVisible;
}
