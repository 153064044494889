import { IDatabaseItem } from "../interfaces";

export const ALCOHOL_ITEMS: Array<IDatabaseItem> = [
	{
		title: "Hnusná vína",
		desc: "Chateu Valtice - všechny jakostní, B/V Ryzlink Vlašský 2021, Ludwig - Chardonnay, Veltlínské zelené",
		images: [{
			src: "01.jpg",
			large: "01-large.jpg",
		}],
	},
	{
		title: "Corsario - Reserve Cask",
		images: [{
			src: "02.jpg",
			large: "02-large.jpg",
		}],
	},
	{
		title: "Goral - Malinovica",
		images: [{
			src: "03.jpg",
			large: "03-large.jpg",
		}],
	},
	{
		title: "Veritas - 2017 agrolip pozdní sběr",
		images: [{
			src: "04.jpg",
			large: "04-large.jpg",
		}],
	},
];
