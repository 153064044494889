import { useEffect, useRef } from "react";
import dayjs from "dayjs";

import { IStoryTellerItem } from "~/interfaces";
import { getClassName } from "~/utils/utils";
import { myUseState } from "~/hooks/myUseState";
import { DAYJS_NICE_FORMAT_DATE } from "~/const";

import "./style.less";

export type TStoryTellerScreenAnimation = "none" | "anim-show" | "anim-hide";

interface IState {
	loaded: boolean;
}

interface IStoryTellerScreen {
	data: IStoryTellerItem;
	anim?: TStoryTellerScreenAnimation;
	visible?: boolean;
	pause?: boolean;
}

const INIT_LOAD = 100;

export default function StoryTellerScreen({
	data,
	anim = "none",
	visible = true,
	pause = false,
}: IStoryTellerScreen) {
	const videoRef = useRef<HTMLVideoElement>(null);
	const { state, updateState } = myUseState<IState>({
		loaded: false,
	});

	function createTextElem() {
		const dateStr = data.date
			? dayjs(data.date).format(DAYJS_NICE_FORMAT_DATE)
			: "";

		return <div className="storyTellerScreen__textStyle">
			<span className="storyTellerScreen__textStyleLeft">
				{ data.ind || "" }
			</span>
			<span className="storyTellerScreen__textStyleCenter">
				{ data.text }
			</span>
			<span className="storyTellerScreen__textStyleRight">
				{ dateStr }
			</span>
		</div>;
	}

	useEffect(() => {
		setTimeout(() => {
			updateState({
				loaded: true,
			});
		}, INIT_LOAD);
	}, []);

	useEffect(() => {
		if (videoRef.current) {
			if (pause) {
				videoRef.current.pause();
			} else {
				videoRef.current.play();
			}
		}
	}, [pause]);

	return <div className={getClassName(["storyTellerScreen", anim === "none" ? "" : `${anim}-start`, anim === "none" || !state.loaded ? "" : anim, visible ? "" : "hide"])}>
		{ data.type === "text" && <div className="storyTellerScreen__typeText">
			{ createTextElem() }
		</div> }
		{ data.type === "image" && <div className="storyTellerScreen__typeImage">
			<div className="storyTellerScreen__typeImage__img">
				<img src={data.path} alt="" className="storyTellerScreen__typeImage__imgElem" />
			</div>
			<div className="storyTellerScreen__typeImage__text">
				{ createTextElem() }
			</div>
		</div> }
		{ data.type === "video" && <div className="storyTellerScreen__typeVideo">
			<div className="storyTellerScreen__typeVideo__video">
				<video src={data.path} poster={data.videoCover} ref={videoRef} controls={false} autoPlay={true} className="storyTellerScreen__typeVideo__videoElem" />
			</div>
			<div className="storyTellerScreen__typeImage__text">
				{ createTextElem() }
			</div>
		</div> }
	</div>;
}
