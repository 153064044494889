/* eslint-disable react/no-array-index-key */
import { useEffect } from "react";

import PageContent from "~/components/PageContent";
import HeaderTitle from "~/components/HeaderTitle";
import { myUseState } from "~/hooks/myUseState";

import MyGallery from "~/my/MyGallery";
import YearGalleryVideoItem from "~/components/YearGalleryVideoItem";
import YearGalleryImageItem from "~/components/YearGalleryImageItem";
import { IGalleryItem } from "~/interfaces";
import FullscreenGallery from "~/components/FullscreenGallery";
import { getPhotoByDirection } from "~/utils/utils";
import { getUploadedFiles } from "~/providers/php-api";
import Loader from "~/components/Loader";

interface IState {
	items: Array<IGalleryItem>;
	loading: boolean;
	galleryImg: null | IGalleryItem;
	flag: boolean;
}

export default function UploadedFilesPage() {
	const { state, updateState } = myUseState<IState>({
		items: [],
		loading: true,
		galleryImg: null,
		flag: false,
	});

	async function loadData() {
		const uploadedData = await getUploadedFiles();

		updateState({
			loading: false,
			items: uploadedData.data || [],
		});
		// bohuzel se po nastaveni nenactou obrazky TODO
		/* eslint-disable no-magic-numbers */
		setTimeout(() => {
			updateState({
				flag: true,
			});
		}, 250);
	}

	function showPhoto(data: IGalleryItem) {
		updateState({
			galleryImg: data,
		});
	}

	function openPhoto(direction: number) {
		const photo = getPhotoByDirection(state.galleryImg.name, state.items, direction);

		photo && showPhoto(photo);
	}

	function prevPhoto() {
		openPhoto(-1);
	}

	function nextPhoto() {
		openPhoto(1);
	}

	function onClose() {
		updateState({
			galleryImg: null,
		});
	}

	useEffect(() => {
		loadData();
	}, []);

	return <PageContent className="uploadedFilesPage" loggedContent={true}>
		<HeaderTitle title="Nahrané soubory" />
		<MyGallery>
			{state.items.map((item, ind) => item.isVideo
				? <YearGalleryVideoItem key={`${ind}`} data={item} seo="" />
				: <YearGalleryImageItem key={`${ind}`} data={item} seo="" onClick={() => showPhoto(item)} />)}
		</MyGallery>
		{ !state.loading && state.items.length === 0 && <p>Nejsou žádné nahrané soubory</p> }
		{ state.loading && <Loader /> }
		{ state.galleryImg && <FullscreenGallery item={state.galleryImg} onClose={onClose} onPrev={prevPhoto} onNext={nextPhoto} />}
	</PageContent>;
}
