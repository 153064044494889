import LandscapeIcon from '@mui/icons-material/Landscape';
import InfoIcon from '@mui/icons-material/Info';

import MyButton from "~/my/MyButton";
import { formatDistance, getClassName, getTrackColorByType, transTrackType } from "~/utils/utils";
import { ITrackItem } from "~/interfaces";
import { IDayData } from "~/map-interfaces";

import "./style.less";

interface IMapDays {
	days: Array<IDayData>;
	activeDate?: string;
	showTrackParts?: boolean;
	onDate?: (date: string) => void;
	onHeightProfileClick?: (track: ITrackItem) => void;
	onTrackClick?: (date: string, track: ITrackItem) => void;
	onTrackInfoClick?: (track: ITrackItem) => void;
}

export default function MapDays({
	days = [],
	activeDate = "",
	showTrackParts = false,
	onDate = () => {},
	onHeightProfileClick = () => {},
	onTrackClick = () => {},
	onTrackInfoClick = () => {},
}: IMapDays) {
	function getTrackName(trackData: ITrackItem, ind: number, tracksLen: number) {
		const trackName = tracksLen === 1
			? `Výlet`
			: `Výlet ${ind + 1}`;
		let distance = trackData.distances.all;

		// pouze pro 1 geometrii neresime all
		if (trackData.geometries.length === 1) {
			distance = trackData.geometries[0].distance;
		}

		return <>
			{trackData.desc || trackName} {formatDistance(distance)}
		</>;
	}

	/* eslint-disable react/no-array-index-key */
	return <div className="mapContainer__settings map__dialog">
		{ days.map((dayData, ind) => <div className={getClassName(["mapContainer__settingsItem", dayData.date === activeDate ? "active" : ""])} key={`settings-${ind}`}>
			<div className="mapContainer__settingsItemHeader">
				<span className="mapContainer__settingsItemHeaderTitle">
					{ dayData.title }
				</span>
				<span className="mapContainer__settingsItemHeaderShow">
					{ (ind > 0 || (ind === 0 && days.length > 1)) && <MyButton text="Zobrazit" variant="outlined" onClick={() => onDate(dayData.date)} /> }
				</span>
			</div>
			{ dayData.subTitle && <div className="mapContainer__settingsItemSubtitle">
				{ dayData.subTitle }
			</div> }
			<div className="mapContainer__settingsItemTracks">
				{ dayData.tracks.map((track, trackInd) => <div className="mapContainer__trackItem" key={`settings-${ind}-track-${trackInd}`}>
					<button type="button" className="mapContainer__trackItemAltitude" style={{ backgroundColor: getTrackColorByType(track.geometries[0].type) }}
						onClick={() => onHeightProfileClick(track)} title="Výškový profil">
						<LandscapeIcon sx={{ fill: "#fff", width: "18px", height: "18px", position: "relative", left: "-5px" }} />
					</button>
					{ track.geometries.length > 1 && <button type="button" className="mapContainer__trackItemInfo" style={{ backgroundColor: getTrackColorByType(track.geometries[0].type) }}
						onClick={() => onTrackInfoClick(track)} title="Informace o stopě">
						<InfoIcon sx={{ fill: "#fff", width: "18px", height: "18px", position: "relative", left: "-5px" }} />
					</button> }
					<span className="mapContainer__trackItemTitle" onClick={() => onTrackClick(dayData.date, track)}>
						{ getTrackName(track, trackInd, dayData.tracks.length) }
						{ (track.geometries.length > 1 && showTrackParts) && <>
							<span className="mapContainer__trackItemTitles">
								{ track.geometries.map((geometry, geometryInd) => <span key={`settings-${ind}-track-${trackInd}-${geometryInd}`} className="mapContainer__trackItemMultiTitle">
									- { transTrackType(geometry.type) } {formatDistance(geometry.distance)}
								</span>)}
							</span>
						</> }
					</span>
				</div>)}
			</div>
		</div>)}
	</div>;
}
