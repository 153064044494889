/* eslint-disable no-magic-numbers */
export const EMOTIONS = [
	"krivonozka",
	"myvalove",
	"opicka",
	"hrabak",
	"zra",
	"kvakalka",
	"bagratkotatinek",
	"kuratko",
	"rysatko",
	"ufuf",
	"vydra",
	"papousek",
	"gaga",
	"ovce",
	"kocicky",
	"mikulasi",
	"patent",
	"jelen",
	"kroky",
	"lama",
];

function generateEmoji(name: string, count = 1) {
	for (let ind = 0; ind < count; ind++) {
		EMOTIONS.push(`${name}${ind > 0 ? ind + 1 : ""}`);
	}
}

generateEmoji("kocicka", 2);
generateEmoji("myval", 7);
generateEmoji("ryba", 3);
generateEmoji("sova", 14);
generateEmoji("gula", 3);
generateEmoji("bagratko", 3);
generateEmoji("bobatko", 2);
generateEmoji("kocicon", 2);
generateEmoji("skupinka", 3);
generateEmoji("kokokyka", 2);
generateEmoji("hadatko", 2);
generateEmoji("marta", 3);
generateEmoji("tomi", 2);

EMOTIONS.sort((aItem, bItem) => {
	const aName = (aItem.match(/[a-zA-Z]+/u) || [""])[0];
	const aNumberValue = aItem.replace(aName, "");
	const aNumber = aNumberValue ? parseFloat(aNumberValue) : 0;
	const bName = (bItem.match(/[a-zA-Z]+/u) || [""])[0];
	const bNumberValue = bItem.replace(aName, "");
	const bNumber = bNumberValue ? parseFloat(bNumberValue) : 0;

	if (aName === bName) {
		return aNumber - bNumber;
	}

	return aItem.localeCompare(bItem);
});
