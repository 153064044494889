import { DatePicker, DateView } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/cs";

interface IJumpToDate {
	value: dayjs.Dayjs;
	onChange?: (date: dayjs.Dayjs) => void;
	className?: string;
	format?: string;
	views?: Array<DateView>;
}

export default function JumpToDate({
	value,
	onChange = () => {},
	className = "",
	// rijen 2023
	format = "MMMM YYYY",
	views = ["month", "year"],
}: IJumpToDate) {
	return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="cs">
		<DatePicker format={format} value={dayjs(value)} className={className} onAccept={onChange} label="Skočit na datum" views={views} />
	</LocalizationProvider>;
}
