import { create } from 'zustand';
import dayjs from "dayjs";

import { IGallery, ILatestYearVisits, TService } from "~/interfaces";
import { getItem, getJSONItem, setItem } from "~/utils/localstorage";
import { getVisitItem, isLocalhost } from "~/utils/utils";
import { DAYJS_FORMAT } from '~/const';
import { getLatestGalleryVisits, updateVisitDataByGallery } from "~/providers/events";
import { insertVisit } from "~/providers/php-api";

interface IOldVisitData {
	date: string;
	permissions: number;
}

interface ISavedStoreData {
	service: TService;
	makuderoviVisit: ILatestYearVisits;
	romanVisit: ILatestYearVisits;
	messengerUser: string;
	messengerLastId: number;
	visitDate: string;
	visitPermissions: number;
	audioVolume: number;
	useRetina: boolean;
}

interface ISavedStore {
	saved: ISavedStoreData;
	setFromLS: (savedData: ISavedStoreData) => void;
	setGalleryVisit: (visitData: ILatestYearVisits) => void;
	updateGalleryVisitCount: (gallery: IGallery) => void;
	allGalleryVisited: () => void;
	logVisit: (permissions: number, isAdmin: boolean) => void;
	setMessengerUser: (messengerUser: string) => void;
	setMessengerLastId: (messengerLastId: number) => void;
	setAudioVolume: (audioVolume: number) => void;
	setRetina: (useRetina: boolean) => void;
}

const LS_KEY = "savedStoreData";
const LS_KEY_VISIT = "ssVisit";
const LS_KEY_MES_NAME = "messengerName";
const LS_KEY_MES_LAST_VISIT = "messengerLastVisit";
const LS_LAST_VISIT_V20 = "makuderoviVisit20";
const LS_LAST_VISIT_ACTIONS = "actionsRoman20";

const DEBUG = false;
const DEF_DATA: ISavedStoreData = {
	service: "makuderovi",
	makuderoviVisit: {},
	romanVisit: {},
	messengerUser: "Roman",
	messengerLastId: 0,
	visitDate: "",
	visitPermissions: 0,
	audioVolume: 100,
	useRetina: false,
};

function saveToLs(savedData: ISavedStoreData) {
	const { service, ...rest } = savedData;

	//console.log(["save", rest]);
	setItem(LS_KEY, JSON.stringify(rest));
}

async function sendVisitToBE(isAdmin: boolean) {
	const data = getVisitItem(isAdmin);

	await insertVisit(data);
}

export const savedStore = create<ISavedStore>(set => ({
	saved: {
		...DEF_DATA,
	},
	setFromLS: savedData => set(() => ({
		saved: savedData,
	})),
	setGalleryVisit: visitData => set(state => {
		const newSaved: ISavedStoreData = {
			...state.saved,
			...state.saved.service === "roman"
				? {
					romanVisit: visitData,
				}
				: {
					makuderoviVisit: visitData,
				},
		};

		saveToLs(newSaved);

		return {
			saved: newSaved,
		};
	}),
	updateGalleryVisitCount: gallery => set(state => {
		const newVisit = updateVisitDataByGallery(state.saved.service === "roman" ? state.saved.romanVisit : state.saved.makuderoviVisit, gallery);
		const newSaved: ISavedStoreData = {
			...state.saved,
			...state.saved.service === "roman"
				? {
					romanVisit: newVisit,
				}
				: {
					makuderoviVisit: newVisit,
				},
		};

		saveToLs(newSaved);

		return {
			saved: newSaved,
		};
	}),
	allGalleryVisited: () => set(state => {
		const newVisit = getLatestGalleryVisits();
		const newSaved: ISavedStoreData = {
			...state.saved,
			...state.saved.service === "roman"
				? {
					romanVisit: newVisit,
				}
				: {
					makuderoviVisit: newVisit,
				},
		};

		saveToLs(newSaved);

		return {
			saved: newSaved,
		};
	}),
	logVisit: (permissions, isAdmin) => set(state => {
		if (!DEBUG && isLocalhost()) {
			/* eslint-disable-next-line */
			console.log(`Skip log visit for localhost`);

			return state;
		}

		const newSaved: ISavedStoreData = {
			...state.saved,
		};
		const curDate = dayjs().format(DAYJS_FORMAT);
		let saveToCache = true;

		if (state.saved.visitPermissions === permissions && state.saved.visitDate === curDate) {
			saveToCache = DEBUG || false;
		}

		if (saveToCache) {
			newSaved.visitDate = curDate;
			newSaved.visitPermissions = permissions;
			saveToLs(newSaved);
			sendVisitToBE(isAdmin);

			return {
				saved: newSaved,
			};
		}

		return state;
	}),
	setMessengerUser: messengerUser => set(state => {
		const newSaved: ISavedStoreData = {
			...state.saved,
			messengerUser,
		};

		saveToLs(newSaved);

		return {
			saved: newSaved,
		};
	}),
	setMessengerLastId: messengerLastId => set(state => {
		const newSaved: ISavedStoreData = {
			...state.saved,
			messengerLastId,
		};

		saveToLs(newSaved);

		return {
			saved: newSaved,
		};
	}),
	setAudioVolume: audioVolume => set(state => {
		const newSaved: ISavedStoreData = {
			...state.saved,
			audioVolume,
		};

		saveToLs(newSaved);

		return {
			saved: newSaved,
		};
	}),
	setRetina: useRetina => set(state => {
		const newSaved: ISavedStoreData = {
			...state.saved,
			useRetina,
		};

		saveToLs(newSaved);

		return {
			saved: newSaved,
		};
	}),
}));

export function savedStoreInit(service: TService = "makuderovi") {
	const savedData = getJSONItem<ISavedStoreData>(LS_KEY);

	// novy format - smazat pak stare klice?
	if (savedData) {
		savedStore.getState().setFromLS({
			service,
			...savedData,
		});
	} else {
		const visitData = getJSONItem<IOldVisitData>(LS_KEY_VISIT);
		const messDataName = getItem(LS_KEY_MES_NAME);
		const messDataLasVisit = getItem(LS_KEY_MES_LAST_VISIT);
		const newData: ISavedStoreData = {
			...DEF_DATA,
			service,
			romanVisit: getJSONItem<ILatestYearVisits>(LS_LAST_VISIT_ACTIONS) || {},
			makuderoviVisit: getJSONItem<ILatestYearVisits>(LS_LAST_VISIT_V20) || {},
			...visitData
				? {
					visitDate: visitData.date,
					visitPermissions: visitData.permissions,
				}
				: {},
			...messDataName
				? {
					messengerUser: messDataName,
				}
				: {},
			...messDataLasVisit
				? {
					messengerLastId: parseFloat(messDataLasVisit),
				}
				: {},
		};

		savedStore.getState().setFromLS(newData);
	}
}
