import "./style.less";

interface IMyGalleryItemDate {
	title: string;
}

export default function MyGalleryItemDate({
	title,
}: IMyGalleryItemDate) {
	return <div className="myGalleryItemDate">
		<div className="myGalleryItemDate__triangle" />
		<div className="myGalleryItemDate__date">
			{ title }
		</div>
	</div>;
}
