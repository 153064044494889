import { getClassName } from "~/utils/utils";

import "./style.less";

export interface IInfoLineItem {
	text: string;
	active?: boolean;
	past?: boolean;
}

interface IInfoLine {
	items: Array<IInfoLineItem>;
}

export default function InfoLine({
	items,
}: IInfoLine) {
	/* eslint-disable react/no-array-index-key */
	return <div className="infoLine">
		{ items.map((item, ind) => <span className={getClassName(["infoLine__item", item.active ? "active" : "", item.past ? "past" : ""])} key={ind} dangerouslySetInnerHTML={{ __html: item.text }} />) }
	</div>;
}
