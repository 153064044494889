/* eslint-disable no-magic-numbers */
/* eslint-disable no-console */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { getClassName, formatNumberToThousands } from "~/utils/utils";
import { IYearsSalaryItem } from "~/interfaces";

import "./style.less";

interface IYearsSalaryTable {
	data: Array<IYearsSalaryItem>;
}

export default function YearsSalaryTable({
	data,
}: IYearsSalaryTable) {
	function getInfo(itemData: IYearsSalaryItem) {
		const parts: Array<string> = [];

		if (itemData.children) {
			parts.push(`dětí ${itemData.children}`);
		}

		if (itemData.byod) {
			parts.push(`byod ${formatNumberToThousands(itemData.byod)}`);
		}

		if (itemData.yearDiscount) {
			parts.push(`roční zúčtování ${formatNumberToThousands(itemData.yearDiscount)}`);
		}

		if (itemData.desc) {
			parts.push(`${itemData.desc[0].toLocaleLowerCase()}${itemData.desc.slice(1)}`);
		}

		return parts.join(", ");
	}

	return <TableContainer component={Paper} sx={{ maxHeight: 640 }}>
		<Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
			<TableHead>
				<TableRow>
					<TableCell className="searchPersonTable__tableHead">Rok</TableCell>
					<TableCell className="searchPersonTable__tableHead center">Měsíc</TableCell>
					<TableCell className="searchPersonTable__tableHead center">Výplata</TableCell>
					<TableCell className="searchPersonTable__tableHead center">Pohotovost</TableCell>
					<TableCell className="searchPersonTable__tableHead center">Prémie</TableCell>
					<TableCell className="searchPersonTable__tableHead center">Hrubá mzda</TableCell>
					<TableCell className="searchPersonTable__tableHead center">Čistá mzda</TableCell>
					<TableCell className="searchPersonTable__tableHead center">Info</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{ data.map(item => <TableRow key={`${item.year}_${item.month}`} className={getClassName(["yearsSalaryTable__row", item.isQuart ? "quart-item" : ""])} onClick={() => console.log(item)}>
					<TableCell>
						{ item.year }
					</TableCell>
					<TableCell className="searchResultsTable__tableTdCenter">
						{ item.month }
					</TableCell>
					<TableCell className="searchResultsTable__tableTdCenter yearsSalaryTable__priceCell">
						{ item.base > 0 ? formatNumberToThousands(item.base) : "" }
					</TableCell>
					<TableCell className="searchResultsTable__tableTdCenter yearsSalaryTable__priceCell">
						{ item.emergency > 0 ? formatNumberToThousands(item.emergency) : "" }
					</TableCell>
					<TableCell className="searchResultsTable__tableTdCenter yearsSalaryTable__priceCell">
						{ item.premium > 0 ? formatNumberToThousands(item.premium) : "" }
					</TableCell>
					<TableCell className="searchResultsTable__tableTdCenter yearsSalaryTable__priceCell">
						{ formatNumberToThousands(item.calcSalary.salary, false) }
					</TableCell>
					<TableCell className="searchResultsTable__tableTdCenter yearsSalaryTable__priceCell">
						{ formatNumberToThousands(item.calcSalary.netto, false) }
					</TableCell>
					<TableCell className="searchResultsTable__tableTdCenter">
						{ getInfo(item) }
					</TableCell>
				</TableRow>)}
			</TableBody>
		</Table>
	</TableContainer>;
}
