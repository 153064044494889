import { Fragment } from "react";
import { Link } from "react-router-dom";

import { getClassName } from "~/utils/utils";

import "./style.less";

interface ISwitcher {
	items: Array<string>;
	getLink: (value: string) => string;
	className?: string;
	active?: string;
	responsiveCount?: number;
}

export default function Switcher({
	items,
	getLink,
	active = items.length ? items[0] : "",
	className = "",
	/* eslint-disable-next-line */
	responsiveCount = 11,
}: ISwitcher) {
	return <div className={getClassName(["switcher", className, items.length > responsiveCount ? "responsive" : ""])}>
		<div className="switcher__items">
			{ items.map((item, ind) => <Fragment key={item}>
				<Link to={getLink(item)} className={getClassName(["switcher__item", item === active ? "active" : ""])}>
					{ item }
				</Link>
				{ ind !== items.length - 1 && <span className="switcher__seperator" /> }
			</Fragment>)}
		</div>
	</div>;
}
