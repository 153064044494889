import { useEffect, useRef } from "react";

import { IProfileData, getProfile } from "~/providers/altitude";
import { myUseState } from "~/hooks/myUseState";
import CloseBtn from "../CloseBtn";
import HeightProfile from "../HeightProfile";
import { IMapLoad } from "~/hooks/useMap";
import { TCoords } from "~/interfaces";
import { ICreateHeightProfileMarkerData, createHeightProfileMarker } from "~/utils/map";
import Loader from "../Loader";

import "./style.less";

interface IMapRefData {
	heightProfileMarker: ICreateHeightProfileMarkerData;
}

interface IState {
	loading: boolean;
	profileData: IProfileData;
}

interface IHeightProfileDialog {
	trackGeometry: string;
	mapData: IMapLoad;
	onClose?: () => void;
}

export default function HeightProfileDialog({
	trackGeometry,
	mapData,
	onClose = () => {},
}: IHeightProfileDialog) {
	const { state, updateState } = myUseState<IState>({
		loading: true,
		profileData: null,
	});
	const mapRefData = useRef<IMapRefData>({
		heightProfileMarker: null,
	});

	function onEnter(coords: TCoords, altitude: number) {
		if (!mapRefData.current.heightProfileMarker) {
			const heightProfileMarker = createHeightProfileMarker({
				text: altitude.toString(),
				coords,
			});

			heightProfileMarker.addToMap(mapData.map);
			mapRefData.current.heightProfileMarker = heightProfileMarker;
		}
	}

	function onMove(coords: TCoords, altitude: number) {
		if (mapRefData.current.heightProfileMarker) {
			mapRefData.current.heightProfileMarker.marker.setLngLat(coords);
			mapRefData.current.heightProfileMarker.setAltitude(altitude);
		}
	}

	function onLeave() {
		if (mapRefData.current.heightProfileMarker) {
			mapRefData.current.heightProfileMarker.marker.remove();
			mapRefData.current.heightProfileMarker = null;
		}
	}

	async function loadData() {
		updateState({
			loading: true,
		});

		try {
			const profileData = await getProfile(trackGeometry);

			updateState({
				profileData,
				loading: false,
			});
		} catch (exc) {
			/* eslint-disable-next-line */
			console.log(exc);
			updateState({
				loading: false,
			});
		}
	}

	useEffect(() => {
		loadData();
	}, []);

	return <div className="heightProfileDialog map__dialog">
		<CloseBtn className="heightProfileDialog__btnClose" onClick={onClose} />
		{ state.loading && <Loader className="heightProfileDialog__loader" /> }
		{ state.profileData && <HeightProfile data={state.profileData} onEnter={onEnter} onLeave={onLeave} onMove={onMove} colors={{ minMaxLine: "#828282" }} /> }
	</div>;
}
