import { useEffect } from "react";
import maplibregl from "maplibre-gl";

import PageContent from "~/components/PageContent";
import { myUseState } from "~/hooks/myUseState";
import { getWorldMap, getCsDistricts } from "~/providers/map";
import { IMyGeoJSON } from "~/interfaces";
import Map from "~/components/Map";
import { DEFAULT_MAP_POSITION } from "~/const";
import HeaderTitle from "~/components/HeaderTitle";
import Tags from "~/components/Tags";
import { getGetWorldMapDiff } from "~/providers/events";
import { COMMON_DATA } from "~/data/common";
import { IMapLoad } from "~/hooks/useMap";

import "./style.less";

interface IState {
	mapData: IMapLoad;
	worldMap?: IMyGeoJSON;
	csDistricts?: IMyGeoJSON;
	geoJsons?: Array<IMyGeoJSON>;
}

export default function WorldMapPage() {
	const { state, updateState } = myUseState<IState>({
		mapData: null,
		worldMap: null,
		csDistricts: null,
		geoJsons: [],
	});
	const worldMapNews = getGetWorldMapDiff();
	const position = {
		...DEFAULT_MAP_POSITION,
		z: 4,
	};
	const states = COMMON_DATA.visitedStates.map(item => item.name);
	const statesSelected = worldMapNews.visitedStates.map(item => item.name);
	const districts = COMMON_DATA.visitedDistricts.map(item => item.name);
	const districtsSelected = worldMapNews.visitedDistricts.map(item => item.name);

	function onStateClick(stateName: string) {
		const id = COMMON_DATA.visitedStates.filter(item => item.name === stateName)[0].id;
		const feature = state.worldMap.features.filter(item => item.properties.a2 === id)[0];

		state.mapData.map.fitBounds(feature.properties.bbox as unknown as maplibregl.LngLatBoundsLike);
	}

	function onDistrictClick(districtName: string) {
		const id = COMMON_DATA.visitedDistricts.filter(item => item.name === districtName)[0].id;
		const feature = state.csDistricts.features.filter(item => item.properties.id === id)[0];

		state.mapData.map.fitBounds(feature.properties.bbox as unknown as maplibregl.LngLatBoundsLike);
	}

	async function load() {
		const worldMap = await getWorldMap();
		const csDistricts = await getCsDistricts();

		updateState({
			worldMap,
			csDistricts,
			geoJsons: [
				worldMap,
				csDistricts,
			],
		});
	}

	useEffect(() => {
		load();
	}, []);

	return <PageContent className="tracksPage" loggedContent={true}>
		<HeaderTitle title="Stírací mapa" />
		<div className="tracksPage__map">
			{ state.geoJsons.length > 0 && <Map geoJsons={state.geoJsons} position={position} hasClose={false} onLoad={mapData => updateState({ mapData })} /> }
		</div>
		{ state.worldMap && <>
			<HeaderTitle title="Státy" className="tracksPage__visitedTitle" />
			<Tags items={states} selected={statesSelected} onClick={onStateClick} />
		</> }
		{ state.csDistricts && <>
			<HeaderTitle title="Okresy" className="tracksPage__visitedTitle" />
			<Tags items={districts} selected={districtsSelected} onClick={onDistrictClick} />
		</> }
	</PageContent>;
}
