/* eslint-disable no-magic-numbers */

// V současnosti zaměstnanec platí 23% daň z příjmů, které přesahují částku 161 296 Kč. Do této sumy platí 15% daň. Nově bude tato částka zhruba ve výši 131 901 Kč.
// [zamestnanec, zamestnavatel]
export const SALARY_MODIFICATORS = {
	health: [4.5, 9],
	ill: [0.6, 0],
	social: [6.5, 24.8],
	tax: [[15, 23], [0, 0]],
};

export const PREMIUM_SALARY = 131901;

export const SEZNAM = {
	premiumRatio: 0.3,
	emergencySalaryRatio: 155,
	emergencySalary: 0.1,
};

export const SALARY_DISCOUNTS = {
	taxPayer: [{
		fromYear: 2014,
		toYear: 2020,
		price: 2070,
	}, {
		fromYear: 2021,
		toYear: 2023,
		price: 2320,
	}, {
		fromYear: 2024,
		toYear: 2030,
		price: 2570,
	}],
	children: [1267, 1860, 2320],
};

export const SEZNAM_BONUS = {
	byod: 1240,
	food: 22 * 55,
};
