import PageContent from "~/components/PageContent";
import HeaderTitle from "~/components/HeaderTitle";
import MyButton from "~/my/MyButton";
import { getStoryTeller2024, IStoryTeller2024Item } from "~/data/story-teller-2024";
import { ILogin, IStoryTellerItem } from "~/interfaces";
import { myUseState } from "~/hooks/myUseState";
import StoryTeller from "~/components/StoryTeller";
import Tags from "~/components/Tags";
import { isLocalhost } from "~/utils/utils";

import "./style.less";

interface IState {
	option: number;
	items: Array<IStoryTellerItem>;
	duration: string;
	test: boolean;
	birthdayItems: IStoryTeller2024Item[];
	allItems: IStoryTeller2024Item[];
}

const DURATIONS = ["3s", "5s", "7s", "10s"];
const OPTIONS_CHOICES = {
	NONE: 0,
	MORNING: 1,
	LUNCH: 10,
	DESERT: 12,
	PRESENTS: 13,
	DINNER: 15,
	SPECIAL: 20,
};

export default function Lenka2024Page() {
	const localhost = isLocalhost();
	const birthDurations = [
		...localhost ? ["1s"] : [],
		...DURATIONS,
	];
	const { state, updateState } = myUseState<IState>({
		option: OPTIONS_CHOICES.SPECIAL,
		items: [],
		duration: localhost ? birthDurations[0] : birthDurations[1],
		test: false,
		birthdayItems: [],
		allItems: [],
	});

	function onEnd() {
		updateState({
			items: [],
		});
	}

	function setDuration(duration: string) {
		updateState({
			duration,
		});
	}

	function getDuration() {
		return parseFloat(state.duration.replace("s", ""));
	}

	function onLoad(loginData: ILogin) {
		updateState({
			...getStoryTeller2024(loginData.type),
		});
	}

	return <PageContent className="lenka2024Page" adminContent={true} onLoad={onLoad}>
		<HeaderTitle title="Narozeniny Lenka 2024" />
		{ state.option === OPTIONS_CHOICES.NONE && <p>V den narozenin se zde budou postupně objevovat informace o tvém narozeninovém dnu 😊</p> }
		{ state.option >= OPTIONS_CHOICES.MORNING && <>
			<HeaderTitle title="Snídaně" />
			<p>Na snídani bude Croque-Monsieur, ale snad nebude tak velký jako naposledy v Ostravě 😈</p>
			{ state.option === OPTIONS_CHOICES.MORNING && <p>Další informace bude ve <strong>{OPTIONS_CHOICES.LUNCH}h</strong></p> }
		</> }
		{ state.option >= OPTIONS_CHOICES.LUNCH && <>
			<HeaderTitle title="Oběd" />
			<p>Jako polévka bude francouzská cibulačka zapečená bagetou a sýrem.</p>
			<p>Na oběd bude grilovaný hovězí stejk, grilované mrkve a višňová omáčka.</p>
			{ state.option === OPTIONS_CHOICES.LUNCH && <p>Další informace bude ve <strong>{OPTIONS_CHOICES.DESERT}h</strong></p> }
		</> }
		{ state.option >= OPTIONS_CHOICES.DESERT && <>
			<HeaderTitle title="Dezert" />
			<p>Jako dezert bude ananasový nepečený dort.</p>
			{ state.option === OPTIONS_CHOICES.DESERT && <p>Další informace bude ve <strong>{OPTIONS_CHOICES.PRESENTS}h</strong></p> }
		</> }
		{ state.option >= OPTIONS_CHOICES.PRESENTS && <>
			<HeaderTitle title="Dárky" />
			<p>Užij si dárky, ale musíš si je sama najít a zjistit pomocí těchto 3. příběhů.</p>
			<p>Večer se pak odkryje poslední bod, který bude možná lepší, když si projdeš sama nebo je projdeme spolu 😘</p>
			<HeaderTitle title="Dárky - příběhy" />
			<div className="lenka2024Page__durations">
				<span>Trvání stránky příběhu:</span><Tags items={birthDurations} selected={[state.duration]} onClick={value => setDuration(value)} />
			</div>
			<div className="lenka2024Page__choices">
				{ state.birthdayItems.map(item => <MyButton key={item.text} text={item.text} variant="filled" onClick={() => updateState({ items: item.items })} />)}
			</div>
			{ state.option === OPTIONS_CHOICES.PRESENTS && <p>Další informace bude ve <strong>{OPTIONS_CHOICES.DINNER}h</strong></p> }
		</> }
		{ state.option >= OPTIONS_CHOICES.DINNER && <>
			<HeaderTitle title="Večeře" />
			<p>Na večeři bude plpa z 🐄🐄🐄</p>
			{ state.option === OPTIONS_CHOICES.DINNER && <p>Další informace bude ve <strong>{OPTIONS_CHOICES.SPECIAL}h</strong></p> }
		</> }
		{ state.option >= OPTIONS_CHOICES.SPECIAL && <>
			<HeaderTitle title="Pitíčka" />
			<p>Letos si konečně můžeš dát do nosu, tak se připrav na piňa coládu!</p>
			<HeaderTitle title="Galerie" />
			<div className="lenka2024Page__choices">
				{ state.allItems.map(item => <MyButton key={item.text} text={item.text} variant="filled" onClick={() => updateState({ items: item.items })} />)}
			</div>
		</> }
		{ state.items.length > 0 && <StoryTeller items={state.items} onEnd={onEnd} duration={getDuration()} /> }
	</PageContent>;
}
