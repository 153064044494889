import dayjs from "dayjs";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { DAYJS_FORMAT, ROUTES } from "~/const";
import PageContent from "~/components/PageContent";
import YearItems from "~/components/YearItems";
import { getPrevYearsGalleries, getImagesInTheDate, getMartinekDate } from "~/providers/events";
import ThisDayGallery from "~/components/ThisDayGallery";
import Scroller from "~/components/Scroller";
import { useScrollToTop } from "~/hooks/useScrollToTop";
import { myUseState } from "~/hooks/myUseState";
import { globalStore } from "~/stores/global";

import "./style.less";

function getData(curDate: dayjs.Dayjs) {
	const curDateStr = curDate.format(DAYJS_FORMAT);
	const lastYearsGalleries = getPrevYearsGalleries(curDateStr);
	const title = `Galerie ze dne ${curDate.get("date")}. ${curDate.locale("cs").format("MMMM")}`;
	const galleryImages = getImagesInTheDate(curDateStr);

	return {
		curDateStr,
		lastYearsGalleries,
		title,
		galleryImages,
	};
}

interface IState {
	date: dayjs.Dayjs;
	martinekDate: string;
	data: ReturnType<typeof getData>;
}

export default function InThisDayPage() {
	const navigate = useNavigate();
	const { global } = globalStore(globalState => ({
		global: globalState.global,
	}));
	const showScroller = useScrollToTop();
	const [searchParams] = useSearchParams();
	const date = searchParams.get("date") || "";
	const { state, updateState, setState } = myUseState<IState>(() => {
		const curDate = date
			? dayjs(date)
			: dayjs();

		return {
			date: curDate,
			martinekDate: getMartinekDate(curDate),
			data: getData(curDate),
		};
	});

	function onDateChange(newDate: dayjs.Dayjs) {
		updateState({
			date: newDate,
			martinekDate: getMartinekDate(newDate),
			data: getData(newDate),
		});
		navigate(`${ROUTES.IN_THIS_DAY}?date=${newDate.format(DAYJS_FORMAT)}`, { replace: true });
	}

	function onPageLoad() {
		setState(prev => ({
			...prev,
			data: getData(prev.date),
		}));
	}

	useEffect(() => {
		const curDate = date
			? dayjs(date)
			: dayjs();

		updateState({
			date: curDate,
			martinekDate: getMartinekDate(curDate),
			data: getData(curDate),
		});
	}, [date]);

	return <PageContent className="inThisDayPage" loggedContent={true} onLoad={onPageLoad}>
		<ThisDayGallery date={state.data.curDateStr} martinekDate={global.service === "makuderovi" ? state.martinekDate : ""} items={state.data.galleryImages} onDateChange={onDateChange} />
		{ state.data.lastYearsGalleries.length > 0 && <YearItems items={state.data.lastYearsGalleries} showYear={true} title={state.data.title} key={state.data.curDateStr} /> }
		{ (state.data.galleryImages.length > 0 || state.data.lastYearsGalleries.length > 0) && <Scroller visible={showScroller} className="inThisDayPage__sticky" /> }
	</PageContent>;
}
