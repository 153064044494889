import { CHUNK_SIZE_MB, MAKUDEROVI_URL } from "~/const";
import { IBEVisitItem, IFetchOutput, IGalleryItem, ILogin, IMessengerItem, IVisitData, IVisitItem, IVisitJson, IUploadFile, IUploadFileOutput, IYearEvent, IGetJson, IGoogleCalendarItem, TService } from "~/interfaces";
import { compareVisitJsonToBrowser, getFullDateWithHours, getVideoCover, getVisitJsonItem, isLocalhost, splitFile } from "~/utils/utils";

const ENDPOINTS = {
	login: "login.php",
	auth: "auth.php",
	messenger: "messenger.php",
	visit: "visit.php",
	settings: "settings.php",
	upload: "upload.php",
	uploadFiles: "uploadFiles.php",
	events: "getEvents.php",
	getData: "getData.php",
	getJson: "getJson.php",
	calendar: "getCalendar.php",
};

function processItem(items: Array<IBEVisitItem>, visitJson: IVisitJson): Array<IVisitItem> {
	return (items || []).map(item => {
		const json = JSON.parse(item.json);

		return {
			id: item.id,
			date: item.date,
			json,
			mobile: item.mobile,
			ip: item.ip,
			client: item.client,
			sameClient: compareVisitJsonToBrowser(json, visitJson),
		};
	});
}

function transferDataVisit(json: any): Promise<IVisitData> {
	const visitJson = getVisitJsonItem();

	return Promise.resolve({
		...json.data,
		admin: processItem(json.data.admin, visitJson),
		user: processItem(json.data.user, visitJson),
	});
}

async function transferDataUploadedFiles(json: { data: Array<{ file: string; filePath: string; date: string; }>; error: string; }) {
	const items: Array<IGalleryItem> = [];

	/* eslint-disable no-await-in-loop */
	for (const item of json.data) {
		const isVideo = item.file.toLowerCase().indexOf(".mp4") !== -1;
		const largeSrc = `${MAKUDEROVI_URL}${item.filePath}`;
		const src = isVideo
			? largeSrc
			: `${MAKUDEROVI_URL}${item.filePath.replace(/[.].*/u, "").replace("/", "/small-")}.jpg`;
		const subTitle = isVideo
			? ""
			: getFullDateWithHours(item.date);
		let preview = "";

		if (isVideo && !isLocalhost()) {
			const cover = await getVideoCover(largeSrc);

			preview = cover;
		}

		items.push({
			name: item.file,
			title: item.file,
			subTitle,
			src,
			largeSrc,
			isVideo,
			preview,
		});
	}

	return items;
}

async function getFetch<T>({
	endpoint,
	method = "GET",
	transferData = value => Promise.resolve(value.data),
	fetchOpts = {},
	body = undefined,
}: {
	endpoint: string;
	method?: "GET" | "POST" | "PUT" | "DELETE";
	transferData?: (value: { [key: string]: any; }) => Promise<T>;
	fetchOpts?: any;
	body?: FormData | {[key: string]: any;};
}) {
	const output: IFetchOutput<T> = {
		error: "",
		data: null,
		noErrors: false,
	};

	try {
		const hasFormData = body && body instanceof FormData;
		const request = await fetch(`/${endpoint}`, {
			method,
			headers: {
				...method === "GET" || hasFormData
					? {}
					: {
						"Accept": 'application/json',
						"Content-Type": "application/json",
					},
			},
			...body
				? {
					body: hasFormData
						? body
						: JSON.stringify(body),
				}
				: {},
			...fetchOpts,
		});
		const json = await request.json();

		output.error = json.error;
		output.data = await transferData(json);
	} catch (exc) {
		output.error = exc.message;
	}

	output.noErrors = output.error === "";

	return output;
}

export function login(password: string) {
	return getFetch<ILogin>({
		endpoint: ENDPOINTS.login,
		method: "POST",
		body: {
			password,
		},
	});
}

export function auth() {
	return getFetch<ILogin>({
		endpoint: ENDPOINTS.auth,
		method: "POST",
	});
}

export function getMessengerItems() {
	return getFetch<Array<IMessengerItem>>({
		endpoint: ENDPOINTS.messenger,
		method: "POST",
		body: {
			method: "GET",
		},
	});
}

export function addMessengerItem(data: Omit<IMessengerItem, "id">) {
	return getFetch<Array<IMessengerItem>>({
		endpoint: ENDPOINTS.messenger,
		method: "POST",
		body: {
			method: "PUT",
			...data,
		},
	});
}

export function removeMessengerItem(id: number) {
	return getFetch<Array<IMessengerItem>>({
		endpoint: ENDPOINTS.messenger,
		method: "POST",
		body: {
			method: "DELETE",
			id,
		},
	});
}

export function getVisits() {
	return getFetch<IVisitData>({
		endpoint: ENDPOINTS.visit,
		method: "POST",
		body: {
			method: "GET",
		},
		transferData: transferDataVisit,
	});
}

export function insertVisit(data: Omit<IBEVisitItem, "ip" | "id" | "client" | "sameClient">) {
	return getFetch<IVisitData>({
		endpoint: ENDPOINTS.visit,
		method: "POST",
		body: {
			method: "PUT",
			...data,
		},
		transferData: transferDataVisit,
	});
}

export function setKokokykaBe(kokokyka: number) {
	return getFetch<IVisitData>({
		endpoint: ENDPOINTS.settings,
		method: "POST",
		body: {
			method: "PUT",
			kokokyka,
		},
	});
}

export function getUploadedFiles() {
	return getFetch<Array<IGalleryItem>>({
		endpoint: ENDPOINTS.uploadFiles,
		method: "POST",
		transferData: transferDataUploadedFiles,
	});
}

export async function uploadFile({
	file,
	chunkSize = CHUNK_SIZE_MB,
}: IUploadFile) {
	const output = {
		error: false,
		log: [],
	};

	try {
		const chunks = splitFile(file, chunkSize);
		let ind = 0;

		for (const chunk of chunks) {
			const formData = new FormData();

			formData.append("file", chunk);
			formData.append("name", file.name);
			formData.append("index", ind.toString());
			formData.append("len", chunks.length.toString());

			const uploadData = await getFetch<IUploadFileOutput>({
				endpoint: ENDPOINTS.upload,
				method: "POST",
				body: formData,
			});

			if (uploadData.error) {
				output.error = true;
				output.log.push(uploadData.error);
				break;
			} else {
				output.log.push(uploadData.data.log.join(", "));
				output.log.push(`File ${file.name} part ${ind} was uploaded`);
			}

			ind++;
		}
	} catch (exc) {
		output.error = true;
		output.log.push(exc.message);
	}

	return output;
}

export function getEvents(service: TService) {
	const endpoint = `${ENDPOINTS.events}?version=${window.appVersion}&service=${service}`;

	return getFetch<Array<IYearEvent>>({
		endpoint,
		method: "GET",
	});
}

export function getDataPath(type: "fotky" | "videa", path: string, userType: string) {
	return `/${ENDPOINTS.getData}?type=${type}&path=${encodeURIComponent(path)}&user=${userType}`;
}

export function getJSON<T extends keyof IGetJson>(type: T) {
	return getFetch<IGetJson[T]>({
		endpoint: `${ENDPOINTS.getJson}?type=${type}`,
		transferData: value => Promise.resolve(value.data),
		method: "GET",
	});
}

export function getCalendar(fromDate: string) {
	return getFetch<Array<IGoogleCalendarItem>>({
		endpoint: `${ENDPOINTS.calendar}?fromdate=${fromDate}`,
		method: "GET",
	});
}
