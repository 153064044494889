import maplibregl, { GeoJSONSourceSpecification, LayerSpecification, MapMouseEvent, Offset } from "maplibre-gl";
import { useEffect, MutableRefObject } from "react";

import { API_KEY, DEFAULT_MAP_POSITION, MAPY_API_ICON, MAPY_API_URL, MAX_ZOOM, MIN_ZOOM } from "~/const";
import { ILookupFeaturePOIProperties, setMapLookup } from "~/providers/lookup";
import { initPOIIcons } from "~/providers/poiagg";
import { IMyGeoJSON, TCoords } from "~/interfaces";
import { myUseState } from "./myUseState";
import { getMapyczUrl } from "~/utils/map";

const LAYER_TURIST = "turist";
const LAYER_OPHOTO = "ophoto";
const LAYER_OPHOTO_TEXT = "ophotoText";
const MAP_TILES_ID = "maptiles";
const MAP_TILES_TEXT_ID = "maptilesOphotoText";
const GEO_JSON_SOURCE = "geoJson";
const GEO_JSON_TRACKS = "geoJsonTrack";
const GEO_JSON_ROUTE = "geoJsonRoute";
const POIS_SOURCE = "pois";
// eslint-disable-next-line
const MARKER_POPUP_OFFSET: Offset = [0, -65];
// eslint-disable-next-line
const MARKER_POPUP_OFFSET_ACTIVE: Offset = [0, -95];

export interface ICreatePopupData {
	lock: boolean;
	popup: maplibregl.Popup;
	setOffset: (isActive?: boolean) => void;
}

export interface IMapLoad {
	map: maplibregl.Map;
	tracksSource: maplibregl.GeoJSONSource;
	routeSource: maplibregl.GeoJSONSource;
	showOphoto: (state: boolean) => void;
	popupData: ICreatePopupData;
	addGeoJsons: (geoJsons?: Array<IMyGeoJSON>) => void;
}

interface IMapClick {
	event: MapMouseEvent;
	coords: TCoords;
	map: maplibregl.Map;
}

interface IState extends IMapLoad {}

interface IUseMap {
	containerRef: MutableRefObject<HTMLDivElement>;
	useRetina?: boolean;
	x?: number;
	y?: number;
	z?: number;
	onMapClick?: (data: IMapClick) => void;
	onPoiClick?: (data: ILookupFeaturePOIProperties) => void;
	onPoisClick?: (data: Array<ILookupFeaturePOIProperties>) => void;
	onContextMenu?: (event: maplibregl.MapMouseEvent) => void;
}

/* eslint-disable id-length */
export function useMap({
	containerRef,
	useRetina = false,
	x = DEFAULT_MAP_POSITION.x,
	y = DEFAULT_MAP_POSITION.y,
	z = DEFAULT_MAP_POSITION.z,
	onMapClick = () => {},
	onPoiClick = () => {},
	onPoisClick = () => {},
	onContextMenu = () => {},
}: IUseMap) {
	const { state, updateState } = myUseState<IState>({
		map: null,
		popupData: null,
		routeSource: null,
		showOphoto: () => {},
		tracksSource: null,
		addGeoJsons: () => {},
	});
	const loadedIcons: { [key: string]: number; } = {};

	function createPopup(): ICreatePopupData {
		const popup = new maplibregl.Popup({
			closeButton: false,
			closeOnClick: false,
			anchor: "bottom",
			offset: MARKER_POPUP_OFFSET,
		});

		return {
			lock: false,
			popup,
			setOffset: isActive => popup.setOffset(isActive ? MARKER_POPUP_OFFSET_ACTIVE : MARKER_POPUP_OFFSET),
		};
	}

	async function addMissingImage(map: maplibregl.Map, imageId: string) {
		if (imageId in loadedIcons) {
			return;
		}

		const iconParts = imageId.split("_");
		// posledni cast je vzdy id ikony v Mapy API
		const iconId = iconParts[iconParts.length - 1];
		// window.devicePixelRatio > 1
		const src = `${MAPY_API_ICON}${iconId}?scale=${useRetina ? "2" : "1"}`;

		loadedIcons[imageId] = 1;

		try {
			const response = await map.loadImage(src);

			map.addImage(imageId, response.data);
		} catch (exc) {
			/* eslint-disable-next-line */
			console.log(exc);
		}
	}

	function createContextMenuLink(title: string, onClick: () => void, popup: maplibregl.Popup) {
		const item = document.createElement("button");

		item.type = "button";
		item.classList.add("map-content-menu-item");
		item.textContent = title;
		item.addEventListener("click", () => {
			onClick();
			popup.remove();
		});

		return item;
	}

	function setPoiPopup(map: maplibregl.Map, popupData: ICreatePopupData) {
		/* eslint-disable no-magic-numbers */
		const popup = new maplibregl.Popup({
			closeButton: false,
			closeOnClick: false,
			offset: [0, -10],
		});

		map.on("mouseenter", POIS_SOURCE, event => {
			if (popupData.lock) {
				return;
			}

			// Change the cursor style as a UI indicator.
			map.getCanvas().style.cursor = "pointer";

			// @ts-ignore
			const coordinates = event.features[0].geometry.coordinates.slice();
			const description = event.features.map(feature => feature.properties.title).join(", ");

			// Ensure that if the map is zoomed out such that multiple
			// copies of the feature are visible, the popup appears
			// over the copy being pointed to.
			while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
				coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
			}

			// Populate the popup and set its coordinates
			// based on the feature found.
			popup.setLngLat(coordinates).setHTML(description).addTo(map);
		});
		map.on("mouseleave", POIS_SOURCE, () => {
			if (popupData.lock) {
				return;
			}

			map.getCanvas().style.cursor = "";
			popup.remove();
		});
		map.on("click", POIS_SOURCE, event => {
			if (event.originalEvent.ctrlKey) {
				return;
			}

			if (event.features.length === 1) {
				const data = event.features[0].properties as ILookupFeaturePOIProperties;

				onPoiClick(data);
			} else if (event.features.length > 1) {
				const popupPois = new maplibregl.Popup({
					closeButton: false,
					closeOnClick: true,
				});
				const node = document.createElement("div");

				node.classList.add("map-content-menu");

				for (const feature of event.features) {
					const data = feature.properties as ILookupFeaturePOIProperties;
					const noBoldTitle = data.title.replace(/[<][^>]+[>]/gu, "");

					node.append(createContextMenuLink(noBoldTitle, () => {
						window.open(getMapyczUrl(data.longitude, data.latitude, [
							{ name: "source", value: data.source },
							{ name: "id", value: data.id.toString() },
						]));
					}, popupPois));
				}

				popupPois.setLngLat(event.lngLat);
				popupPois.setDOMContent(node);
				popupPois.addTo(map);
				// zrusime soucasny
				popup.remove();
				// signal
				onPoisClick(event.features.map(feature => feature.properties as ILookupFeaturePOIProperties));
			}
		});
	}

	function getGeoJsonSource(): GeoJSONSourceSpecification {
		return {
			type: 'geojson',
			data: {
				type: "FeatureCollection",
				features: [],
			},
			generateId: true,
		};
	}

	function getGeoJsonStyle(id: string, source: string): LayerSpecification {
		return {
			id,
			type: 'line',
			source,
			layout: {
				'line-join': 'round',
				'line-cap': 'round',
			},
			paint: {
				'line-color': ['get', 'color'],
				'line-width': 8,
				'line-opacity': 0.6,
			},
		};
	}

	function getGeoJsonFillLayer(id: string, source: string): LayerSpecification {
		return {
			id,
			type: 'fill',
			source,
			paint: {
				'fill-color': "#c01",
				'fill-opacity': 0.2,
			},
		};
	}

	function getGeoJsonStrokeLayer(id: string, source: string): LayerSpecification {
		return {
			id,
			type: 'line',
			source,
			layout: {
				'line-join': 'round',
				'line-cap': 'round',
			},
			paint: {
				'line-color': "#333",
				'line-width': 2,
			},
		};
	}

	function getTileConfig(id: string, source: string): LayerSpecification {
		return {
			id,
			type: 'raster',
			source,
			minzoom: MIN_ZOOM,
			maxzoom: MAX_ZOOM + 1,
		};
	}

	function createMap() {
		// ikony
		initPOIIcons();
		//console.time("map-start");

		// mapa
		const map = new maplibregl.Map({
			container: containerRef.current,
			style: {
				version: 8,
				sources: {
					[LAYER_TURIST]: {
						type: 'raster',
						minzoom: 1,
						maxzoom: 19,
						tiles: [
							`${MAPY_API_URL}/maptiles/outdoor/256${useRetina ? "@2x" : ""}/{z}/{x}/{y}?lang=cs&apikey=${API_KEY}`,
						],
						tileSize: 256,
					},
					[LAYER_OPHOTO]: {
						type: 'raster',
						minzoom: 1,
						maxzoom: 19,
						tiles: [
							`${MAPY_API_URL}/maptiles/aerial/256/{z}/{x}/{y}?lang=cs&apikey=${API_KEY}`,
						],
						tileSize: 256,
					},
					[LAYER_OPHOTO_TEXT]: {
						type: 'raster',
						minzoom: 1,
						maxzoom: 19,
						tiles: [
							`${MAPY_API_URL}/maptiles/names-overlay/256/{z}/{x}/{y}?lang=cs&apikey=${API_KEY}`,
						],
						tileSize: 256,
					},
					[GEO_JSON_SOURCE]: getGeoJsonSource(),
					[GEO_JSON_TRACKS]: getGeoJsonSource(),
					[GEO_JSON_ROUTE]: getGeoJsonSource(),
				},
				layers: [
					getTileConfig(MAP_TILES_ID, LAYER_TURIST),
					{
						id: POIS_SOURCE,
						source: GEO_JSON_SOURCE,
						type: 'symbol',
						layout: {
							'icon-image': ['concat', 'icon_', ['get', 'icon']],
							// eslint-disable-next-line no-magic-numbers
							'icon-size': useRetina && window.devicePixelRatio > 1 ? 0.5 : 1,
							'icon-allow-overlap': true,
						},
						filter: ['==', '$type', 'Point'],
					},
					getGeoJsonStyle("mapTracks", GEO_JSON_TRACKS),
					getGeoJsonStyle("mapRoute", GEO_JSON_ROUTE),
				],
			},
			center: [x, y],
			zoom: z,
			maxZoom: MAX_ZOOM,
			minZoom: MIN_ZOOM,
			attributionControl: false,
		});

		const destroyCallbacks = [
			() => map.remove(),
		];

		// disable map rotation using right click + drag
		map.dragRotate.disable();
		// disable map rotation using touch rotation gesture
		map.touchZoomRotate.disableRotation();
		// nacitani chybejicich ikonek POIu
		map.on("styleimagemissing", function onImageMissing(event: maplibregl.MapStyleImageMissingEvent & Object): void {
			addMissingImage(map, event.id);
		});
		map.on("click", event => {
			const coords: TCoords = [event.lngLat.lng, event.lngLat.lat];

			onMapClick({ map, event, coords });
		});
		map.on("contextmenu", event => {
			onContextMenu(event);
		});
		map.on("load", () => {
			//console.timeEnd("map-start");
			map.addControl(new maplibregl.AttributionControl({ compact: false }), 'bottom-left');
			map.addControl(new maplibregl.NavigationControl({
				showCompass: false,
			}));

			const popupData = createPopup();
			const lookupDestroy = setMapLookup(map, GEO_JSON_SOURCE);
			const tracksSource = map.getSource(GEO_JSON_TRACKS) as maplibregl.GeoJSONSource;
			const routeSource = map.getSource(GEO_JSON_ROUTE) as maplibregl.GeoJSONSource;
			const showOphoto = (ophotoState: boolean) => {
				map.removeLayer(MAP_TILES_ID);
				map.getLayer(MAP_TILES_TEXT_ID) && map.removeLayer(MAP_TILES_TEXT_ID);
				map.addLayer(getTileConfig(MAP_TILES_ID, ophotoState ? LAYER_OPHOTO : LAYER_TURIST), POIS_SOURCE);

				// vrstva popisku
				if (ophotoState) {
					map.addLayer(getTileConfig(MAP_TILES_TEXT_ID, LAYER_OPHOTO_TEXT), POIS_SOURCE);
				}
			};

			destroyCallbacks.push(lookupDestroy);
			setPoiPopup(map, popupData);

			updateState({
				map,
				tracksSource,
				routeSource,
				showOphoto,
				popupData,
				addGeoJsons: geoJsons => {
					// custom data
					geoJsons.forEach((item, ind) => {
						const fillSourceId = `geojsonSourceFill${ind}`;
						const strokeSourceId = `geojsonSourceStroke${ind}`;
						const fillLayerId = `geojsonLayerFill${ind}`;
						const strokeLayerId = `geojsonLayerStroke${ind}`;

						// fill
						map.addSource(fillSourceId, {
							type: 'geojson',
							data: null,
							generateId: true,
						});
						map.addLayer(getGeoJsonFillLayer(fillLayerId, fillSourceId));

						const fillSource = map.getSource(fillSourceId) as maplibregl.GeoJSONSource;

						// @ts-ignore
						fillSource.setData(item);

						// klik do mapy
						map.on("click", fillLayerId, event => {
							const data = event.features[0].properties;
							const popup = new maplibregl.Popup({
								closeButton: false,
								closeOnClick: true,
							});

							popup.setLngLat(event.lngLat).setHTML(`<strong>${data.name}</strong>`).addTo(map);
						});

						// stroke
						map.addSource(strokeSourceId, {
							type: 'geojson',
							data: null,
							generateId: true,
						});
						map.addLayer(getGeoJsonStrokeLayer(strokeLayerId, strokeSourceId));

						const strokeSource = map.getSource(strokeSourceId) as maplibregl.GeoJSONSource;

						// @ts-ignore
						strokeSource.setData(item);
					});
				},
			});
		});

		return () => {
			destroyCallbacks.forEach(destroy => destroy());
		};
	}

	useEffect(() => {
		if (containerRef.current) {
			const destroy = createMap();

			return destroy;
		}

		return () => {};
	}, [containerRef]);

	return state;
}
