import { IDatabaseItem } from "../interfaces";

export const TOYS_ITEMS: Array<IDatabaseItem> = [
	{
		title: "Babu - Kuchyňské náčiní",
		images: [{
			src: "01.jpg",
			large: "01-large.jpg",
		}, {
			src: "02.jpg",
			large: "02-large.jpg",
		}],
	},
	{
		title: "QiAike - Led board",
		desc: "Kaufland",
		images: [{
			src: "03.jpg",
			large: "03-large.jpg",
		}, {
			src: "04.jpg",
			large: "04-large.jpg",
		}],
	},
	{
		title: "Technic 42023 3 možnosti",
		desc: "Kaufland",
		images: [{
			src: "05.jpg",
			large: "05-large.jpg",
		}, {
			src: "06.jpg",
			large: "06-large.jpg",
		}],
	},
];
