/* eslint-disable no-magic-numbers */
import { DependencyList, useEffect } from "react";
import { debounce } from "~/utils/utils";

interface IUseScroll {
	dependecies?: DependencyList;
	percent?: number;
	timeout?: number;
	onScroll?: () => void;
}

export function useScroll({
	dependecies = [],
	percent = 80,
	timeout = 100,
	onScroll = () => {},
}: IUseScroll) {
	const onScrollDebounce = debounce((appElem: HTMLDivElement, contentElem: HTMLDivElement) => {
		const scrollHeight = appElem.scrollTop + appElem.offsetHeight >>> 0;
		const contHeight = contentElem.offsetHeight;
		const isBottom = scrollHeight / contHeight * 100 > percent;

		isBottom && onScroll();
	}, timeout);

	useEffect(() => {
		const appElem = document.getElementById("app");
		const contentElem = document.querySelector(".mainContent");

		function onAppScroll() {
			onScrollDebounce(appElem, contentElem);
		}

		appElem.addEventListener("scroll", onAppScroll);

		return () => {
			appElem.removeEventListener("scroll", onAppScroll);
		};
	}, dependecies);
}
