import { useNavigate } from "react-router-dom";

import { getClassName, getEventDetailLink, getGalleryLink } from "~/utils/utils";
import { IEvent } from "~/interfaces";
import MyButton from "~/my/MyButton";

import "./style.less";

export type TMonthDataType = "gallery" | "detail";

interface IMonthData {
	event: IEvent;
	className?: string;
	type?: TMonthDataType;
}

export default function MonthData({
	event,
	className = "",
	type = "gallery",
}: IMonthData) {
	const navigate = useNavigate();

	function setMonth(seo: string) {
		navigate(type === "gallery" ? getGalleryLink(seo) : getEventDetailLink(seo));
	}

	return <div className={getClassName(["monthData", className])}>
		{ event.monthData.prevMonth && <MyButton text="Předchozí měsíc" variant="filled" onClick={() => setMonth(event.monthData.prevMonth)} /> }
		{ event.monthData.nextMonth && <MyButton text="Další měsíc" variant="filled" onClick={() => setMonth(event.monthData.nextMonth)} /> }
	</div>;
}
