import { IParseCoordsData, TCoords } from "~/interfaces";
import { myUseState } from "~/hooks/myUseState";
import MyButton from "~/my/MyButton";
import { parseCoords } from "~/utils/utils";
import CloseBtn from "../CloseBtn";
import SelectLocation from "../SelectLocation";
import { DEFAULT_ZOOM } from "~/const";

import "./style.less";

interface IState extends IParseCoordsData {
	text: string;
}

interface ISetCoordsDialog {
	showLocations?: boolean;
	onSave?: (coords: TCoords, zoom: number) => void;
	onClose?: () => void;
}

export default function SetCoordsDialog({
	showLocations = false,
	onSave = () => {},
	onClose = () => {},
}: ISetCoordsDialog) {
	const { state, updateState } = myUseState<IState>({
		text: "",
		...parseCoords(""),
	});

	function updateCoords(text: string) {
		updateState({
			text,
			...parseCoords(text),
		});
	}

	function goToCoords() {
		if (state.type !== "error") {
			onSave(state.coords, state.zoom);
		}
	}

	function setPlace(coords: TCoords) {
		onSave(coords, DEFAULT_ZOOM);
	}

	return <div className="setCoordsDialog map__dialog">
		<CloseBtn className="setCoordsDialog__btnClose" onClick={onClose} />
		<div className="setCoordsDialog__textarea">
			<textarea value={state.text} onChange={event => updateCoords(event.target.value)} placeholder="Souřadnice [lon, lat] | lat, lon | OLC"></textarea>
		</div>
		{ state.type !== "error" && <div className="setCoordsDialog__coords">
			<div className="setCoordsDialog__coordsLine">
				<span className="caption">Lon:</span>
				<strong>{ state.coords[0] }</strong>
			</div>
			<div className="setCoordsDialog__coordsLine">
				<span className="caption">Lat:</span>
				<strong>{ state.coords[1] }</strong>
			</div>
			<div className="setCoordsDialog__coordsLine">
				<span className="caption">Zoom:</span>
				<strong>{ state.zoom }</strong>
			</div>
			<div className="setCoordsDialog__coordsLine">
				<span className="caption">Zdroj:</span>
				<strong>{ state.type }</strong>
			</div>
		</div> }
		{ showLocations && <SelectLocation onSelect={setPlace} /> }
		<div className="setCoordsDialog__btns">
			<MyButton text="Přejít na souřadnice" onClick={goToCoords} disabled={state.type === "error"} variant="filled" />
		</div>
	</div>;
}
