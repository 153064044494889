import { useNavigate } from "react-router-dom";

import { ROUTES } from "~/const";
import PageContent from "~/components/PageContent";
import MyButton from "~/my/MyButton";
import { globalStore } from "~/stores/global";
import HeaderTitle from "~/components/HeaderTitle";
import Tags from "~/components/Tags";
import { getAllGalleries } from "~/providers/events";
import { IGalleryItem } from "~/interfaces";

import "./style.less";

const LAST_GALLERIES_COUNT = 4;

export default function OthersPage() {
	const navigate = useNavigate();
	const { global, setPixiMode, setHidePage, setChristmas } = globalStore(globalState => ({
		global: globalState.global,
		setPixiMode: globalState.setPixiMode,
		setHidePage: globalState.setHidePage,
		setChristmas: globalState.setChristmas,
	}));
	const PAGES: Array<{ name: string; url: string; }> = [{
		name: "Příběhy",
		url: ROUTES.STORY,
	}, {
		name: "Narozeniny",
		url: ROUTES.BIRTHDAYS,
	}, {
		name: "Nahrané soubory",
		url: ROUTES.UPLOADED_FILES,
	}, {
		name: "Návštěvy",
		url: ROUTES.VISITS,
	}, {
		name: "Výplaty",
		url: ROUTES.SALARY,
	}, {
		name: "Tanec",
		url: ROUTES.DANCE,
	}, {
		name: "Poplatky",
		url: ROUTES.PAYMENTS,
	}, {
		name: "Lenka narozeniny 2024",
		url: ROUTES.LENKA_2024,
	}, {
		name: "Tester obrázků",
		url: ROUTES.IMAGE_TESTER,
	}].sort((aItem, bItem) => aItem.name.localeCompare(bItem.name));
	const lastMonthGalleries = getAllGalleries().filter(item => item.event.monthData).reverse().slice(0, LAST_GALLERIES_COUNT);

	function hidePage() {
		setHidePage(true);
	}

	function setDefaultPixi() {
		setPixiMode("default");
	}

	function setOwlsPixi() {
		setPixiMode("owls");
	}

	function setFishesPixi() {
		setPixiMode("fishes");
	}

	function clearPixi() {
		setPixiMode("none");
	}

	function setChristmasMode() {
		setChristmas(true);
		setHidePage(true);
	}

	function navigeteToPage(name: string) {
		const filtered = PAGES.filter(item => item.name === name);

		if (filtered.length === 1) {
			navigate(filtered[0].url);
		}
	}

	function getLastItem(array: Array<IGalleryItem>) {
		const firstItem = array[0];
		const lastItem = array[array.length - 1];
		const firstInd = parseFloat(firstItem.name);
		const lastInd = parseFloat(lastItem.name);

		if (lastInd > firstInd) {
			return lastItem;
		}

		return firstItem;
	}

	return <PageContent className="othersPage" adminContent={true}>
		<HeaderTitle title="Ostatní" />
		<div className="othersPage__list">
			<div>Poslední fotky z měsíčních galerií:</div>
			{ lastMonthGalleries.map(item => <div key={item.event.seo}>
				{ item.event.title } - <strong>{getLastItem(item.items).name}</strong>
			</div>)}
		</div>
		<HeaderTitle title="Stránky" className="othersPage__title" />
		<Tags items={PAGES.map(item => item.name)} onClick={navigeteToPage} />
		<HeaderTitle title="Nastavení" className="othersPage__title" />
		<div className="othersPage__pixi">
			<MyButton text="Skrýt stránku" onClick={hidePage} variant="filled" />
			<MyButton text="Zapnout pozadí" onClick={setDefaultPixi} variant="filled" />
			<MyButton text="Zapnout sovy" onClick={setOwlsPixi} variant="filled" />
			<MyButton text="Zapnout ryby" onClick={setFishesPixi} variant="filled" />
			{ global.pixiMode !== "none" && <MyButton text="Vypnout pozadí" onClick={clearPixi} variant="filled" /> }
			<MyButton text="Vánoce" onClick={setChristmasMode} variant="filled" />
		</div>
	</PageContent>;
}
