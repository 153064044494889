import CloseBtn from "../CloseBtn";
import { ITrackItem } from "~/interfaces";
import { formatDistance, transTrackType } from "~/utils/utils";

import "./style.less";

interface ITrackInfoDialog {
	track: ITrackItem;
	onClose?: () => void;
}

export default function TrackInfoDialog({
	track,
	onClose = () => {},
}: ITrackInfoDialog) {
	/* eslint-disable react/no-array-index-key */
	return <div className="trackInfoDialog map__dialog">
		<CloseBtn className="trackInfoDialog__btnClose" onClick={onClose} />
		<h3 className="trackInfoDialog__title">
			<span className="trackInfoDialog__titleMain">
				{ track.desc || "Popis tracku" }
			</span>
			<span className="trackInfoDialog__titleDistance">
				{formatDistance(track.distances.all)}
			</span>
		</h3>
		<div className="trackInfoDialog__tracks">
			{ track.geometries.map((geom, ind) => <ul key={ind} className="trackInfoDialog__tracksList">
				<div className="trackInfoDialog__tracksItem">
					{ind + 1}. { transTrackType(geom.type) } { formatDistance(geom.distance) }
				</div>
			</ul>) }
		</div>
	</div>;
}
