/* eslint-disable no-magic-numbers */
import { ReactNode, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import type { IEvent, IGallery, TService } from "~/interfaces";
import GalleryHeader from '~/components/GalleryHeader';
import Map, { IOnClickData } from '~/components/Map';
import MyGallery from "~/my/MyGallery";
import MyGalleryItemLink from "~/my/MyGalleryItemLink";
import MyGalleryItemBar from "~/my/MyGalleryItemBar";
import MyGalleryTopRightDesc from "~/my/MyGalleryTopRightDesc";
import { getClassName, getDateFromEvent, getGalleryLink, getGroupTitle, getMapPositionFromYearItems, getShortEventTitleDate, getYearsBack } from "~/utils/utils";
import { DAYJS_NICE_FORMAT_DATE, GALLERY_COUNT } from "~/const";
import MyButton from "~/my/MyButton";
import { myUseState } from "~/hooks/myUseState";
import MyGalleryTopLeftDesc from "~/my/MyGalleryTopLeftDesc";
import { savedStore } from "~/stores/saved";
import { checkGalleryNews } from "~/providers/events";
import { useScroll } from "~/hooks/useScroll";
import LazyImage from "../LazyImage";
import { useMedia } from "~/hooks/useMedia";
import { IMapItem } from "~/map-interfaces";
import { globalStore } from "~/stores/global";

import "./style.less";

interface IState {
	loadMore: boolean;
	visibleCount: number;
	galleryItems: Array<IGallery>;
	selectItem: string;
	showMap: boolean;
	showTags: boolean;
	select: string;
	mapItems: Array<IMapItem>;
}

interface IYearItems {
	items: Array<IGallery>;
	count?: number;
	title?: string;
	showYear?: boolean;
	afterHeaderPlaceholder?: ReactNode;
	galleryBtns?: Array<ReactNode>;
	showInfo?: boolean;
	folder?: string;
	service?: TService;
}

export default function YearItems({
	items,
	count = GALLERY_COUNT,
	title = "",
	showYear = false,
	afterHeaderPlaceholder = null,
	galleryBtns = [],
	showInfo = false,
	folder = "",
	service = "makuderovi",
}: IYearItems) {
	const navigate = useNavigate();
	const mapJumpElem = useRef<HTMLDivElement>(null);
	const { savedData } = savedStore(visitState => ({
		savedData: visitState.saved,
	}));
	const { global } = globalStore(globalState => ({
		global: globalState.global,
	}));

	function getSelect() {
		const usp = new URLSearchParams(location.search);
		const seo = usp.get("seo");

		return seo;
	}

	function getMapItems(galleryItems: Array<IGallery>): Array<IMapItem> {
		const curYear = dayjs().get("year");

		return galleryItems.filter(item => item.event.coords).map(item => {
			const year = dayjs(item.event.date).get("year");
			const titleYear = showYear
				? `${getYearsBack(year, curYear, true)}, `
				: "";

			return {
				name: item.event.seo,
				src: item.cover,
				coords: item.event.coords,
				title: getShortEventTitleDate(item.event),
				popupTitle: `${titleYear}${getShortEventTitleDate(item.event)}`,
			};
		});
	}

	function getGalleryItems(visibleCount: number, prevMapItems: IMapItem[]): Pick<IState, "visibleCount" | "galleryItems" | "loadMore" | "mapItems"> {
		const galleryItems = items.slice(0, visibleCount);
		const mapItems = showYear
			? getMapItems(galleryItems)
			: prevMapItems;

		return {
			visibleCount,
			galleryItems,
			mapItems,
			loadMore: items.length > visibleCount,
		};
	}

	const { state, updateState, setState } = myUseState<IState>({
		...getGalleryItems(count, getMapItems(items)),
		selectItem: "",
		showMap: false,
		showTags: false,
		select: getSelect(),
	});
	const isSmallDevice = useMedia("(max-width: 468px)");
	const year = items.length > 0
		? dayjs(items[0].event.date).get("year")
		: dayjs().get("year");
	const galleryTitle = title || `Přehled roku ${year}`;

	function showMapClick() {
		const showMap = !state.showMap;

		updateState({
			showMap,
			select: "",
			...showMap
				? {}
				: {
					selectItem: "",
				},
		});
		mapJumpElem.current?.scrollIntoView();
	}

	const galleryHeaderBtns = [
		...state.mapItems.length || global.loginData?.isAdmin
			? [<MyButton text={state.showMap ? "Skrýt mapu" : state.mapItems.length ? "Zobrazit mapu" : "Prázdná mapa"} onClick={showMapClick} variant="filled" key="key02" />]
			: [],
		...galleryBtns,
	];

	function goToGallery(seo: string, fromDate?: string, toDate?: string) {
		navigate(getGalleryLink(seo, fromDate, toDate));
	}

	function onMapClick({ item: mapItem }: IOnClickData) {
		goToGallery(mapItem.name);
	}

	function onItemDataClick(item: IGallery) {
		updateState({
			select: "",
			showMap: true,
			selectItem: item.event.seo,
		});
		setTimeout(() => {
			mapJumpElem.current?.scrollIntoView();
		}, 0);
	}

	function closeMap() {
		updateState({
			select: "",
			showMap: false,
		});
	}

	function getTitle(item: IGallery, debug?: boolean) {
		if (!debug || item.type) {
			return item.event.title;
		}

		const nc = item.items.filter(filteredItem => !filteredItem.isVideo && !filteredItem.coords).length;
		const nd = item.items.filter(filteredItem => !filteredItem.isVideo && !filteredItem.date).length;
		const all = [nc, nd].filter(value => value > 0);
		const allTitle = all.length > 0
			? `${all.map((allItem, ind) => `${ind === 0 ? "nc" : "nd"} ${allItem}`).join(", ")}: `
			: "";

		return `${allTitle}${item.event.title}`;
	}

	function getSubtitle(item: IGallery) {
		if (item.galleryType === "year") {
			const curYear = dayjs().get("year");
			const itemYear = dayjs(item.event.date).get("year");

			if (item.type === "month-item") {
				return showYear
					? getYearsBack(itemYear, curYear, true)
					: null;
			}

			const yearStr = showYear
				? `${getYearsBack(itemYear, curYear, true)}, `
				: "";

			return `${yearStr}${getDateFromEvent(item.event)}`;
		} else if (item.galleryType === "other") {
			if (item.event.toDate) {
				const fromDate = dayjs(item.event.date);
				const toDate = dayjs(item.event.toDate);

				// stejny rok
				if (fromDate.get("year") === toDate.get("year")) {
					return `${fromDate.format("D. M.")} - ${toDate.format(DAYJS_NICE_FORMAT_DATE)}`;
				}

				return `${fromDate.format("M/YYYY")} - ${toDate.format("M/YYYY")}`;
			}

			return dayjs(item.event.date).format(DAYJS_NICE_FORMAT_DATE);
		}

		return "";
	}

	function getDescTitle(event: IEvent) {
		if (showInfo) {
			return getGroupTitle(event, service);
		}

		return "";
	}

	useEffect(() => {
		if (state.select) {
			for (let ind = 0, max = Math.ceil(items.length / count); ind < max; ind++) {
				const fromInd = ind * count;
				const toInd = fromInd + count;
				const testItems = items.slice(fromInd, toInd);

				if (testItems.filter(item => item.event.seo === state.select).length > 0) {
					setState(prev => ({
						...prev,
						visibleCount: toInd,
						galleryItems: items.slice(0, toInd),
					}));
					setTimeout(() => {
						const elem = document.querySelector(`.yearItems__item[data-seo="${state.select}"]`);

						elem && elem.scrollIntoView();
					}, 100);
					break;
				}
			}
		}
	}, [state.select]);

	// vzdycky znovu nacist
	useScroll({
		onScroll: () => {
			setState(prev => ({
				...prev,
				...prev.loadMore
					? {
						...getGalleryItems(prev.visibleCount + count, prev.mapItems),
					}
					: {},
			}));
		},
	});

	return <div className="yearItems">
		<GalleryHeader title={galleryTitle} first={false} buttons={galleryHeaderBtns} />
		{ afterHeaderPlaceholder }
		<div ref={mapJumpElem} className={getClassName(["yearItems__mapJump", state.showMap ? "open" : ""])}></div>
		{ state.showMap && <div className="yearItems__mapHolder">
			<Map items={state.mapItems} position={getMapPositionFromYearItems(items)} onClick={onMapClick} onClose={closeMap} selectItem={state.selectItem} showSearch={true} key={year} />
		</div> }
		{ items.length === 0 && <p className="yearItems__emptyGallery">
			Galerie je prázdná
		</p> }
		<MyGallery>
			{state.galleryItems.map(item => (
				<MyGalleryItemLink link={getGalleryLink(item.event.seo, item.event.date, item.event.toDate)} key={item.event.seo} className={getClassName(["yearItems__item", state.select === item.event.seo ? "selected" : ""])}
					onClick={() => goToGallery(item.event.seo, item.event.date, item.event.toDate)} seo={item.event.seo}>
					<LazyImage src={item.cover} alt={item.event.title} className="yearItems__img" xPos={isSmallDevice ? undefined : item.xCor ?? undefined} />
					<MyGalleryItemBar
						title={getTitle(item)}
						subtitle={getSubtitle(item)}
						photosCount={item.photosCount}
						videosCount={item.videosCount}
					/>
					{ item.event.coords && <MyGalleryTopLeftDesc coords={item.event.coords} tracksCount={item.event.allTracks.length} onClick={() => onItemDataClick(item)} /> }
					<MyGalleryTopRightDesc group={getDescTitle(item.event)} news={folder.match(/\d{4}/u) && checkGalleryNews(savedData.service === "roman" ? savedData.romanVisit : savedData.makuderoviVisit, item)} daysFormat={"days" in item.event} />
				</MyGalleryItemLink>
			))}
		</MyGallery>
	</div>;
}
