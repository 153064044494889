import Switcher from "../Switcher";
import { IGalleryFolder } from "~/interfaces";

export interface IOverviewSwitcher {
	items: Array<IGalleryFolder>;
	className?: string;
}

export default function OverviewSwitcher({
	items,
	className = "",
}: IOverviewSwitcher) {
	function getYearsLink(forTitle: string) {
		const filtered = items.filter(item => item.title === forTitle);

		return filtered[0].url || "";
	}

	return <Switcher items={items.map(item => item.title)} active={items.filter(item => item.active)[0]?.title || ""} getLink={getYearsLink} className={className} />;
}
