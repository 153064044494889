import { TTag } from "~/interfaces";
import MyButton from "~/my/MyButton";
import { getClassName } from "~/utils/utils";

import "./style.less";

interface TTags {
	items: Array<TTag>;
	selected?: Array<TTag>;
	onClick?: (tag: TTag) => void;
}

export default function Tags({
	items,
	selected = [],
	onClick,
}: TTags) {
	const isClickable = !!onClick;

	function itemClick(tag: TTag) {
		onClick && onClick(tag);
	}

	function isActive(tag: TTag) {
		return selected.indexOf(tag) !== -1;
	}

	return <div className={getClassName(["tags", isClickable ? "clickable" : ""])}>
		{ items.map(tag => <div className="tags__tag" key={tag}>
			<MyButton text={tag} onClick={() => itemClick(tag)} className={getClassName(["tags__tagBtn", isActive(tag) ? "active" : ""])} />
		</div>) }
	</div>;
}
