import { formatDistance, getClassName } from "~/utils/utils";
import { IEventDayItem } from "~/interfaces";

import "./style.less";

interface IDescItem {
	title: string;
	value: string;
}

interface IDescriptionDay {
	day: IEventDayItem;
	className?: string;
}

export default function DescriptionDay({
	day,
	className = "",
}: IDescriptionDay) {
	function getTitle() {
		switch (day.type) {
			case "prolog":
				return "";

			case "custom":
				return day.title || "";

			case "day":
				return new Intl.DateTimeFormat('cs-CZ', { month: 'long', day: 'numeric', weekday: 'long' }).format(new Date(day.date));

			case "epilog":
				return "";

			default:
		}

		return "";
	}

	function getSubtitle() {
		const descItems: Array<IDescItem> = [];
		const tracks = day.tracks || [];

		if (day.photosCount) {
			descItems.push({
				title: "počet fotek",
				value: day.photosCount.toString(),
			});
		}

		if (day.videosCount) {
			descItems.push({
				title: "počet videí",
				value: day.videosCount.toString(),
			});
		}

		if (tracks.length) {
			descItems.push({
				title: "počet tracků",
				value: tracks.length.toString(),
			});
		}

		if (day.distances.walk > 0) {
			descItems.push({
				title: "pěšky",
				value: formatDistance(day.distances.walk),
			});
		}

		if (day.distances.bike > 0) {
			descItems.push({
				title: "kolo",
				value: formatDistance(day.distances.bike),
			});
		}

		if (day.distances.car > 0) {
			descItems.push({
				title: "autem",
				value: formatDistance(day.distances.car),
			});
		}

		if (day.distances.boat > 0) {
			descItems.push({
				title: "lodí",
				value: formatDistance(day.distances.boat),
			});
		}

		if (day.distances.cable > 0) {
			descItems.push({
				title: "lanovkou",
				value: formatDistance(day.distances.cable),
			});
		}

		if (day.distances.pubt > 0) {
			descItems.push({
				title: "hromadná doprava",
				value: formatDistance(day.distances.pubt),
			});
		}

		// prvni pismeno chceme velke
		if (descItems.length) {
			descItems[0].title = `${descItems[0].title[0].toLocaleUpperCase()}${descItems[0].title.slice(1)}`;
		}

		return descItems;
	}

	const mainTitle = getTitle();
	const subtitleItems = getSubtitle();
	const hasSubtitle = subtitleItems.length > 0;

	/* eslint-disable react/no-array-index-key */
	return <div className={getClassName(["descriptionDay", className])}>
		{ (mainTitle || hasSubtitle) && <div className={getClassName(["descriptionDay__header", day.desc.length > 0 ? "bottom-border" : ""])}>
			{ mainTitle && <div className="descriptionDay__title">
				{ mainTitle }
			</div> }
			{ hasSubtitle && <div className="descriptionDay__subt">
				{ subtitleItems.map((item, ind) => <span className="descriptionDay__subtItem" key={ind}>
					{ item.title }: <strong>{ item.value }</strong>{ ind === subtitleItems.length - 1 ? "" : "," }
				</span>)}
			</div> }
		</div> }
		{ day.desc.map((desc, ind) => <div className="descriptionDay__desc" key={ind} dangerouslySetInnerHTML={{ __html: desc }} />)}
	</div>;
}
