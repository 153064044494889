import dayjs from "dayjs";
import maplibregl from "maplibre-gl";

import { ALL_OVERVIEW_DATA, DAYJS_FORMAT, MARTINEK_BIRTHDATE, PERMS, ROMAN_PERMS, TOMASEK_BIRTHDATE, YEAR_DAYS } from "~/const";
import { VISITED_DISTRICTS, VISITED_STATES } from "~/data/map";
import { COMMON_DATA } from "~/data/common";
import { ICalendarEvent, IEvent, IGallery, IGalleryItems, TTag, ILatestYearVisits, IGalleryItem, ITracksList, IVisitedItem, ICommonDataDiff, IYearEvent, ISearch, IGalleryFolder, TSeachType, IFullPersonItem, IEventDayItem, TService, ILogin, ICalendarLegendItem } from "~/interfaces";
import { getEvents } from "~/providers/php-api";
import { searchNames } from "./month-data";
import { getDataLink, getDateHours, getStateHolidays, getYearsBack } from "~/utils/utils";
import { calculateTrackDistances, createGallery, createTrackListItem, mergeDistances } from "~/utils/events";
import { createEmptyDistances } from "~/utils/map";

interface IEventFolder {
	folder: string;
	title: string;
	isYear: boolean;
}

interface IMonthEvent {
	[key: string]: IEvent[];
}

interface IFolderGallery {
	folder: string;
	count: number;
	title: string;
	isYear: boolean;
}

// globalni data
let eventsFolders: Array<IEventFolder> = [];
let galleryFolders: Array<IFolderGallery> = [];
let allEvents: Array<IEvent> = [];
let calendarEvents: Array<ICalendarEvent> = [];
let allGalleries: Array<IGallery> = [];
let galleryItems: IGalleryItems = {};
let allTags: Array<string> = [];
const tracksList: ITracksList = {
	0: [],
};

export function getCalendarLegendItems(service: TService, isAdmin: boolean) {
	const items: Array<ICalendarLegendItem> = [];

	if (isAdmin) {
		items.push({
			title: "Všechny události",
			className: "default",
			isDefault: true,
		});
		items.push({
			title: isAdmin ? "Admin" : "Události",
			className: "default",
			isAdmin: true,
		});
		items.push({
			title: "Veřejné",
			className: "calendar3",
			isPublic: true,
		});

		if (service === "makuderovi") {
			items.push({
				title: "Rodina",
				className: "calendar1",
				permissions: PERMS.RODINA,
			});
			items.push({
				title: "Děti",
				className: "calendar2",
				permissions: PERMS.DETI,
			});
		} else if (service === "roman") {
			items.push({
				title: "Kámoši",
				className: "calendar1",
				permissions: ROMAN_PERMS.KAMOSI,
			});
			items.push({
				title: "Seznam",
				className: "calendar4",
				permissions: ROMAN_PERMS.SEZNAM,
			});
			items.push({
				title: "Rodina",
				className: "calendar2",
				permissions: ROMAN_PERMS.RODINA,
			});
		}
	} else {
		items.push({
			title: "Události",
			className: "calendar3",
		});
	}

	items.push({
		title: "Státní svátky",
		className: "state-holiday",
	});

	return items;
}

// kalendar vyzaduje primo 01-01
function createEvent(event: IEvent, service: TService, loginData: ILogin): ICalendarEvent {
	const hasGallery = !!event.gallery;
	const hasTracks = event.allTracks.length > 0;
	let appendix = "";

	if (hasGallery) {
		appendix += "📷";
	}

	if (hasTracks) {
		appendix += "🚶";
	}

	const title = `${event.title}${appendix.length > 0 ? " " : ""}${appendix}`;
	const classNames = ["my-event", "calendar-event"];

	if (loginData.isAdmin) {
		if (event.public) {
			classNames.push("calendar3");
		} else if (event.groups) {
			if (service === "makuderovi") {
				if (event.groups.includes(PERMS.RODINA)) {
					classNames.push("calendar1");
				}

				if (event.groups.includes(PERMS.DETI)) {
					classNames.push("calendar2");
				}
			} else if (service === "roman") {
				if (event.groups.includes(ROMAN_PERMS.KAMOSI)) {
					classNames.push("calendar1");
				}

				if (event.groups.includes(ROMAN_PERMS.SEZNAM)) {
					classNames.push("calendar4");
				}

				if (event.groups.includes(ROMAN_PERMS.RODINA)) {
					classNames.push("calendar2");
				}
			}
		} else {
			classNames.push("default");
		}
	} else {
		classNames.push("calendar3");
	}

	return {
		title,
		...event.date && event.toDate
			? {
				start: event.date,
				end: dayjs(event.toDate).add(1, 'day').format(DAYJS_FORMAT),
			}
			: {
				date: event.date,
			},
		classNames,
		extendedProps: {
			seo: event.seo,
			date: event.date,
			toDate: event.toDate || "",
			public: event.public,
			groups: event.groups,
		},
	};
}

async function loadEventsByType(service: TService): Promise<Array<IYearEvent>> {
	try {
		const eventsData = await getEvents(service);

		return eventsData.data;
	} catch (exc) {
		/* eslint-disable-next-line */
		console.log(exc);
	}

	return [];
}

export function clearEventsData() {
	// reset
	eventsFolders = [];
	galleryFolders = [];
	allEvents = [];
	calendarEvents = [];
	allGalleries = [];
	galleryItems = {};
}

export async function loadEvents(service: TService, loginData: ILogin) {
	// nacteme
	const yearEvents = await loadEventsByType(service);
	const foldersGalleriesYears: Array<IFolderGallery> = [];
	const foldersGalleriesOthers: Array<IFolderGallery> = [];
	const eventsFoldersYears: Array<IEventFolder> = [];
	const eventsFoldersOthers: Array<IEventFolder> = [];
	const monthEvents: IMonthEvent = {};
	const allEventsTags: Array<string> = [];

	// projdeme a nastavime
	for (const yearEvent of yearEvents) {
		let galleriesCount = 0;

		// slozky
		if (yearEvent.isYear) {
			eventsFoldersYears.push({
				folder: yearEvent.folder,
				isYear: true,
				title: yearEvent.folder,
			});
		} else {
			eventsFoldersOthers.push({
				folder: yearEvent.folder,
				isYear: false,
				title: yearEvent.title || yearEvent.folder,
			});
		}

		// jednotlive roky
		for (const preEvent of yearEvent.events) {
			const event: IEvent = {
				...preEvent,
				allTracks: [],
				search: {
					title: preEvent.title.toLocaleLowerCase(),
					desc: "",
					galleryTitles: "",
				},
			};
			const folder = yearEvent.folder;
			let allDesc: Array<string> = event.desc;

			// vsechny eventy
			allEvents.push(event);

			// mame galerii
			const galleryData = createGallery(event, folder, yearEvent.isYear, loginData.type);

			// tagy
			if (event.tags) {
				for (const tag of event.tags) {
					if (!allEventsTags.includes(tag)) {
						allEventsTags.push(tag);
					}
				}
			}

			// mame galerii
			if (galleryData) {
				// celkovy pocet galerii
				galleriesCount++;
				// titulky
				event.search.galleryTitles = galleryData.meta.galleryTitles;

				if (!(folder in galleryItems)) {
					galleryItems[folder] = [];
				}

				galleryItems[folder].push(galleryData.gallery);
				allGalleries.push(galleryData.gallery);

				if (galleryData.gallery.type === "month-item") {
					if (!monthEvents[event.date]) {
						monthEvents[event.date] = [];
					}

					monthEvents[event.date].push(event);
				}
			}

			if (event.days) {
				for (const day of event.days) {
					// defaultne nic neni
					day.items = [];
					day.photosCount = 0;
					day.videosCount = 0;
					day.distances = createEmptyDistances();
					// vsechny popisky
					allDesc = allDesc.concat(day.desc);

					if (day.tracks && day.tracks.length > 0) {
						// pridame datum
						day.tracks.forEach(track => {
							track.date = day.date || "";
							calculateTrackDistances(track);

							if (!track.date) {
								/* eslint-disable-next-line */
								console.log(["Error", track.date, event, track]);
							}

							event.allTracks.push(track);
						});

						const trackListItem = createTrackListItem(event, galleryData?.gallery, day.tracks);

						mergeDistances(day.distances, trackListItem.distances);

						if (!(folder in tracksList)) {
							tracksList[folder] = [];
						}

						tracksList[folder].push(trackListItem);
						tracksList["0"].push(trackListItem);
					}
				}

				let prolog: IEventDayItem = null;
				let epilog: IEventDayItem = null;

				// priradime fotky
				if (galleryData) {
					for (const day of event.days) {
						switch (day.type) {
							case "prolog":
								prolog = day;
								prolog.items = galleryData.meta.prologItems || [];
								break;

							case "day":
							case "custom": {
								const name = day.type === "day"
									? day.date
									: day.customName;

								// priradime fotky podle dnu/vlastniho jmena
								if (name in galleryData.meta.datesObjDays) {
									day.items = galleryData.meta.datesObjDays[name];
									day.photosCount = day.items.filter(item => !item.isVideo).length;
									day.videosCount = day.items.length - day.photosCount;
								}
								break;
							}

							case "epilog":
								epilog = day;
								epilog.items = galleryData.meta.epilogItems;
								break;

							default:
						}
					}

					if (galleryData.meta.othersItems.length) {
						// mame oba, dame na konec - nebo jenom epilog
						if ((prolog && epilog) || (!prolog && epilog)) {
							epilog.items = epilog.items.concat(galleryData.meta.othersItems);
						} else if (prolog) {
							prolog.items = prolog.items.concat(galleryData.meta.othersItems);
						}
					}

					/* eslint-disable-next-line */
					//event.days && console.log(galleryData.meta.datesObjDays);
				}

				if (prolog) {
					prolog.photosCount = prolog.items.filter(item => !item.isVideo).length;
					prolog.videosCount = prolog.items.length - prolog.photosCount;
				}

				if (epilog) {
					epilog.photosCount = epilog.items.filter(item => !item.isVideo).length;
					epilog.videosCount = epilog.items.length - epilog.photosCount;
				}
			}

			if (event.tracks) {
				event.tracks.forEach(track => {
					// pridame datum
					track.date = track.date || event.date || "";
					calculateTrackDistances(track);
					event.allTracks.push(track);
				});

				const trackListItem = createTrackListItem(event, galleryData?.gallery, event.tracks);

				if (!(folder in tracksList)) {
					tracksList[folder] = [];
				}

				tracksList[folder].push(trackListItem);
				tracksList["0"].push(trackListItem);
			}

			// pridame vsechny texty
			event.search.desc = allDesc.join(",").toLocaleLowerCase();

			// nemame souradnice -> spocitame z tracku
			if (!event.coords && event.allTracks.length) {
				const mapBounds = event.allTracks.reduce((bounds, item) => bounds.extend(item.bbox), new maplibregl.LngLatBounds(event.allTracks[0].bbox));
				const centerData = mapBounds.getCenter();

				event.coords = [
					centerData.lng,
					centerData.lat,
				];
			}

			// do kalendare
			if (!event.skipCalendar) {
				calendarEvents.push(createEvent(event, service, loginData));
			}
		}

		// pocty galerii
		if (yearEvent.isYear) {
			foldersGalleriesYears.push({
				folder: yearEvent.folder,
				count: galleriesCount,
				isYear: true,
				title: yearEvent.folder,
			});
		} else {
			foldersGalleriesOthers.push({
				folder: yearEvent.folder,
				count: galleriesCount,
				isYear: false,
				title: yearEvent.title || yearEvent.folder,
			});
		}
	}

	// seradime pro kalendar
	eventsFoldersYears.sort((aItem, bItem) => aItem.folder.localeCompare(bItem.folder));
	eventsFoldersOthers.sort((aItem, bItem) => aItem.folder.localeCompare(bItem.folder));
	eventsFoldersYears.reverse();
	eventsFolders = eventsFoldersYears.concat(eventsFoldersOthers);
	// seradime gallerie
	foldersGalleriesYears.sort((aItem, bItem) => aItem.folder.localeCompare(bItem.folder));
	foldersGalleriesOthers.sort((aItem, bItem) => aItem.folder.localeCompare(bItem.folder));
	foldersGalleriesYears.reverse();
	// galerie
	galleryFolders = foldersGalleriesYears.concat(foldersGalleriesOthers).filter(item => item.count > 0);
	// nastavime tagy
	allTags = allEventsTags;
	allTags.sort((aItem, bItem) => aItem.localeCompare(bItem));

	// nastavime mesice
	const monthItems = Object.keys(monthEvents);

	// seradime
	monthItems.sort((aItem, bItem) => aItem.localeCompare(bItem));

	// vsechny udalosti
	const allMonthEvents: Array<IEvent> = [];

	for (const monthDate of monthItems) {
		for (const monthEvent of monthEvents[monthDate]) {
			allMonthEvents.push(monthEvent);
		}
	}

	for (let ind = 0, max = allMonthEvents.length; ind < max; ind++) {
		const monthEvent = allMonthEvents[ind];

		monthEvent.monthData = {};

		if (ind > 0) {
			monthEvent.monthData.prevMonth = allMonthEvents[ind - 1].seo;
		}

		if (ind !== max -1) {
			monthEvent.monthData.nextMonth = allMonthEvents[ind + 1].seo;
		}
	}
}

export function getSeo(seo: string) {
	return seo.toLowerCase();
}

interface IGetCalendarEvents {
	publicEvent?: boolean;
	adminEvent?: boolean;
	groups?: Array<number>;
}

export function getCalendarEvents({
	publicEvent = false,
	adminEvent = false,
	groups = [],
}: IGetCalendarEvents) {
	const curYear = dayjs().get("year");
	let output: Array<ICalendarEvent> = [];

	// statni svatky
	eventsFolders.forEach(folderItem => {
		if (folderItem.isYear) {
			const year = parseFloat(folderItem.folder);
			const yearHolidays = getStateHolidays(year);

			output = output.concat(yearHolidays);
		}
	});

	// +1 rok
	output = output.concat(getStateHolidays(curYear + 1));

	// nase udalosti
	output = output.concat(calendarEvents.filter(event => {
		if (adminEvent) {
			return !event.extendedProps.public && (!event.extendedProps.groups || event.extendedProps.groups.length === 0);
		}

		if (publicEvent) {
			return event.extendedProps.public;
		}

		if (groups.length) {
			if (!event.extendedProps.groups || event.extendedProps.groups.length === 0) {
				return false;
			}

			for (const testGroup of groups) {
				if (event.extendedProps.groups.includes(testGroup)) {
					return true;
				}
			}

			return false;
		}

		if (!publicEvent && groups.length === 0) {
			return true;
		}

		return false;
	}));

	return output;
}

export function getAllGalleries() {
	return allGalleries;
}

export function getGalleryBySeo(seo: string) {
	const compSeo = getSeo(seo);

	return allGalleries.filter(gallery => gallery.event.seo === compSeo)[0];
}

export function getGalleryFolders(year = "", addAll = false) {
	const output: Array<IGalleryFolder> = galleryFolders.map(item => ({
		title: item.title,
		url: getDataLink(item.folder),
		active: item.folder === year,
	}));

	if (addAll) {
		output.unshift({
			title: ALL_OVERVIEW_DATA.TITLE,
			url: getDataLink(ALL_OVERVIEW_DATA.URL),
			active: year === ALL_OVERVIEW_DATA.URL,
		});
	}

	return output;
}

export function getFolderTitle(folder: string) {
	const filtered = galleryFolders.filter(item => item.folder === folder);

	if (filtered.length) {
		return filtered[0].title;
	}

	return "";
}

export function getDataByYear(folder: string) {
	const source: Array<IGallery> = galleryItems[folder] || [];

	return source;
}

export function getLatestGalleryVisits() {
	const output: ILatestYearVisits = {};

	if (eventsFolders.length) {
		const years = eventsFolders.filter(item => item.isYear).map(item => item.folder);
		const items = getDataByYear(years[0]);

		items.forEach(item => {
			output[item.event.seo] = item.items.length;
		});
	}

	return output;
}

// jsou stejne
export function compareGalleryVisits(lsGallery: ILatestYearVisits) {
	const curGallery = getLatestGalleryVisits();
	const curKeys = Object.keys(curGallery);

	for (const key of curKeys) {
		if (!(key in lsGallery) || curGallery[key] !== lsGallery[key]) {
			return false;
		}
	}

	return true;
}

export function checkGalleryNews(visitData: ILatestYearVisits, gallery: IGallery) {
	// check na rok
	const galleryYear = dayjs(gallery.event.date).get("year");
	const curYear = dayjs().get("year");

	if (galleryYear !== curYear) {
		return false;
	}

	if (!(gallery.event.seo in visitData) || visitData[gallery.event.seo] !== gallery.items.length) {
		return true;
	}

	return false;
}

export function updateVisitDataByGallery(visitData: ILatestYearVisits, gallery: IGallery) {
	const output = {
		...visitData,
	};

	output[gallery.event.seo] = gallery.items.length;

	return output;
}

export function getGalleryDataByEvent(event: IEvent) {
	if (event?.gallery) {
		return getGalleryBySeo(event.seo);
	}

	return null;
}

export function getEventBySeo(seo: string) {
	const compSeo = getSeo(seo);

	return allEvents.filter(event => event.seo === compSeo)[0];
}

export function getGalleriesByTags(gallerySeo: string, tags: Array<TTag>): Array<IGallery> {
	const searchTags = tags || [];
	const compSeo = getSeo(gallerySeo);

	if (!searchTags.length) {
		return [];
	}

	const allItems = getAllGalleries();

	return allItems.filter(galleryItem => {
		const galleryTags = galleryItem.event.tags || [];

		for (const tag of galleryTags) {
			if (searchTags.includes(tag) && galleryItem.event.seo !== compSeo) {
				return true;
			}
		}

		return false;
	// potrebujeme od nejnovejsiho
	}).reverse();
}

export function getAllTags() {
	return allTags;
}

function checkDateEvent(fromDateStr: string, toDateStr: string, checkDate?: string) {
	const fromDate = dayjs(fromDateStr);
	const toDate = toDateStr
		? dayjs(toDateStr)
		: null;
	const curDate = dayjs(checkDate).subtract(1, "year");

	// from - prev year
	if (fromDate.get("year") <= curDate.get("year")) {
		if (toDate) {
			// nastavime stejny rok
			const curTime = curDate.clone().year(fromDate.year()).unix();
			const fromTime = fromDate.unix();
			const toTime = toDate.unix();

			return curTime >= fromTime && curTime <= toTime;
		}

		return curDate.get("month") === fromDate.get("month")
			&& curDate.get("date") === fromDate.get("date");
	}

	return false;
}

/* eslint-disable no-magic-numbers */
function checkGalleryEvent(fromDateStr: string, toDateStr: string, checkDate?: string, interval = 32) {
	const fromDate = dayjs(fromDateStr);
	const toDate = toDateStr
		? dayjs(toDateStr)
		: null;
	const checkYear = dayjs(checkDate).subtract(1, "year").get("year");

	// from - prev year
	if (fromDate.get("year") <= checkYear) {
		const intDate = dayjs(checkDate).set("year", fromDate.year());
		const intFromDate = intDate.subtract(interval, "day").unix();
		const intToDate = intDate.add(interval, "day").unix();

		// nastavime stejny rok
		const fromTime = fromDate.unix();
		const toTime = (toDate || fromDate).unix();
		const leftInt = Math.max(intFromDate, fromTime);
		const rightInt = Math.min(intToDate, toTime);

		return leftInt >= intFromDate && leftInt <= intToDate && rightInt >= intFromDate && rightInt <= intToDate;
	}

	return false;
}

function getGalleryItemsByDate(items: Array<IGalleryItem>, date?: string) {
	const curDay = dayjs(date || undefined);

	return items.filter(item => {
		const itemDate = item.date
			? dayjs(item.date)
			: null;

		return itemDate
			&& itemDate.get("year") <= curDay.get("year") - 1
			&& curDay.get("month") === itemDate.get("month")
			&& curDay.get("date") === itemDate.get("date");
	});
}

export function getPrevYearsEvents(date?: string) {
	return calendarEvents.filter(calEvent => checkDateEvent(calEvent.date || calEvent.start, calEvent.end, date)).reverse();
}

export function getPrevYearsGalleries(date?: string) {
	return allGalleries.filter(gallery => {
		if (gallery.dateType === "same" && checkDateEvent(gallery.event.date, gallery.event.toDate, date)) {
			return true;
		} else if (checkGalleryEvent(gallery.event.date, gallery.event.toDate, date)) {
			return getGalleryItemsByDate(gallery.items, date).length > 0;
		}

		return false;
	});
}

export function getMartinekDate(curDate: dayjs.Dayjs) {
	const tomasekBirthdate = dayjs(TOMASEK_BIRTHDATE);
	const days = tomasekBirthdate.diff(MARTINEK_BIRTHDATE, "days");
	let diff = 0;

	for (let curYear = curDate.get("year"), toYear = tomasekBirthdate.get("year"); curYear >= toYear; curYear--) {
		const yearDays = dayjs(`${curYear + 1}-01-01`).diff(dayjs(`${curYear}-01-01`), "days");

		if (yearDays > YEAR_DAYS) {
			diff++;
		}
	}

	return curDate.subtract(days + diff, "days").add(1, "year").format(DAYJS_FORMAT);
}

export function getImagesInTheDate(date?: string) {
	let items: Array<IGalleryItem> = [];

	allGalleries.forEach(gallery => {
		let filteredItems: Array<IGalleryItem> = [];

		// ale chceme pouze pro fotky, kdy mame pouze 1 datum!
		if (gallery.dateType === "same" && !gallery.event.toDate && checkDateEvent(gallery.event.date, gallery.event.toDate, date)) {
			filteredItems = gallery.items;
		} else if (checkGalleryEvent(gallery.event.date, gallery.event.toDate, date)) {
			filteredItems = getGalleryItemsByDate(gallery.items, date);
		}

		if (filteredItems.length) {
			const curYear = dayjs().get("year");

			items = items.concat(filteredItems.map(galleryItem => {
				const year = dayjs(gallery.event.date).get("year");
				const hours = getDateHours(galleryItem.date || gallery.event.date);
				const hoursStr = hours
					? `${hours}, `
					: "";

				return {
					...galleryItem,
					// vlastni titulek
					title: galleryItem.isVideo
						? galleryItem.title
						: gallery.event.title,
					subTitle: `${hoursStr}${getYearsBack(year, curYear, true)}`,
					coords: null,
				};
			}));
		}
	});

	// seradime podle - nejnovejsi rok, ve stejnem roce od rana do vecera
	items.sort((aItem, bItem) => {
		const aDate = dayjs(aItem.date || "");
		const bDate = dayjs(bItem.date || "");

		if (aDate.get("year") > bDate.get("year")) {
			return -1;
		} else if (aDate.get("year") < bDate.get("year")) {
			return 1;
		}

		return aDate.unix() - bDate.unix();
	});

	return items;
}

export function getTracksYearsList() {
	return tracksList;
}

export function diffVisitedArrays(original: Array<string>, compare: Array<IVisitedItem>) {
	const diff: Array<IVisitedItem> = [];

	compare.forEach(item => {
		if (original.indexOf(item.id) === -1) {
			diff.push(item);
		}
	});

	return diff;
}

export function getGetWorldMapDiff() {
	const visitedStates = diffVisitedArrays(VISITED_STATES, COMMON_DATA.visitedStates);
	const visitedDistricts = diffVisitedArrays(VISITED_DISTRICTS, COMMON_DATA.visitedDistricts);

	return {
		change: visitedStates.length > 0 || visitedDistricts.length > 0,
		visitedStates,
		visitedDistricts,
	} as ICommonDataDiff;
}

export function fulltextSearch({
	query = "",
	type = "title",
}: {
	query: string;
	type: TSeachType;
}): ISearch {
	const lcQuery = query.toLowerCase();
	let results: Array<IEvent> = [];
	let persons: Array<IFullPersonItem> = [];

	if (query) {
		if (type === "persons") {
			persons = searchNames(query);
		} else {
			results = allEvents.filter(item => {
				switch (type) {
					case "title":
						return item.search.title.indexOf(lcQuery) !== -1;

					case "desc":
						return item.search.desc.indexOf(lcQuery) !== -1;

					case "gallery":
						return item.search.galleryTitles.indexOf(lcQuery) !== -1;

					case "all":
						return item.search.title.indexOf(lcQuery) !== -1
							|| item.search.desc.indexOf(lcQuery) !== -1
							|| item.search.galleryTitles.indexOf(lcQuery) !== -1;

					default:
				}

				return false;
			});

			if (type === "all") {
				persons = searchNames(query);
			}
		}
	}

	return {
		query,
		results,
		persons,
	};
}

export function tagSearch(tagValue: string): ISearch {
	const results = allEvents.filter(item => item.tags && item.tags.includes(tagValue));

	return {
		query: tagValue,
		results,
		persons: [],
	};
}
