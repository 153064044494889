/* eslint-disable no-magic-numbers */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CloseIcon from '@mui/icons-material/Close';

import { IStoryTellerItem } from "~/interfaces";
import { myUseState } from "~/hooks/myUseState";
import StoryTellerScreen, { TStoryTellerScreenAnimation } from "../StoryTellerScreen";
import { useEffect } from "react";
import { KEYS } from "~/const";

import "./style.less";

interface IItem {
	data: IStoryTellerItem;
	anim: TStoryTellerScreenAnimation;
	visible: boolean;
	pause: boolean;
}

interface IState {
	seconds: number;
	perc: number;
	ind: number;
	durInd: number;
	tellerItems: Array<IItem>;
	end: boolean;
	pause: boolean;
}

interface IStoryTeller {
	items: Array<IStoryTellerItem>;
	animDuration?: number;
	duration?: number;
	onEnd?: () => void;
}

function getDurations(items: Array<IStoryTellerItem>, duration: number) {
	const durations: Array<number> = [];

	items.forEach(item => {
		if (item.type === "video") {
			durations.push(item.videoDuration || duration);
		} else {
			durations.push(duration);
		}
	});

	return durations;
}

function getPerc(seconds: number, curDuration: number, animDuration: number) {
	return seconds / (curDuration + animDuration - 1) * 100 >>> 0;
}

export default function StoryTeller({
	items,
	animDuration = 1,
	duration = 3,
	onEnd = () => {},
}: IStoryTeller) {
	const durations = getDurations(items, duration);
	const updItems = items.map((item, ind) => ({
		...item,
		ind: `${ind + 1} / ${items.length}`,
	}));
	const { state, setState, updateState } = myUseState<IState>(() => ({
		seconds: 0,
		perc: getPerc(0, durations[0], animDuration),
		ind: 0,
		durInd: 0,
		tellerItems: updItems.length > 0
			? [{
				data: updItems[0],
				anim: "anim-show",
				visible: true,
				pause: false,
			}]
			: [],
		pause: false,
		end: false,
	}));

	function keyDown(event: KeyboardEvent) {
		if (event.key === KEYS.ESC) {
			updateState({
				end: true,
			});
		}
	}

	function prevClick() {
		setState(prev => {
			if (prev.ind > 0) {
				const tellerItems = prev.tellerItems.slice();

				tellerItems.pop();
				tellerItems[tellerItems.length - 1].anim = "none";
				tellerItems[tellerItems.length - 1].visible = true;

				return {
					...prev,
					ind: prev.ind - 1,
					durInd: prev.durInd - 1,
					seconds: 0,
					perc: 0,
					tellerItems,
				};
			}

			return prev;
		});
	}

	function endStory() {
		updateState({
			end: true,
		});
	}

	function pauseClick() {
		setState(prev => {
			const pause = !prev.pause;
			const tellerItems = prev.tellerItems.slice();

			tellerItems[tellerItems.length - 1].pause = pause;

			return {
				...prev,
				pause,
				tellerItems,
			};
		});
	}

	function tick() {
		setState(prev => {
			if (prev.pause) {
				return prev;
			}

			const seconds = prev.seconds + 1;
			const curDuration = durations[prev.durInd];
			const newState: IState = {
				...prev,
				seconds,
				perc: getPerc(seconds, curDuration, animDuration),
			};

			if (seconds === curDuration) {
				const tellerItems = prev.tellerItems.slice();
				const newInd = prev.ind + 1;

				tellerItems[prev.ind].anim = "anim-hide";

				if (newInd <= updItems.length - 1) {
					tellerItems.push({
						data: updItems[newInd],
						anim: "anim-show",
						visible: true,
						pause: false,
					});
				}

				newState.ind = newInd;
				newState.tellerItems = tellerItems;
			} else if (seconds === curDuration + animDuration) {
				const tellerItems = prev.tellerItems.slice();
				const end = prev.ind > updItems.length - 1;

				tellerItems.forEach((item, curInd) => {
					item.visible = !(curInd <= prev.ind - 1);
					item.anim = "none";
				});
				newState.tellerItems = tellerItems;
				newState.end = end;
				newState.seconds = 0;
				newState.durInd++;
				newState.perc = getPerc(0, durations[newState.durInd], animDuration);
			}

			return newState;
		});
	}

	useEffect(() => {
		if (state.end) {
			onEnd();

			return () => {};
		}

		let intId = setInterval(() => {
			tick();
		}, 1000);

		return () => {
			clearInterval(intId);
			intId = null;
		};
	}, [state.end]);

	useEffect(() => {
		document.addEventListener("keydown", keyDown);

		return () => {
			document.removeEventListener("keydown", keyDown);
		};
	}, []);

	/* eslint-disable react/no-array-index-key */
	return <div className="storyTeller">
		<div className="storyTeller__ui">
			<div className="storyTeller__progress" style={{ width: `${state.perc}%` }} />
			<div className="storyTeller__controls">
				<button type="button" onClick={prevClick} className="storyTeller__btn">
					<ArrowBackIcon sx={{ fill: "#fff", width: "32px", height: "32px" }} />
				</button>
				<button type="button" onClick={pauseClick} className="storyTeller__btn">
					{ state.pause ? <PlayArrowIcon sx={{ fill: "#fff", width: "32px", height: "32px" }} /> : <PauseIcon sx={{ fill: "#fff", width: "32px", height: "32px" }} /> }
				</button>
				<button type="button" onClick={endStory} className="storyTeller__btn">
					<CloseIcon sx={{ fill: "#fff", width: "32px", height: "32px" }} />
				</button>
			</div>
		</div>
		<div className="storyTeller__screen">
			{ state.tellerItems.map((tellerItem, ind) => <StoryTellerScreen key={ind} data={tellerItem.data}
				anim={tellerItem.anim} visible={tellerItem.visible} pause={tellerItem.pause} />)}
		</div>
	</div>;
}
