/* eslint-disable no-magic-numbers */
import { useEffect } from "react";

import PageContent from "~/components/PageContent";
import HeaderTitle from "~/components/HeaderTitle";
import { myUseState } from "~/hooks/myUseState";
import { getJSON } from "~/providers/php-api";
import { IYearPayment } from "~/interfaces";
import PaymentsTable from "~/components/PaymentsTable";
import Loader from "~/components/Loader";

import "./style.less";

interface IState {
	loading: boolean;
	payments: Array<IYearPayment>;
}

export default function PaymentPage() {
	const { state, updateState } = myUseState<IState>({
		loading: true,
		payments: [],
	});

	async function loadData() {
		const paymentData = await getJSON("payment");

		updateState({
			loading: false,
			...paymentData.noErrors
				? {
					payments: paymentData.data,
				}
				: {},
		});
	}

	useEffect(() => {
		loadData();
	}, []);

	return <PageContent className="paymentsPage" adminContent={true}>
		<HeaderTitle title="Poplatky" />
		{ state.loading && <Loader /> }
		{ (!state.loading && state.payments.length > 0) && <PaymentsTable data={state.payments} /> }
	</PageContent>;
}
