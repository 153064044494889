import { IGallery, TTag } from "~/interfaces";
import YearItems from "../YearItems";
import { getGalleriesByTags } from "~/providers/events";
import Tags from "../Tags";
import { myUseState } from "~/hooks/myUseState";

import "./style.less";

interface IState {
	selectedTags: Array<TTag>;
}

interface TTagsGallery {
	gallery: IGallery;
	alwaysVisible?: boolean;
}

export default function TagsGallery({
	gallery,
	alwaysVisible = false,
}: TTagsGallery) {
	const galleryTags = (gallery.event.tags || []).slice();
	const { state, setState } = myUseState<IState>({
		selectedTags: galleryTags,
	});
	const items = getGalleriesByTags(gallery.event.seo, state.selectedTags);

	function onTagClick(tag: TTag) {
		setState(prev => {
			const selectedTags = prev.selectedTags;
			const ind = selectedTags.indexOf(tag);

			if (ind === -1) {
				selectedTags.push(tag);
			// necheme vse odstranit
			} else if (selectedTags.length > 1) {
				selectedTags.splice(ind, 1);
			}

			return {
				...prev,
				selectedTags,
			};
		});
	}

	const afterHeaderPlaceholder = <Tags items={galleryTags} selected={state.selectedTags} onClick={galleryTags.length > 1 ? onTagClick : null} />;

	return <>
		{ ((alwaysVisible && galleryTags.length > 0) || (items.length > 0 && galleryTags.length > 0)) && <div className="tagsGallery">
			<YearItems items={items} key={state.selectedTags.join("")} showYear={true} title="Podobné události podle tagů" afterHeaderPlaceholder={afterHeaderPlaceholder} />
		</div> }
	</>;
}
