import { API_KEY, MAPY_API_URL, WEB_DATA_URL } from "~/const";
import { IMyGeoJSON, TCoords, TRouteTypes } from "~/interfaces";

interface ICache {
	[key: string]: Array<TCoords>;
}

const MIN_POINTS = 2;
let worldMapGeoJSON: IMyGeoJSON = null;
let csDistrictsJSON: IMyGeoJSON = null;
const routeCache: ICache = {};

export async function getWorldMap() {
	if (!worldMapGeoJSON) {
		const response = await fetch(`${WEB_DATA_URL}world.geojson`);

		/* eslint-disable-next-line */
		worldMapGeoJSON = await response.json();
	}

	return worldMapGeoJSON;
}

export async function getCsDistricts() {
	if (!csDistrictsJSON) {
		const response = await fetch(`${WEB_DATA_URL}districts.geojson`);

		/* eslint-disable-next-line */
		csDistrictsJSON = await response.json();
	}

	return csDistrictsJSON;
}

function getId(routeCoords: Array<TCoords>, routeType: TRouteTypes) {
	return JSON.stringify({
		routeCoords,
		routeType,
	});
}

async function getPartRouteJSON(routeCoords: Array<TCoords>, routeType: TRouteTypes): Promise<Array<TCoords>> {
	try {
		if (routeCoords.length >= MIN_POINTS) {
			// test na kes
			const id = getId(routeCoords, routeType);

			if (id in routeCache) {
				return routeCache[id];
			}

			const url = new URL(`${MAPY_API_URL}/routing/route`);
			const requestData = {
				start: null,
				end: null,
				waypoints: [],
			};

			routeCoords.forEach((coord, ind) => {
				const strCoords = coord.join(',');

				if (ind === 0) {
					requestData.start = strCoords;
				} else if (ind === routeCoords.length - 1) {
					requestData.end = strCoords;
				} else {
					requestData.waypoints.push(strCoords);
					url.searchParams.append('waypoints', strCoords);
				}
			});

			url.searchParams.set('apikey', API_KEY);
			url.searchParams.set('lang', 'cs');
			url.searchParams.set('start', requestData.start);
			url.searchParams.set('end', requestData.end);
			url.searchParams.set('routeType', routeType);

			const response = await fetch(url.toString(), {
				mode: 'cors',
			});
			const json = await response.json();
			const coordinates = (json.geometry?.geometry?.coordinates || []) as Array<TCoords>;

			// ulozime do kese
			/* eslint-disable-next-line */
			routeCache[id] = coordinates;

			return coordinates;
		}

		return routeCoords;
	} catch (exc) {
		/* eslint-disable-next-line */
		console.log(exc);
	}

	return [];
}

export async function getRoute(routeCoords: Array<TCoords>, routeType: TRouteTypes = "car_fast") {
	let coords: Array<TCoords> = [];
	/* eslint-disable-next-line */
	const perRequest = 15 + 2;
	const count = Math.ceil(routeCoords.length / perRequest);

	/* eslint-disable no-await-in-loop */
	for (let ind = 0; ind < count; ind++) {
		const start = ind * perRequest;
		const curRouteCoords = routeCoords.slice(start, start + perRequest);
		const routeCoordiantes = await getPartRouteJSON(curRouteCoords, routeType);

		coords = coords.concat(routeCoordiantes);
	}

	return coords;
}
