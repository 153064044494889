/* eslint-disable */
import { ICommonData } from "~/interfaces";

export const COMMON_DATA: ICommonData = {
	visitedStates: [
		{id: "HR", name: "Chorvatsko"},
		{id: "IT", name: "Itálie"},
		{id: "HU", name: "Maďarsko"},
		{id: "AT", name: "Rakousko"},
		{id: "GR", name: "Řecko"},
		{id: "SK", name: "Slovensko"},
		{id: "ES", name: "Španělsko"},
		{id: "CH", name: "Švýcarsko"}
	],
	visitedDistricts: [
		{id: "CZ0641", name: "Blansko"},
		{id: "CZ0644", name: "Břeclav"},
		{id: "CZ0642", name: "Brno-město"},
		{id: "CZ0643", name: "Brno-venkov"},
		{id: "CZ0801", name: "Bruntál"},
		{id: "CZ0311", name: "České Budějovice"},
		{id: "CZ0312", name: "Český Krumlov"},
		{id: "CZ0411", name: "Cheb"},
		{id: "CZ0802", name: "Frýdek-Místek"},
		{id: "CZ0100", name: "Hlavní město Praha"},
		{id: "CZ0645", name: "Hodonín"},
		{id: "CZ0521", name: "Hradec Králové"},
		{id: "CZ0512", name: "Jablonec nad Nisou"},
		{id: "CZ0711", name: "Jeseník"},
		{id: "CZ0632", name: "Jihlava"},
		{id: "CZ0313", name: "Jindřichův Hradec"},
		{id: "CZ0412", name: "Karlovy Vary"},
		{id: "CZ0721", name: "Kroměříž"},
		{id: "CZ0205", name: "Kutná Hora"},
		{id: "CZ0513", name: "Liberec"},
		{id: "CZ0523", name: "Náchod"},
		{id: "CZ0804", name: "Nový Jičín"},
		{id: "CZ0712", name: "Olomouc"},
		{id: "CZ0806", name: "Ostrava-město"},
		{id: "CZ0323", name: "Plzeň-město"},
		{id: "CZ0209", name: "Praha-východ"},
		{id: "CZ0714", name: "Přerov"},
		{id: "CZ0715", name: "Šumperk"},
		{id: "CZ0317", name: "Tábor"},
		{id: "CZ0722", name: "Uherské Hradiště"},
		{id: "CZ0646", name: "Vyškov"},
		{id: "CZ0724", name: "Zlín"},
		{id: "CZ0647", name: "Znojmo"}
	]
};