import { ReactNode, MouseEvent, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useSwipeable } from "react-swipeable";
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';

import { KEYS } from '~/const';
import { myUseState } from '~/hooks/myUseState';
import { IGalleryItem } from '~/interfaces';
import MyVideo from '~/my/MyVideo';
import MyGalleryItemBar from "~/my/MyGalleryItemBar";
import { getClassName, loadImage } from "~/utils/utils";

import "./style.less";

const PortalComponent = ({ children }: { children: ReactNode }) => {
	const portal = createPortal(
		<div className="modal">
			{children}
		</div>,
		document.getElementById("portal") || document.body,
	);

	return portal;
};

interface IState {
	imgSrc: string;
	imgTitle: string;
	imgSubtitle: string;
	imgLoaded: boolean;
}

interface IFullscreenGallery {
	item: IGalleryItem;
	onPrev?: () => void;
	onNext?: () => void;
	onClose?: () => void;
	navigation?: boolean;
}

export default function FullscreenGallery({
	item,
	onPrev = () => {},
	onNext = () => {},
	onClose = () => {},
	navigation = true,
}: IFullscreenGallery) {
	const { state, updateState, setState } = myUseState<IState>({
		imgSrc: "",
		imgTitle: "",
		imgSubtitle: "",
		imgLoaded: false,
	});

	function prevPhoto(event: MouseEvent) {
		event.stopPropagation();
		onPrev();
	}

	function nextPhoto(event: MouseEvent) {
		event.stopPropagation();
		onNext();
	}

	function fullscreenClose(event?: MouseEvent) {
		if (event) {
			const target = event.target as HTMLElement;

			if (target.closest(".myVideo")) {
				return;
			}
		}

		onClose();
	}

	const handlers = useSwipeable({
		onSwiped: eventData => {
			if (eventData.dir === "Right") {
				nextPhoto(eventData.event as MouseEvent);
			} else if (eventData.dir === "Left") {
				prevPhoto(eventData.event as MouseEvent);
			}
		},
	});

	function keyDown(event: KeyboardEvent) {
		if (event.key === KEYS.ESC) {
			fullscreenClose();
		} else if (event.key === KEYS.LEFT) {
			onPrev();
		} else if (event.key === KEYS.RIGHT) {
			onNext();
		}
	}

	useEffect(() => {
		document.addEventListener("keydown", keyDown);
		setState(prev => ({
			...prev,
			imgLoaded: false,
		}));

		if (item.isVideo) {
			updateState({
				imgLoaded: true,
				imgSrc: "",
				imgTitle: item.title,
				imgSubtitle: item.subTitle,
			});
		} else {
			loadImage(item.largeSrc).then(() => {
				updateState({
					imgLoaded: true,
					imgSrc: item.largeSrc,
					imgTitle: item.title,
					imgSubtitle: item.subTitle,
				});
			}, () => {
				updateState({
					imgLoaded: true,
				});
			});
		}

		return () => {
			document.removeEventListener("keydown", keyDown);
		};
	}, [item.src]);

	return <PortalComponent>
		<div className="fullscreenGallery" onClick={fullscreenClose}>
			{ navigation && <div className="fullscreenGalleryPanel left" onClick={prevPhoto}>
				<span className="fullscreenGalleryIcon">
					<WestIcon sx={{ width: "48px", height: "48px" }} />
				</span>
			</div> }
			<div className="fullscreenGalleryContentArea">
				<div className="fullscreenGalleryContent">
					{ !item.isVideo && <div className="fullscreenGalleryImg">
						<img {...handlers} src={state.imgSrc} alt={item.title || ""} style={{
							display: state.imgSrc ? "block" : "none",
						}} />
						{ !state.imgLoaded && <div className="fullscreenGalleryLoader"></div> }
						{ state.imgSrc && (state.imgTitle || state.imgSubtitle) && <div className={getClassName(["fullscreenGallery__desc", state.imgSubtitle ? "has-subtitle" : ""])}>
							<div className="fullscreenGallery__title">
								{ state.imgTitle }
							</div>
							<div className="fullscreenGallery__subTitle">
								{ state.imgSubtitle }
							</div>
						</div> }
					</div> }
					{ item.isVideo && <MyVideo src={item.src} cover={item.preview}>
						<MyGalleryItemBar
							title={item.title}
							subtitle={item.subTitle}
							disableMouse={true}
							position="top"
						/>
					</MyVideo> }
				</div>
			</div>
			{ navigation && <div className="fullscreenGalleryPanel right" onClick={nextPhoto}>
				<span className="fullscreenGalleryIcon">
					<EastIcon sx={{ width: "48px", height: "48px" }} />
				</span>
			</div> }
		</div>
	</PortalComponent>;
}
