import { DEFAULT_UPLOAD_PREVIEW_SIZE } from "~/const";
import { deferPromise, getFileCover } from "~/utils/utils";

interface IUploadCoverItem {
	promiseData: ReturnType<typeof deferPromise>;
	file: File;
	size: number;
}

const items: Array<IUploadCoverItem> = [];
let isRunning = false;
const PARALEL = 4;

async function runBatch() {
	if (items.length === 0) {
		isRunning = false;

		return;
	}

	isRunning = true;

	const processItems: Array<IUploadCoverItem> = [];

	for (let ind = 0; ind < PARALEL; ind++) {
		const topItem = items.shift();

		if (topItem) {
			processItems.push(topItem);
		}
	}

	const allProms = [];

	processItems.forEach(item => {
		allProms.push((async () => {
			try {
				const cover = await getFileCover(item.file, item.size);

				item.promiseData.resolveCb(cover);
			} catch (exc) {
				item.promiseData.rejectCb("");
			}
		})());
	});

	await Promise.all(allProms);

	runBatch();
}

export function getCover(file: File, size = DEFAULT_UPLOAD_PREVIEW_SIZE) {
	const promiseData = deferPromise<string>();

	items.push({
		promiseData,
		file,
		size,
	});
	!isRunning && runBatch();

	return promiseData.promise;
}
