import { INotificationItem } from "~/interfaces";
import { getClassName } from "~/utils/utils";

import "./style.less";

interface INotificationItemComp {
	data: INotificationItem;
}

export default function NotificationItem({
	data,
}: INotificationItemComp) {
	return <div className={getClassName(["notificationItem", data.state === "disapearing" ? "disapearing" : ""])}>
		{ data.text }
	</div>;
}
