import { ROUTES } from "~/const";
import { STORIES } from "~/data/stories";
import { replaceRoute } from "~/utils/utils";

export interface IStoryItem {
	text: string;
	emoji?: string;
}

export interface IStoryEvent {
	items: Array<IStoryItem>;
	seo: string;
	title: string;
	message: string;
	desc: Array<string>;
	date: string;
	reverse?: boolean;
}

export function getStory(seo: string) {
	const filtered = STORIES.filter(item => item.seo === seo);

	return filtered.length === 1
		? filtered[0]
		: null;
}

export function getLink(seo: string) {
	return replaceRoute(ROUTES.STORY_DETAIL, {
		":story": seo,
	});
}
