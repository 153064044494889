import { useEffect, useRef } from "react";
import dayjs from "dayjs";
import "dayjs/locale/cs";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { DatesSetArg, EventClickArg } from "@fullcalendar/core";
import { useNavigate } from "react-router-dom";

import { DAYJS_FORMAT } from "~/const";
import PageContent from "~/components/PageContent";
import { getCalendarEvents, getCalendarLegendItems } from "~/providers/events";
import { getEventDetailLink } from "~/utils/utils";
import { myUseState } from "~/hooks/myUseState";
import JumpToDate from "~/components/JumpToDate";
import CalendarLegend from "~/components/CalendarLegend";
import HeaderTitle from "~/components/HeaderTitle";
import { globalStore } from "~/stores/global";
import { ICalendarLegendItem } from "~/interfaces";

import "./style.less";

interface IState {
	jumpDate: string;
	public: boolean;
	admin: boolean;
	groups: Array<number>;
	active: ICalendarLegendItem | null;
}

export default function CalendarPage() {
	const calRef = useRef<FullCalendar>(null);
	const calendarTopRef = useRef<HTMLDivElement>(null);
	const navigate = useNavigate();
	const usp = new URLSearchParams(location.search);
	const urlDate = usp.get("date");
	const { global } = globalStore(globalState => ({
		global: globalState.global,
	}));
	const { state, updateState } = myUseState<IState>({
		jumpDate: urlDate || dayjs().format(DAYJS_FORMAT),
		public: false,
		admin: false,
		groups: [],
		active: null,
	});
	const events = getCalendarEvents({
		publicEvent: state.public,
		adminEvent: state.admin,
		groups: state.groups,
	});

	function onDateChange(day: dayjs.Dayjs) {
		const formStr = day.format(DAYJS_FORMAT);
		const api = calRef.current.getApi();

		api.gotoDate(formStr);
	}

	function onEventClick(info: EventClickArg) {
		if (info.event.extendedProps.stateHoliday) {
			return;
		}

		const link = getEventDetailLink(info.event.extendedProps.seo, info.event.extendedProps.date || "", info.event.extendedProps.toDate || "");

		navigate(link);
	}

	function onDateSet(data: DatesSetArg) {
		const start = dayjs(data.startStr);
		const end = dayjs(data.endStr);
		/* eslint-disable-next-line */
		const days = end.diff(start, "days") / 2 >>> 0;
		const middleDate = start.add(days, "days");

		updateState({
			jumpDate: middleDate.format(DAYJS_FORMAT),
		});
	}

	function legendClick(item: ICalendarLegendItem) {
		const newState: Partial<IState> = {
			public: false,
			admin: false,
			active: null,
			groups: [],
		};

		if (item.isPublic) {
			newState.public = true;
		} else if (item.isAdmin) {
			newState.admin = true;
		} else if (item.permissions) {
			newState.groups = [item.permissions];
		}

		updateState(newState);
	}

	useEffect(() => {
		if (urlDate && calendarTopRef.current) {
			calendarTopRef.current.scrollIntoView();
		}
	}, [calendarTopRef.current]);

	return <PageContent className="calendarPage" loggedContent={true}>
		<HeaderTitle title="Kalendář" />
		<div className="calendarPage__dateArea">
			<JumpToDate className="calendarPage__datePicker" value={dayjs(state.jumpDate)} onChange={onDateChange} />
		</div>
		<div className="calendarPage__calendarTop" ref={calendarTopRef} />
		<FullCalendar
			ref={calRef}
			initialDate={state.jumpDate}
			plugins={[dayGridPlugin, interactionPlugin]}
			eventClick={onEventClick}
			initialView="dayGridMonth"
			firstDay={1}
			datesSet={onDateSet}
			headerToolbar={{
				start: "prevYear,nextYear",
				center: "title",
				end: "today prev,next",
			}}
			locale="cs"
			events={events}
			buttonText={{
				today: "dnes",
			}}
			dateAlignment="month"
			fixedWeekCount={false}
		/>
		<CalendarLegend items={getCalendarLegendItems(global.service, global.loginData?.isAdmin)} onClick={global.loginData?.isAdmin ? legendClick : null} active={state.active} />
	</PageContent>;
}
