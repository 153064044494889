/* eslint-disable no-magic-numbers */
/* eslint-disable no-console */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { IYearPayment } from "~/interfaces";
import { getYearData } from "~/utils/payments";

import "./style.less";

interface IYearsSalaryTable {
	data: Array<IYearPayment>;
}

export default function PaymentsTable({
	data,
}: IYearsSalaryTable) {
	const rows = data.map(item => getYearData(item));

	return <TableContainer component={Paper} sx={{ maxHeight: 640 }}>
		<Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
			<TableHead>
				<TableRow>
					<TableCell className="paymentsTable__tableHead">Rok</TableCell>
					<TableCell className="paymentsTable__tableHead">Byt</TableCell>
					<TableCell className="paymentsTable__tableHead">Hypotéka</TableCell>
					<TableCell className="paymentsTable__tableHead">Celkem</TableCell>
					<TableCell className="paymentsTable__tableHead">Byt (rok)</TableCell>
					<TableCell className="paymentsTable__tableHead">Hypotéka (rok)</TableCell>
					<TableCell className="paymentsTable__tableHead">Elektřina</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{ rows.map(item => <TableRow key={item.year}>
					<TableCell className="paymentsTable__center">
						{ item.year }
					</TableCell>
					<TableCell className="paymentsTable__center">
						{ item.flatsMonthPrices }
					</TableCell>
					<TableCell className="paymentsTable__center">
						{ item.mortageMonthPrices }
					</TableCell>
					<TableCell className="paymentsTable__center">
						{ item.perMonth }
					</TableCell>
					<TableCell className="paymentsTable__center">
						{ item.flatsYearPrices }
					</TableCell>
					<TableCell className="paymentsTable__center">
						{ item.mortagesYearPrices }
					</TableCell>
					<TableCell className="paymentsTable__center">
						{ item.electric }
					</TableCell>
				</TableRow>)}
			</TableBody>
		</Table>
	</TableContainer>;
}
