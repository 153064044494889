import { MouseEvent, useEffect } from "react";
import { useParams } from "react-router-dom";

import PageContent from "~/components/PageContent";
import HeaderTitle from "~/components/HeaderTitle";
import { myUseState } from "~/hooks/myUseState";
import { ICategoryItem, IDataItem, IPersonItem } from "~/interfaces";
import { getJSON } from "~/providers/php-api";
import { copyToClipboard, getAddressString, getDataContentLink, getPersonsTitle } from "~/utils/utils";
import Loader from "~/components/Loader";
import Switcher from "~/components/Switcher";
import { EMPTY_DATA_CONTENT_CATEGORY } from "~/const";

import "./style.less";

interface IState {
	loading: boolean;
	active: IDataItem;
	data: Array<IDataItem>;
	allItems: Array<string>;
}

interface IParams {
	category: string;
	[key: string]: string;
}

export default function DataContentPage() {
	const { state, updateState } = myUseState<IState>({
		loading: true,
		active: null,
		data: [],
		allItems: [],
	});
	const { category: urlCategory } = useParams<IParams>();

	function updateItemsByAddresses(items: Array<IDataItem>, persons: Array<IPersonItem>) {
		const output: Array<IDataItem> = [
			...items,
		];
		const addresses: Array<{ persons: Array<IPersonItem>; address: string; }> = [];

		persons.forEach(person => {
			if (person.address) {
				const address = getAddressString(person.address);
				const filtered = addresses.filter(item => item.address === address);

				if (filtered.length === 1) {
					filtered[0].persons.push(person);
				} else {
					addresses.push({
						persons: [person],
						address,
					});
				}
			}
		});

		output.push({
			title: "Adresy",
			seo: "adresy",
			categories: [{
				title: "Seznam adres",
				items: addresses.map(item => ({
					title: getPersonsTitle(item.persons),
					copyStr: item.address,
				})),
			}],
		});

		return output;
	}

	async function loadData() {
		const [dataItems, dataPersons] = await Promise.all([
			getJSON("dataitems"),
			getJSON("persons"),
		]);
		const updData = dataItems.noErrors
			? updateItemsByAddresses(dataItems.data, dataPersons.noErrors ? dataPersons.data : [])
			: [];

		updateState({
			loading: false,
			...dataItems.noErrors
				? {
					active: updData[0],
					data: updData,
					allItems: updData.map(item => item.title),
				}
				: {},
		});
	}

	function copyData(event: MouseEvent, item: ICategoryItem) {
		event.stopPropagation();
		event.preventDefault();

		copyToClipboard(item.copyStr);
	}

	function getLink(name: string) {
		const filtered = state.data.filter(item => item.title === name);

		if (filtered.length) {
			return getDataContentLink(filtered[0].seo);
		}

		return getDataContentLink();
	}

	useEffect(() => {
		if (state.data.length === 0) {
			loadData();
		} else if (state.data.length > 0 && urlCategory && urlCategory !== EMPTY_DATA_CONTENT_CATEGORY) {
			updateState({
				active: state.data.filter(item => item.seo === urlCategory)[0],
			});
		}
	}, [urlCategory, state.data]);

	return <PageContent className="dataContentPage" adminContent={true}>
		<HeaderTitle title="Údaje" />
		{ state.loading && <Loader /> }
		{ (!state.loading && state.data.length > 0) && <>
			<Switcher items={state.allItems} getLink={getLink} active={state.active.title} />
			<div className="dataContentPage__categories">
				{ state.active.categories.map(category => <div className="dataContentPage__category" key={category.title}>
					<h3 className="dataContentPage__categoryTitle">{ category.title }</h3>
					<div className="dataContentPage__items">
						{ category.items.map(item => <span className="dataContentPage__item" key={`${category.title}_${item.copyStr}`}>
							{ item.title } <a href="#" onClick={event => copyData(event, item)}>{ item.linkStr || item.copyStr }</a>
						</span>)}
					</div>
				</div>)}
			</div>
		</> }
	</PageContent>;
}
