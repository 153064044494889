/* eslint-disable */
import { IHeightProfile } from "~/components/HeightProfile";
import { TCoords } from "~/interfaces";
import { IProfileData, IProfileDataItem } from "~/providers/altitude";

export interface IDrawItem {
	x: number;
	y: number;
	coords: TCoords;
	ind: number;
	altitude: number;
}

export interface IMinMaxItem extends IDrawItem {
	y2: number;
	top: number;
	type: "min" | "max";
	position: "left" | "center" | "right";
}

export interface ISectionItem {
	x: number;
	y: number;
	value: string;
	unit: string;
}

export interface IHeightProfileData {
	items: Array<IDrawItem>;
	measureItems: Array<ISectionItem>;
	minItem: IMinMaxItem;
	maxItem: IMinMaxItem;
}

export function getValue(index: number, items: IProfileData["altitude"]["data"], getItemValue: (item: IProfileDataItem) => number): number {
	const wholePoint = index >>> 0;
	const diff = index - wholePoint;

	if (diff < 0.05) {
		return getItemValue(items[wholePoint]);
	}

	const indA = wholePoint;
	const indB = Math.min(wholePoint + 1, items.length - 1);
	const partA = getItemValue(items[indA]) * (1 - diff);
	const partB = getItemValue(items[indB]) * diff;
	const altitude = partA + partB;

	return altitude;
}

export function getDecDivider(value: number) {
	if (value < 10) {
		return 0;
	} else {
		return Math.floor(Math.log(value) / Math.log(10)) * 10;
	}
}

export function valueWithUnit(inputValue: number, units: IHeightProfile["xUnits"]): Pick<ISectionItem, "value" | "unit"> {
	let value = Math.round(inputValue).toString();
	let unit = "";

	for (let p in units) {
		if (Math.floor(inputValue / parseInt(p)) >= 1) {
			const format = inputValue / parseInt(p);
			const prepare = Math.round(format * 10 ) / 10;
			const dec = (format < 10 && prepare % 1 ? 1 : 0);

			value = format.toFixed(dec);
			unit = units[p];
		} else if (inputValue < 1) {
			unit = units[p];
			break;
		} else {
			break;
		}
	}

	value = value.toString().replace(".", ",");

	return {
		value,
		unit
	};
}

function buildMeasure(opts: IHeightProfile, drawItems: Array<IDrawItem>) {
	let distance = opts.data.altitude.length;
	const sectionItems: Array<ISectionItem> = [];
	let multiplier = 1;

	if (distance <= 0 || drawItems.length === 0) {
		return sectionItems;
	}

	// km - pouze u vzdalenosti vetsi jak 1000m a jeden dilek je vetsi jak pocet dilku meritka
	if (Math.round(distance / ((opts.measureSections + 1) * 1000)) > opts.measureSections) {
		distance = Math.round(distance / 1000);
		multiplier = 1000;
	}

	let part = Math.round(distance / (opts.measureSections + 1));
	const dd = getDecDivider(part);
	const partRest = dd > 0 ? part % dd : part;
	const decValue = part - partRest;
	let testPart = null;

	// test, jestli muzou byt v meritku hezke hodnoty 0, 5
	if (partRest > 0 && partRest < 5) {
		testPart = 5 + decValue;
	} else if (partRest > 5 && partRest < 10) {
		testPart = decValue + 10;
	}

	// nova hodnota - nesmi to byt uplne nalepene vpravo
	if (testPart !== null && testPart * opts.measureSections <= distance * 0.9) {
		part = testPart;
	}

	for (let i = 1; i <= opts.measureSections; i++) {
		const value = part * i;
		const pos = Math.round((drawItems.length - 1) * value / distance);
		const item = drawItems[pos];

		sectionItems.push({
			x: item.x,
			y: item.y,
			...valueWithUnit(value * multiplier, opts.xUnits),
		});
	}

	return sectionItems;
}

function getPosition(width: number, x: number): IMinMaxItem["position"] {
	if (x >= width * 0.25 && x <= width * 0.75) {
		return "center"
	} else if (x > width * 0.75) {
		return "right";
	}

	return "left";
}

function getMinMaxItem(opts: IHeightProfile, item: IDrawItem, isMaxItem?: boolean): IMinMaxItem {
	// posuneme od okraju o edgeThreshold [px] - samozrejme to pak nevychazi, ale aspon jde neco videt
	const x = Math.max(Math.min(item.x, opts.width - 1 - opts.circleRadius), opts.circleRadius);
	const y = item.y - opts.circleRadius;
	const height = isMaxItem
		? item.y - opts.padding * 0.8
		: item.y - opts.padding;

	return {
		...item,
		x,
		y,
		top: y - height - opts.circleRadius,
		y2: y - height,
		type: isMaxItem ? "max" : "min",
		position: getPosition(opts.width, x),
	};
}

export function getHeightProfileData(opts: IHeightProfile): IHeightProfileData {
	const dataItems = opts.data.altitude.data;
	const dataLen = dataItems.length - 1;
	const altitudeDiff = opts.data.altitude.max - opts.data.altitude.min;
	const items: Array<IDrawItem> = [];
	let minItem: IDrawItem = null;
	let maxItem: IDrawItem = null;

	for (let ind = 0, max = opts.width; ind < max; ind++) {
		const perc = ind === max - 1
			? 1
			: ind / opts.width;
		const pos = perc * dataLen;
		const altitude = getValue(pos, dataItems, dataItem => dataItem.alt) >>> 0;
		const lon = getValue(pos, dataItems, dataItem => dataItem.lon);
		const lat = getValue(pos, dataItems, dataItem => dataItem.lat);
		const altitudeRatio = (altitude - opts.data.altitude.min) / altitudeDiff;
		const y = altitudeRatio * (opts.height - 2 * opts.padding);
		const newItem: IDrawItem = {
			x: ind,
			y: opts.height - opts.padding - y,
			coords: [lon, lat],
			ind,
			altitude,
		};

		items.push(newItem);

		if (!minItem || minItem.altitude > newItem.altitude) {
			minItem = newItem;
		}

		if (!maxItem || maxItem.altitude < newItem.altitude) {
			maxItem = newItem;
		}
	}

	return {
		items,
		measureItems: buildMeasure(opts, items),
		minItem: getMinMaxItem(opts, minItem),
		maxItem: getMinMaxItem(opts, maxItem, true),
	};
}
