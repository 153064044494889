import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import CloseIcon from '@mui/icons-material/Close';

import MyButton from "~/my/MyButton";
import { MAP_ACTIVE_SVG_FILL } from "~/const";

import "./style.less";

export type TMapControlsRightContent = "fullscreen" | "zoom" | "close";

interface IMapControlsRight {
	zoom?: boolean;
	close?: boolean;
	onClick?: (type: TMapControlsRightContent) => void;
}

export default function MapControlsRight({
	zoom = false,
	close = false,
	onClick = () => {},
}: IMapControlsRight) {
	function btnClick(type: TMapControlsRightContent) {
		onClick(type);
	}

	return <div className="mapContainer__mapControlsRight">
		<MyButton text={<FullscreenIcon sx={{ fill: MAP_ACTIVE_SVG_FILL }} />} onClick={() => btnClick("fullscreen")} className="mapContainer__btn" title="Celá obrazovka" />
		{ zoom && <MyButton text={<ZoomInIcon sx={{ fill: MAP_ACTIVE_SVG_FILL }} />} onClick={() => btnClick("zoom")} className="mapContainer__btn" title="Zoom na všechny markery" /> }
		{ close && <MyButton text={<CloseIcon sx={{ fill: MAP_ACTIVE_SVG_FILL }} />} onClick={() => btnClick("close")} className="mapContainer__btn" title="Zavřít mapu" /> }
	</div>;
}
