import { API_KEY, MAPY_API_URL } from "~/const";

export interface ISearchItem {
	name: string;
	label: string;
	position: {
		lon: number;
		lat: number;
	};
	type: string;
	location: string;
	regionalStructure: Array<{
		name: string;
		type: string;
	}>;
}

interface ISearchData {
	items: Array<ISearchItem>;
}

export async function search(query: string): Promise<ISearchData> {
	const url = new URL(`${MAPY_API_URL}/geocode`);

	url.searchParams.set('lang', 'cs');
	url.searchParams.set('apikey', API_KEY);
	url.searchParams.set('query', query);
	url.searchParams.set('limit', '15');
	[
		'regional.municipality',
		'regional.municipality_part',
		'regional.street',
		'regional.address',
		'poi',
	].forEach(type => url.searchParams.append('type', type));

	const response = await fetch(url.toString(), {
		mode: 'cors',
	});
	const json = await response.json();

	return json;
}
