import { ReactNode } from "react";
import { NavLink } from "react-router-dom";

interface IMenuItem {
	link: string;
	title: string;
	onClick: () => void;
	children?: ReactNode;
	activeLink?: string;
}

export default function MenuItem({
	link = "",
	title,
	onClick,
	children = null,
	activeLink = "",
}: IMenuItem) {
	function isActiveLink(testLink: string) {
		const curLink = location.href;

		return (testLink.indexOf("prehled") !== -1 && curLink.indexOf("galerie") !== -1)
			|| (testLink.indexOf("prehled") !== -1 && curLink.indexOf("prehled") !== -1)
			|| (testLink.indexOf("stopy") !== -1 && curLink.indexOf("stopy") !== -1)
			|| (testLink.indexOf("detail") !== -1 && curLink.indexOf("detail") !== -1);
	}

	function getActive(isActive: boolean, customLink?: string) {
		return isActive || (customLink && isActiveLink(customLink))
			? "menu__list__activeItem"
			: "";
	}

	return <NavLink to={link} className={({ isActive }) => getActive(isActive, activeLink)} onClick={onClick}>
		<li className="menu__list__item first">
			<span className="menu__listItemTitle">{ title }</span>
			{ children }
		</li>
	</NavLink>;
}
