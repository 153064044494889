import { useEffect } from "react";

import PageContent from "~/components/PageContent";
import HeaderTitle from "~/components/HeaderTitle";
import { myUseState } from "~/hooks/myUseState";
import { getVisits } from "~/providers/php-api";
import { getVisitJsonItem } from "~/utils/utils";
import VisitsTable from "~/components/VisitsTable";
import { IVisitData } from "~/interfaces";
import Loader from "~/components/Loader";

interface IState extends IVisitData {
	loading: boolean;
}

export default function VisitsPage() {
	const { state, updateState } = myUseState<IState>({
		loading: true,
		admin: [],
		user: [],
		total: {
			adminCount: 0,
			userCount: 0,
		},
		ip: "",
	});
	const visitJson = getVisitJsonItem();

	async function loadData() {
		const data = await getVisits();

		updateState({
			...data.noErrors
				? data.data
				: {},
			loading: false,
		});
	}

	useEffect(() => {
		loadData();
	}, []);

	return <PageContent className="visitsPage" adminContent={true}>
		<HeaderTitle title="Poslední přístupy" />
		<p>
			Aktuální IP adresa: <strong>{ state.ip || "není definovaná" }</strong>, jazyk: <strong>{ visitJson.lang }</strong>, obrazovka: <strong>{ visitJson.screenWidth }x{visitJson.screenHeight }</strong>
		</p>
		<p>
			Agent: <strong>{ visitJson.agent }</strong>
		</p>
		<p>
			Limitováno posledními 25 přístupy - user/admin zvlášť
		</p>
		{ state.loading && <Loader /> }
		<HeaderTitle title={`Uživatelé (${state.total.userCount})`} className="visitsPage__title" />
		{ state.user.length > 0 && <VisitsTable ip={state.ip} visits={state.user} /> }
		<HeaderTitle title={`Admini (${state.total.adminCount})`} className="visitsPage__title" />
		{ state.admin.length > 0 && <VisitsTable ip={state.ip} visits={state.admin} /> }
	</PageContent>;
}
