/* eslint-disable no-magic-numbers */
import maplibregl from "maplibre-gl";
import simplify from 'simplify-geojson';

import { DEFAULT_ZOOM, SIMPLIFY_PRECISION } from "~/const";
import { IDataEventGalleryData, IEvent, IEventDayItem, IGalleryItem, IMyGeoFeatureLineString, IMyGeoFeaturePoint, IMyGeoFeatureProps, IMyGeoJSON, ITrackDistances, ITrackItem, TCoords, TGeoJsonFeatures, TRouteTypes } from "~/interfaces";
import { copyToClipboard, formatDistance, getClassName, getTrackColorByType, transPhotosCount } from "./utils";
import { IDayData, IMapItem, IRouteMarker, ICreateMarkerData, ISavedTrackItem } from "~/map-interfaces";
import { coordsToString, stringToCoords } from "./mirek";
import { ICreatePopupData } from "~/hooks/useMap";
import { ILookupFeaturePOIProperties } from "~/providers/lookup";
import { getRoute } from "~/providers/map";
import dayjs from "dayjs";
import { mergeDistancesWithTracks } from "./events";

type TDebugItems = Pick<IDataEventGalleryData, "items">;

export interface ICreateHeightProfileMarkerData extends Pick<ICreateMarkerData, "marker" | "addToMap"> {
	setAltitude: (altitude: number) => void;
}

export function getGeoJson(features?: Array<TGeoJsonFeatures>) {
	return {
		type: "FeatureCollection",
		features: features || [],
	};
}

export function getLineStringGeoJson(coordinates: IMyGeoFeatureLineString["geometry"]["coordinates"], opts?: IMyGeoFeatureProps): IMyGeoFeatureLineString {
	return {
		type: "Feature",
		geometry: {
			type: "LineString",
			coordinates,
		},
		properties: {
			color: getTrackColorByType("walk"),
			...opts,
		},
	};
}

export function getPointGeoJson(coordinates: IMyGeoFeaturePoint["geometry"]["coordinates"], opts?: IMyGeoFeatureProps): IMyGeoFeaturePoint {
	return {
		type: "Feature",
		geometry: {
			type: "Point",
			coordinates,
		},
		properties: {
			color: getTrackColorByType("walk"),
			...opts,
		},
	};
}

export function getGeoJsonFromSource(source: maplibregl.GeoJSONSource) {
	const allFeatures = source.serialize().data as IMyGeoJSON;

	return allFeatures;
}

export async function getRouteJSON(routeItems: Array<IRouteMarker>, routeType: TRouteTypes = "car_fast", useSimplify?: boolean): Promise<IMyGeoFeatureLineString> {
	/* eslint-disable */
	const feature = getLineStringGeoJson([]);
	let coordinates = [];

	// rozdelime body na routovane a klikane
	const sortedItems: Array<{ type: IRouteMarker["type"], coords: Array<TCoords>; }> = [];
	let currentCoords: Array<TCoords> = [];
	let currentType: IRouteMarker["type"] = "route";

	for (const item of routeItems) {
		if (currentCoords.length === 0) {
			currentCoords.push(item.coords);
			currentType = item.type;
		} else if (item.type === currentType) {
			currentCoords.push(item.coords);
		} else {
			// vlozime
			sortedItems.push({
				type: currentType,
				coords: currentCoords,
			});
			// reset
			currentCoords = [item.coords];
			currentType = item.type;
		}
	}

	if (currentCoords.length > 0) {
		// vlozime
		sortedItems.push({
			type: currentType,
			coords: currentCoords,
		});
	}

	/* eslint-disable no-await-in-loop */
	for (const sortedItem of sortedItems) {
		if (sortedItem.type === "route") {
			coordinates = coordinates.concat(await getRoute(sortedItem.coords, routeType));
		} else if (sortedItem.type === "user") {
			coordinates = coordinates.concat(sortedItem.coords);
		}
	}

	feature.geometry.coordinates = coordinates;

	return useSimplify && feature.geometry.coordinates.length > 0
		? simplify(feature, SIMPLIFY_PRECISION)
		: feature;
}

export function createHeightProfileMarker({
	coords,
	text = "",
}: {
	coords: maplibregl.LngLatLike;
	text?: string;
}): ICreateHeightProfileMarkerData {
	const markerElem = document.createElement('div');
	const markerInnerElem = document.createElement('div');
	const textElem = document.createElement('span');

	textElem.textContent = text;
	markerElem.className = getClassName(["map-marker-text", "top-marker"]);
	markerInnerElem.className = "map-marker-text-inner";
	markerElem.append(markerInnerElem);
	markerInnerElem.append(textElem);

	const marker = new maplibregl.Marker({
		element: markerElem,
		anchor: 'bottom',
	}).setLngLat(coords);

	function addToMap(map: maplibregl.Map) {
		marker.addTo(map);
	}

	function setAltitude(altitude: number) {
		textElem.textContent = altitude.toString();
	}

	return {
		marker,
		addToMap,
		setAltitude,
	};
}

export function createTextMarker({
	coords,
	text = "",
	onTop = false,
	className = "",
	onClick = () => {},
	onDragEnd = () => {},
}: {
	coords: maplibregl.LngLatLike;
	text?: string;
	onTop?: boolean;
	className?: string;
	onClick?: (event: MouseEvent) => void;
	onDragEnd?: (coords: TCoords) => void;
}): ICreateMarkerData {
	const markerElem = document.createElement('div');
	const markerInnerElem = document.createElement('div');
	const textElem = document.createElement('span');

	textElem.textContent = text;
	markerElem.className = getClassName(["map-marker-text", onTop ? "top-marker" : "", className]);
	markerInnerElem.className = "map-marker-text-inner";
	markerElem.append(markerInnerElem);
	markerInnerElem.append(textElem);
	markerInnerElem.addEventListener("click", event => {
		event.stopPropagation();
		event.preventDefault();
		onClick(event);
	});

	const marker = new maplibregl.Marker({
		element: markerElem,
		anchor: 'bottom',
	}).setLngLat(coords);

	function addToMap(map: maplibregl.Map) {
		marker.addTo(map);
	}

	if (typeof onDragEnd === "function") {
		marker.on("dragend", () => {
			const newCoords = marker.getLngLat();

			onDragEnd([newCoords.lng, newCoords.lat]);
		});
	}

	return {
		marker,
		showCaption: () => {},
		showNameCaption: () => {},
		addToMap,
	};
}

interface ICreateImageMarker {
	data: IMapItem;
	active?: boolean;
	onClick?: (event: MouseEvent) => void;
	onDragEnd?: (coords: TCoords) => void;
	onPopupAdd?: (popup: maplibregl.Popup) => void;
}

export function createImageMarker({
	data,
	active = false,
	onClick = () => {},
	onDragEnd = () => {},
}: ICreateImageMarker): ICreateMarkerData {
	const markerElem = document.createElement('div');
	const markerInnerElem = document.createElement('div');
	const img = document.createElement('img');

	markerElem.className = "map-marker";
	markerInnerElem.classList.add("map-marker-inner");

	if (data.isVideo) {
		markerInnerElem.classList.add("video");
	}

	img.src = data.src;
	markerInnerElem.append(img);
	markerInnerElem.addEventListener("click", event => {
		event.stopPropagation();
		event.preventDefault();
		onClick(event);
	});
	markerElem.append(markerInnerElem);

	const marker = new maplibregl.Marker({
		element: markerElem,
		anchor: 'bottom',
	}).setLngLat(data.coords as maplibregl.LngLatLike);

	if (active) {
		marker.addClassName("active");
	}

	marker.on("dragend", () => {
		const coords = marker.getLngLat();

		onDragEnd([coords.lng, coords.lat]);
	});

	function showCaption(captionState: boolean) {
		if (captionState && data.captionTitle) {
			const nameElem = document.createElement('div');

			nameElem.textContent = data.captionTitle;
			nameElem.classList.add("map-marker__caption");
			markerElem.append(nameElem);
		} else {
			marker.getElement().querySelector(".map-marker__caption")?.remove();
		}
	}

	function showNameCaption(nameState: boolean) {
		if (nameState) {
			const nameElem = document.createElement('div');

			nameElem.textContent = data.name.replace(/[.].*$/u, "");
			nameElem.classList.add("map-marker__nameCaption");
			markerElem.append(nameElem);
		} else {
			marker.getElement().querySelector(".map-marker__nameCaption")?.remove();
		}
	}

	function addToMap(map: maplibregl.Map, popupData: ICreatePopupData) {
		marker.addTo(map);
		markerElem.addEventListener("mouseenter", () => {
			popupData.lock = true;

			if (!marker.isDraggable() && data.popupTitle) {
				const isActive = markerElem.classList.contains("active");

				popupData.setOffset(isActive);
				popupData.popup.setLngLat(marker.getLngLat()).setHTML(`<strong>${data.popupTitle}</strong>`);
				popupData.popup.addTo(map);
			}
		});
		markerElem.addEventListener("mouseleave", () => {
			popupData.lock = false;

			if (!marker.isDraggable() && data.popupTitle) {
				popupData.setOffset();
				popupData.popup.remove();
			}
		});
	}

	return {
		marker,
		showCaption,
		showNameCaption,
		addToMap,
	};
}

export function getSeoFromUrl() {
	const parts = location.pathname.split("/").filter(item => item);
	const folderPart = parts[1];
	const folder = folderPart.split("-")[0];
	const namePart = parts[2];

	return {
		folder,
		name: namePart,
		seo: `${folder}-${namePart}`,
	};
}

interface IGeometryItem {
	original: string;
	simplified: string;
	origLen: number;
	simplLen: number;
	ratio: number;
}

export function createTracks(tracks: Array<ITrackItem>, useSimplify?: boolean) {
	const geoJSON = getGeoJson();
	const markers: Array<ICreateMarkerData> = [];
	const geometries: Array<IGeometryItem> = [];

	tracks.forEach((track, ind) => {
		let start: maplibregl.LngLatLike = null;
		let end: maplibregl.LngLatLike = null;

		for (const geometry of track.geometries) {
			const coordinates = stringToCoords(geometry.geometry);

			// start vezmeme z prvni geometrie
			if (!start) {
				start = coordinates[0];
			}

			// cil z te posledni
			end = coordinates[coordinates.length - 1];

			const feature = getLineStringGeoJson(coordinates, {
				color: getTrackColorByType(geometry.type),
			});
			const simplified = useSimplify
				? simplify(feature, SIMPLIFY_PRECISION)
				: feature;
			const geometryItem: IGeometryItem = {
				original: geometry.geometry,
				origLen: geometry.geometry.length,
				ratio: 1,
				simplified: "",
				simplLen: 0,
			};

			if (useSimplify) {
				geometryItem.simplified = coordsToString(simplified.geometry.coordinates);
				geometryItem.simplLen = geometryItem.simplified.length;
				geometryItem.ratio = geometryItem.simplLen / geometryItem.origLen;
			}

			geometries.push(geometryItem);
			geoJSON.features.push(simplified);
		}

		markers.push(
			createTextMarker({
				text: "Start",
				coords: start,
			}),
			createTextMarker({
				text: "Cíl",
				coords: end,
			}),
		);
	});

	return {
		geoJSON,
		markers,
		geometries,
	};
}

export function copyDebug2(routeGeometry: string, items: TDebugItems) {
	const seoData = getSeoFromUrl();
	const folder = seoData.folder;
	const newSeo = seoData.name.replace(/-/ug, "_").toUpperCase();
	const lines = [];

	if (routeGeometry) {
		lines.push(`const TRACKS_${folder} = {`);
		lines.push(`\t${newSeo}: "${routeGeometry}",`);
		lines.push(`};`);
		lines.push("");
	}

	lines.push(`seo: "${seoData.seo}",`);

	if (routeGeometry) {
		lines.push(`tracks: [{ geometries: [{ geometry: TRACKS_${folder}.${newSeo}, type: "walk" }], desc: "Výlet" }],`);
	}

	lines.push(`galleryData: {`);
	lines.push(`\titems: {`);

	Object.keys(items).forEach(key => {
		lines.push(`\t\t"${key}": {`);

		Object.keys(items[key]).forEach(objKey => {
			if (objKey === "coords") {
				const value = items[key].coords;

				lines.push(`\t\t\tcoords: [${value[0]}, ${value[1]}],`);
			} else if (typeof items[key][objKey] === "string") {
				lines.push(`\t\t\t${objKey}: "${items[key][objKey]}",`);
			} else {
				lines.push(`\t\t\t${objKey}: ${items[key][objKey]},`);
			}
		});

		lines.push(`\t\t},`);
	});

	lines.push(`\t},`);
	lines.push(`},`);

	const text = lines.join("\n");

	/* eslint-disable-next-line */
	//console.log(text);
	copyToClipboard(text);
}

function getTracksObjNames() {
	const seoData = getSeoFromUrl();
	const folder = seoData.folder;
	const objName = seoData.name.replace(/-/ug, "_").toUpperCase();

	return {
		mainObjName: `TRACKS_${folder}`,
		objName,
		seo: seoData.seo,
	};
}

function setTracksObj(tracks: ISavedTrackItem[], lines: Array<string>) {
	const namesData = getTracksObjNames();

	lines.push(`const ${namesData.mainObjName} = {`);

	if (tracks.length === 1) {
		lines.push(`\t${namesData.objName}: "${tracks[0].geometry}",`);
	} else if (tracks.length > 1) {
		let ind = 1;

		lines.push(`\t${namesData.objName}: {`);

		for (const track of tracks) {
			lines.push(`\t\tTRACK_${ind}: "${track.geometry}",`);
			ind++;
		}

		lines.push(`\t},`);
	}

	lines.push(`};`);
}

function setSeoObj(tracks: ISavedTrackItem[], lines: Array<string>) {
	const namesData = getTracksObjNames();

	lines.push(`const EVENT = {`);
	lines.push(`\tseo: "${namesData.seo}",`);
	lines.push(`\ttracks: [{ geometries: [`);

	if (tracks.length === 1) {
		lines.push(`\t\t{ geometry: ${namesData.mainObjName}.${namesData.objName}, type: "${tracks[0].type}" },`);
	} else {
		let ind = 1;

		for (const track of tracks) {
			lines.push(`\t\t{ geometry: ${namesData.mainObjName}.${namesData.objName}.TRACK_${ind}, type: "${track.type}" },`);
			ind++;
		}
	}

	lines.push(`\t],`);
	lines.push(`\tdesc: "Výlet" }],`);
}

function setItemsObj(items: TDebugItems, lines: Array<string>) {
	const allItems = Object.keys(items);

	if (allItems.length) {
		allItems.sort((aItem, bItem) => aItem.localeCompare(bItem));
		lines.push(`\titems: {`);

		allItems.forEach(key => {
			lines.push(`\t\t"${key}": {`);

			Object.keys(items[key]).forEach(objKey => {
				if (objKey === "coords") {
					const value = items[key].coords;

					lines.push(`\t\t\tcoords: [${value[0]}, ${value[1]}],`);
				} else if (typeof items[key][objKey] === "string") {
					lines.push(`\t\t\t${objKey}: "${items[key][objKey]}",`);
				} else {
					lines.push(`\t\t\t${objKey}: ${items[key][objKey]},`);
				}
			});

			lines.push(`\t\t},`);
		});

		lines.push(`\t},`);
	}

	lines.push(`};`);
	lines.push("");
}

export function copyDebug(tracks: ISavedTrackItem[], items: TDebugItems) {
	const lines = [];

	if (tracks.length) {
		setTracksObj(tracks, lines);
		lines.push("");
		setSeoObj(tracks, lines);
	}

	setItemsObj(items, lines);

	const text = lines.join("\n");

	/* eslint-disable-next-line */
	copyToClipboard(text);
}

export function getMapyczUrl(lon: number, lat: number, params: Array<{ name: string; value: string }>) {
	const link = new URL("https://mapy.cz/turisticka");

	link.searchParams.append("x", lon.toString());
	link.searchParams.append("y", lat.toString());
	link.searchParams.append("z", DEFAULT_ZOOM.toString());
	params.forEach(param => link.searchParams.append(param.name, param.value));

	return link.toString();
}

export function openMapyczDetail(data: ILookupFeaturePOIProperties) {
	window.open(getMapyczUrl(data.longitude, data.latitude, [
		{ name: "source", value: data.source },
		{ name: "id", value: data.id.toString() },
	]));
}

export function getMapData(items: Array<IMapItem>, days: Array<IEventDayItem>) {
	const datesItems: Array<IDayData> = [];
	let allTracks: Array<ITrackItem> = [];
	let tracksBounds: maplibregl.LngLatBounds = null;

	for (const day of days) {
		if (day.type === "day") {
			const photosCount = items.filter(photo => photo.date === day.date).length;
			const tracks = day.tracks || [];

			// kdyz nic neni
			if (!tracks.length && !photosCount) {
				continue;
			}

			datesItems.push({
				date: day.date,
				title: dayjs(day.date).format("D. M."),
				subTitle: `${transPhotosCount(photosCount)}${tracks.length > 0 ? `, ${formatDistance(day.distances.all)}` : ""}`,
				tracks,
			});

			if (tracks.length) {
				allTracks = allTracks.concat(tracks);
			}
		}
	}

	if (days.length > 1) {
		// seradime
		datesItems.sort((aItem, bItem) => dayjs(aItem.date).unix() - dayjs(bItem.date).unix());
		// vlozime
		datesItems.push({
			date: "",
			subTitle: "",
			title: "Všechny dny",
			tracks: [],
		});
	}

	for (const track of allTracks) {
		if (tracksBounds) {
			tracksBounds.extend(track.bbox);
		} else {
			tracksBounds = new maplibregl.LngLatBounds(track.bbox[0], track.bbox[1]);
		}
	}

	return {
		datesItems,
		allTracks,
		tracksBounds,
	};
}

export function createEmptyDistances(): ITrackDistances {
	return {
		walk: 0,
		car: 0,
		boat: 0,
		bike: 0,
		cable: 0,
		pubt: 0,
		all: 0,
	};
}

function createEmptyEvent(date: string): IEventDayItem {
	return {
		desc: [],
		items: [],
		photosCount: 0,
		videosCount: 0,
		type: "day",
		distances: createEmptyDistances(),
		date,
		tracks: [],
	};
}

export function getMapDays(event: IEvent): Array<IEventDayItem> {
	if (event.days) {
		return event.days;
	}

	const datesObjDays: {[key: string]: IEventDayItem; } = {};
	const newDays: Array<IEventDayItem> = [];

	if (event.gallery) {
		event.gallery.items.forEach((galleryItem: IGalleryItem) => {
			const dateKey = galleryItem.date || "";
			const dateMatch = dateKey.match(/\d{4}-\d{2}-\d{2}/u);

			if (dateMatch) {
				const onlyDate = dateMatch[0];

				if (!(onlyDate in datesObjDays)) {
					datesObjDays[onlyDate] = createEmptyEvent(onlyDate);
				}

				datesObjDays[onlyDate].items.push(galleryItem);
				datesObjDays[onlyDate].photosCount += galleryItem.isVideo ? 0 : 1;
				datesObjDays[onlyDate].videosCount += galleryItem.isVideo ? 1 : 0;
			}
		});
	}

	if (event.tracks) {
		event.tracks.forEach((track: ITrackItem) => {
			if (!(track.date in datesObjDays)) {
				datesObjDays[track.date] = createEmptyEvent(track.date);
			}

			datesObjDays[track.date].tracks.push(track);
			mergeDistancesWithTracks(datesObjDays[track.date].distances, [track]);
		});
	}

	const allDays = Object.keys(datesObjDays);

	allDays.sort((aItem, bItem) => aItem.localeCompare(bItem));
	allDays.forEach(day => {
		newDays.push(datesObjDays[day]);
	});

	return newDays;
}

export function getBoundsCornerPoints(bounds: maplibregl.LngLatBounds) {
	const output: Array<maplibregl.LngLat> = [];

	output.push(bounds.getNorthEast());
	output.push(bounds.getNorthWest());
	output.push(bounds.getSouthEast());
	output.push(bounds.getSouthWest());

	return output;
}

export function boundsDistance(bounds1: maplibregl.LngLatBounds, bounds2: maplibregl.LngLatBounds) {
	const distances: Array<number> = [];
	const sourcePoints = getBoundsCornerPoints(bounds1);
	const destPoints = getBoundsCornerPoints(bounds2);

	for (const sourcePoint of sourcePoints) {
		for (const destPoint of destPoints) {
			distances.push(sourcePoint.distanceTo(destPoint));
		}
	}

	distances.sort((aItem, bItem) => aItem - bItem);

	const centerDist = bounds1.getCenter().distanceTo(bounds2.getCenter());

	return {
		center: centerDist,
		corners: distances[0],
	};
}

export function coordsToBounds(coords: Array<TCoords | maplibregl.LngLatLike>) {
	return coords.reduce((bounds, coord) => bounds.extend(coord), new maplibregl.LngLatBounds(coords[0], coords[0]));
}

export function createContextMenuItem(popup: maplibregl.Popup, title = "", onClick: () => void) {
	const item = document.createElement("button");

	item.type = "button";
	item.classList.add("map-content-menu-item");
	item.textContent = title;
	item.addEventListener("click", () => {
		onClick();
		popup.remove();
	});

	return item;
}

export function createContextMenu() {
	const popup = new maplibregl.Popup({
		closeButton: false,
		closeOnClick: true,
	});
	const node = document.createElement("div");
	const exists = document.querySelector(".map-content-menu");

	if (exists) {
		exists.remove();
	}

	node.classList.add("map-content-menu");

	return {
		popup,
		node,
		addToMap: (map: maplibregl.Map, coords: maplibregl.LngLat) => {
			popup.setLngLat(coords);
			popup.setDOMContent(node);
			popup.addTo(map);
		},
	};
}
