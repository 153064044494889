import { useParams } from "react-router-dom";
import { useEffect } from "react";
import dayjs from "dayjs";
import maplibregl from "maplibre-gl";

import PageContent from "~/components/PageContent";
import { getEventBySeo, getGalleryBySeo, getTracksYearsList } from "~/providers/events";
import { myUseState } from "~/hooks/myUseState";
import { formatDistance, getEventDetailLink, getGalleryLink, getShortEventTitleDate, getTracksLink } from "~/utils/utils";
import { IGalleryFolder, ITrackItem, ITrackListItem, ITracksList, TTrackType } from "~/interfaces";
import HeaderTitle from "~/components/HeaderTitle";
import Tags from "~/components/Tags";
import OverviewSwitcher from '~/components/OverviewSwitcher';
import { ALL_OVERVIEW_DATA, DEFAULT_MAP_POSITION } from '~/const';
import Map, { IOnClickData } from "~/components/Map";
import { ASSETS } from "~/utils/assets";
import { CAR_DISTANCES, ITrackTagItem, TYPES, WALK_DISTANCES } from "~/providers/tracks";
import { IMapItem } from "~/map-interfaces";
import { createContextMenu, createContextMenuItem } from "~/utils/map";

import "./style.less";

interface IParams {
	year: string;
	[key: string]: string;
}

interface IState {
	years: Array<IGalleryFolder>;
	items: Array<IMapItem>;
	selType: TTrackType;
	selFilter: ITrackTagItem;
	distances: Array<ITrackTagItem>;
	tracksList: ITracksList;
	firstLoad: boolean;
	customTracks: Array<ITrackItem>;
	loaded: boolean;
}

export default function TracksPage() {
	const { year } = useParams<IParams>();
	const { state, updateState, setState } = myUseState<IState>({
		years: [],
		items: [],
		selType: "walk",
		selFilter: WALK_DISTANCES[0],
		distances: WALK_DISTANCES,
		tracksList: {},
		firstLoad: true,
		customTracks: [],
		loaded: false,
	});
	const position = {
		...DEFAULT_MAP_POSITION,
	};

	function getLink(item: ITrackListItem) {
		return item.gallery
			? `${getGalleryLink(item.event.seo, item.event.date, item.event.toDate)}?map=1`
			: getEventDetailLink(item.event.seo, item.event.date, item.event.toDate);
	}

	function getItems(selYear: string, tracksList: ITracksList) {
		if (selYear === ALL_OVERVIEW_DATA.URL) {
			return tracksList["0"] || [];
		}

		return tracksList[selYear] || [];
	}

	function openGallery(name: string) {
		const item = getItems(year, state.tracksList).filter(trackItem => trackItem.event.seo === name)[0];
		const link = getLink(item);

		window.open(link);
	}

	function onMapClick({ item: mapItem, map }: IOnClickData) {
		const contextMenuData = createContextMenu();

		contextMenuData.node.append(
			createContextMenuItem(contextMenuData.popup, "Detail", () => {
				openGallery(mapItem.name);
			}),
			createContextMenuItem(contextMenuData.popup, "Stopa", () => {
				const galleryData = getGalleryBySeo(mapItem.name);
				let sourceTracks: Array<ITrackItem> = [];

				if (galleryData) {
					sourceTracks = galleryData.event.allTracks;
				} else {
					const eventData = getEventBySeo(mapItem.name);

					if (eventData.allTracks) {
						sourceTracks = eventData.allTracks;
					}
				}

				setState(prev => ({
					...prev,
					customTracks: [
						...prev.customTracks,
						...sourceTracks.filter(track => track.geometries.filter(geom => geom.type === state.selType).length > 0),
					],
				}));
			}),
		);
		contextMenuData.addToMap(map, new maplibregl.LngLat(mapItem.coords[0], mapItem.coords[1]));
	}

	function filterByType(item: ITrackItem, type: TTrackType) {
		return item.geometries.filter(geom => geom.type === type).length > 0;
	}

	function filterItems(items: Array<ITrackListItem>, range: ITrackTagItem["range"], type: TTrackType) {
		const filtered = items.filter(item => item.tracks.filter(trackItem => filterByType(trackItem, type) && (!range || (trackItem.distances[type] >= range[0] && trackItem.distances[type] <= range[1]))).length > 0);
		const output: Array<IMapItem> = filtered.map(item => {
			const titleYear = year === ALL_OVERVIEW_DATA.URL
				? `${dayjs(item.event.date).get("year")}, `
				: "";
			const title = getShortEventTitleDate(item.event);
			const distanceItems = range
				? item.tracks.filter(trackItem => filterByType(trackItem, type) && trackItem.distances[type] >= range[0] && trackItem.distances[type] <= range[1]).map(distItem => formatDistance(distItem.distances[type]))
				: [formatDistance(item.distances[type])];

			return {
				name: item.event.seo,
				src: item.gallery
					? item.gallery.cover
					: ASSETS.PlaceholderImg,
				coords: item.event.coords,
				captionTitle: distanceItems[0],
				popupTitle: `${titleYear}${title}, ${distanceItems.join(", ")}`,
			};
		});

		return output;
	}

	function setFilter(filterName: string) {
		const filtered = state.distances.filter(item => item.title === filterName);

		if (filtered.length === 1) {
			setState(prev => ({
				...prev,
				selFilter: filtered[0],
				items: filterItems(getItems(year, prev.tracksList), filtered[0].range, prev.selType),
				customTracks: [],
			}));
		}
	}

	function loadTracks() {
		const tracksList = getTracksYearsList();
		const years: Array<IGalleryFolder> = Object.keys(tracksList).map(item => item).filter(item => parseFloat(item) > 0).reverse().map(item => ({
			title: item,
			url: getTracksLink(item.toString()),
			active: item === year,
		}));

		years.unshift({
			title: ALL_OVERVIEW_DATA.TITLE,
			url: getTracksLink("0"),
			active: year === ALL_OVERVIEW_DATA.URL,
		});
		updateState({
			years,
			items: filterItems(getItems(year, tracksList), state.selFilter.range, state.selType),
			tracksList,
			customTracks: [],
		});
	}

	function onPageLoad() {
		updateState({
			loaded: true,
		});
	}

	function setType(typeStr: string) {
		const type: TTrackType = TYPES.filter(item => item.title === typeStr)[0].type;

		let selFilter: ITrackTagItem = null;
		let distances: Array<ITrackTagItem> = [];

		if (type === "car") {
			selFilter = CAR_DISTANCES[0];
			distances = CAR_DISTANCES;
		} else {
			selFilter = WALK_DISTANCES[0];
			distances = WALK_DISTANCES;
		}

		setState(prev => ({
			...prev,
			selType: type,
			selFilter,
			distances,
			items: filterItems(getItems(year, prev.tracksList), distances[0].range, type as TTrackType),
			customTracks: [],
		}));
	}

	useEffect(() => {
		if (state.firstLoad) {
			updateState({
				firstLoad: false,
			});
		}
	}, [state.firstLoad]);

	useEffect(() => {
		if (state.loaded) {
			loadTracks();
		}
	}, [year, state.loaded]);

	return <PageContent className="tracksPage" loggedContent={true} onLoad={onPageLoad} dependencies={[year]} disableScrollTop={!state.firstLoad}>
		<HeaderTitle title="Seznam stop" />
		{ state.years.length > 0 && <>
			<OverviewSwitcher items={state.years} className="tracksPage__yearsSwitcher" />
			<div className="tracksPage__tagsFilter">
				<Tags items={TYPES.map(item => item.title)} selected={[TYPES.filter(item => item.type === state.selType)[0].title]} onClick={setType} />
				<Tags items={state.distances.map(item => item.title)} selected={state.selFilter ? [state.selFilter.title] : []} onClick={setFilter} />
			</div>
			<Map items={state.items} position={position} onClick={onMapClick} hasClose={false} customTracks={state.customTracks} />
		</> }
	</PageContent>;
}
