import CircularProgress from '@mui/material/CircularProgress';

import { getClassName } from "~/utils/utils";

import "./style.less";

interface ILoader {
	className?: string;
}

export default function Loader({
	className = "",
}: ILoader) {
	return <div className={getClassName(["loader", className])}>
		<CircularProgress />
	</div>;
}
