import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import LoopIcon from '@mui/icons-material/Loop';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import CloseIcon from '@mui/icons-material/Close';

import { getClassName } from "~/utils/utils";
import MyVideoControlProgressBar from "../MyVideoControlProgressBar";

import "./style.less";

export type TMyVideoControlsPosition = "top" | "bottom";
export type TMyVideoControlsVariant = "gallery" | "playlist";
export type TMyVideoControlsClickItem = "play" | "pause" | "progress" | "sound" | "loop" | "fullscreen" | "prev" | "next" | "close";

export interface IMyVideoControlsClickData {
	// procent
	sound: number;
	// procent
	progress: number;
}

interface IMyVideoControls {
	percent?: number;
	sound?: number;
	soundEdge?: number;
	text?: string;
	isPlaying?: boolean;
	isFullscreen?: boolean;
	isActiveLoop?: boolean;
	position?: TMyVideoControlsPosition;
	hide?: boolean;
	variant?: TMyVideoControlsVariant;
	onClick?: (type: TMyVideoControlsClickItem, data?: IMyVideoControlsClickData) => void;
}

const MAX_PERC = 100;
const SOUND_EDGE_PERC = 5;

export default function MyVideoControls({
	percent = 0,
	sound = MAX_PERC,
	soundEdge = SOUND_EDGE_PERC,
	text = "",
	isPlaying = false,
	isFullscreen = false,
	isActiveLoop = false,
	position = "bottom",
	hide = false,
	variant = "gallery",
	onClick = () => {},
}: IMyVideoControls) {
	function playPauseClick() {
		onClick(isPlaying ? "pause" : "play");
	}

	function onVideoProgressClick(perc: number) {
		onClick("progress", {
			sound,
			progress: perc,
		});
	}

	function muteClick() {
		onClick("sound", {
			progress: percent,
			sound: sound === 0 ? MAX_PERC : 0,
		});
	}

	function onSoundProgressClick(perc: number) {
		// pro okraje
		let setPerc = perc;

		if (setPerc <= soundEdge) {
			setPerc = 0;
		} else if (setPerc >= MAX_PERC - soundEdge) {
			setPerc = MAX_PERC;
		}

		onClick("sound", {
			progress: percent,
			sound: setPerc,
		});
	}

	const showControls = (isFullscreen && variant === "gallery") || (!isFullscreen && variant === "playlist");

	return <div className={getClassName(["myVideoControls", position, hide ? "hide" : ""])}>
		<button className="myVideoControls__playStop" type="button" onClick={playPauseClick}>
			{ isPlaying ? <PauseIcon sx={{ fill: "#fff" }} /> : <PlayArrowIcon sx={{ fill: "#fff" }} /> }
		</button>
		{ variant === "playlist" && <button className="myVideoControls__prevNext" type="button" onClick={() => onClick("prev")}>
			<SkipPreviousIcon sx={{ fill: "#fff" }} />
		</button> }
		<MyVideoControlProgressBar percent={percent} text={`${text}`} onClick={onVideoProgressClick} />
		{ variant === "playlist" && <button className="myVideoControls__prevNext" type="button" onClick={() => onClick("next")}>
			<SkipNextIcon sx={{ fill: "#fff" }} />
		</button> }
		<button className="myVideoControls__sound" type="button" onClick={muteClick}>
			{ sound === 0 ? <VolumeMuteIcon sx={{ fill: "#fff" }} /> : <VolumeUpIcon sx={{ fill: "#fff" }} /> }
		</button>
		{ showControls && <MyVideoControlProgressBar percent={sound} text={<VolumeUpIcon sx={{ fill: "#fff", width: "22px", height: "22px" }} />} className="myVideoControls__progressSound"
			onClick={onSoundProgressClick} /> }
		{ showControls && <button className="myVideoControls__loop" type="button" onClick={() => onClick("loop")}>
			<LoopIcon sx={{ width: "22px", height: "22px", fill: isActiveLoop ? "#df5f5f" : "#fff" }} />
		</button> }
		<button className="myVideoControls__fullscreen" type="button" onClick={() => onClick("fullscreen")}>
			<FullscreenIcon sx={{ fill: "#fff" }} />
		</button>
		{ variant === "playlist" && <button className="myVideoControls__close" type="button" onClick={() => onClick("close")}>
			<CloseIcon sx={{ fill: "#fff" }} />
		</button> }
	</div>;
}
