import "./style.less";

interface IMyGalleryTopRightDesc {
	group?: string;
	news?: boolean;
	daysFormat?: boolean;
}

export default function MyGalleryTopRightDesc({
	group = "",
	news = false,
	daysFormat = false,
}: IMyGalleryTopRightDesc) {
	return <>
		{ (group || news || daysFormat) && <div className="myGalleryTopRightDesc">
			{ daysFormat && <div className="myGalleryTopRightDesc__item myGalleryTopRightDesc__daysFormat">
				Přehled dnů
			</div> }
			{ news && <div className="myGalleryTopRightDesc__item myGalleryTopRightDesc__news">
				Nový obsah
			</div> }
			{ group && <div className="myGalleryTopRightDesc__item myGalleryTopRightDesc__public">
				{ group }
			</div> }
		</div> }
	</>;
}
