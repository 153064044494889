/* eslint-disable no-magic-numbers */
import { useEffect, useRef } from 'react';
import { Stage, Sprite, useTick } from '@pixi/react';
import '@pixi/events';

import { EMOTIONS } from "~/data/emotions";
import { myUseState } from "~/hooks/myUseState";
import { setDefaultBackground, setFishBackground } from "~/utils/assets";
import { debounce } from "~/utils/utils";

import "./style.less";

export type TPixiMode = "none" | "default" | "owls" | "fishes";

interface IState {
	width: number;
	height: number;
	loaded: boolean;
	showEmojis: boolean;
}

interface IMartinekSprite {
	x: number;
	y: number;
	direction: number;
	texture: string;
	size: Array<number>;
}

interface IEntitySprite {
	x: number;
	y: number;
	rotation: number;
	direction: number;
	turningSpeed: number;
	speed: number;
}

interface IPixiBackground {
	mode: TPixiMode;
}

type TSourceItems = Array<string>;

function getFilteredItems(filter?: string, count?: number): TSourceItems {
	let emojis = [];

	if (filter && count > 0) {
		const sourceEmojis = EMOTIONS.filter(item => item.indexOf(filter) !== -1);


		for (let ind = 0; ind < count; ind++) {
			emojis = emojis.concat(sourceEmojis);
		}
	} else {
		emojis = EMOTIONS;
	}

	return emojis.map(emoji => `/emoji/${emoji}.png`);
}

function getItems(mode: TPixiMode): TSourceItems {
	switch (mode) {
		case "default":
			return getFilteredItems();

		case "owls":
			return getFilteredItems("sova", 3);

		case "fishes":
			return getFilteredItems("ryba", 15);

		default:
	}

	return [];
}

export default function PixiBackground({
	mode = "default",
}: IPixiBackground) {
	const { state, updateState } = myUseState<IState>({
		width: 100,
		height: 100,
		loaded: false,
		showEmojis: true,
	});
	const pixiCoverElem = useRef<HTMLDivElement>(null);
	const items = getItems(mode);
	const Martinek = () => {
		const speed = 3;
		const sizeNormal = [256, 256];
		const sizeFull = [360, 400];
		const textureNormal = "/pixi/martinek.png";
		const textureFull = "/pixi/tatrovka-01.png";
		const { state: spriteData, setState: setSpriteData } = myUseState<IMartinekSprite>({
			x: 0,
			y: state.height - sizeNormal[1],
			direction: 1,
			texture: textureNormal,
			size: sizeNormal,
		});

		function onClick() {
			setSpriteData(prev => {
				const isFull = prev.texture === textureNormal;
				const size = isFull ? sizeFull : sizeNormal;

				return {
					...prev,
					texture: isFull ? textureFull : textureNormal,
					size,
					y: state.height - size[1],
				};
			});
		}

		useTick(delta => {
			setSpriteData(prev => {
				const xVal = prev.x + (prev.direction * speed * delta);
				let direction = prev.direction;

				if (xVal + prev.size[0] >= state.width) {
					direction = -1;
				} else if (xVal < 0) {
					direction = 1;
				}

				return {
					...prev,
					x: xVal,
					direction,
				};
			});
		});

		return <Sprite image={spriteData.texture} anchor={0} pointerdown={onClick} width={spriteData.size[0]} height={spriteData.size[1]} x={spriteData.x} y={spriteData.y} interactive />;
	};

	const MyEntity = ({ texture }: { texture: string; }) => {
		const width = 256;
		const height = 256;
		const scale = 0.8 + (Math.random() * 0.3);
		const dudeBounds = {
			x: 0,
			y: 0,
			width: state.width,
			height: state.height,
		};
		const { state: spriteData, setState: setSpriteData } = myUseState<IEntitySprite>({
			x: Math.random() * state.width,
			y: Math.random() * state.height,
			rotation: 0,
			direction: Math.random() * Math.PI * 2,
			turningSpeed: Math.random() - 0.8,
			speed: 2 + (Math.random() * 2),
			...mode === "fishes"
				? {
					speed: 1.5 + (Math.random() * 1.5),
					direction: 3/2 * Math.PI,
				}
				: {},
		});

		function onClick() {
			setSpriteData(prev => ({
				...prev,
				direction: prev.direction * -1,
			}));
		}

		useTick(() => {
			setSpriteData(prev => {
				const direction = prev.direction + (prev.turningSpeed * 0.01);
				let xVal = prev.x + (Math.sin(prev.direction) * prev.speed);
				let yVal = prev.y + (Math.cos(prev.direction) * prev.speed);
				const rotation = -prev.direction - (Math.PI / 2);

				if (xVal < dudeBounds.x) {
					xVal += dudeBounds.width;
				} else if (xVal > dudeBounds.x + dudeBounds.width) {
					xVal -= dudeBounds.width;
				}

				if (yVal < dudeBounds.y) {
					yVal += dudeBounds.height;
				} else if (yVal > dudeBounds.y + dudeBounds.height) {
					yVal -= dudeBounds.height;
				}

				return {
					...prev,
					...mode === "fishes"
						? {}
						: {
							direction,
							y: yVal,
							rotation,
						},
					x: xVal,
				};
			});
		});

		return <Sprite image={texture} pointerdown={onClick} anchor={0.5} scale={scale} width={width} height={height} x={spriteData.x} y={spriteData.y} rotation={spriteData.rotation} interactive />;
	};

	// resize canvasu
	useEffect(() => {
		if (pixiCoverElem.current) {
			updateState({
				width: pixiCoverElem.current.offsetWidth,
				height: pixiCoverElem.current.offsetHeight,
			});

			const resize = debounce(() => {
				updateState({
					width: pixiCoverElem.current.offsetWidth,
					height: pixiCoverElem.current.offsetHeight,
				});
			}, 200);

			window.addEventListener("resize", resize);

			return () => {
				window.removeEventListener("resize", resize);
			};
		}

		return () => {};
	}, [pixiCoverElem.current]);

	// uprava pozadi
	useEffect(() => {
		if (mode === "fishes") {
			setFishBackground();
		}

		return () => {
			if (mode === "fishes") {
				setDefaultBackground();
			}
		};
	}, [mode]);

	/* eslint-disable react/no-array-index-key */
	return <div className="pixiBackground" ref={pixiCoverElem} style={{ visibility: state.loaded ? "visible" : "hidden" }}>
		<Stage width={state.width} height={state.height} options={{ backgroundAlpha: 0 }} onMount={() => updateState({ loaded: true })}>
			{ state.showEmojis && items.map((texture, ind) => <MyEntity texture={texture} key={ind} />) }
			{ mode === "default" && <Martinek /> }
		</Stage>
	</div>;
}
