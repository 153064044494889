import { MAPY_ALTITUDE } from "~/const";
import { call } from "~/lib/net/rpc";

export interface IProfileDataItem {
	alt: number;
	dist: number;
	lat: number;
	lon: number;
	niceAlt: number;
}

export interface IProfileData {
	altitude: {
		data: Array<IProfileDataItem>;
		gain: number;
		length: number;
		loss: number;
		max: number;
		min: number;
	};
}

/* eslint-disable no-magic-numbers */
export function getProfile(geometry: string, count = 100) {
	return call("profile", [geometry, { count }], {
		url: MAPY_ALTITUDE,
		hints: {},
	}) as Promise<IProfileData>;
}
