import { MouseEvent } from "react";
import RouteIcon from '@mui/icons-material/Route';
import { SxProps } from "@mui/material";

import { TCoords } from "~/interfaces";

import "./style.less";

interface IMyGalleryTopLeftDesc {
	coords?: TCoords;
	title?: string;
	tracksCount?: number;
	onClick?: (coords: TCoords) => void;
}

export default function MyGalleryTopLeftDesc({
	coords = null,
	title = "",
	tracksCount = 0,
	onClick = () => {},
}: IMyGalleryTopLeftDesc) {
	const iconStyle: SxProps = {
		fill: "#fff",
		width: "16px",
		height: "16px",
	};

	function onBtnClick(event: MouseEvent) {
		event.preventDefault();
		event.stopPropagation();

		if (coords) {
			onClick(coords);
		}
	}

	return <div className="myGalleryTopLeftDesc" onClick={onBtnClick}>
		{ coords && <div className="myGalleryTopLeftDesc__mapLine">
			<span>Mapa</span>
			{ tracksCount > 0 && <RouteIcon sx={iconStyle} /> }
			{ tracksCount > 0 && tracksCount }
		</div> }
		{ title && <div className="myGalleryTopLeftDesc__titleLine">
			{ title }
		</div> }
	</div>;
}
