import { HTTPError, FastRPCError, TimeoutError, AbortError } from './errors';
import { serializeCall, parse, Hints } from './fastrpc';

interface Header {
	name: string;
	value: string;
}

export interface Options {
	timeout: number;
	hints: Hints;
	url: string;
	hashId: string;
	headers: Array<Header>;
	type: 'frpc';
}

const OPTIONS: Options = {
	timeout: 0,
	hints: {},
	url: '/RPC2',
	hashId: '',
	headers: [],
	type: 'frpc',
};

export const STATUS_OK = 200;
export const STATUS_ERROR = 400;
export const STATUS_REDIRECT = 300;

const NO_FUNC = function () {};

export default class Request {
	_resolve: Function;

	_reject: Function;

	_options: Options;

	_xhr: XMLHttpRequest;

	_promise: Promise<object>;

	constructor(method: string, args: any[] = [], options: Partial<Options> = {}) {
		this._resolve = NO_FUNC;
		this._reject = NO_FUNC;
		this._options = {
			...OPTIONS,
			...options,
		};

		this._promise = new Promise((resolve, reject) => {
			this._resolve = resolve;
			this._reject = reject;
		});

		const xhr = new XMLHttpRequest();

		xhr.addEventListener('abort', this);
		xhr.addEventListener('error', this);
		xhr.addEventListener('timeout', this);
		xhr.addEventListener('load', this);
		xhr.open('POST', this._options.url, true);
		if ('timeout' in xhr && typeof xhr.timeout !== 'function') {
			// v IE lze az po .open()
			xhr.timeout = this._options.timeout;
		}
		if (this._options.hashId) {
			xhr.setRequestHeader('X-Seznam-hashId', this._options.hashId);
		}

		for (let index = 0; index < this._options.headers.length; index++) {
			const { name, value } = this._options.headers[index];

			xhr.setRequestHeader(name, value);
		}

		if (this._options.type === 'frpc' && Uint8Array) {
			xhr.responseType = 'arraybuffer';
			xhr.setRequestHeader('Accept', 'application/x-frpc');
			xhr.setRequestHeader('Content-Type', 'application/x-frpc');
			xhr.send(new Uint8Array(serializeCall(method, args, options.hints)));
		} else {
			throw new Error('Unknown RPC type, frpc only supported or no Uint8Array!');
		}

		this._xhr = xhr;
	}

	getPromise() {
		return this._promise;
	}

	abort() {
		if (this._xhr) {
			this._xhr.abort();
			this._reject(new AbortError());
		}
	}

	handleEvent(event: Event) {
		let error = null;

		switch (event.type) {
			case 'abort':
				this._reject(new AbortError());
				break;

			case 'error':
				error = new HTTPError(this._xhr.status, this._xhr.statusText);
				this._reject(error);
				break;

			case 'timeout':
				error = new TimeoutError();
				this._reject(error);
				break;

			case 'load':
				this._response(this._xhr);
				break;

			default:
				// nic
		}
	}

	_response(xhr: XMLHttpRequest) {
		const status = xhr.status;

		if (status < STATUS_OK || status >= STATUS_REDIRECT) {
			this._reject(new HTTPError(status, xhr.statusText));

			return;
		}

		let data = null;

		if (this._options.type === 'frpc') {
			if (Uint8Array && xhr.response instanceof ArrayBuffer) {
				data = parse(new Uint8Array(xhr.response));
			}
		}

		if (!data) {
			this._reject(new FastRPCError(STATUS_ERROR, 'Cannot parse response, or no respone'));
		}

		if ('status' in data && (data.status < STATUS_OK || data.status >= STATUS_REDIRECT)) {
			this._reject(new FastRPCError(data.status, data.statusMessage, data.data));

			return;
		}

		this._resolve(data);
	}
}
