import { useEffect } from "react";

import { myUseState } from "~/hooks/myUseState";
import MyButton from "~/my/MyButton";
import { getCover } from "~/utils/uploadCovers";
import { formatSize, getClassName } from "~/utils/utils";
import { ASSETS } from "~/utils/assets";

import "./style.less";

interface IState {
	imgSrc: string;
}

interface IUploadItem {
	id: number;
	file: File;
	sizeError?: boolean;
	onDelete?: (id: number) => void;
}

export default function UploadItem({
	id,
	file,
	sizeError,
	onDelete = () => {},
}: IUploadItem) {
	const { state, updateState } = myUseState<IState>({
		imgSrc: ASSETS.PlaceholderImg,
	});

	async function loadCover() {
		const coverData = await getCover(file);

		if (coverData.data) {
			updateState({
				imgSrc: coverData.data,
			});
		}
	}

	useEffect(() => {
		loadCover();
	}, []);

	return <div className="uploadItem">
		<div className="uploadItem__cover">
			<div className="uploadItem__type">
				{ file.type.match("image") ? "obrázek" : "video" }
			</div>
			<img className="uploadItem__img" src={state.imgSrc} alt="" />
		</div>
		<div className="uploadItem__title">
			{ file.name.replace(/[.].*$/u, "") }
		</div>
		<div className={getClassName(["uploadItem__title", "size", sizeError ? "error" : ""])}>
			{ formatSize(file.size) }
		</div>
		<MyButton text="Smazat" variant="filled" onClick={() => onDelete(id)} />
	</div>;
}
