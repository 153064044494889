import { useEffect, MutableRefObject } from "react";

export function useImageLoad(imageRef: MutableRefObject<HTMLImageElement>) {
	function loadImage() {
		if (!imageRef.current) {
			return () => {};
		}

		const observer = new IntersectionObserver(
			entries => {
				entries.forEach(entry => {
					const img = entry.target as HTMLImageElement;

					if (entry.isIntersecting) {
						observer.unobserve(img);
						observer.disconnect();

						if (img.dataset.load === "false") {
							img.dataset.load = "true";
							img.setAttribute('src', img.dataset.src);
						}
					}
				});
			},
			{},
		);

		observer.observe(imageRef.current);

		return () => {
			observer.disconnect();
		};
	}

	useEffect(loadImage, [imageRef]);
}
