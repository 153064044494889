/* eslint-disable no-magic-numbers */
/* eslint-disable no-await-in-loop */
import { ChangeEvent, DragEvent } from "react";

import PageContent from "~/components/PageContent";
import HeaderTitle from "~/components/HeaderTitle";
import { myUseState } from "~/hooks/myUseState";
import { formatSize, getClassName } from "~/utils/utils";
import UploadItem from "~/components/UploadItem";
import MyButton from "~/my/MyButton";
import { uploadFile } from "~/providers/php-api";
import { MAX_UPLOADED_SIZE, MAX_UPLOADED_SIZE_MB } from "~/const";
import Loader from "~/components/Loader";

import "./style.less";

export interface IUploadItem {
	id: number;
	file: File;
	sizeCheck: boolean;
}

interface IState {
	isActive: boolean;
	lastId: number;
	items: Array<IUploadItem>;
	loading: boolean;
	message: string;
}

export default function UploadPage() {
	const { state, updateState, setState } = myUseState<IState>({
		isActive: false,
		lastId: 0,
		items: [],
		loading: false,
		message: "",
	});

	function getItemsMsg(items: Array<IUploadItem>) {
		if (!items.length) {
			return "";
		}

		const sizeB = items.reduce((acc, item) => acc + item.file.size, 0);

		return `Souborů <strong>${items.length}</strong>, velikost <strong>${formatSize(sizeB)}</strong>, max velikost souboru <strong>${MAX_UPLOADED_SIZE_MB}MB</strong>`;
	}

	function processFiles(files: Array<File>) {
		setState(prev => {
			const items: Array<IUploadItem> = [...prev.items];
			let lastId = prev.lastId;

			files.forEach(file => {
				if (
					(file.type.match("video") || file.type.match("image"))
					&& prev.items.filter(item => item.file.name === file.name).length === 0
				) {
					items.push({
						id: lastId,
						file,
						sizeCheck: file.size <= MAX_UPLOADED_SIZE,
					});
					lastId++;
				}
			});

			return {
				...prev,
				lastId,
				items,
				message: getItemsMsg(items),
			};
		});
	}

	function onChange(event: ChangeEvent<HTMLInputElement>) {
		processFiles(Array.from(event.target.files));
		event.target.value = null;
	}

	function onDragOver(event: DragEvent) {
		event.stopPropagation();
		event.preventDefault();
	}

	function onDrop(event: DragEvent) {
		event.stopPropagation();
		event.preventDefault();
		processFiles(Array.from(event.dataTransfer.files));
	}

	function deleteItem(id: number) {
		setState(prev => {
			const items = prev.items.filter(item => item.id !== id);

			return {
				...prev,
				items,
				message: getItemsMsg(items),
			};
		});
	}

	async function upload() {
		const newItems: Array<IUploadItem> = [];
		const uploadItems = state.items.filter(item => item.sizeCheck);
		const count = uploadItems.length;
		let done = 0;

		updateState({
			message: "Začíná upload",
			loading: true,
		});

		for (const item of uploadItems) {
			const uploadInfo = await uploadFile({
				file: item.file,
			});

			if (uploadInfo.error) {
				newItems.push(item);
			} else {
				done++;
			}

			updateState({
				message: `Bylo nahráno <strong>${done}/${count}</strong> souborů`,
			});
		}

		updateState({
			loading: false,
			message: `Upload byl dokončen`,
			items: newItems,
		});
	}

	return <PageContent className="uploadPage" loggedContent={true}>
		<HeaderTitle title="Nahrávání" />
		<label className={getClassName(["uploadPage__dad", state.isActive ? "active" : ""])} onDrop={onDrop} onDragOver={onDragOver} onDragEnter={() => updateState({ isActive: true })}>
			Otevřít / Přetáhnout soubory
			<input type="file" multiple={true} accept="video/*,image/*" onChange={onChange} />
		</label>
		{ state.items.length > 0 && <div className="uploadPage__uploadArea">
			<MyButton text="Nahrát" variant="filled" onClick={upload} />
		</div> }
		{ state.message && <p className="uploadPage__message" dangerouslySetInnerHTML={{ __html: state.message }} /> }
		<div className="uploadPage__items">
			{ state.items.map(item => <UploadItem key={item.id} id={item.id} file={item.file} sizeError={!item.sizeCheck} onDelete={() => deleteItem(item.id)} />) }
		</div>
		{ state.loading && <Loader className="uploadPage__loader" /> }
	</PageContent>;
}
