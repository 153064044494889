import dayjs from "dayjs";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { DAYJS_NICE_FORMAT_DATE } from "~/const";
import { getPersonGeneration } from "~/utils/utils";
import { IFullPersonItem } from "~/interfaces";

import "./style.less";

interface ISearchPersonTable {
	items: Array<IFullPersonItem>;
}

export default function SearchPersonTable({
	items,
}: ISearchPersonTable) {
	return <TableContainer component={Paper}>
		<Table sx={{ minWidth: 650 }} aria-label="simple table">
			<TableHead>
				<TableRow>
					<TableCell className="searchPersonTable__tableHead">Jméno a příjmení</TableCell>
					<TableCell className="searchPersonTable__tableHead center">Narozeniny</TableCell>
					<TableCell className="searchPersonTable__tableHead center">Věk</TableCell>
					<TableCell className="searchPersonTable__tableHead center">Generace</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{ items.map(item => <TableRow key={item.fullname} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} className="searchPersonTable__tableTr">
					<TableCell component="th" scope="row">
						{ `${item.fullname}${item.desc ? ` (${item.desc})` : ""}` }
					</TableCell>
					<TableCell className="searchResultsTable__tableTdCenter" scope="row">
						{ item.birthdate ? dayjs(item.birthdate).format(DAYJS_NICE_FORMAT_DATE) : "-" }
					</TableCell>
					<TableCell className="searchResultsTable__tableTdCenter" scope="row">
						{ item.years }
					</TableCell>
					<TableCell className="searchResultsTable__tableTdCenter" scope="row">
						{ getPersonGeneration(dayjs(item.birthdate).get("year")) }
					</TableCell>
				</TableRow>) }
			</TableBody>
		</Table>
	</TableContainer>;
}
