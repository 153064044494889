import { IEvent, IGallery, IGalleryItem, ILogin, ITrackDistances, ITrackItem, ITrackListItem } from "~/interfaces";
import { getDataPath } from "~/providers/php-api";
import { createEmptyDistances } from "./map";

interface IGalleryData {
	meta: {
		galleryTitles: string;
		prologItems: Array<IGalleryItem>;
		epilogItems: Array<IGalleryItem>;
		othersItems: Array<IGalleryItem>;
		datesObjDays: {[key: string]: Array<IGalleryItem>};
		customDays: Array<string>;
	};
	gallery: IGallery;
}

export function createGallery(event: IEvent, folder: string, isYear: boolean, type: ILogin["type"]): IGalleryData | null {
	if (!event.gallery) {
		return null;
	}

	// mame galerii
	const noneDateKey = "none";
	const items: Array<IGalleryItem> = [];
	const galleryTitles: Array<string> = [];
	const prologItems: IGalleryData["meta"]["prologItems"] = [];
	const datesObjDays: IGalleryData["meta"]["datesObjDays"] = {};
	const epilogItems: IGalleryData["meta"]["epilogItems"] = [];
	const customDays: IGalleryData["meta"]["customDays"] = [];
	let photosCount = 0;
	let videosCount = 0;

	event.gallery.items.forEach(galleryItem => {
		const updatedItem: IGalleryItem = {
			...galleryItem,
			src: getDataPath(galleryItem.isVideo ? "videa" : "fotky", `${folder}/${event.gallery.src}/${galleryItem.name}`, type),
			...galleryItem.isVideo
				? {
					preview: getDataPath("fotky", `${folder}/${event.gallery.src}/${galleryItem.name.replace(".mp4", "-cover.jpg")}`, type),
				}
				: {
					largeSrc: getDataPath("fotky", `${folder}/${event.gallery.src}/${galleryItem.name.replace(".", "-large.")}`, type),
				},
		};

		items.push(updatedItem);

		if (updatedItem.title) {
			galleryTitles.push(updatedItem.title);
		}

		// pouzijeme souradnice z rodice pro fotky
		if (!updatedItem.coords && event.coords && !updatedItem.isVideo) {
			updatedItem.coords = event.coords;
		}

		/*
		if (!galleryItem.date && !event.toDate) {
			galleryItem.date = event.date;
		}
		*/

		if (galleryItem.isVideo) {
			videosCount++;
		} else {
			photosCount++;
		}

		if (galleryItem.customDayName) {
			customDays.push(galleryItem.customDayName);
		}

		const dateKey = galleryItem.date || noneDateKey;
		const dateMatch = dateKey.match(/\d{4}-\d{2}-\d{2}/u);
		const onlyDate = galleryItem.customDayName
			? galleryItem.customDayName
			: dateMatch
				? dateMatch[0]
				: dateKey;
		// zdroj
		let source: Array<IGalleryItem> = [];

		if (updatedItem.dayType) {
			if (updatedItem.dayType === "prolog") {
				source = prologItems;
			} else if (updatedItem.dayType === "epilog") {
				source = epilogItems;
			}
		} else {
			if (!(onlyDate in datesObjDays)) {
				datesObjDays[onlyDate] = [];
			}

			source = datesObjDays[onlyDate];
		}

		source.push(updatedItem);
	});

	return {
		meta: {
			galleryTitles: galleryTitles.join(",").toLocaleLowerCase(),
			prologItems,
			epilogItems,
			datesObjDays,
			othersItems: noneDateKey in datesObjDays ? datesObjDays[noneDateKey] : [],
			customDays: [...new Set(customDays)],
		},
		gallery: {
			cover: getDataPath("fotky", `${folder}/${event.gallery.src}/${event.gallery.cover}`, type),
			dateType: event.gallery.dateType,
			type: event.gallery.type,
			items,
			photosCount,
			videosCount,
			event,
			galleryType: isYear ? "year" : "other",
			xCor: event.gallery.xCor,
		},
	};
}

export function calculateTrackDistances(track: ITrackItem) {
	// do all pocitame pouze pesky + auto?
	track.distances = createEmptyDistances();

	for (const geometry of track.geometries) {
		switch (geometry.type) {
			case "walk":
				track.distances.walk += geometry.distance;
				track.distances.all += geometry.distance;
				break;

			case "car":
				track.distances.car += geometry.distance;
				track.distances.all += geometry.distance;
				break;

			case "boat":
				track.distances.boat += geometry.distance;
				break;

			case "bike":
				track.distances.bike += geometry.distance;
				track.distances.all += geometry.distance;
				break;

			case "cable":
				track.distances.cable += geometry.distance;
				break;

			case "pubt":
				track.distances.pubt += geometry.distance;
				break;

			default:
		}
	}
}

export function mergeDistances(distancesSource: ITrackDistances, distancesDest: ITrackDistances) {
	distancesSource.walk += distancesDest.walk;
	distancesSource.car += distancesDest.car;
	distancesSource.boat += distancesDest.boat;
	distancesSource.cable += distancesDest.cable;
	distancesSource.pubt += distancesDest.pubt;
	distancesSource.bike += distancesDest.bike;
	distancesSource.all += distancesDest.all;
}

export function mergeDistancesWithTracks(distances: ITrackDistances, tracks: Array<ITrackItem>) {
	tracks.forEach(track => {
		mergeDistances(distances, track.distances);
	});
}

export function createTrackListItem(event: IEvent, gallery: IGallery, tracks: Array<ITrackItem>): ITrackListItem {
	const trackListItem: ITrackListItem = {
		tracks: tracks,
		event,
		gallery,
		distances: createEmptyDistances(),
	};

	mergeDistancesWithTracks(trackListItem.distances, tracks);

	return trackListItem;
}
