import { timeToSeconds } from "~/utils/utils";

export const TIMES = [{
	time: "0:10",
	text: "příchod",
}, {
	time: "0:12",
	text: "namotání",
}, {
	time: "0:16",
	text: "záklonová otočka",
}, {
	time: "0:20",
	text: "ZK proti soběe",
}, {
	time: "0:24",
	text: "otočka ruce nahoře",
}, {
	time: "0:32",
	text: "točení proti sobě vychýlení",
}, {
	time: "0:38",
	text: "podtočení pod rukou",
}, {
	time: "0:40",
	text: "ZK proti sobě po prostoru",
}, {
	time: "0:47",
	text: "otočení podrukou dovnitř",
}, {
	time: "0:49",
	text: "ZK + high five",
}, {
	time: "0:53",
	text: "ZK proti sobě po prostoru",
}, {
	time: "1:05",
	text: "ZK + podtočení",
}, {
	time: "1:08",
	text: "roztáhnutí noh do strany",
}, {
	time: "1:10",
	text: "podtočení",
}, {
	time: "1:12",
	text: "ZK proti sobě pásla ovečky",
}, {
	time: "1:17",
	text: "podtočení tam a zpátky",
}, {
	time: "1:20",
	text: "ZK proti sobě pásla ovečky",
}, {
	time: "1:25",
	text: "podtočení muž žena a zamotání",
}, {
	time: "1:30",
	text: "ZK houpání do strany zamotané ruce",
}, {
	time: "1:32",
	text: "odtočení vedle sebe",
}, {
	time: "1:34",
	text: "ZK od sebe k sobě high five",
}, {
	time: "1:38",
	text: "namotání",
}, {
	time: "1:39",
	text: "náklon split jerk",
}, {
	time: "1:44",
	text: "odmotání vedle sebe",
}, {
	time: "1:46",
	text: "namotání před sebe",
}, {
	time: "1:48",
	text: "ZK mazurka",
}, {
	time: "1:52",
	text: "otočení 180",
}, {
	time: "1:53",
	text: "ZK vedle sebe ruce za zády",
}, {
	time: "1:56",
	text: "otočení 180",
}, {
	time: "1:57",
	text: "ZK mazurka po prostoru",
}, {
	time: "2:06",
	text: "otočit dopředu",
}, {
	time: "2:07",
	text: "ZK proti sobě",
}, {
	time: "2:10",
	text: "výměny ruk",
}, {
	time: "2:16",
	text: "točení ruce na bocích",
}, {
	time: "2:18",
	text: "podtočení",
}, {
	time: "2:20",
	text: "ZK proti sobě",
}, {
	time: "2:25",
	text: "otočení podrukou dovnitř",
}, {
	time: "2:28",
	text: "ZK + high five",
}, {
	time: "2:32",
	text: "ZK proti sobě po prostoru",
}, {
	time: "2:44",
	text: "ZK + podtočení",
}, {
	time: "2:48",
	text: "roztáhnutí noh do stran",
}, {
	time: "2:50",
	text: "podtočení",
}, {
	time: "2:51",
	text: "ZK proti sobě pásla ovečky",
}, {
	time: "2:56",
	text: "podtočení tam a zpátky",
}, {
	time: "2:59",
	text: "ZK proti sobě pásla ovečky",
}, {
	time: "3:04",
	text: "podtočení muž žena a zamotání",
}, {
	time: "3:08",
	text: "ZK houpání do strany zamotané ruce",
}, {
	time: "3:11",
	text: "odtočení vedle sebe",
}, {
	time: "3:12",
	text: "ZK od sebe k sobě high five",
}, {
	time: "3:17",
	text: "namotání",
}, {
	time: "3:18",
	text: "náklon split jerk",
}, {
	time: "3:22",
	text: "odmotání vedle sebe",
}, {
	time: "3:24",
	text: "namotání proti sobě",
}, {
	time: "3:26",
	text: "točení proti sobě vychýlení",
}, {
	time: "3:31",
	text: "odmotání vedle sebe",
}, {
	time: "3:33",
	text: "namotání na sebe",
}, {
	time: "3:35",
	text: "ZK mazurka",
}, {
	time: "3:38",
	text: "otočení 180",
}, {
	time: "3:39",
	text: "ZK vedle sebe ruce za zády",
}, {
	time: "3:41",
	text: "otočení 180",
}, {
	time: "3:42",
	text: "ZK mazurka po prostoru",
}, {
	time: "3:52",
	text: "otočit dopředu",
}, {
	time: "3:53",
	text: "ZK proti sobě",
}, {
	time: "3:56",
	text: "výměna ruk",
}, {
	time: "4:02",
	text: "točení ruce na bocích",
}, {
	time: "4:05",
	text: "podtočení a pryč",
}, {
	time: "4:06",
	text: "namotání na sebe + zvedačka",
}, {
	time: "4:12",
	text: "dolů",
}, {
	time: "4:13",
	text: "zaklonění na místě",
}, {
	time: "4:18",
	text: "odtočení vedle sebe",
}, {
	time: "4:21",
	text: "k sobě závěr",
}].sort((aItem, bItem) => timeToSeconds(aItem.time) - timeToSeconds(bItem.time));
