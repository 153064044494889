import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import PageContent from "~/components/PageContent";
import { getClassName } from "~/utils/utils";
import { ROUTES } from "~/const";
import { myUseState } from "~/hooks/myUseState";
import { globalStore } from "~/stores/global";
import HeaderTitle from "~/components/HeaderTitle";
import { getGalleryBySeo } from "~/providers/events";
import Password from "~/components/Password";

import "./style.less";

interface IChildItem {
	name: string;
	value: string;
}

const PHOTO_DELAY = 5000;
const ANIM_DELAY = 1000;
const GALLERY_SEO = "ostatni-nahledovky";
const MARTINEK: Array<IChildItem> = [{
	name: "Narodil se",
	value: "11. 12. 2021, 10:30",
}, {
	name: "Měřil",
	value: "49 cm",
}, {
	name: "Vážil",
	value: "3540 g",
}];
const TOMASEK: Array<IChildItem> = [{
	name: "Narodil se",
	value: "28. 7. 2023, 17:20",
}, {
	name: "Měřil",
	value: "50 cm",
}, {
	name: "Vážil",
	value: "3270 g",
}];

interface IRefData {
	intId: number;
	toId: number;
}

interface IState {
	animation: string;
	oldPhotoSrc: string;
	familyImages: Array<string>;
	photoInd: number;
	photoSrc: string;
}

export default function MainPage() {
	const navigate = useNavigate();
	const refData = useRef<IRefData>({
		intId: null,
		toId: null,
	});
	const { global } = globalStore(globalState => ({
		global: globalState.global,
	}));

	function getRandomNumber(len: number) {
		return Math.floor(Math.random() * len);
	}

	const { state, setState, updateState } = myUseState<IState>({
		animation: "",
		photoInd: -1,
		familyImages: [],
		oldPhotoSrc: "",
		photoSrc: "",
	});

	function destroyInterval() {
		refData.current.intId && clearInterval(refData.current.intId);
	}

	function destroyTimeout() {
		refData.current.toId && clearTimeout(refData.current.toId);
	}

	function setNextPhoto(wasClick?: boolean) {
		wasClick && destroyInterval();
		destroyTimeout();
		setState(prev => {
			let photoInd = -1;

			/* eslint-disable  no-constant-condition */
			while (true) {
				photoInd = getRandomNumber(prev.familyImages.length);

				if (photoInd !== prev.photoInd) {
					break;
				}
			}

			return {
				...prev,
				photoInd,
				oldPhotoSrc: prev.familyImages[prev.photoInd],
				photoSrc: prev.familyImages[photoInd],
				animation: "switch-images",
			};
		});
		refData.current.toId = setTimeout(() => {
			refData.current.toId = null;
			updateState({
				animation: "",
			});

			// obnovime interval
			if (wasClick) {
				refData.current.intId = setInterval(() => {
					setNextPhoto();
				}, PHOTO_DELAY) as unknown as number;
			}
		}, ANIM_DELAY) as unknown as number;
	}

	function photoClick() {
		if (!global.loginData) {
			return;
		}

		updateState({
			animation: "",
		});
		setTimeout(() => {
			setNextPhoto(true);
		}, 0);
	}

	function onLogin() {
		navigate(ROUTES.ROOT);
	}

	useEffect(() => {
		const gallery = getGalleryBySeo(GALLERY_SEO);

		if (!global.loginData || !gallery) {
			return () => {};
		}

		const familyImages: Array<string> = gallery.items.map(item => item.largeSrc);
		const photoInd = getRandomNumber(familyImages.length);

		updateState({
			familyImages,
			photoInd,
			oldPhotoSrc: familyImages[photoInd],
			photoSrc: familyImages[photoInd],
		});

		refData.current.intId = setInterval(() => {
			setNextPhoto();
		}, PHOTO_DELAY) as unknown as number;

		return () => {
			destroyInterval();
			destroyTimeout();
		};
	}, [global.loginData]);

	return <PageContent className="mainPage">
		<HeaderTitle title="Makuderovi" />
		{ global.loginData && <>
			{ state.photoSrc && <div className={getClassName(["mainPage__circlePhotoCont", state.animation])}>
				<div className="mainPage__circlePhotoContInt">
					<img className="mainPage__circlePhoto old" src={state.oldPhotoSrc} alt="Rodinné foto" />
					<img className="mainPage__circlePhoto" src={state.photoSrc} alt="Rodinné foto" onClick={photoClick} style={{ cursor: global.loginData ? "pointer" : "default" }} />
				</div>
			</div> }
			<div className="mainPage__children">
				<div className="mainPage__childCont">
					<h2 className="mainPage__title">Martínek</h2>
					<div className="mainPage__child">
						{ MARTINEK.map(martinekItem => <div className="mainPage__childItem" key={martinekItem.name}>
							<span className="mainPage__childItemName">
								{ martinekItem.name}:
							</span>
							<span className="mainPage__childItemValue">
								{martinekItem.value}
							</span>
						</div>) }
					</div>
				</div>
				<div className="mainPage__childCont">
					<h2 className="mainPage__title">Tomášek</h2>
					<div className="mainPage__child">
						{ TOMASEK.map(tomasekItem => <div className="mainPage__childItem" key={tomasekItem.name}>
							<span className="mainPage__childItemName">
								{ tomasekItem.name}:
							</span>
							<span className="mainPage__childItemValue">
								{tomasekItem.value}
							</span>
						</div>) }
					</div>
				</div>
			</div>
		</> }
		{ (!global.loginData && !global.logAuthLoading) && <>
			<p>Pro zobrazení obsahu se přihlašte.</p>
			<Password onLogin={onLogin} />
		</> }
	</PageContent>;
}
