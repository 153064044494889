import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

import { ALL_OVERVIEW_DATA, PERMS, ROMAN_PERMS } from "~/const";
import YearItems from "~/components/YearItems";
import PageContent from "~/components/PageContent";
import { compareGalleryVisits, getAllGalleries, getAllTags, getDataByYear, getFolderTitle, getGalleryFolders } from "~/providers/events";
import { globalStore } from "~/stores/global";
import { useScrollToTop } from "~/hooks/useScrollToTop";
import Scroller from "~/components/Scroller";
import { myUseState } from "~/hooks/myUseState";
import Tags from "~/components/Tags";
import { IGallery, IGalleryItem, TTag } from "~/interfaces";
import MyButton from "~/my/MyButton";
import OverviewSwitcher from "~/components/OverviewSwitcher";
import { savedStore } from "~/stores/saved";
import VideoPlaylist from "~/components/VideoPlaylist";

import "./style.less";

type TFilterType = "none" | "tracks" | "public" | "days" | "months" | "makuderoviRodina" | "makuderoviDeti" | "romanKamosi" | "romanSeznam" | "romanRodina";

interface IState {
	selectedTags: Array<TTag>;
	showTags: boolean;
	newest: boolean;
	videos: Array<IGalleryItem>;
	filter: TFilterType;
}

const DEF_STATE: IState = {
	selectedTags: [],
	showTags: false,
	newest: false,
	videos: [],
	filter: "none",
};

interface IParams {
	year: string;
	[key: string]: string;
}

export default function OverviewPage() {
	const showScroller = useScrollToTop();
	const { year } = useParams<IParams>();
	const { global } = globalStore(globalState => ({
		global: globalState.global,
	}));
	const { savedData, allGalleryVisited } = savedStore(visitState => ({
		savedData: visitState.saved,
		allGalleryVisited: visitState.allGalleryVisited,
	}));
	const isAll = year === ALL_OVERVIEW_DATA.URL;
	const yearItems = isAll
		? getAllGalleries()
		: getDataByYear(year);
	const allTags = getAllTags();
	const { state, updateState, setState, toggleState } = myUseState<IState>(DEF_STATE);
	const news = !compareGalleryVisits(savedData.service === "roman" ? savedData.romanVisit : savedData.makuderoviVisit);

	function toggleTag(tag: TTag) {
		setState(prev => {
			const selectedTags = prev.selectedTags.slice();
			const ind = selectedTags.indexOf(tag);

			if (ind === -1) {
				selectedTags.push(tag);
			} else {
				selectedTags.splice(ind, 1);
			}

			return {
				...prev,
				selectedTags,
			};
		});
	}

	function getItemsData() {
		let items: Array<IGallery> = [];
		let videos: Array<IGalleryItem> = [];
		const tagsFilter = isAll && state.selectedTags.length > 0;

		if (tagsFilter || state.filter !== "none") {
			items = yearItems.filter(yearItem => {
				let selectItem = false;

				if (tagsFilter) {
					const yearItemTags = yearItem.event.tags || [];

					for (const tag of yearItemTags) {
						if (state.selectedTags.includes(tag)) {
							selectItem = true;
							break;
						}
					}
				}

				if (!selectItem) {
					switch (state.filter) {
						case "tracks":
							selectItem = yearItem.event.allTracks.length > 0;
							break;

						case "public":
							selectItem = yearItem.event.public;
							break;

						case "days":
							selectItem = !!yearItem.event.days;
							break;

						case "months":
							selectItem = "monthData" in yearItem.event;
							break;

						case "makuderoviRodina":
							selectItem = yearItem.event.groups?.includes(PERMS.RODINA);
							break;

						case "makuderoviDeti":
							selectItem = yearItem.event.groups?.includes(PERMS.DETI);
							break;

						case "romanKamosi":
							selectItem = yearItem.event.groups?.includes(ROMAN_PERMS.KAMOSI);
							break;

						case "romanSeznam":
							selectItem = yearItem.event.groups?.includes(ROMAN_PERMS.SEZNAM);
							break;

						case "romanRodina":
							selectItem = yearItem.event.groups?.includes(ROMAN_PERMS.RODINA);
							break;

						default:
					}
				}

				return selectItem;
			});
		} else {
			items = yearItems.slice();
		}

		if (state.newest) {
			items = items.reverse();
		}

		for (const gallery of items) {
			const filtered = gallery.items.filter(item => item.isVideo);

			if (filtered.length) {
				videos = videos.concat(filtered);
			}
		}

		return {
			items,
			videos,
		};
	}

	function toggleTagOpen() {
		const showTags = !state.showTags;

		updateState({
			showTags,
			...showTags
				? {}
				: {
					selectedTags: [],
				},
		});
	}

	const itemsData = getItemsData();

	function openVideos() {
		updateState({
			videos: itemsData.videos,
		});
	}

	function toggleStateFilter(name: TFilterType) {
		updateState({
			filter: state.filter === name ? "none" : name,
		});
	}

	const afterHeaderPlaceholder = isAll && state.showTags
		? <Tags items={allTags} selected={state.selectedTags} onClick={toggleTag} />
		: null;
	const yearItemBtns = [
		...itemsData.videos.length > 0
			? [<MyButton text={<PlayCircleIcon />} onClick={openVideos} className="yearGallery__playBtn" variant="filled" key="key07" />]
			: [],
		...news
			? [<MyButton text="Vše shlédnuto" onClick={allGalleryVisited} variant="filled" key="key01" />]
			: [],
		...global.loginData?.isAdmin
			? [
				...global.service === "makuderovi"
					? [
						<MyButton text="Děti" onClick={() => toggleStateFilter("makuderoviDeti")} active={state.filter === "makuderoviDeti"} variant="filled" key="key07" />,
						<MyButton text="Rodina" onClick={() => toggleStateFilter("makuderoviRodina")} active={state.filter === "makuderoviRodina"} variant="filled" key="key11" />,
					]
					: [],
				...global.service === "roman"
					? [
						<MyButton text="Rodina" onClick={() => toggleStateFilter("romanRodina")} active={state.filter === "romanRodina"} variant="filled" key="key07" />,
						<MyButton text="Seznam" onClick={() => toggleStateFilter("romanSeznam")} active={state.filter === "romanSeznam"} variant="filled" key="key12" />,
						<MyButton text="Kámoši" onClick={() => toggleStateFilter("romanKamosi")} active={state.filter === "romanKamosi"} variant="filled" key="key11" />,
					]
					: [],
				<MyButton text="Veřejné" onClick={() => toggleStateFilter("public")} active={state.filter === "public"} variant="filled" key="key10" />,
			]
			: [],
		<MyButton text="Měsíce" onClick={() => toggleStateFilter("months")} active={state.filter === "months"} variant="filled" key="key09" />,
		<MyButton text="Přehled dnů" onClick={() => toggleStateFilter("days")} active={state.filter === "days"} variant="filled" key="key08" />,
		<MyButton text="Stopy" onClick={() => toggleStateFilter("tracks")} active={state.filter === "tracks"} variant="filled" key="key06" />,
		...isAll
			? [<MyButton text={state.showTags ? "Zavřít tagy" : "Tagy"} onClick={toggleTagOpen} variant="filled" key="key02" />]
			: [],
		<MyButton text="Nejnovější" onClick={() => toggleState("newest")} active={state.newest} variant="filled" key="key05" />,
	];

	function getTitle() {
		if (isAll) {
			return "Přehled všech let";
		}

		if (year.match(/\d{4}/u)) {
			return `Přehled roku ${year}`;
		}

		return getFolderTitle(year);
	}

	function onPageLoad() {
		const curYear = dayjs().get("year").toString();

		updateState({
			...DEF_STATE,
			newest: curYear === year,
		});
	}

	function getId() {
		const parts: Array<string> = [];

		parts.push(year);
		parts.push(`newest${state.newest ? 1 : 0}`);
		parts.push(`filter${state.filter}`);
		parts.push(state.selectedTags.join("|"));

		return parts.join("_");
	}

	return <PageContent className="overview" loggedContent={true} showMartinek={true} onLoad={onPageLoad} dependencies={[year]}>
		<div className="overview__contents">
			<OverviewSwitcher items={getGalleryFolders(year, true)} className="overview__yearsSwitcher" />
		</div>
		<YearItems title={getTitle()} items={itemsData.items} afterHeaderPlaceholder={afterHeaderPlaceholder} galleryBtns={yearItemBtns} showYear={isAll}
			showInfo={global.loginData?.isAdmin} service={global.service} key={getId()} folder={year} />
		<div className="overview__aboveScroller"></div>
		<Scroller visible={showScroller} />
		{ state.videos.length > 0 && <VideoPlaylist items={state.videos} onClose={() => updateState({ videos: [] })} /> }
	</PageContent>;
}
