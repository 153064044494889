/* eslint-disable max-classes-per-file */
export class HTTPError extends Error {
	status: number;

	message: string;

	constructor(status: number, message: string) {
		super(message);
		this.message = message;
		this.name = 'HTTPError';
		this.status = status;
	}
}

export class FastRPCError extends Error {
	status: number;

	message: string;

	// FIXME: lepsi typ?
	data: object | undefined;

	constructor(status: number, message: string, data?: object) {
		super(message);
		this.status = status;
		this.name = 'FastRPCError';
		this.message = message;
		if (data) {
			this.data = data;
		}
	}
}

export class TimeoutError extends Error {
	message: string;

	constructor() {
		const message = 'Request timed out';

		super(message);
		this.name = 'TimeoutError';
		this.message = message;
	}
}

export class AbortError extends Error {
	message: string;

	constructor() {
		const message = 'Request aborted';

		super(message);
		this.name = 'AbortError';
		this.message = message;
	}
}
