export function setItem(name: string, value: string): void {
	try {
		localStorage.setItem(name, value);
	} catch (exc) {
		/* eslint-disable no-console */
		console.log(exc);
	}
}

export function getItem(name: string): string {
	try {
		return localStorage.getItem(name) || "";
	} catch (exc) {
		return "";
	}
}

export function removeItem(name: string): void {
	try {
		localStorage.removeItem(name);
	} catch (exc) {
		/* eslint-disable no-console */
		console.log(exc);
	}
}

export function getJSONItem<T>(name: string): (T | null) {
	try {
		const strData = getItem(name);

		if (!strData) {
			return null;
		}

		const json = JSON.parse(strData);

		return json;
	} catch (exc) {
		/* eslint-disable no-console */
		console.log(exc);

		return null;
	}
}
