import { Fragment, ReactNode } from "react";

import { getClassName } from "~/utils/utils";
import MyButton from "~/my/MyButton";

import "./style.less";

interface IGalleryHeader {
	title: string;
	backTitle?: string;
	backChildren?: ReactNode;
	onBack?: () => void;
	first?: boolean;
	buttons?: Array<ReactNode>;
}

export default function GalleryHeader({
	first,
	title = "",
	backTitle = "Galerie",
	backChildren = null,
	onBack = null,
	buttons = [],
}: IGalleryHeader) {
	const allButtons = [
		...buttons,
		...onBack
			? [<MyButton text={backTitle} onClick={onBack} variant="filled" key="lastkey" />]
			: [],
	];
	const noContent = allButtons.length === 0 && backChildren === null;

	function getCount() {
		const len = allButtons.length;
		const back = backChildren === null
			? 0
			: 1;

		return (len + back).toString();
	}

	/* eslint-disable react/no-array-index-key */
	return <div className={getClassName(["galleryHeader", first ? "first" : "", noContent ? "bottom-border" : ""])}>
		<div className="galleryHeader__textPart">
			{ title }
		</div>
		{ (allButtons.length > 0 || backChildren) && <div className="galleryHeader__btns" data-count={getCount()}>
			{ allButtons.map((elem, ind) => <Fragment key={ind}>
				{ elem }
			</Fragment>) }
			{ backChildren }
		</div> }
	</div>;
}
