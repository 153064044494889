import { IStoryEvent } from "~/providers/story";

export const STORIES: Array<IStoryEvent> = [{
	items: [{
		text: "Všechno nejlepší ke svátku! Připrav se na to, že v 10:00 vyrážíme na cestu.",
	}, {
		text: "Těšíš se?<br />Nejdříve se vydáme někam, kam bys sám nemohl.",
	}, {
		text: "Užij si brunch.<br />Potom asi uvidíš nějaký ten obrázek.",
	}, {
		text: "Líbila se ti výstava?<br />Teď budeme pokračovat dál a možná potkáme i Karla.",
	}, {
		text: "Na zdraví!<br />Za chvíli tě čeká první z vrcholů tohoto výletu.",
	}, {
		text: "To je výhled, co?<br />A teď by to za odměnu chtělo něco dobrého.",
	}, {
		text: "Dobrou chuť!<br />Po jídle budou následovat ještě nějaké bublinky.",
	}, {
		text: "Ano, jdeme do výrivky.<br />Tak ještě jednou všechno nejlepší!",
	}],
	seo: "2020-roman-svatek",
	title: "2020 Roman svátek",
	message: "Miluju tě",
	desc: [],
	date: "2020-08-09",
}, {
	items: [{
		text: "Všechno nejlepší k narozeninám!<br />Dobrou chuť a ještě chvíli přemýšlej, co tě čeká",
	}, {
		text: "Připrav se, v 15:05 odcházíme.<br />Do 14:30 si nachystej věci na 4 dny a odevzdej je ke sbalení.<br /><i>*Dres code: smart casual*</i>",
	}, {
		text: "Připraven?<br />Těšíš se?<br /> Na začátek se trošku rozehřejeme.",
	}, {
		text: "Minule jsme vířivkou skončili, tak dneska začneme. A po vyzhářání to bude chtít něco dobrého.",
	}, {
		text: "Dobrou chuť! Mýval doporučuje kravatkové risotto. A pak se přesuneme zase trošku dál.",
	}, {
		text: "Vítej na palubě žlutého vlaku! Dej si pitíčko, něco dobrého a přemýšlej, kam jedeme.",
	}, {
		text: "Tak jsme tady. Jdi dneska brzo spát a dobře se vyspi, zítra nás toho čeká hodně.",
	}, {
		text: "Trošku brzo na vstávání? Však tě mýval upozornil, že bude dneska dlouhý den.",
	}, {
		text: "Překvapení, letíme letadlem! Schválně, jestli víš, kam.",
	}, {
		text: "Vítej v Římě. Vidíš, že ještě letos ochutnáš pravou italskou pizzu a gelato.",
	}, {
		text: "Tady začínáme naše putování Římem. Nejdříve nás čeká pár kostelů a Konstantinův oblouk.",
	}, {
		text: "Teď se podíváme na nejznámější starověké památky - Koloseum, Forum Romanum a Palatinum.",
	}, {
		text: "Určitě už jsi po dnešku utahaný. Tak si odpočiň a těš se na zítra, kdy se přesuneme do dalšího státu.",
	}, {
		text: "Program na dnešní den je hlavně Vatikán.<br />Tak pozor, ať nejsi omylem zvolený papežem.",
	}, {
		text: "Kdybys četl Andělé a démony, věděl bys, že je Andělský hrad sídlo iluminátů. Takto se jím aspoň můžeš pokochat.",
	}, {
		text: "Dost bylo památek, dneska už nás čeká jenom nějaká dobrá večeře v této hezké čtvrti.",
	}, {
		text: "Dneska nás čeká procházka po Římě.<br />Začínáme na Kapitolském náměstí.",
	}, {
		text: "Už ses dneska dost nachodil po městě? Tak si na chvilku odpočiň v parku.",
	}, {
		text: "Ještě nám pár římských památek zbývá. Třeba tyto Španělské schody nebo známá fontána di Trevi.",
	}, {
		text: "Dobré ráno! Za chvíli nám letí letadlo, tak chop, chop!",
	}, {
		text: "Myslíš si, že už je konec dovolené? Ale kdepak.",
	}, {
		text: "Ještě si musíme projít Vídeň. A tady v Schonnbrunu možná potkáme i kamarády.",
	}, {
		text: "Teďka nás čeká procházka po hlavních památkách. A cestou třeba i nějaký ten sacher.",
	}, {
		text: "Další krásné zahrady nás čekají v Belvedéru.<br />A to bude z Vídně už všechno.",
	}, {
		text: "Ani teď ještě nekončíme...",
	}, {
		text: "Miluju tě!<br />Ještě jednou všechno nejlepší!",
	}],
	seo: "2020-roman-narozeniny",
	title: "2020 Roman narozeniny",
	message: "Všechno nejlepší k narozeninám",
	reverse: true,
	desc: [],
	date: "2020-09-10",
}, {
	items: [{
		text: "Všechno nejlepší k valentýnu!",
		emoji: "myval7",
	}, {
		text: "Už spolu zažíváme našeho 4. valentýna",
		emoji: "myval",
	}, {
		text: "A když jsem nad tím přemýšlel...",
		emoji: "krivonozka",
	}, {
		text: "tak jsem hrozně rád...",
		emoji: "gaga",
	}, {
		text: "že jsem potkal nejůžasnější...",
		emoji: "skupinka",
	}, {
		text: "ženu a manželku na světě.",
		emoji: "kocicka",
	}, {
		text: "Miluju tě moje...",
		emoji: "sova",
	}, {
		text: "nejroztomilejší kuřátko!",
		emoji: "sova5",
	}],
	seo: "2024-lenka-valentyn",
	title: "2024 Lenka valentýn",
	message: "Miluju tě",
	desc: [],
	date: "2024-02-14",
}, {
	items: [{
		text: "Všechno nejlepší k svátku! Užij si snídani a během dne ti třeba nějaké stopy napoví, co se bude dít dál.",
	}, {
		text: "Tentokrát není mýval jediné zvířátko, co se zapojilo. Vydej se po stopách...",
		emoji: "kroky",
	}, {
		text: "Ale ale, co to tady je? Někdo je tu oblhůt a chce vydůňat všechny jeleny.",
	}, {
		text: "Tak abys je měl z čeho důňat...",
	}, {
		text: "Tak už víš, jaké další zvířátko mývalovi dneska pomáhá. A to ještě není všechno. Užij si dezert a brzy se dozvíš, co dalšího tě ještě čeká.",
		emoji: "jelen",
	}, {
		text: "Jak už jsme se dozvěděli dříve, tentokrát spolupracovalo s mývalem více zvířátek. Jakých? No to se dozvíš v neděli. Zatím ti musí stačit informace, že v neděli v 11:00 vyrážíme na cestu.",
		emoji: "lama",
	}, {
		text: "Tak už se to blíží. Jsi zvědavý? Třeba cestou přijdeš na to, kam jedeme.",
	}, {
		text: "Jak vidíš, jsme na statku Samsara. Kromě zvířátek, na která se tu můžeme podívat, nás tu čeká all you can eat obědový raut ve španělském stylu, takže můžeš zavzpomínat na Barcelonu, dát si rýži s krevetami nebo churos.",
	}],
	seo: "2024-roman-svatek",
	title: "2024 Roman svátek",
	message: "Miluju tě",
	desc: [],
	date: "2024-08-09",
}, {
	items: [{
		text: "Chybí Ě",
	}, {
		text: "Doufám, že ti snídaně chutnala. Dneska tě čeká ještě několik překvapení. Jedno z nich je daleká cesta, tak se sbal na 4 dny, pojedeme autem, vyrážíme kolem 16 hodin.",
	}, {
		text: "K dnešnímu obědu mýval přichystal:<br />Čínská polévka<br />Kuřecí křídla s hranolky a salátem coleslaw<br />Dobrou chuť!",
	}, {
		text: "Doufám, že ti oběd chutnal. Teď si můžeš na chvíli odpočinout a čekat, co bude dál.",
	}, {
		text: "Tady ti mýval přichystal dort a pár dárků. Ale pozor, některé jsou mládeži nepřístupné...",
	}, {
		text: "Vyrážíme na cestu. Kam to asi bude?",
	}, {
		text: "Jsme tady. V Kutné Hoře, jak už jsi poznal. A ve velmi specifickém hotelu, takže kromě procházek po KH se můžeme podívat i do muzea Lega.",
	}, {
		text: "Děti spí a mýval ti nachystal na večer ještě něco dobrého. A třeba pak můžeš vyzkoušet narozneninový dárek.",
	}],
	seo: "2024-roman-narozeniny",
	title: "2024 Roman narozeniny",
	message: "Miluju tě",
	reverse: true,
	desc: [],
	date: "2024-09-10",
}];
