/* eslint-disable no-magic-numbers */
import dayjs from "dayjs";

import { IGetSallaryOutput, ISalary, IYearsSalaryItem } from "~/interfaces";
import { PREMIUM_SALARY, SALARY_DISCOUNTS, SALARY_MODIFICATORS, SEZNAM, SEZNAM_BONUS } from "~/data/salary";
import { formatNumberToThousands } from "./utils";

function getSeznamDiscount(discountObj: { [key: string]: number }) {
	return Object.keys(discountObj).reduce((prevValue, item) => prevValue + discountObj[item], 0);
}

export function getSallaryAllTaxPerc() {
	return SALARY_MODIFICATORS.health[0]
		+ SALARY_MODIFICATORS.ill[0]
		+ SALARY_MODIFICATORS.social[0]
		+ SALARY_MODIFICATORS.tax[0][0];
}

function getPercentage(value: number, percent: Array<number>) {
	return value * percent[0] * 0.01 >>> 0;
}

function getChildrenDiscount(children: number) {
	const maxInd = SALARY_DISCOUNTS.children.length - 1;
	let discount = 0;
	let ptr = 0;

	for (let ind = 0; ind < children; ind++) {
		discount += SALARY_DISCOUNTS.children[ptr];
		ptr++;

		if (ptr > maxInd) {
			ptr = maxInd;
		}
	}

	return discount;
}

function getEmergency(salary: number, hours: number) {
	return salary / SEZNAM.emergencySalaryRatio * hours * SEZNAM.emergencySalary >>> 0;
}

function getTax(salary: number) {
	const premium = salary > PREMIUM_SALARY
		? salary - PREMIUM_SALARY
		: 0;
	const restSalary = salary - premium;
	const premiumTax = getPercentage(premium, [SALARY_MODIFICATORS.tax[0][1]]);
	const salaryTax = getPercentage(restSalary, [SALARY_MODIFICATORS.tax[0][0]]);

	return premiumTax + salaryTax;
}

function getTaxPayerDiscount(year: number) {
	const filtered = SALARY_DISCOUNTS.taxPayer.filter(item => year >= item.fromYear && year <= item.toYear);
	const selItem = filtered.length === 1
		? filtered[0]
		: SALARY_DISCOUNTS.taxPayer[SALARY_DISCOUNTS.taxPayer.length - 1];

	return selItem.price;
}

interface IGetSallary {
	salary: number;
	bonus?: number;
	nettoBonus?: number;
	children?: number;
	taxPayer?: boolean;
	debug?: boolean;
	year?: number;
}

export function getSalary({
	salary,
	bonus = 0,
	nettoBonus = 0,
	taxPayer = true,
	children = 0,
	debug = false,
	year = dayjs().get("year"),
}: IGetSallary): IGetSallaryOutput {
	const salaryWithBonus = salary + bonus;
	const health = getPercentage(salaryWithBonus, SALARY_MODIFICATORS.health);
	// az od letoska
	const ill = year >= 2024
		? getPercentage(salaryWithBonus, SALARY_MODIFICATORS.ill)
		: 0;
	const social = getPercentage(salaryWithBonus, SALARY_MODIFICATORS.social);
	const tax = getTax(salaryWithBonus);
	const allTaxes = health + ill + social + tax;
	const beforeDiscount = salaryWithBonus - allTaxes;
	const childrenDiscount = getChildrenDiscount(children);
	const taxPayerDiscount = taxPayer ? getTaxPayerDiscount(year) : 0;
	const netto = beforeDiscount + taxPayerDiscount + childrenDiscount + nettoBonus;

	return {
		...debug
			? {
				data: {
					salary,
					health,
					ill,
					social,
					tax,
					allTaxes,
					beforeDiscount,
					childrenDiscount,
					taxPayerDiscount,
					bonus,
					nettoBonus,
				},
			}
			: {},
		salary: salaryWithBonus,
		netto,
	};
}

interface IGetSallarySeznam extends IGetSallary {
	emergencyHours?: number;
	incPercent?: number;
}

export function getSeznamSalary({
	salary,
	children = 0,
	emergencyHours = 0,
	nettoBonus = 0,
	incPercent = 20,
	debug = false,
}: IGetSallarySeznam) {
	const emergency = getEmergency(salary, emergencyHours);
	const premiumSalary = SEZNAM.premiumRatio * salary;
	const seznamConfig = {
		salary,
		bonus: emergency,
		children,
		nettoBonus,
		debug,
	};
	const normal = getSalary({
		...seznamConfig,
	});
	const premium = getSalary({
		...seznamConfig,
		bonus: emergency + premiumSalary,
	});
	const wholeYearSalary = (8 * normal.salary) + (4 * premium.salary);
	const wholeYearNetto = (8 * normal.netto) + (4 * premium.netto);
	const avgSalary = wholeYearSalary / 12 >>> 0;
	const avgNetto = wholeYearNetto / 12 >>> 0;
	const percentRatio = (100 + incPercent) / 100;

	return {
		...debug
			? {
				data: {
					normal,
					premium,
				},
			}
			: {},
		salary,
		children,
		emergencyHours,
		nettoBonus,
		wholeYearSalary,
		wholeYearNetto,
		avgSalary,
		avgNetto,
		incPercent,
		avgSalaryIncPercent: avgSalary * percentRatio >>> 0,
		avgNettoIncPercent: avgNetto * percentRatio >>> 0,
	};
}

function getCompareValue(value: number): string {
	if (value > 0) {
		return `+${value}`;
	} else if (value === 0) {
		return `+0`;
	}

	return value.toString();
}

function getPercentDiff(newValue: number, oldValue: number) {
	const diff = ((newValue / oldValue) - 1) * 100 >>> 0;

	return `${getCompareValue(diff)}%`;
}

function getCompareLine(newValue: number, oldValue: number) {
	const salary = getCompareValue(newValue - oldValue);
	const percent = getPercentDiff(newValue, oldValue);

	return `${salary}Kč (${percent}) - ${newValue}Kč / ${oldValue}Kč`;
}

interface IGetTestSalariesOutput {
	[key: string]: {
		netto: string;
		salary: string;
	};
}

function getTestSalaries(compareSalary: ReturnType<typeof getSeznamSalary>, salaries: Array<number>): IGetTestSalariesOutput {
	const output = {};

	for (const salary of salaries) {
		const name = `salary${salary / 1000 >>> 0}k`;
		const data = getSalary({
			salary,
			children: 2,
		});

		output[name] = {
			netto: getCompareLine(data.netto, compareSalary.avgNetto),
			salary: getCompareLine(data.salary, compareSalary.avgSalary),
		};
	}

	return output;
}

export function getSallaryJSON({
	romanSalary,
	lenkaSalary,
	children = 2,
	salaries = [90000, 100000, 110000, 120000, 130000, 140000, 150000],
}: {
	romanSalary: number;
	lenkaSalary: number;
	children?: number;
	salaries?: Array<number>;
}) {
	const emergencyHours = (2 * 8) + (2 * 24) + (4 * 16);
	const nettoBonusRoman = getSeznamDiscount({
		byod: SEZNAM_BONUS.byod,
		food: SEZNAM_BONUS.food,
	});
	const nettoBonusLenka = getSeznamDiscount({
		food: SEZNAM_BONUS.food,
	});
	const roman = getSeznamSalary({
		salary: romanSalary,
		children,
		emergencyHours,
		nettoBonus: nettoBonusRoman,
	});

	return {
		info: {
			salary: "hrubá mzda",
			children: "počet dětí",
			emergencyHours: "počet hodin pohotovosti za měsíc",
			nettoBonus: "počet čistého bonusu za měsíc (stravenky, byod...)",
			wholeYearSalary: "hrubá mzda za rok",
			wholeYearNetto: "čistá mzda za rok",
			avgSalary: "průměrná hrubá mzda za měsíc",
			avgNetto: "průměrná čistá mzda za měsíc",
		},
		makuderovi: {
			seznam: {
				roman,
				lenka: getSeznamSalary({
					salary: lenkaSalary,
					children: 0,
					nettoBonus: nettoBonusLenka,
				}),
			},
			pohovor: getTestSalaries(roman, salaries),
		},
	};
}

function getRowSallary(itemData: IYearsSalaryItem) {
	const salary = itemData.base;
	const emergency = itemData.emergency > 0
		? itemData.emergency
		: 0;
	const premium = itemData.premium > 0
		? itemData.premium
		: 0;
	const byod = itemData.byod > 0
		? itemData.byod
		: 0;
	const nettoBonus = byod + (byod > 0 ? SEZNAM_BONUS.food : 0);
	const calcSalary = getSalary({
		salary,
		bonus: emergency + premium,
		children: itemData.children || 0,
		nettoBonus,
		year: parseFloat(itemData.year),
		taxPayer: true,
		debug: true,
	});

	return calcSalary;
}

export function updateSalaryDataByQuarters({
	dataSalary,
	useQuarters = false,
	reverseItems = false,
}: {
	dataSalary: ISalary;
	useQuarters?: boolean;
	reverseItems?: boolean;
}) {
	const rows: Array<IYearsSalaryItem> = [];
	let lastQuart: IYearsSalaryItem = null;

	Object.keys(dataSalary).forEach(year => Object.keys(dataSalary[year]).forEach((month, monthInd) => {
		const rowData: IYearsSalaryItem = {
			year,
			month,
			calcSalary: null,
			...dataSalary[year][month],
		};

		rowData.calcSalary = getRowSallary(rowData);
		rows.push(rowData);

		if (useQuarters && ["3", "6", "9", "12"].indexOf(month) !== -1) {
			const lastThree = rows.slice(rows.length - 3);
			const quart = ((monthInd + 1) / 4 >>> 0) + 1;
			const avgSalary = lastThree.reduce((acc, prevItem) => acc + prevItem.calcSalary.salary, 0) / 3 >>> 0;
			const avgSalaryNetto = lastThree.reduce((acc, prevItem) => acc + prevItem.calcSalary.netto, 0) / 3 >>> 0;
			const newQuart: IYearsSalaryItem = {
				base: 0,
				calcSalary: {
					salary: avgSalary,
					netto: avgSalaryNetto,
				},
				month: `Q${quart}`,
				year,
				isQuart: true,
			};

			if (lastQuart) {
				const salary = formatNumberToThousands(newQuart.calcSalary.salary - lastQuart.calcSalary.salary, false);
				const netto = formatNumberToThousands(newQuart.calcSalary.netto - lastQuart.calcSalary.netto, false);

				newQuart.desc = `${salary} / ${netto}`;
			}

			lastQuart = newQuart;
			rows.push(newQuart);
		}
	}));

	reverseItems && rows.reverse();

	return rows;
}
