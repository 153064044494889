import Cookies from "js-cookie";

import { COOKIE_EXPIRES, JWT_COOKIE } from "~/const";
import { ILogin } from "~/interfaces";
import { loadEvents } from "./events";
import { globalStore } from "~/stores/global";
import { savedStore } from "~/stores/saved";
import { loadCalendar } from "./google-calendar";
import { getInfoLine } from "~/utils/utils";
import { loadPersons } from "./persons";

function afterLogin() {
	const storeState = globalStore.getState();
	const infoLine = getInfoLine(storeState.global.messengerNews);

	storeState.setInfoLine(infoLine);
}

export async function setUserData(data?: ILogin) {
	const storeState = globalStore.getState();

	if (data) {
		// nastavime cookies
		Cookies.set(JWT_COOKIE, data.jwt, {
			expires: COOKIE_EXPIRES,
			sameSite: "Strict",
		});
		// zalogujeme
		const savedState = savedStore.getState();

		// musi prvni
		await loadEvents(savedState.saved.service, data);
		// muze paralelne
		await Promise.all([
			loadCalendar(data.isAdmin),
			loadPersons(data.isAdmin),
		]);
		// nastaveni storu
		storeState.setLogin(data);

		if (savedState.saved.service === "roman") {
			/* eslint-disable-next-line */
			console.log(`Skip log visit for roman`);
		} else {
			savedState.logVisit(data.permissions, data.isAdmin);
		}

		// operace pote
		if (data.isAdmin) {
			afterLogin();
		}
	} else {
		storeState.setLogin(null);
		Cookies.remove(JWT_COOKIE);
	}
}

export function getJWTFromCookies() {
	return Cookies.get(JWT_COOKIE);
}
