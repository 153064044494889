import { IStoryTellerItem } from "~/interfaces";
import { getDataPath } from "~/providers/php-api";

export interface IStoryTeller2024Item {
	text: string;
	items: Array<IStoryTellerItem>;
}

const FOLDERS = {
	cerveneSaty: "ostatni/story-teller-2024/cervene-saty/",
	houpyHoup: "ostatni/story-teller-2024/houpy/",
	dunani: "ostatni/story-teller-2024/dunani/",
	oblhut: "ostatni/story-teller-2024/oblhut/",
	spolecne: "ostatni/story-teller-2024/spolecne/",
};

export function getStoryTeller2024(type: string) {
	const CERVENE_SATY: Array<IStoryTellerItem> = [
		{
			text: "Červené šaty",
			type: "text",
		},
		{
			text: "První valentýnský oběd",
			path: getDataPath("fotky", `${FOLDERS.cerveneSaty}01.jpg`, type),
			date: "2020-02-16",
			type: "image",
		},
		{
			text: "Lence to moc slušelo",
			type: "text",
		},
		{
			text: "Romantická večeře",
			path: getDataPath("fotky", `${FOLDERS.cerveneSaty}02.jpg`, type),
			date: "2020-03-28",
			type: "image",
		},
		{
			text: "Žádost o ruku",
			path: getDataPath("fotky", `${FOLDERS.cerveneSaty}03.jpg`, type),
			date: "2020-12-12",
			type: "image",
		},
		{
			text: "Vícechodové menu",
			path: getDataPath("fotky", `${FOLDERS.cerveneSaty}04.jpg`, type),
			date: "2021-06-11",
			type: "image",
		},
		{
			text: "Romantická večeře Amande",
			path: getDataPath("fotky", `${FOLDERS.cerveneSaty}05.jpg`, type),
			date: "2021-11-17",
			type: "image",
		},
		{
			text: "Romantická večeře",
			path: getDataPath("fotky", `${FOLDERS.cerveneSaty}06.jpg`, type),
			date: "2023-11-18",
			type: "image",
		},
		{
			text: "Vše nej k narozeninám lásečko! 😘",
			path: getDataPath("fotky", `${FOLDERS.cerveneSaty}07.jpg`, type),
			date: "2024-05-09",
			type: "image",
		},
	];

	const HOUPY: Array<IStoryTellerItem> = [
		{
			text: "Houpy houp",
			type: "text",
		},
		{
			text: "Máme doma jedno oblhůťátko a jedno oblhůtě, kteří jsou závislí na houpání...",
			type: "text",
		},
		{
			text: "Martínek",
			path: getDataPath("fotky", `${FOLDERS.houpyHoup}12-large.jpg`, type),
			date: "2024-03-10",
			type: "image",
		},
		{
			text: "a maminka",
			path: getDataPath("fotky", `${FOLDERS.houpyHoup}01-large.jpg`, type),
			date: "2022-06-18",
			type: "image",
		},
		{
			text: "Maminka se houpe v Řecku...",
			path: getDataPath("fotky", `${FOLDERS.houpyHoup}02-large.jpg`, type),
			date: "2023-05-15",
			type: "image",
		},
		{
			text: "Česku...",
			path: getDataPath("fotky", `${FOLDERS.houpyHoup}03-large.jpg`, type),
			date: "2023-05-21",
			type: "image",
		},
		{
			text: "na svatebních místech...",
			path: getDataPath("fotky", `${FOLDERS.houpyHoup}04-large.jpg`, type),
			date: "2023-05-28",
			type: "image",
		},
		{
			text: "na poli...",
			path: getDataPath("fotky", `${FOLDERS.houpyHoup}05-large.jpg`, type),
			date: "2023-06-25",
			type: "image",
		},
		{
			text: "na hodech...",
			path: getDataPath("fotky", `${FOLDERS.houpyHoup}06-large.jpg`, type),
			date: "2023-10-01",
			type: "image",
		},
		{
			text: "na hodech i s Martínkem...",
			path: getDataPath("fotky", `${FOLDERS.houpyHoup}07-large.jpg`, type),
			date: "2023-10-01",
			type: "image",
		},
		{
			text: "na procházkách u trachtéřu...",
			path: getDataPath("fotky", `${FOLDERS.houpyHoup}08-large.jpg`, type),
			date: "2024-02-18",
			type: "image",
		},
		{
			text: "na příborech...",
			path: getDataPath("fotky", `${FOLDERS.houpyHoup}09-large.jpg`, type),
			date: "2024-03-10",
			type: "image",
		},
		{
			text: "v českých budějovicích poprvé...",
			path: getDataPath("fotky", `${FOLDERS.houpyHoup}10-large.jpg`, type),
			date: "2024-03-29",
			type: "image",
		},
		{
			text: "a nakonec i podruhé",
			path: getDataPath("fotky", `${FOLDERS.houpyHoup}11-large.jpg`, type),
			date: "2024-03-30",
			type: "image",
		},
		{
			text: "S Marťou jsi se pohoupala i o víkendu v Bojanovicích",
			path: getDataPath("fotky", `${FOLDERS.houpyHoup}13-large.jpg`, type),
			date: "2024-05-04",
			type: "image",
		},
		{
			text: "Video z houpání (dodělané 8. 5., jak jste byli venku a měl jsem ležet 🦝)",
			path: getDataPath("videa", `${FOLDERS.houpyHoup}14.mp4`, type),
			videoCover: getDataPath("fotky", `${FOLDERS.houpyHoup}14-cover.jpg`, type),
			videoDuration: 19,
			date: "2024-05-04",
			type: "video",
		},
	];

	const DUNANI: Array<IStoryTellerItem> = [
		{
			text: "Důňání",
			type: "text",
		},
		{
			text: "Lenka se ráda mazlí nejenom s Romčou",
			path: getDataPath("fotky", `${FOLDERS.dunani}01-large.jpg`, type),
			date: "2020-06-14",
			type: "image",
		},
		{
			text: "U hroznu měla chuť na zkvašené hrozny",
			path: getDataPath("fotky", `${FOLDERS.dunani}02-large.jpg`, type),
			date: "2020-09-26",
			type: "image",
		},
		{
			text: "Doma jí do šampaňského spadla jahoda",
			path: getDataPath("fotky", `${FOLDERS.dunani}03-large.jpg`, type),
			date: "2022-05-08",
			type: "image",
		},
		{
			text: "Pod stanem si dala víno, aby do něj nepršelo",
			path: getDataPath("fotky", `${FOLDERS.dunani}04-large.jpg`, type),
			date: "2022-06-04",
			type: "image",
		},
		{
			text: "Martínek se už na to druhé deci nemohl dívat",
			path: getDataPath("fotky", `${FOLDERS.dunani}05-large.jpg`, type),
			date: "2022-07-16",
			type: "image",
		},
		{
			text: "Tady si raději hrál s kšiltovkou",
			path: getDataPath("fotky", `${FOLDERS.dunani}06-large.jpg`, type),
			date: "2022-07-17",
			type: "image",
		},
		{
			text: "Lenka opět stanula u velkého hroznu",
			path: getDataPath("fotky", `${FOLDERS.dunani}07-large.jpg`, type),
			date: "2022-09-24",
			type: "image",
		},
		{
			text: "Občas se do cesty připletl i Romča",
			path: getDataPath("fotky", `${FOLDERS.dunani}08-large.jpg`, type),
			date: "2022-10-01",
			type: "image",
		},
		{
			text: "Na pochodu bylo taky dobré vínko",
			path: getDataPath("fotky", `${FOLDERS.dunani}09-large.jpg`, type),
			date: "2022-10-08",
			type: "image",
		},
		{
			text: "Ve Velkých Bílovicích taky",
			path: getDataPath("fotky", `${FOLDERS.dunani}10-large.jpg`, type),
			date: "2023-05-27",
			type: "image",
		},
		{
			text: "Václavský pochod taky zvádnula na jedničku",
			path: getDataPath("fotky", `${FOLDERS.dunani}11-large.jpg`, type),
			date: "2023-09-23",
			type: "image",
		},
		{
			text: "Další pochod poté neměl šanci",
			path: getDataPath("fotky", `${FOLDERS.dunani}12-large.jpg`, type),
			date: "2023-10-07",
			type: "image",
		},
		{
			text: "O vánocích se musí taky hřešit",
			path: getDataPath("fotky", `${FOLDERS.dunani}13-large.jpg`, type),
			date: "2023-12-24",
			type: "image",
		},
		{
			text: "Na výstavě vín byla jedna lajna k degustaci",
			path: getDataPath("fotky", `${FOLDERS.dunani}14-large.jpg`, type),
			date: "2024-03-23",
			type: "image",
		},
		{
			text: "Před skoro pizzou vínko taky sednulo",
			path: getDataPath("fotky", `${FOLDERS.dunani}15-large.jpg`, type),
			date: "2024-03-24",
			type: "image",
		},
		{
			text: "Při návštěvě hobitů jsi se taky musela posilnit",
			path: getDataPath("fotky", `${FOLDERS.dunani}16-large.jpg`, type),
			date: "2024-05-04",
			type: "image",
		},
	];

	const OBLHUT: Array<IStoryTellerItem> = [
		{
			text: "Oblhůt",
			type: "text",
		},
		{
			text: "Začalo to už Řeckem",
			path: getDataPath("fotky", `${FOLDERS.oblhut}01-large.jpg`, type),
			date: "2020-03-06",
			type: "image",
		},
		{
			text: "V Česku taky Indie neměla šanci",
			path: getDataPath("fotky", `${FOLDERS.oblhut}02-large.jpg`, type),
			date: "2020-06-01",
			type: "image",
		},
		{
			text: "aby se projevilo největší oblhůťátko na světě",
			path: getDataPath("fotky", `${FOLDERS.oblhut}03-large.jpg`, type),
			date: "2020-06-13",
			type: "image",
		},
		{
			text: "Zadar nás poctil omeletou",
			path: getDataPath("fotky", `${FOLDERS.oblhut}04-large.jpg`, type),
			date: "2020-07-05",
			type: "image",
		},
		{
			text: "nějakou sépií a masem",
			path: getDataPath("fotky", `${FOLDERS.oblhut}05-large.jpg`, type),
			date: "2020-07-07",
			type: "image",
		},
		{
			text: "luxusními krocánky",
			path: getDataPath("fotky", `${FOLDERS.oblhut}06-large.jpg`, type),
			date: "2020-07-08",
			type: "image",
		},
		{
			text: "a těstovinami",
			path: getDataPath("fotky", `${FOLDERS.oblhut}07-large.jpg`, type),
			date: "2020-07-08",
			type: "image",
		},
		{
			text: "V Praze jsme ochutnali taky něco dobrého",
			path: getDataPath("fotky", `${FOLDERS.oblhut}08-large.jpg`, type),
			date: "2020-07-18",
			type: "image",
		},
		{
			text: "Na Rodosu to bylo dušené maso",
			path: getDataPath("fotky", `${FOLDERS.oblhut}09-large.jpg`, type),
			date: "2020-07-27",
			type: "image",
		},
		{
			text: "a talíř plný dobrot",
			path: getDataPath("fotky", `${FOLDERS.oblhut}10-large.jpg`, type),
			date: "2020-07-27",
			type: "image",
		},
		{
			text: "prostě si moje nejmilejší oblhůťátko",
			path: getDataPath("fotky", `${FOLDERS.oblhut}11-large.jpg`, type),
			date: "2020-07-29",
			type: "image",
		},
		{
			text: "Menší snídaně před výletem",
			path: getDataPath("fotky", `${FOLDERS.oblhut}12-large.jpg`, type),
			date: "2020-08-09",
			type: "image",
		},
		{
			text: "Tady za dosažení vrcholu byla zasloužená odměna",
			path: getDataPath("fotky", `${FOLDERS.oblhut}13-large.jpg`, type),
			date: "2020-08-15",
			type: "image",
		},
		{
			text: "V Římu ti zapomněli rozdělit pizzu 😓",
			path: getDataPath("fotky", `${FOLDERS.oblhut}14-large.jpg`, type),
			date: "2020-09-11",
			type: "image",
		},
		{
			text: "Ale za to na snídani jsi jim to pěkně natřela",
			path: getDataPath("fotky", `${FOLDERS.oblhut}15-large.jpg`, type),
			date: "2020-09-12",
			type: "image",
		},
		{
			text: "Těstoviny bojím bojím",
			path: getDataPath("fotky", `${FOLDERS.oblhut}16-large.jpg`, type),
			date: "2020-09-12",
			type: "image",
		},
		{
			text: "Škoda toho plyvátka s tím parmezánem",
			path: getDataPath("fotky", `${FOLDERS.oblhut}17-large.jpg`, type),
			date: "2020-09-13",
			type: "image",
		},
		{
			text: "Canole drážděná jazýčkem pro zbystření smyslů",
			path: getDataPath("fotky", `${FOLDERS.oblhut}18-large.jpg`, type),
			date: "2020-09-13",
			type: "image",
		},
		{
			text: "Ve Vídni pak smažené sloní ouško",
			path: getDataPath("fotky", `${FOLDERS.oblhut}19-large.jpg`, type),
			date: "2020-09-14",
			type: "image",
		},
		{
			text: "Vejce benedikt nesmí chybět",
			path: getDataPath("fotky", `${FOLDERS.oblhut}20-large.jpg`, type),
			date: "2020-10-11",
			type: "image",
		},
		{
			text: "Karlovy Vary nám daly hezké mexiko",
			path: getDataPath("fotky", `${FOLDERS.oblhut}21-large.jpg`, type),
			date: "2021-07-10",
			type: "image",
		},
		{
			text: "Toskánské rajčátka",
			path: getDataPath("fotky", `${FOLDERS.oblhut}22-large.jpg`, type),
			date: "2021-08-27",
			type: "image",
		},
		{
			text: "V Třeboni se naštěstí nepapali koňi",
			path: getDataPath("fotky", `${FOLDERS.oblhut}23-large.jpg`, type),
			date: "2021-09-11",
			type: "image",
		},
		{
			text: "a byly tam luxusní snídaně",
			path: getDataPath("fotky", `${FOLDERS.oblhut}24-large.jpg`, type),
			date: "2021-09-13",
			type: "image",
		},
		{
			text: "Na hody si byla ve vatě",
			path: getDataPath("fotky", `${FOLDERS.oblhut}25-large.jpg`, type),
			date: "2021-09-26",
			type: "image",
		},
		{
			text: "Burger u firemní akce",
			path: getDataPath("fotky", `${FOLDERS.oblhut}26-large.jpg`, type),
			date: "2021-10-13",
			type: "image",
		},
		{
			text: "Nějaká dlouhé wafle byly hnedka pryč",
			path: getDataPath("fotky", `${FOLDERS.oblhut}27-large.jpg`, type),
			date: "2021-11-20",
			type: "image",
		},
		{
			text: "ani stovky háďátek neunikly tvému zlobivému jazýčku",
			path: getDataPath("fotky", `${FOLDERS.oblhut}28-large.jpg`, type),
			date: "2022-03-03",
			type: "image",
		},
		{
			text: "Kde se tam zase vzaly ty wafle???",
			path: getDataPath("fotky", `${FOLDERS.oblhut}29-large.jpg`, type),
			date: "2022-06-11",
			type: "image",
		},
		{
			text: "Vzpomínka na Řecko",
			path: getDataPath("fotky", `${FOLDERS.oblhut}30-large.jpg`, type),
			date: "2022-07-08",
			type: "image",
		},
		{
			text: "Braťia slováci mali čo nabídnut",
			path: getDataPath("fotky", `${FOLDERS.oblhut}31-large.jpg`, type),
			date: "2022-07-31",
			type: "image",
		},
		{
			text: "Pochod s oškvarkama",
			path: getDataPath("fotky", `${FOLDERS.oblhut}32-large.jpg`, type),
			date: "2022-10-08",
			type: "image",
		},
		{
			text: "Piňakrupička",
			path: getDataPath("fotky", `${FOLDERS.oblhut}33-large.jpg`, type),
			date: "2022-11-06",
			type: "image",
		},
		{
			text: "Před koncertem ti zčervenaly líčka",
			path: getDataPath("fotky", `${FOLDERS.oblhut}34-large.jpg`, type),
			date: "2022-11-12",
			type: "image",
		},
		{
			text: "A pak že biby nejíš! 🐟",
			path: getDataPath("fotky", `${FOLDERS.oblhut}35-large.jpg`, type),
			date: "2022-12-24",
			type: "image",
		},
		{
			text: "Opět dobré benedikty",
			path: getDataPath("fotky", `${FOLDERS.oblhut}36-large.jpg`, type),
			date: "2023-04-28",
			type: "image",
		},
		{
			text: "Velké Bílovice nás poctily spoustou dobrého jídla",
			path: getDataPath("fotky", `${FOLDERS.oblhut}37-large.jpg`, type),
			date: "2023-05-27",
			type: "image",
		},
		{
			text: "Na pochodu si našla v alobalu překvapení",
			path: getDataPath("fotky", `${FOLDERS.oblhut}38-large.jpg`, type),
			date: "2023-09-23",
			type: "image",
		},
		{
			text: "A na dalším pochodu další oškvarky",
			path: getDataPath("fotky", `${FOLDERS.oblhut}39-large.jpg`, type),
			date: "2023-09-23",
			type: "image",
		},
		{
			text: "Burger do tebe spadnul rychlostí blesku",
			path: getDataPath("fotky", `${FOLDERS.oblhut}40-large.jpg`, type),
			date: "2023-10-07",
			type: "image",
		},
		{
			text: "A tento mega chleba se skoro nevešel na fotku",
			path: getDataPath("fotky", `${FOLDERS.oblhut}41-large.jpg`, type),
			date: "2023-10-14",
			type: "image",
		},
		{
			text: "Patenty byly taky rychle pryč",
			path: getDataPath("fotky", `${FOLDERS.oblhut}42-large.jpg`, type),
			date: "2023-10-14",
			type: "image",
		},
		{
			text: "Na Vánoce se baští jedna radost",
			path: getDataPath("fotky", `${FOLDERS.oblhut}43-large.jpg`, type),
			date: "2023-12-24",
			type: "image",
		},
		{
			text: "Na dovolené jsou snídaně nejlepší začátek dne",
			path: getDataPath("fotky", `${FOLDERS.oblhut}44-large.jpg`, type),
			date: "2024-03-30",
			type: "image",
		},
		{
			text: "Tady ti někdo dal polévku do nějakého divného taléřku",
			path: getDataPath("fotky", `${FOLDERS.oblhut}45-large.jpg`, type),
			date: "2024-03-30",
			type: "image",
		},
		{
			text: "Tuna zmrzlin pro moje oblhůťátko",
			path: getDataPath("fotky", `${FOLDERS.oblhut}46-large.jpg`, type),
			date: "2024-03-31",
			type: "image",
		},
		{
			text: "Žerpý dva menší chleby na snídani",
			path: getDataPath("fotky", `${FOLDERS.oblhut}47-large.jpg`, type),
			date: "2024-04-28",
			type: "image",
		},
		{
			text: "V Luhačovicích jsi se musela před oplatkami posilnit",
			path: getDataPath("fotky", `${FOLDERS.oblhut}48-large.jpg`, type),
			date: "2024-05-01",
			type: "image",
		},
		{
			text: "A fakt nechápu, že ti zbylo místo ještě na obří zmrzlinu 😨",
			path: getDataPath("fotky", `${FOLDERS.oblhut}49-large.jpg`, type),
			date: "2024-05-01",
			type: "image",
		},
	];

	const SPOLECNE: Array<IStoryTellerItem> = [
		{
			text: "Společné fotky",
			type: "text",
		},
		{
			text: "Na první fotce ve Znojmě ti to moc slušelo",
			path: getDataPath("fotky", `${FOLDERS.spolecne}01-large.jpg`, type),
			date: "2020-01-11",
			type: "image",
		},
		{
			text: "Po víření v Luhačovicích se fotí jedna radost",
			path: getDataPath("fotky", `${FOLDERS.spolecne}02-large.jpg`, type),
			date: "2020-02-09",
			type: "image",
		},
		{
			text: "V Řecku to byl krásný polibek u západu slunce",
			path: getDataPath("fotky", `${FOLDERS.spolecne}03-large.jpg`, type),
			date: "2020-03-05",
			type: "image",
		},
		{
			text: "V Zadaru zase kousek od vodních varhan",
			path: getDataPath("fotky", `${FOLDERS.spolecne}04-large.jpg`, type),
			date: "2020-07-04",
			type: "image",
		},
		{
			text: "Před výšlapem na Lysou horu",
			path: getDataPath("fotky", `${FOLDERS.spolecne}05-large.jpg`, type),
			date: "2020-08-15",
			type: "image",
		},
		{
			text: "V Římě u fontány",
			path: getDataPath("fotky", `${FOLDERS.spolecne}06-large.jpg`, type),
			date: "2020-09-13",
			type: "image",
		},
		{
			text: "Zásnubní fotka",
			path: getDataPath("fotky", `${FOLDERS.spolecne}07-large.jpg`, type),
			date: "2020-12-12",
			type: "image",
		},
		{
			text: "První společné Vánoce",
			path: getDataPath("fotky", `${FOLDERS.spolecne}08-large.jpg`, type),
			date: "2020-12-24",
			type: "image",
		},
		{
			text: "Lázeňské focení",
			path: getDataPath("fotky", `${FOLDERS.spolecne}09-large.jpg`, type),
			date: "2021-07-10",
			type: "image",
		},
		{
			text: "Florencie naproti starému mostu",
			path: getDataPath("fotky", `${FOLDERS.spolecne}10-large.jpg`, type),
			date: "2021-08-23",
			type: "image",
		},
		{
			text: "První focení s Markem",
			path: getDataPath("fotky", `${FOLDERS.spolecne}11-large.jpg`, type),
			date: "2021-10-26",
			type: "image",
		},
		{
			text: "První výstava vín",
			path: getDataPath("fotky", `${FOLDERS.spolecne}12-large.jpg`, type),
			date: "2022-03-26",
			type: "image",
		},
		{
			text: "Opět v Praze a Obecním domě",
			path: getDataPath("fotky", `${FOLDERS.spolecne}13-large.jpg`, type),
			date: "2022-06-12",
			type: "image",
		},
		{
			text: "Výšlap na Dívčí hrady",
			path: getDataPath("fotky", `${FOLDERS.spolecne}14-large.jpg`, type),
			date: "2022-08-07",
			type: "image",
		},
		{
			text: "Barcelona",
			path: getDataPath("fotky", `${FOLDERS.spolecne}15-large.jpg`, type),
			date: "2022-09-14",
			type: "image",
		},
		{
			text: "Na pochodu",
			path: getDataPath("fotky", `${FOLDERS.spolecne}16-large.jpg`, type),
			date: "2022-10-01",
			type: "image",
		},
		{
			text: "Ostrava Báňská",
			path: getDataPath("fotky", `${FOLDERS.spolecne}17-large.jpg`, type),
			date: "2022-11-06",
			type: "image",
		},
		{
			text: "Budapešť",
			path: getDataPath("fotky", `${FOLDERS.spolecne}18-large.jpg`, type),
			date: "2023-04-09",
			type: "image",
		},
		{
			text: "Kréta kousek od 🐑🐑🐑",
			path: getDataPath("fotky", `${FOLDERS.spolecne}19-large.jpg`, type),
			date: "2023-05-15",
			type: "image",
		},
		{
			text: "ČokoVěnec výstava",
			path: getDataPath("fotky", `${FOLDERS.spolecne}20-large.jpg`, type),
			date: "2023-09-17",
			type: "image",
		},
		{
			text: "Další burčákový pochod",
			path: getDataPath("fotky", `${FOLDERS.spolecne}21-large.jpg`, type),
			date: "2023-10-07",
			type: "image",
		},
		{
			text: "a ještě jeden (to už si důňala o106)",
			path: getDataPath("fotky", `${FOLDERS.spolecne}22-large.jpg`, type),
			date: "2023-10-14",
			type: "image",
		},
		{
			text: "Ve Znojmě při návštěvě babičky",
			path: getDataPath("fotky", `${FOLDERS.spolecne}23-large.jpg`, type),
			date: "2023-10-28",
			type: "image",
		},
		{
			text: "Po 4 letech opět na plese",
			path: getDataPath("fotky", `${FOLDERS.spolecne}24-large.jpg`, type),
			date: "2024-01-13",
			type: "image",
		},
		{
			text: "Při degustaci dobrot",
			path: getDataPath("fotky", `${FOLDERS.spolecne}25-large.jpg`, type),
			date: "2024-01-20",
			type: "image",
		},
		{
			text: "Koncert ve Vlčiej diere",
			path: getDataPath("fotky", `${FOLDERS.spolecne}26-large.jpg`, type),
			date: "2024-02-24",
			type: "image",
		},
		{
			text: "Mandloně",
			path: getDataPath("fotky", `${FOLDERS.spolecne}27-large.jpg`, type),
			date: "2024-03-17",
			type: "image",
		},
		{
			text: "Zhářání na hůře?",
			path: getDataPath("fotky", `${FOLDERS.spolecne}28-large.jpg`, type),
			date: "2024-03-23",
			type: "image",
		},
		{
			text: "Ostrava!!!",
			path: getDataPath("fotky", `${FOLDERS.spolecne}29-large.jpg`, type),
			date: "2024-04-28",
			type: "image",
		},
	];

	const DAREK_1: Array<IStoryTellerItem> = [
		{
			text: "Dárek 1",
			type: "text",
		},
		{
			text: "Jako původní dárek jsem ti chtěl koupit nové hodinky, ale jak jsme zjistili už se nedaly pořádně sehnat",
			type: "text",
		},
		{
			text: "A nové modely byl taky problém sehnat",
			type: "text",
		},
		{
			text: "Tak jsem rád, že se nakonec podařilo sehnat ty opravené, protože ti to udělalo radost!",
			path: getDataPath("fotky", `ostatni/grafika/04-large.jpg`, type),
			type: "image",
		},
	];

	const DAREK_2: Array<IStoryTellerItem> = [
		{
			text: "Dárek 2",
			type: "text",
		},
		{
			text: "Jako druhý dárek dostaneš něco zážitkového a něco co si nikdy nezažila!",
			type: "text",
		},
		{
			text: "Už jsem ti na whatsupu poslal nápovědu",
			path: getDataPath("fotky", `ostatni/story-teller-2024/darek2/myval.jpg`, type),
			type: "image",
		},
		{
			text: "Událost se koná 2. června na výstavišti a máme zajištěné hlídání",
			type: "text",
		},
		{
			text: "Pro získání dárku se musíš podívat na první věc co jsem ti dal ze ságy zaklínače!",
			path: getDataPath("fotky", `ostatni/story-teller-2024/darek2/geralt.jpg`, type),
			type: "image",
		},
	];

	const DAREK_3: Array<IStoryTellerItem> = [
		{
			text: "Dárek 3",
			type: "text",
		},
		{
			text: "Poslední dárek nemusí být velký velikostí, ale jinými vlastnostmi a může v sobě skrývat spoustu věcí",
			type: "text",
		},
		{
			text: "Akorát se mně stala při chystání dárků jedna nemilá věc 😓",
			type: "text",
		},
		{
			text: "Dárek se mně ztratil a nemůžu ho najít - to jsem zkoušel hledat opravdu všude!",
			type: "text",
		},
		{
			text: "Třeba budeš v hledání úspěšnější ty?",
			path: getDataPath("fotky", `ostatni/story-teller-2024/darek3/hrabak.jpg`, type),
			type: "image",
		},
	];


	const allItems: Array<IStoryTeller2024Item> = [{
		text: "Červené šaty",
		items: CERVENE_SATY,
	}, {
		text: "Houpy houp",
		items: HOUPY,
	}, {
		text: "Důňání",
		items: DUNANI,
	}, {
		text: "Oblhůt",
		items: OBLHUT,
	}, {
		text: "Společné fotky",
		items: SPOLECNE,
	}];
	const birthdayItems: Array<IStoryTeller2024Item> = [{
		text: "Dárek 1",
		items: DAREK_1,
	}, {
		text: "Dárek 2",
		items: DAREK_2,
	}, {
		text: "Dárek 3",
		items: DAREK_3,
	}];

	return {
		allItems,
		birthdayItems,
	};
}
