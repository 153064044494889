/* eslint-disable no-magic-numbers */
import dayjs from "dayjs";

import PageContent from "~/components/PageContent";
import HeaderTitle from "~/components/HeaderTitle";
import Tags from "~/components/Tags";
import { getMonthsData } from "~/providers/month-data";
import { MONTHS } from "~/const";
import { myUseState } from "~/hooks/myUseState";

interface IState {
	data: ReturnType<typeof getMonthsData>;
}

export default function BirthdaysPage() {
	const curMonth = dayjs().get("month");
	const { state, updateState } = myUseState<IState>({
		data: null,
	});

	function getTitleMonth(month = 0) {
		let newMonth = curMonth + month;

		if (newMonth < 0) {
			newMonth = 11;
		} else if (newMonth > 12) {
			newMonth = 0;
		}

		return MONTHS[newMonth];
	}

	function onPageLoad() {
		updateState({
			data: getMonthsData({
				curMonth,
			}),
		});
	}

	return <PageContent className="birthdaysPage" adminContent={true} onLoad={onPageLoad}>
		<HeaderTitle title="Narozeniny" />
		{ state.data && <>
			<h3>{ getTitleMonth(-1) }</h3>
			<Tags items={state.data.prev} />
			<h3>{ getTitleMonth() }</h3>
			<Tags items={state.data.cur} selected={state.data.active} />
			<h3>{ getTitleMonth(1) }</h3>
			<Tags items={state.data.next} />
		</> }
	</PageContent>;
}
