
import { IGalleryItem, IGallery } from "~/interfaces";
import MyGalleryItem from "~/my/MyGalleryItem";
import MyGalleryItemDate from "~/my/MyGalleryItemDate";
import MyGalleryTopLeftDesc from "~/my/MyGalleryTopLeftDesc";
import MyGalleryItemBar from "~/my/MyGalleryItemBar";
import MyGalleryDownload from "~/my/MyGalleryDownload";
import LazyImage from "../LazyImage";
import { getGalleryItemTitle, getImagePersons } from "~/utils/utils";

interface IYearGalleryImageItem {
	data: IGalleryItem;
	gallery?: IGallery;
	seo: string;
	onClick?: () => void;
	onMapClick?: (data: IGalleryItem) => void;
	downloadMode?: boolean;
	selectMode?: boolean;
	noOverlays?: boolean;
	selected?: boolean;
	showOnlyHours?: boolean;
}

export default function YearGalleryImageItem({
	data,
	gallery,
	seo,
	onClick = () => {},
	onMapClick = () => {},
	downloadMode = false,
	selectMode = false,
	selected = false,
	showOnlyHours = false,
}: IYearGalleryImageItem) {
	const title = getGalleryItemTitle(data, gallery, showOnlyHours);
	const alt = gallery
		? gallery.event.title
		: data.title || "";

	function onPositionClick() {
		onMapClick(data);
	}

	return <MyGalleryItem sx={{ cursor: "pointer", overflow: "hidden" }} onClick={() => onClick()} seo={seo} className={selected ? "selected" : ""}>
		<LazyImage src={data.src} alt={alt} className="galleryPage__img" />
		{ downloadMode && <MyGalleryDownload /> }
		{ !downloadMode && <>
			{ title && <MyGalleryItemDate title={title} /> }
			{ (data.coords || data.persons) && <MyGalleryTopLeftDesc coords={data.coords} onClick={onPositionClick} title={data.persons ? getImagePersons(data.persons) : ""} /> }
			{ (data.title || selectMode) && <MyGalleryItemBar title={selectMode ? data.name : data.title} subtitle={data.subTitle} /> }
		</> }
	</MyGalleryItem>;
}
