import { useNavigate } from "react-router-dom";

import PageContent from "~/components/PageContent";
import Password from "~/components/Password";
import { ROUTES } from "~/const";

export default function LoginPage() {
	const navigate = useNavigate();

	function onLogin() {
		navigate(ROUTES.ROOT);
	}

	return <PageContent className="loginPage">
		<Password onLogin={onLogin} />
	</PageContent>;
}
