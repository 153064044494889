import { useEffect } from "react";

import MyButton from "~/my/MyButton";
import { IMapTodoItem } from "~/map-interfaces";
import { myUseState } from "~/hooks/myUseState";
import MyCheckbox from "~/my/MyCheckbox";
import { getClassName, isKeyEnter } from "~/utils/utils";

import "./style.less";

export interface IAddMarker {
	addItem: IMapTodoItem;
	addToLastPosition: boolean;
	savePosition: boolean;
	date?: string;
}

interface IState {
	dateItem: IMapTodoItem;
	dateValue: string;
	stateAddToLastPosition: boolean;
	stateSavePosition: boolean;
	selectItems: boolean;
	showPhoto: boolean;
	selected: Array<string>;
}

interface IMapAddMarkers {
	items: Array<IMapTodoItem>;
	date?: string;
	mapDate?: string;
	addToLastPosition?: boolean;
	savePosition?: boolean;
	onAddMarker?: (data: IAddMarker) => void;
	onOpenPhoto?: (data: IMapTodoItem) => void;
}

export default function MapAddMarkers({
	date = "",
	mapDate = "",
	items = [],
	addToLastPosition = true,
	savePosition = true,
	onAddMarker = () => {},
	onOpenPhoto = () => {},
}: IMapAddMarkers) {
	const { state, updateState } = myUseState<IState>({
		dateItem: null,
		dateValue: "",
		stateAddToLastPosition: addToLastPosition,
		stateSavePosition: savePosition,
		selectItems: false,
		showPhoto: false,
		selected: [],
	});

	function setItemDate() {
		if (state.dateValue.length) {
			onAddMarker({
				addItem: state.dateItem,
				addToLastPosition: state.stateAddToLastPosition,
				savePosition: state.stateSavePosition,
				date: state.dateValue,
			});
			updateState({
				dateValue: "",
				dateItem: null,
			});
		}
	}

	function clearItemDate() {
		updateState({
			dateValue: "",
			dateItem: null,
		});
	}

	function addItems(todoMarkers: Array<IMapTodoItem>) {
		const reversed = todoMarkers.reverse();

		for (const item of reversed) {
			onAddMarker({
				addItem: item,
				addToLastPosition: state.stateAddToLastPosition,
				savePosition: state.stateSavePosition,
			});
		}
	}

	function addAll() {
		addItems(items);
	}

	function addSelected() {
		const filtered = items.filter(item => state.selected.includes(item.name));

		addItems(filtered);
	}

	function tryAddMarker(todoMarker: IMapTodoItem) {
		if (todoMarker.date) {
			onAddMarker({
				addItem: todoMarker,
				addToLastPosition: state.stateAddToLastPosition,
				savePosition: state.stateSavePosition,
			});
		} else {
			updateState({
				dateValue: date || mapDate || "",
				dateItem: todoMarker,
			});
		}
	}

	function onKeyDown(event: KeyboardEvent) {
		if (isKeyEnter(event)) {
			setItemDate();
		}
	}

	function onClick(todoMarker: IMapTodoItem) {
		if (state.selectItems) {
			const selected = state.selected.slice();
			const ind = selected.indexOf(todoMarker.name);

			if (ind === -1) {
				selected.push(todoMarker.name);
			} else {
				selected.splice(ind, 1);
			}

			updateState({
				selected,
			});
		} else if (state.showPhoto) {
			onOpenPhoto(todoMarker);
		} else {
			tryAddMarker(todoMarker);
		}
	}

	function getItemClassName(todoMarker: IMapTodoItem) {
		return getClassName(["mapContainer__addMarkersAreaBtn", state.selected.includes(todoMarker.name) ? "selected" : ""]);
	}

	useEffect(() => {
		if (state.dateItem) {
			document.addEventListener("keydown", onKeyDown);

			return () => {
				document.removeEventListener("keydown", onKeyDown);
			};
		}

		return () => {};
	}, [state.dateItem]);

	/* eslint-disable react/no-array-index-key */
	return <div className="mapContainer__addMarkers map__dialog">
		<div className="mapContainer__checkboxes">
			<span>{ items.length }</span>
			<MyCheckbox text="Přidat na poslední pozici" value={state.stateAddToLastPosition} onChange={stateAddToLastPosition => updateState({ stateAddToLastPosition })} />
			<MyCheckbox text="Uložit poslední pozici" value={state.stateSavePosition} onChange={stateSavePosition => updateState({ stateSavePosition })} />
			<MyCheckbox text="Vybrat položky" value={state.selectItems} onChange={selectItems => updateState({ selectItems })} />
			<MyCheckbox text="Otevřít fotku" value={state.showPhoto} onChange={showPhoto => updateState({ showPhoto })} />
		</div>
		<div className="mapContainer__btns">
			{ state.dateItem && <div className="mapContainer__btnsPart">
				<input type="text" value={state.dateValue} onChange={event => updateState({ dateValue: event.target.value })} />
				<MyButton text="Nastavit" variant="filled" onClick={setItemDate} />
				<MyButton text="Zrušit" variant="filled" onClick={clearItemDate} />
			</div> }
			<div className="mapContainer__btnsPart">
				<MyButton text="Přidat vybrané" variant="filled" onClick={addSelected} />
				<MyButton text="Přidat vše" variant="filled" onClick={addAll} />
			</div>
		</div>
		<div className="mapContainer__addMarkersArea">
			{ items.map(todoMarker => <MyButton className={getItemClassName(todoMarker)} text={<><img src={todoMarker.src} alt="" /><span>{ todoMarker.name.replace(/[.].*$/u, "") }</span></>} onClick={() => onClick(todoMarker)} key={todoMarker.name} />)}
		</div>
	</div>;
}
