import { ReactNode, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

import { myUseState } from "~/hooks/myUseState";
import { IGalleryItem } from "~/interfaces";
import MyVideo, { IControlsData } from "~/my/MyVideo";
import { formatDuration, getFullDesc } from "~/utils/utils";
import VideoPlaylistItem from "../VideoPlaylistItem";

import "./style.less";

interface IState {
	current: IGalleryItem;
	prev: null | IGalleryItem;
	next: null | IGalleryItem;
	ind: number;
	isEnd: boolean;
	isLoop: boolean;
}

interface IVideoPlaylist {
	items: Array<IGalleryItem>;
	onClose: () => void;
}

const PortalComponent = ({ children }: { children: ReactNode }) => {
	const portal = createPortal(
		<div className="modal">
			{children}
		</div>,
		document.getElementById("portal") || document.body,
	);

	return portal;
};
const NEXT_IND = 2;

export default function VideoPlaylist({
	items = [],
	onClose = () => {},
}: IVideoPlaylist) {
	const videoPlaylistRef = useRef<HTMLDivElement>(null);

	function getInitState(): Pick<IState, "current" | "prev" | "next" | "ind"> {
		return {
			current: items[0],
			prev: null,
			next: items.length > 1 ? items[1] : null,
			ind: 0,
		};
	}

	const { state, setState } = myUseState<IState>({
		...getInitState(),
		isEnd: false,
		isLoop: false,
	});

	function setNewVideo(direction: number) {
		setState(prev => {
			const len = items.length - 1;
			const newInd = prev.ind + direction;

			if (newInd < 0) {
				return prev;
			} else if (newInd > len) {
				if (prev.isLoop) {
					return {
						...prev,
						...getInitState(),
					};
				}

				return {
					...prev,
					isEnd: true,
				};
			}

			return {
				...prev,
				current: items[newInd],
				prev: newInd > 0 ? items[newInd - 1] : null,
				next: newInd > len ? null : items[newInd + 1],
				ind: newInd,
			};
		});
	}

	function fullscreenClick() {
		if (document.fullscreenElement) {
			document.exitFullscreen();
		} else if (videoPlaylistRef.current) {
			videoPlaylistRef.current.requestFullscreen();
		}
	}

	function onControlsClick(data: IControlsData) {
		switch (data.type) {
			case "prev":
				setNewVideo(-1);
				break;

			case "next":
				setNewVideo(1);
				break;

			case "close":
				onClose();
				break;

			case "loop":
				setState(prev => ({
					...prev,
					isLoop: data.loopEnabled,
				}));
				break;

			case "fullscreen": {
				fullscreenClick();
				break;
			}

			default:
		}
	}

	function onVideoEnded() {
		setNewVideo(1);
	}

	useEffect(() => {
		if (state.isEnd) {
			onClose();
		}
	}, [state.isEnd]);

	return <PortalComponent>
		<div className="videoPlaylist" ref={videoPlaylistRef}>
			<div className="videoPlaylist__info">
				{ state.prev
					? <VideoPlaylistItem title={state.prev.title || ""} date={state.prev.date ? getFullDesc(state.prev.date) : ""} position={`${state.ind} / ${items.length}`} onClick={() => setNewVideo(-1)} duration={formatDuration(state.prev.duration || 0)} />
					: <VideoPlaylistItem /> }
				<VideoPlaylistItem title={state.current.title || ""} date={state.current.date ? getFullDesc(state.current.date) : ""} position={`${state.ind + 1} / ${items.length}`} active={true} duration={formatDuration(state.current.duration || 0)} />
				{ state.next
					? <VideoPlaylistItem title={state.next.title || ""} date={state.next.date ? getFullDesc(state.next.date) : ""} position={`${state.ind + NEXT_IND} / ${items.length}`} onClick={() => setNewVideo(1)} duration={formatDuration(state.next.duration || 0)} />
					: <VideoPlaylistItem /> }
			</div>
			<MyVideo src={state.current.src} cover={state.current.preview} variant="playlist" onControlsClick={onControlsClick} onVideoEnded={onVideoEnded} autoHide={false} />
		</div>
	</PortalComponent>;
}
