import { create } from 'zustand';
import { removeFromArray } from "~/utils/utils";

interface IVideoStore {
	video: {
		playing: Array<string>;
	}
	addPlayingVideo: (src: string) => void;
	removePlayingVideo: (src: string) => void;
}

export const videoStore = create<IVideoStore>(set => ({
	video: {
		playing: [],
	},
	addPlayingVideo: src => set(state => ({
		video: {
			...state.video,
			playing: [
				...state.video.playing,
				src,
			],
		},
	})),
	removePlayingVideo: src => set(state => ({
		video: {
			...state.video,
			playing: removeFromArray(state.video.playing, videoSrc => videoSrc === src),
		},
	})),
}));
