import { useRef } from 'react';

import { TIMES } from "./times";
import { timeToSeconds } from "~/utils/utils";
import { getDataPath } from "~/providers/php-api";
import type { IDanceTimeItem, ILogin } from "~/interfaces";
import PageContent from "~/components/PageContent";
import { myUseState } from "~/hooks/myUseState";
import MyVideo from "~/my/MyVideo";
import HeaderTitle from "~/components/HeaderTitle";

import "./style.less";

const DEF_TIME = "";

interface IState {
	showTimes: boolean;
	selectedItem: null|IDanceTimeItem;
	selectedNextItem: null|IDanceTimeItem;
	stopTime: string;
	time: string;
	videoSrc: string;
	videoCover: string;
}

export default function DancePage() {
	const inputEl = useRef<HTMLInputElement>(null);
	const { state, setState, updateState } = myUseState<IState>({
		showTimes: false,
		selectedItem: null,
		selectedNextItem: null,
		stopTime: DEF_TIME,
		time: "",
		videoSrc: "",
		videoCover: "",
	});

	function setTime(time: string) {
		// [s]
		updateState({
			time,
		});
	}

	function getItemIndex(time: string): number {
		for (let ind = 0; ind < TIMES.length; ind++) {
			if (TIMES[ind].time === time) {
				return ind;
			}
		}

		return -1;
	}

	function onClick() {
		const value = (inputEl?.current?.value || "").trim();

		setTime(value);
	}

	function linkSetTime(item: IDanceTimeItem|null) {
		if (item) {
			const ind = getItemIndex(item.time);

			updateState({
				stopTime: ind > -1 && TIMES[ind + 1] ? TIMES[ind + 1].time : DEF_TIME,
				showTimes: false,
				selectedItem: item,
				selectedNextItem: TIMES[ind + 1] || null,
			});

			setTime(item.time);
		}
	}

	function clearTimeClick() {
		updateState({
			stopTime: DEF_TIME,
			selectedItem: null,
			selectedNextItem: null,
		});
	}

	function onTimeUpdate(videoEl: HTMLVideoElement) {
		const time = state.stopTime ? timeToSeconds(state.stopTime) : 0;
		const curTime = videoEl.currentTime || 0;

		if (time && curTime > time) {
			videoEl.pause();

			updateState({
				stopTime: DEF_TIME,
			});
		}
	}

	function onLoad(loginData: ILogin) {
		const videoCover = getDataPath("fotky", "2022/svatba-nase/05v-cover.jpg", loginData.type);
		const videoSrc = getDataPath("videa", "2022/svatba-nase/05v.mp4", loginData.type);

		updateState({
			videoCover,
			videoSrc,
		});
	}

	return <PageContent className="dancePage" adminContent={true} onLoad={onLoad}>
		<HeaderTitle title="Tanec" />
		{ state.videoSrc && <MyVideo cover={state.videoCover} src={state.videoSrc} onTimeUpdate={onTimeUpdate} time={state.time} /> }
		<div className="dancePage__controls">
			<input type="text" className="dancePage__inputText" defaultValue="0:00" ref={inputEl} />
			<button type="button" onClick={onClick} className="dancePage__btn">Nastavit čas</button>
			<button type="button" onClick={() => setState(prev => ({ ...prev, showTimes: !prev.showTimes }))} className="dancePage__btn">Seznam časů</button>
		</div>
		{ state.showTimes
			? <div className="dancePage__timesPopup">
				<ul>
					{TIMES.map(partItem => <li key={`partInd${Math.random()}`} className="dancePage__item">
						<a href="#" onClick={() => linkSetTime(partItem)}>
							<span className="dancePage__itemTime">[{partItem.time}]</span>
							<span className="dancePage__itemText">{partItem.text}</span></a>
					</li>)}
				</ul>
			</div>
			: <>
			</>
		}
		<div className="dancePage__selectedTime" style={{ display: state.selectedItem ? "block" : "none" }}>
			<div>
				Vybraný čas: {state.selectedItem ? <a href="#" onClick={() => linkSetTime(state.selectedItem)}>[{state.selectedItem.time}]{state.stopTime ? ` - [${state.stopTime}] ` : ` `}{state.selectedItem.text}</a> : null}
			</div>
			{state.selectedNextItem
				? <div>
					<span>Následující krok: </span>
					<a href="#" onClick={() => linkSetTime(state.selectedNextItem)}>[{state.selectedNextItem.time}] {state.selectedNextItem.text}</a>
				</div>
				: null
			}
			<div>
				<button type="button" onClick={clearTimeClick} className="dancePage__btn clear">Smazat</button>
			</div>
		</div>
	</PageContent>;
}
