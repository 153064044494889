import { IDatabaseItem } from "../interfaces";

export const DUPLO_ITEMS: Array<IDatabaseItem> = [
	{
		title: "Duplo - 10872 most",
		images: [{
			src: "01.jpg",
			large: "01-large.jpg",
		}, {
			src: "02.jpg",
			large: "02-large.jpg",
		}],
	},
	{
		title: "Duplo - 10874 vláček a koleje",
		images: [{
			src: "03.jpg",
			large: "03-large.jpg",
		}, {
			src: "04.jpg",
			large: "04-large.jpg",
		}],
	},
	{
		title: "Duplo - 10882 náhradní koleje",
		images: [{
			src: "05.jpg",
			large: "05-large.jpg",
		}, {
			src: "06.jpg",
			large: "06-large.jpg",
		}],
	},
	{
		title: "Duplo - 10909 srdíčkový box",
		images: [{
			src: "07.jpg",
			large: "07-large.jpg",
		}, {
			src: "08.jpg",
			large: "08-large.jpg",
		}],
	},
	{
		title: "Duplo - 10931 bagr",
		images: [{
			src: "09.jpg",
			large: "09-large.jpg",
		}, {
			src: "10.jpg",
			large: "10-large.jpg",
		}],
	},
	{
		title: "Duplo - 10949 zvířátka",
		images: [{
			src: "11.jpg",
			large: "11-large.jpg",
		}, {
			src: "12.jpg",
			large: "12-large.jpg",
		}],
	},
	{
		title: "Duplo - 10954 vláček",
		images: [{
			src: "13.jpg",
			large: "13-large.jpg",
		}, {
			src: "14.jpg",
			large: "14-large.jpg",
		}],
	},
];
