import CSS from 'csstype';
import { ReactNode } from "react";

import { getClassName } from "~/utils/utils";

import "./style.less";

interface IMyButton {
	text: ReactNode;
	className?: string;
	onClick?: () => void;
	style?: CSS.Properties;
	disabled?: boolean;
	title?: string;
	active?: boolean;
	variant?: "normal" | "outlined" | "filled";
}

export default function MyButton({
	text,
	className = "",
	onClick = () => {},
	style = {},
	disabled = false,
	title = "",
	active = false,
	variant = "normal",
}: IMyButton) {
	return <button type="button" onClick={() => onClick()} className={getClassName(["myButton", className, variant === "normal" ? "" : `variant-${variant}`, active ? "active" : ""])} style={style} disabled={disabled} title={title}>
		{ text }
	</button>;
}
