import { ReactNode } from "react";
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';

import MyButton from "~/my/MyButton";
import { getClassName } from "~/utils/utils";

import "./style.less";

interface IScroller {
	className?: string;
	children?: ReactNode;
	visible?: boolean;
}

export default function Scroller({
	className = "",
	children = null,
	visible = true,
}: IScroller) {
	function onClick() {
		const elem = document.querySelector(".mainContent__header");

		if (elem) {
			elem.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		}
	}

	return <div className={getClassName(["scroller", visible ? "visible" : "", className])}>
		<MyButton text={<VerticalAlignTopIcon /> } variant="filled" className="scroller__btn" onClick={onClick} />
		{ children }
	</div>;
}
