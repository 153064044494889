/* eslint-disable no-magic-numbers */
import { MouseEvent, ReactNode, useRef } from "react";

import { getClassName } from "~/utils/utils";

import "./style.less";

interface IMyVideoControlProgressBar {
	percent?: number;
	text?: ReactNode;
	className?: string;
	onClick?: (percent: number) => void;
}

export default function MyVideoControlProgressBar({
	percent = 0,
	text = null,
	className = "",
	onClick = () => {},
}: IMyVideoControlProgressBar) {
	const elemRef = useRef<HTMLDivElement>(null);

	function progressClick(event: MouseEvent) {
		const mainElem = elemRef.current;

		if (mainElem) {
			const divElem = event.target as HTMLDivElement;
			const bcr = divElem.getBoundingClientRect();
			const perc = (event.clientX - bcr.left) / divElem.offsetWidth * 100;

			onClick(perc);
		}
	}

	return <div className={getClassName(["myVideoControlProgressBar", className])} ref={elemRef}>
		<div className="myVideoControlProgressBar__cover">
			<div className="myVideoControlProgressBar__progressBar" style={{ width: `${percent.toFixed(2)}%` }} />
			<div className="myVideoControlProgressBar__progressBar background" />
			<div className="myVideoControlProgressBar__progressBar click" onClick={progressClick} />
			<div className="myVideoControlProgressBar__progressBarTime">
				{ text }
			</div>
		</div>
	</div>;
}
