import { globalStore } from "~/stores/global";
import { setKokokykaBe } from "~/providers/php-api";
import { ASSETS } from "~/utils/assets";

import "./style.less";

export default function Kokokyka() {
	const { global, setKokokyka } = globalStore(globalState => ({
		global: globalState.global,
		setKokokyka: globalState.setKokokyka,
	}));

	function getImgSrc() {
		return global.loginData?.kokokyka === 1
			? ASSETS.KokokykaWhite
			: ASSETS.KokokykaBlack;
	}

	async function switchKokokyka() {
		const kokokyka = global.loginData?.kokokyka === 1 ? 0 : 1;

		await setKokokykaBe(kokokyka);

		setKokokyka(kokokyka);
	}

	return <div className="kokokyka" onDoubleClick={switchKokokyka}>
		<img src={getImgSrc()} alt="Kokokyka" />
	</div>;
}
