import { useRef, KeyboardEvent, useEffect } from 'react';

import { EMOTIONS } from '~/data/emotions';
import { getMessengerItems, addMessengerItem, removeMessengerItem } from '~/providers/php-api';
import { INIT_SUGGEST_DATA, KEYS, PREV_PREV_INDEX } from "~/const";
import type { ISuggestData, IMessengerItem } from "~/interfaces";
import { getClassName } from "~/utils/utils";
import PageContent from "~/components/PageContent";
import { myUseState } from "~/hooks/myUseState";
import HeaderTitle from "~/components/HeaderTitle";
import MyButton from "~/my/MyButton";
import { globalStore } from "~/stores/global";
import Loader from "~/components/Loader";
import { savedStore } from "~/stores/saved";
import { addNotification } from "~/stores/notifications";

import "./style.less";

interface IState {
	loading: boolean;
	user: string;
	items: Array<IMessengerItem>;
	showEmoji: boolean;
}

export default function MessengerPage() {
	const textareaEl = useRef<HTMLTextAreaElement>(null);
	const { setMessengerNews } = globalStore(globalState => ({
		setMessengerNews: globalState.setMessengerNews,
	}));
	const { savedData, setMessengerUser, setMessengerLastId } = savedStore(savedState => ({
		savedData: savedState.saved,
		setMessengerUser: savedState.setMessengerUser,
		setMessengerLastId: savedState.setMessengerLastId,
	}));
	const { state, setState, updateState } = myUseState<IState>(() => ({
		loading: true,
		user: savedData.messengerUser,
		items: [],
		showEmoji: false,
	}));
	const { state: stateData, setState: setStateData, updateState: updateStateData } = myUseState<ISuggestData>({
		...INIT_SUGGEST_DATA,
	});

	async function loadData() {
		const data = await getMessengerItems();

		updateState({
			items: data.noErrors
				? data.data
				: [],
			loading: false,
		});
		// nastavime posledni viditelne id
		data.data && setMessengerLastId(data.data[0].id);
		setMessengerNews(false);
	}

	async function addItem() {
		const text = textareaEl?.current?.value.trim();

		if (!text) {
			return;
		}

		let content = text;

		const matches = text.match(/:[a-zA-Z0-9]+:/ug);

		if (matches) {
			for (let ind = 0, max = matches.length; ind < max; ind++) {
				const name = matches[ind].replace(/:/ug, "");

				content = content.replace(new RegExp(matches[ind], "gu"), `<img src="/emoji/${name}.png" alt="${name}" />`);
			}
		}

		if (textareaEl.current) {
			textareaEl.current.value = "";
		}

		textareaEl?.current?.focus();

		// pridame
		const data = await addMessengerItem({
			name: state.user,
			description: content,
			date: new Date().toISOString(),
		});

		if (data.noErrors) {
			updateState({
				items: data.data,
			});
		}
	}

	function openItem() {
		const suggestWord = stateData.suggestItems[stateData.suggestInd];
		const rest = suggestWord.slice(stateData.suggestWord.length, suggestWord.length);

		if (textareaEl.current) {
			textareaEl.current.value = `${textareaEl.current.value}${rest}: `;
		}

		updateStateData({
			...INIT_SUGGEST_DATA,
		});

		textareaEl?.current?.focus();
	}

	function onChange() {
		const elem = textareaEl.current;

		if (elem) {
			setStateData(prev => {
				const valueLen = elem.value.length;
				const newData: ISuggestData = {
					...prev,
				};

				if (newData.useSuggest) {
					// pridavame nebo mazeme
					if (elem.selectionStart > newData.lastPos || elem.selectionStart === newData.lastPos - 1) {
						if (elem.selectionStart > newData.lastPos) {
							newData.suggestWord += elem.value[elem.selectionStart - 1];
						} else if (newData.suggestWord) {
							newData.suggestWord = newData.suggestWord.slice(0, newData.suggestWord.length - 1);
						} else {
							newData.useSuggest = false;
							newData.suggestWord = "";
							newData.lastPos = 0;
						}

						newData.lastPos = elem.selectionStart;
					// rusime to
					} else {
						newData.useSuggest = false;
						newData.suggestWord = "";
						newData.lastPos = 0;
					}
				} else if (!newData.useSuggest && valueLen && elem.value[elem.selectionStart - 1] === ":") {
					if (valueLen === 1 || (valueLen > 1 && elem.value[elem.selectionStart - PREV_PREV_INDEX] === " ")) {
						newData.useSuggest = true;
						newData.suggestWord = "";
						newData.lastPos = elem.selectionStart;
					}
				} else if (valueLen === 0) {
					newData.useSuggest = false;
					newData.suggestWord = "";
					newData.lastPos = 0;
				}

				newData.showSuggest = newData.suggestWord.length > 1;
				newData.suggestItems = newData.showSuggest ? EMOTIONS.filter(item => item.indexOf(newData.suggestWord) !== -1) : [];
				newData.suggestInd = newData.showSuggest ? 0 : -1;

				return newData;
			});
		}
	}

	function onKeyDown(event: KeyboardEvent) {
		if (stateData.useSuggest) {
			switch (event.key) {
				case KEYS.UP:
					event.preventDefault();
					updateStateData({
						suggestInd: Math.max(0, Math.min(stateData.suggestInd - 1, stateData.suggestItems.length - 1)),
					});
					break;
				case KEYS.DOWN:
					event.preventDefault();
					updateStateData({
						suggestInd: Math.max(0, Math.min(stateData.suggestInd + 1, stateData.suggestItems.length - 1)),
					});
					break;
				case KEYS.ESC:
					event.preventDefault();
					updateStateData({
						...INIT_SUGGEST_DATA,
					});
					break;
				case KEYS.ENTER: {
					event.preventDefault();
					openItem();
				}
					break;
				default:
			}
		}
	}

	function selectItem(ind: number) {
		updateStateData({
			suggestInd: ind,
		});
	}

	function createSuggestItem(name: string, ind: number) {
		const className = getClassName(["suggest__item", ind === stateData.suggestInd ? "active" : ""]);
		const imgSrc = `/emoji/${name}.png`;

		return <div key={name} className={className} onMouseEnter={() => selectItem(ind)} onClick={openItem}>
			<span className="image">
				<img src={imgSrc} alt={name} />
			</span>
			<span className="title">{name}</span>
		</div>;
	}

	function emojiClick() {
		setState(prev => ({
			...prev,
			showEmoji: !prev.showEmoji,
		}));
	}

	function addEmoji(item: string) {
		if (textareaEl.current) {
			textareaEl.current.value += `:${item}: `;
		}
	}

	function saveUserName() {
		setMessengerUser(state.user);
		addNotification("Přezdívka byla uložena");
	}

	async function deleteItem(id: number) {
		// pridame
		const data = await removeMessengerItem(id);

		if (data.noErrors) {
			updateState({
				items: data.data,
			});
		}
	}

	useEffect(() => {
		loadData();
	}, []);

	return <PageContent className="messengerPage" adminContent={true}>
		<HeaderTitle title="Messenger" />
		<h2 className="messengerPage__emojis" onClick={emojiClick}>Seznam emoji</h2>
		{ state.showEmoji
			? <ul className="messengerPage__emojisList">
				{EMOTIONS.map(item => <li key={item} className="messengerPage__emojisList__item" onClick={() => addEmoji(item)} title={item}>
					<img src={`/emoji/${item}.png`} alt={item} className="messengerPage__emojisList__itemImg" />
					<span className="messengerPage__emojisList__itemTitle">{item}</span>
				</li>)}
			</ul>
			: null
		}
		<div className="messengerPage__addSection">
			<textarea ref={textareaEl} className="messengerPage__textarea" onChange={onChange} onKeyDown={onKeyDown}></textarea>
			{ stateData.showSuggest
				? <div className="suggest">
					{ stateData.suggestItems.map((name, ind) => createSuggestItem(name, ind))}
				</div>
				: null
			}
			<div className="messengerPage__user-area">
				<div className="messengerPage__user-name">
					Uživatel: <input type="text" value={state.user} onChange={event => updateState({ user: event.target.value })} />
					<MyButton text="Uložit přezdívku" variant="filled" onClick={saveUserName} />
				</div>
				<MyButton text="Přidat zprávu" variant="filled" onClick={addItem} />
			</div>
		</div>
		{ state.loading && <Loader /> }
		<ul className="messengerPage__list">
			{ state.items.map(item => <li key={item.id} className="messengerPage__list__item">
				<div className="inner-content">
					<p dangerouslySetInnerHTML={{ __html: item.description }}></p>
					<div className="bottom">
						<div className="user">
							Uživatel: { item.name }
						</div>
						<MyButton text="Smazat" onClick={() => deleteItem(item.id)} className="removeUser" />
						<div className="date">
							{new Intl.DateTimeFormat('cs-CZ', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }).format(new Date(item.date))}
						</div>
					</div>
				</div>
			</li>)}
		</ul>
	</PageContent>;
}
