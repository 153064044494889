import { KeyboardEvent } from "react";

import PageContent from "~/components/PageContent";
import HeaderTitle from "~/components/HeaderTitle";
import { myUseState } from "~/hooks/myUseState";
import MyButton from "~/my/MyButton";
import { fulltextSearch, getAllTags, tagSearch } from "~/providers/events";
import { KEYS } from "~/const";
import SearchResultsTable from "~/components/SearchResultsTable";
import SearchPersonTable from "~/components/SearchPersonTable";
import Tags from "~/components/Tags";
import { TSeachType } from "~/interfaces";

import "./style.less";

interface IState {
	search: string;
	searchType: TSeachType;
	searchData: ReturnType<typeof fulltextSearch>;
	showTags: boolean;
}

export default function SearchPage() {
	const { state, updateState, toggleState } = myUseState<IState>({
		search: "",
		searchType: "title",
		searchData: null,
		showTags: false,
	});
	const allTags = getAllTags();

	function makeSearch() {
		const searchData = fulltextSearch({
			query: state.search,
			type: state.searchType,
		});

		updateState({
			search: "",
			searchData,
			showTags: false,
		});
	}

	function onKeyDown(event: KeyboardEvent) {
		if (event.key === KEYS.ENTER) {
			makeSearch();
		}
	}

	function selectTag(tagValue: string) {
		const searchData = tagSearch(tagValue);

		updateState({
			search: "",
			searchData,
			showTags: false,
		});
	}

	return <PageContent className="searchPage" loggedContent={true}>
		<HeaderTitle title="Hledání" />
		<div className="searchPage__searchArea">
			<input type="text" value={state.search} onKeyDown={onKeyDown} placeholder="hledaná fráze" onChange={event => updateState({ search: event.target.value })} />
			<select value={state.searchType} onChange={event => updateState({ searchType: event.target.value as TSeachType })} className="searchPage__select">
				<option value={"title" as TSeachType}>Titulky</option>
				<option value={"desc" as TSeachType}>Popisky</option>
				<option value={"gallery" as TSeachType}>Galerie</option>
				<option value={"persons" as TSeachType}>Osoby</option>
				<option value={"all" as TSeachType}>Všude</option>
			</select>
			<MyButton text="Tagy" onClick={() => toggleState("showTags")} variant="filled" />
			<MyButton text="Hledat" onClick={makeSearch} variant="filled" />
		</div>
		{ state.showTags && <div className="searchPage__tags">
			<Tags items={allTags} onClick={selectTag} />
		</div>}
		{ state.searchData && <>
			<HeaderTitle title="Výsledky hledání" className="searchPage__resultsTitle" />
			<p>
				Hledaná fráze <strong>{ state.searchData.query }</strong>{ state.searchData.results.length === 0 ? " - nebylo nic nalezeno" : ""}
			</p>
			{ state.searchData.results.length > 0 && <SearchResultsTable items={state.searchData.results} /> }
		</> }
		{ state.searchData?.persons.length > 0 && <>
			<HeaderTitle title="Výsledky hledání - jména" className="searchPage__resultsTitle" />
			<SearchPersonTable items={state.searchData.persons} />
		</> }
	</PageContent>;
}
