/* eslint-disable no-magic-numbers */
import dayjs from "dayjs";

import { IFullPersonItem } from "~/interfaces";
import { getGoogleCalendarEvents } from "./google-calendar";
import { DAYJS_FORMAT } from "~/const";
import { getPersons } from "./persons";

interface IBirthdateItem {
	person: string;
	age: number;
	type: "birthdate";
}

interface IHolidateItem {
	person: string;
	type: "holidate";
}

interface ICalendarItem {
	text: string;
	type: "calendar";
}

type TAllItems = IBirthdateItem | IHolidateItem | ICalendarItem;

interface IDateItem {
	date: number;
	month: number;
	diffMonth: boolean;
	active: boolean;
	past: boolean;
	items: Array<TAllItems>;
}

function insertToArray(sourceArray: Array<IDateItem>, dateData: Omit<IDateItem, "items">, insItem: TAllItems) {
	const filtered = sourceArray.filter(item => item.date === dateData.date);

	if (filtered.length === 1) {
		filtered[0].items.push(insItem);
	} else {
		sourceArray.push({
			...dateData,
			items: [insItem],
		});
	}
}

function getCustomEvents(activeDays: Array<IDateItem>, fromDate = "", toDate = "") {
	if (fromDate === "" || toDate === "") {
		return;
	}

	const fromDateObj = dayjs(fromDate);
	const toDateObj = dayjs(toDate);
	const fromDateUnix = fromDateObj.unix();
	const toDateUnix = toDateObj.unix();
	const diffMonth = fromDateObj && toDateObj && fromDateObj.get("month") !== toDateObj.get("month");
	const curDate = dayjs();

	// google kalendar
	const googleCalendarEvents = getGoogleCalendarEvents();

	googleCalendarEvents.forEach(calendarEvent => {
		const eventDate = dayjs(dayjs(calendarEvent.startDate).format(DAYJS_FORMAT));
		const eventDateUnix = eventDate.unix();

		if (eventDateUnix >= fromDateUnix && eventDateUnix <= toDateUnix) {
			insertToArray(activeDays, {
				date: eventDate.get("date"),
				month: eventDate.get("month"),
				active: curDate.get("date") === eventDate.get("date") && curDate.get("month") === eventDate.get("month"),
				past: curDate.get("month") === eventDate.get("month") && eventDate.get("date") < curDate.get("date"),
				diffMonth,
			}, {
				text: calendarEvent.summary,
				type: "calendar",
			});
		}
	});
}

function getMonthEvents({
	curMonth = dayjs().get("month"),
	fromDate = "",
	toDate = "",
	calendar = false,
}: {
	curMonth?: number;
	fromDate?: string;
	toDate?: string;
	calendar?: boolean;
}) {
	const items: Array<IDateItem> = [];
	const fromDateObj = fromDate === ""
		? null
		: dayjs(fromDate);
	const toDateObj = toDate === ""
		? null
		: dayjs(toDate);
	// lide
	const persons = getPersons();
	const diffMonth = fromDateObj && toDateObj && fromDateObj.get("month") !== toDateObj.get("month");
	const curDate = dayjs().set("month", curMonth);

	persons.forEach(person => {
		const birthdate = person.birthdate
			? dayjs(person.birthdate)
			: null;
		const holiday = person.holiday
			? dayjs(`${dayjs().get("year")}-${person.holiday}`)
			: null;

		if (birthdate) {
			if (curMonth === birthdate.get("month")) {
				const curYearBirthdayObj = birthdate.clone().set("year", dayjs().get("year"));
				const curYearBirthday = curYearBirthdayObj.unix();

				if ((!fromDateObj && !toDateObj) || (fromDateObj && toDateObj && curYearBirthday >= fromDateObj.unix() && curYearBirthday <= toDateObj.unix())) {
					const years = dayjs().get("years") - birthdate.get("years");

					insertToArray(items, {
						date: birthdate.get("date"),
						month: curMonth,
						active: curDate.get("date") === birthdate.get("date") && curMonth === birthdate.get("month"),
						past: curMonth === birthdate.get("month") && birthdate.get("date") < curDate.get("date"),
						diffMonth,
					}, {
						person: person.fullname,
						age: years,
						type: "birthdate",
					});
				}
			}
		}

		if (holiday) {
			if (curMonth === holiday.get("month")) {
				if ((!fromDateObj && !toDateObj) || (fromDateObj && toDateObj && holiday.unix() >= fromDateObj.unix() && holiday.unix() <= toDateObj.unix())) {
					insertToArray(items, {
						date: holiday.get("date"),
						month: curMonth,
						active: curDate.get("date") === holiday.get("date") && curMonth === holiday.get("month"),
						past: curMonth === holiday.get("month") && holiday.get("date") < curDate.get("date"),
						diffMonth,
					}, {
						person: person.fullname,
						type: "holidate",
					});
				}
			}
		}
	});

	calendar && getCustomEvents(items, fromDate, toDate);
	items.sort((aItem, bItem) => (aItem.date + (aItem.month * 100)) - (bItem.date + (bItem.month * 100)));

	return items;
}

function formatItems(items: Array<IDateItem>, diffDays = 0) {
	const output: Array<string> = [];

	items.forEach(item => {
		const curDate = dayjs().get("date");
		const fromDate = curDate - diffDays;
		const toDate = curDate + diffDays;

		if (diffDays === 0 || (item.date >= fromDate && item.date <= toDate)) {
			const allItems: Array<string> = [];

			item.items.forEach(subItem => {
				let itemText = "";

				switch (subItem.type) {
					case "birthdate":
						itemText = `${subItem.person} - nar. ${subItem.age}`;
						break;

					case "calendar":
						itemText = "";
						break;

					case "holidate":
						itemText = `${subItem.person} - svát.`;
						break;

					default:
						itemText = "";
				}

				if (itemText) {
					allItems.push(itemText);
				}
			});

			if (allItems.length) {
				output.push(`${item.date}. ${allItems.join(", ")}`);
			}
		}
	});

	return output;
}

export function getMonthsData({
	curMonth = dayjs().get("month"),
	diffDays = 3,
}: {
	curMonth: number;
	diffDays?: number;
}) {
	const prevMonth = curMonth - 1 < 0 ? 11 : curMonth - 1;
	const nextMonth = curMonth + 1 === 12 ? 0 : curMonth + 1;

	const prevData = getMonthEvents({
		curMonth: prevMonth,
	});
	const curData = getMonthEvents({
		curMonth,
	});
	const nextData = getMonthEvents({
		curMonth: nextMonth,
	});

	return {
		prev: formatItems(prevData),
		cur: formatItems(curData),
		next: formatItems(nextData),
		active: formatItems(curData, diffDays),
	};
}

export function getCurrentWeekData(monday: dayjs.Dayjs) {
	const curData = getMonthEvents({
		curMonth: dayjs().get("month"),
		fromDate: monday.format(DAYJS_FORMAT),
		toDate: monday.add(6, "days").format(DAYJS_FORMAT),
		calendar: true,
	});

	return curData;
}

export function searchNames(query: string) {
	const output: Array<IFullPersonItem> = [];
	const persons = getPersons();

	persons.forEach(item => {
		if (query && item.fullname.toLocaleLowerCase().indexOf(query.toLowerCase()) !== -1) {
			output.push({
				...item,
				years: item.birthdate
					? dayjs().diff(item.birthdate, "years")
					: 0,
			});
		}
	});

	return output;
}
