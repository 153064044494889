/* eslint-disable no-magic-numbers */
import { ReactNode, useEffect } from "react";
import { createPortal } from "react-dom";

import { ASSETS, setChristmasBackgound, setDefaultBackground } from "~/utils/assets";
import MyButton from "~/my/MyButton";
import { myUseState } from "~/hooks/myUseState";

import "./style.less";

interface IState {
	done: boolean;
	running: boolean;
	seconds: number;
}

// 3 * 60
const TIMEOUT = 180;

const PortalComponent = ({ children }: { children: ReactNode }) => {
	const portal = createPortal(
		<div className="christmas-content">
			{children}
		</div>,
		document.getElementById("portal") || document.body,
	);

	return portal;
};

function getTimeout() {
	const usp = new URLSearchParams(location.search);
	const time = usp.get("time");

	if (time) {
		return parseFloat(time);
	}

	return TIMEOUT;
}

export default function Christmas() {
	const { state, setState, updateState } = myUseState<IState>({
		done: false,
		running: false,
		seconds: 0,
	});

	function setTime() {
		updateState({
			done: false,
			running: true,
			seconds: getTimeout(),
		});
	}

	function formatTime(seconds: number) {
		const minutes = seconds > 60
			? seconds / 60 >>> 0
			: 0;
		const rest = seconds - (minutes * 60);

		return `${minutes}:${rest.toString().padStart(2, "0")}`;
	}

	function stopVideo() {
		setState({
			done: false,
			running: false,
			seconds: getTimeout(),
		});
	}

	// uprava pozadi
	useEffect(() => {
		setChristmasBackgound();

		return () => {
			setDefaultBackground();
		};
	}, []);

	useEffect(() => {
		if (!state.running) {
			return () => {};
		}

		const id = setInterval(() => {
			setState(prev => {
				const seconds = prev.seconds - 1;

				return {
					...prev,
					seconds,
					running: seconds > 0,
					done: seconds === 0,
				};
			});
		}, 1000);

		return () => {
			clearInterval(id);
		};
	}, [state.running]);

	return <PortalComponent>
		<MyButton text={<img src={ASSETS.Zvonecek} alt="" />} onClick={setTime} className="christmas__btn" />
		{ state.running && <div className="christmas__timeout">{ formatTime(state.seconds) }</div> }
		{ state.done && <>
			<MyButton text="Zastavit video" onClick={stopVideo} className="christmas__stopVideo" />
			<video src="/vanoce/zvonecek.mp4" autoPlay={true} loop={true} className="christmas__video" />
		</> }
	</PortalComponent>;
}
