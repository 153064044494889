import { myUseState } from "~/hooks/myUseState";
import { IGalleryItem } from "~/interfaces";
import MyGalleryItem from "~/my/MyGalleryItem";
import MyGalleryItemBar from "~/my/MyGalleryItemBar";
import MyGalleryTopLeftDesc from "~/my/MyGalleryTopLeftDesc";
import MyVideo from "~/my/MyVideo";
import { formatDuration, getClassName, getImagePersons } from "~/utils/utils";

interface IState {
	showBar: boolean;
	firstShow: boolean;
}

interface IYearGalleryVideoItem {
	seo: string;
	data: IGalleryItem;
	selectMode?: boolean;
	selected?: boolean;
	onClick?: () => void;
	onMapClick?: (data: IGalleryItem) => void;
}

export default function YearGalleryVideoItem({
	seo,
	data,
	selectMode = false,
	selected = false,
	onClick = () => {},
	onMapClick = () => {},
}: IYearGalleryVideoItem) {
	const { state, setState } = myUseState<IState>({
		showBar: true,
		firstShow: true,
	});

	function updateVisibility(isVisible: boolean) {
		setState(prev => ({
			...prev,
			showBar: isVisible,
			...prev.firstShow && isVisible
				? {
					firstShow: false,
				}
				: {},
		}));
	}

	function onPositionClick() {
		onMapClick(data);
	}

	return <MyGalleryItem seo={seo} isVideo={true} onClick={onClick} className={getClassName([selected ? "selected" : "", selectMode ? "select-mode" : ""]) }>
		<MyVideo
			src={data.src}
			cover={data.preview}
			onControlsChange={updateVisibility}
			position={state.firstShow ? "top" : "bottom"}
		/>
		<MyGalleryItemBar
			title={selectMode ? data.name : data.title}
			subtitle={data.subTitle || ""}
			duration={data.duration > 0 ? formatDuration(data.duration) : ""}
			disableMouse={true}
			hide={!state.showBar}
			position={state.firstShow ? "bottom" : "top"}
		/>
		{ state.firstShow && (data.coords || data.persons) && <MyGalleryTopLeftDesc coords={data.coords} onClick={onPositionClick} title={data.persons ? getImagePersons(data.persons) : ""} /> }
	</MyGalleryItem>;
}
