import { Dispatch, SetStateAction, useState } from "react";

export function myUseState<T>(initState: T | (() => T)): {
	state: T;
	setState: Dispatch<SetStateAction<T>>;
	updateState: (changes: Partial<T>) => void;
	toggleState: (name: keyof T) => void;
} {
	const [state, setState] = useState<T>(initState);

	function updateState(changes: Partial<T>): void {
		setState(prev => ({ ...prev, ...changes }));
	}

	function toggleState(name: keyof T) {
		setState(prev => {
			const newValue = prev[name] as boolean;

			return {
				...prev,
				[name]: !newValue,
			};
		});
	}

	return {
		state,
		setState,
		updateState,
		toggleState,
	};
}
