import { useEffect, useRef } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import { IGalleryItem, IGallery } from '~/interfaces';
import PageContent from "~/components/PageContent";
import { myUseState } from "~/hooks/myUseState";
import { getDataLink, getGalleryDetailLink, getPhotoByDirection, updateFullscreenGalleryItem, valueSplitByDem } from "~/utils/utils";
import { getGalleryBySeo } from "~/providers/events";
import YearGallery from "~/components/YearGallery";
import { useScrollToTop } from "~/hooks/useScrollToTop";
import Scroller from "~/components/Scroller";
import { globalStore } from "~/stores/global";
import MyButton from "~/my/MyButton";
import FullscreenGallery from '~/components/FullscreenGallery';
import { savedStore } from "~/stores/saved";
import MonthData from "~/components/MonthData";

import "./style.less";

interface IParams {
	folderSeo: string;
	nameSeo: string;
	detailImage: string;
	[key: string]: string;
}

interface IState {
	galleryWasSet: boolean;
	gallery: null | IGallery;
	galleryImg: null | IGalleryItem;
	firstLoad: boolean;
}

export default function GalleryPage() {
	const navigate = useNavigate();
	const showScroller = useScrollToTop();
	const topElem = useRef<HTMLDivElement>(null);
	const { folderSeo, nameSeo, detailImage } = useParams<IParams>();
	const folder = valueSplitByDem(folderSeo).leftPart;
	const seo = `${folder}-${nameSeo}`;
	const { state, updateState } = myUseState<IState>({
		galleryWasSet: false,
		gallery: null,
		galleryImg: null,
		firstLoad: true,
	});
	const { global } = globalStore(globalState => ({
		global: globalState.global,
	}));
	const { updateGalleryVisitCount } = savedStore(visitState => ({
		updateGalleryVisitCount: visitState.updateGalleryVisitCount,
	}));

	function backToGalleries() {
		const parts = seo.split("-");
		const year = parts.length > 0
			? parts[0]
			: dayjs().get("year").toString();

		navigate(`${getDataLink(year)}?seo=${seo}`);
	}

	function showPhoto(data: IGalleryItem) {
		updateState({
			galleryImg: updateFullscreenGalleryItem(data),
		});
		navigate(getGalleryDetailLink(folderSeo, nameSeo, data.name.replace(/[.].*$/u, "")), { replace: true });
	}

	function onClose() {
		updateState({
			galleryImg: null,
		});
		navigate(getGalleryDetailLink(folderSeo, nameSeo));
	}

	function openPhoto(direction: number) {
		const photo = getPhotoByDirection(state.galleryImg.name, state.gallery.items, direction);

		photo && showPhoto(photo);
	}

	function prevPhoto() {
		openPhoto(-1);
	}

	function nextPhoto() {
		openPhoto(1);
	}

	function onPageLoad() {
		const gallery = getGalleryBySeo(seo);

		updateState({
			gallery,
			galleryWasSet: true,
		});
		topElem.current?.scrollIntoView();

		if (gallery) {
			updateGalleryVisitCount(gallery);
		}
	}

	useEffect(() => {
		if (state.firstLoad) {
			updateState({
				firstLoad: false,
			});
		}
	}, [state.firstLoad]);

	// otevreni galerie
	useEffect(() => {
		if (state.gallery) {
			if (detailImage) {
				const photo = state.gallery.items.filter(item => item.name.replace(/[.].*$/u, "") === detailImage)[0];

				photo && showPhoto(photo);
			} else {
				updateState({
					galleryImg: null,
				});
			}
		}
	}, [detailImage, state.gallery]);

	return <PageContent className="galleryPage" disableScrollTop={!state.firstLoad} onLoad={onPageLoad} loggedContent={true} dependencies={[seo]} showMartinek={true}>
		{ state.gallery && <>
			<div className="galleryPage__top" ref={topElem} />
			<YearGallery gallery={state.gallery} onClick={item => showPhoto(item)} onBack={backToGalleries} downloadMode={true} showDebug={global.loginData?.isAdmin} />
			<div className="galleryPage__aboveScroller"></div>
			<Scroller visible={showScroller} className="galleryPage__sticky">
				{ state.gallery.event.monthData && <MonthData event={state.gallery.event} type="gallery" /> }
				<MyButton text="Galerie" onClick={backToGalleries} variant="filled" />
			</Scroller>
		</> }
		{ state.galleryWasSet && !state.gallery && <p>Nemáte právo k zobrazení galerie.</p> }
		{ state.galleryImg && <FullscreenGallery item={state.galleryImg} onClose={onClose} onPrev={prevPhoto} onNext={nextPhoto} />}
	</PageContent>;
}
