import { getClassName } from "~/utils/utils";
import { IEvent } from "~/interfaces";
import DescriptionDay from "../DescriptionDay";
import MonthData, { TMonthDataType } from "../MonthData";

import "./style.less";

interface IDescription {
	event: IEvent;
	className?: string;
	monthType?: TMonthDataType;
}

export default function Description({
	event,
	className = "",
	monthType,
}: IDescription) {
	const hasDays = "days" in event;
	const empty = event.desc.length === 0 && (event.days || []).length === 0 && !event.monthData;

	/* eslint-disable react/no-array-index-key */
	return <div className={getClassName(["description", className, empty ? "empty" : ""])}>
		{ !hasDays && event.desc.map((desc, ind) => <p className="description__oldItem" key={ind} dangerouslySetInnerHTML={{ __html: desc }} />)}
		{ hasDays && event.days.map((day, ind) => <DescriptionDay day={day} key={ind} />) }
		{ event.monthData && <MonthData event={event} type={monthType} /> }
	</div>;
}
