/* eslint-disable no-magic-numbers */
import { IGenerationItem, TCoords } from "./interfaces";

// pocet dnu
export const COOKIE_EXPIRES = 30;

export const API_KEY = "Mui5-okocHiz_Ls5rfHV6ptSYGt7-okYvTzhMzfXu98";

export const JWT_COOKIE = "jwt";

export const INIT_SUGGEST_DATA = {
	showSuggest: false,
	useSuggest: false,
	lastPos: 0,
	suggestWord: "",
	suggestItems: [],
	suggestInd: -1,
};

export const KEYS = {
	ESC: "Escape",
	LEFT: "ArrowLeft",
	RIGHT: "ArrowRight",
	UP: "ArrowUp",
	DOWN: "ArrowDown",
	ENTER: "Enter",
	NUM_ENTER: "NumpadEnter",
};

export const ROUTES = {
	OVERVIEW: `/prehled/:year`,
	MESSENGER: "/messenger",
	DANCE: "/tanec",
	SEARCH: "/hledani",
	GALLERY: "/galerie/:folderSeo/:nameSeo",
	GALLERY_DETAIL: "/galerie/:folderSeo/:nameSeo/:detailImage",
	LOGIN: "/prihlaseni",
	OTHERS: "/ostatni",
	CALENDAR: "/kalendar",
	DETAIL: "/detail/:folderSeo/:nameSeo",
	IN_THIS_DAY: "/v-tento-den",
	TRACKS: "/stopy/:year",
	WORLD_MAP: "/stiraci-mapa",
	UPLOAD: "/nahravani",
	UPLOADED_FILES: "/nahrane-soubory",
	VISITS: "/pristupy",
	SALARY: "/vyplaty",
	STORY_DETAIL: "/pribeh/:story",
	STORY: "/pribeh/",
	BIRTHDAYS: "/narozeniny",
	SNAKE: "/had",
	DATA_CONTENT: "/udaje/:category",
	DATABASE_ITEMS: "/veci/:category",
	PAYMENTS: "/poplatky",
	LENKA_2024: "/lenka-2024",
	IMAGE_TESTER: "/tester-obrazku",
	ROOT: "/",
};

/**
pobočka ostrava [18.26125, 49.81644]
peťka [17.02755, 48.85825]
holíč letecký den coords: [17.13945, 48.81787]
marecova buda coords: [17.01964, 48.86129]
kulík [17.02135, 48.86242]
koki [17.02552, 48.85711]
kalič [17.02687, 48.86282]
mor [17.84049, 49.21732]
jeseníky [16.86347, 50.07432]
brno pub [16.60889, 49.19629]
brno ubyt. mojmírovo n [16.5962, 49.22518]
 */
export const PLACES: Array<{ title: string; coords: TCoords; }> = [{
	title: "Oblá",
	coords: [16.55605, 49.17603],
}, {
	title: "Lond. n.",
	coords: [16.59882, 49.17177],
}, {
	title: "Nováčk.",
	coords: [16.6314, 49.21075],
}, {
	title: "Boja.",
	coords: [17.02271, 48.86071],
}, {
	title: "Bůda",
	coords: [17.01949, 48.86153],
}, {
	title: "Vinár.",
	coords: [17.02956, 48.85639],
}, {
	title: "Horní",
	coords: [17.02832, 48.8593],
}, {
	title: "Kačka",
	coords: [17.03108, 48.85778],
}, {
	title: "Příb.",
	coords: [18.13706, 49.62853],
}, {
	title: "Soboň.",
	coords: [17.21611, 48.89911],
}, {
	title: "Babička",
	coords: [17.03607, 48.90618],
}, {
	title: "Roseta",
	coords: [17.3469413, 48.8576738],
}, {
	title: "Lejča b.",
	coords: [17.16548, 48.86288],
}, {
	title: "Radlic.",
	coords: [14.40085, 50.07101],
}];

export const GALLERY_COUNT = 16;

export const PREV_PREV_INDEX = 2;

export const ANIM_DURATION = 10500;

export const DEFAULT_ZOOM = 17;
export const DEFAULT_MAP_POSITION = {
	x: 16.55605,
	y: 49.17603,
	z: DEFAULT_ZOOM,
};
export const MIN_ZOOM = 1;
export const MAX_ZOOM = 19;
export const BESTFIT_PADDING = { left: 60, right: 60, top: 80, bottom: 20 };

const API_BACKEND = "https://api.mapy.cz/";

export const MAPY_API_URL = `${API_BACKEND}v1`;
// url poiagregatoru
export const MAPY_POIAGG = `${API_BACKEND}poiagg`;
export const MAPY_API_ICON = `${API_BACKEND}poiimg/icon/`;
export const MAPY_ALTITUDE = `${API_BACKEND}altitude`;

export const DAYJS_FORMAT = "YYYY-MM-DD";
export const DAYJS_WITH_TIME_FORMAT = "YYYY-MM-DD H:mm";
export const DAYJS_WITH_TIME_FORMAT_FULL = "YYYY-MM-DD H:mm:ss";
export const DAYJS_NICE_FORMAT_DATE = "D. M. YYYY";
export const DAYJS_NICE_FORMAT_TIME = "D. M. YYYY H:mm";
export const ALL_OVERVIEW_DATA = {
	TITLE: "Vše",
	URL: "vse",
};
// cervena, modra, zelena, zluta, fialova, oranzova "#cc0011", "#0033ff", "#006400", "#ffff6b", "#f4adf9", "#fdb918", "#00fff5"];
export const TRACK_COLORS_BY_TYPE = {
	walk: "#cc0011",
	car: "#0033ff",
	bike: "#0033ff",
	boat: "#666",
	cable: "#666",
	pubt: "#006400",
};

export const DEFAULT_UPLOAD_PREVIEW_SIZE = 200;
export const MAX_UPLOADED_SIZE_MB = 512;
export const CHUNK_SIZE_MB = 64;
export const MAX_UPLOADED_SIZE = MAX_UPLOADED_SIZE_MB * Math.pow(1024, 2);
export const MAKUDEROVI_URL = "https://makuderovi.cz/";
export const WEB_DATA_URL = "/web-data/";

export const MONTHS = ["Leden", "Únor", "Březen", "Duben", "Květen", "Červen", "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"];

export const EMPTY_DATA_CONTENT_CATEGORY = "vse";
export const MAP_ACTIVE_SVG_FILL = "#7b2882";
export const SIMPLIFY_PRECISION = 0.0001;

export const GENERATIONS: Array<IGenerationItem> = [{
	title: "Nejlepší",
	fromYear: 1901,
	toYear: 1927,
}, {
	title: "Tichá",
	fromYear: 1928,
	toYear: 1945,
}, {
	title: "Boomer",
	fromYear: 1946,
	toYear: 1964,
}, {
	title: "X",
	fromYear: 1965,
	toYear: 1980,
}, {
	title: "Miléniálové",
	fromYear: 1981,
	toYear: 1996,
}, {
	title: "Z",
	fromYear: 1997,
	toYear: 2010,
}, {
	title: "Alfa",
	fromYear: 2011,
	toYear: 2024,
}];

export const PERMS = {
	RODINA: 1,
	DETI: 10,
	ADMIN: 100,
};

export const ROMAN_PERMS = {
	KAMOSI: 1,
	SEZNAM: 5,
	RODINA: 10,
	ADMIN: 100,
};

export const MARTINEK_BIRTHDATE = "2021-12-11";
export const TOMASEK_BIRTHDATE = "2023-07-28";
export const YEAR_DAYS = 365;
