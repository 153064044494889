import { ChangeEvent, KeyboardEvent } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { globalStore } from "~/stores/global";
import { login } from "~/providers/php-api";
import { myUseState } from "~/hooks/myUseState";
import { setUserData } from "~/providers/user";
import { isKeyEnter } from "~/utils/utils";

import "./style.less";

interface IState {
	passwordValue: string;
	errorInfo: string;
	isPasswordMode: boolean;
}

interface IPassword {
	onLogin?: () => void;
}

export default function Password({
	onLogin = () => {},
}: IPassword) {
	const { state, setState } = myUseState<IState>({
		passwordValue: "",
		errorInfo: "",
		isPasswordMode: true,
	});
	const { loginData, setLogAuthLoading } = globalStore(globalState => ({
		loginData: globalState.global.loginData,
		setLogAuthLoading: globalState.setLogAuthLoading,
	}));

	function setPasswordValue(event: ChangeEvent<HTMLInputElement>) {
		const value = event.target.value;

		setState(prev => ({
			...prev,
			passwordValue: value,
		}));
	}

	async function setPasswordClick() {
		setLogAuthLoading(true);

		const data = await login(state.passwordValue);

		if (!data.error && data.data) {
			await setUserData(data.data);
			setState(prev => ({
				...prev,
				errorInfo: "",
			}));
			onLogin();
		} else {
			setLogAuthLoading(false);
			setState(prev => ({
				...prev,
				errorInfo: data.error,
			}));
		}
	}

	function onKeyDown(event: KeyboardEvent) {
		if (isKeyEnter(event)) {
			setPasswordClick();
		}
	}

	function togglePassVisibility() {
		setState(prev => ({
			...prev,
			isPasswordMode: !prev.isPasswordMode,
		}));
	}

	return <div className="password">
		{ !loginData && <>
			<span className="password__info">Přihlásit se:</span>
			<button type="button" onClick={togglePassVisibility} className="password__visibilityButton">
				<VisibilityIcon sx={{ fill: state.isPasswordMode ? "red" : "green" }} />
			</button>
			<input type={state.isPasswordMode ? "password" : "text"} value={state.passwordValue} onChange={setPasswordValue} onKeyDown={onKeyDown} className="password__passwordInput" />
			<button type="button" onClick={setPasswordClick} className="password__passwordButton">Přihlásit</button>
		</> }
		{ state.errorInfo.length > 0 && <p className="password__errorInfo">
			{ state.errorInfo }
		</p> }
	</div>;
}
