import { KeyboardEvent } from "react";

import MyButton from "~/my/MyButton";
import { TCoords } from "~/interfaces";
import { myUseState } from "~/hooks/myUseState";
import { search } from "~/providers/search";
import { isKeyEnter } from "~/utils/utils";

import "./style.less";

interface IItem {
	id: number;
	title: string;
	subtitle: string;
	coords: TCoords;
}

interface IState {
	query: string;
	items: Array<IItem>;
	loading: boolean;
}

interface IMapSearchApi {
	onCoords: (coords: TCoords) => void;
}

export default function MapSearchApi({
	onCoords = () => {},
}: IMapSearchApi) {
	const { state, updateState } = myUseState<IState>({
		query: "",
		items: [],
		loading: false,
	});

	async function makeSearch() {
		const searchQuery = state.query.trim();

		if (searchQuery.length) {
			updateState({
				loading: true,
				items: [],
			});

			const data = await search(searchQuery);

			updateState({
				items: data.items.map((item, ind) => ({
					id: ind,
					title: item.name,
					subtitle: item.location,
					coords: [item.position.lon, item.position.lat],
				})),
				loading: false,
			});
		} else {
			updateState({
				items: [],
			});
		}
	}

	function onKeyDown(event: KeyboardEvent) {
		if (isKeyEnter(event)) {
			makeSearch();
		}
	}

	return <div className="searchApi map__dialog">
		<div className="searchApi__controls">
			<input type="text" value={state.query} onChange={event => updateState({ query: event.target.value })} placeholder="Hledaná fráze" onKeyDown={onKeyDown} />
			<MyButton text="Hledat" onClick={makeSearch} />
		</div>
		{ state.loading && <div className="searchApi__loading">načítám...</div> }
		{ state.items.length > 0 && <div className="searchApi__results">
			{ state.items.map(item => <div className="searchApi__resultsItem" key={item.id} onClick={() => onCoords(item.coords)}>
				<div className="searchApi__resultsItemTitle">
					{ item.title }
				</div>
				<div className="searchApi__resultsItemSubtitle">
					{ item.subtitle }
				</div>
			</div>)}
		</div> }
	</div>;
}
