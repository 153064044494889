import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

import { globalStore } from "~/stores/global";
import { ROUTES } from '~/const';
import { savedStore } from "~/stores/saved";
import { getClassName, getDataContentLink, getDatabaseItemsLink, getEventDetailLink, getTracksLink } from "~/utils/utils";
import { clearEventsData, compareGalleryVisits, getGalleryFolders, getGetWorldMapDiff } from "~/providers/events";
import MenuItem from "../MenuItem";
import { ICommonDataDiff, TService } from "~/interfaces";
import { myUseState } from "~/hooks/myUseState";
import { setUserData } from "~/providers/user";

import "./style.less";

interface IState {
	showMenu: boolean;
}

interface IMenu {
	mobileVersion: boolean;
	service?: TService;
	onClick?: () => void;
}

export default function Menu({
	mobileVersion = false,
	service = "makuderovi",
	onClick = () => {},
}: IMenu) {
	const navigate = useNavigate();
	const { savedData } = savedStore(visitState => ({
		savedData: visitState.saved,
	}));
	const { global } = globalStore(globalState => ({
		global: globalState.global,
	}));
	const { state, toggleState, updateState } = myUseState<IState>({
		showMenu: false,
	});
	const years = getGalleryFolders();
	const overviewLink = years.length ? years[0].url : "";
	const noNews = compareGalleryVisits(savedData.service === "roman" ? savedData.romanVisit : savedData.makuderoviVisit);
	const worldMapNews = getGetWorldMapDiff();
	const tracksLink = getTracksLink("2024");

	function getWorldMapNewsCaption(data: ICommonDataDiff) {
		if (data.change) {
			const statesChange = data.visitedStates.length > 0;
			const districtsChange = data.visitedDistricts.length > 0;

			if (statesChange && districtsChange) {
				return "Nové státy a okresy";
			} else if (statesChange) {
				return "Nové státy";
			}

			return "Nové okresy";
		}

		return "";
	}

	function getNewsItem(caption: string) {
		return caption
			? <>
				<span className="menu__listItemSeparator"></span>
				<span className="menu__listNewItems">{ caption }</span>
			</>
			: null;
	}

	async function logout() {
		await setUserData();
		clearEventsData();
		navigate(ROUTES.LOGIN);
		onClick();
	}

	function sendOnClick() {
		updateState({
			showMenu: false,
		});
		onClick();
	}

	const restContent = <>
		{ (global.loginData && service === "makuderovi") && <>
			<MenuItem link={ROUTES.WORLD_MAP} title="Stírací mapa" onClick={sendOnClick}>
				{ getNewsItem(getWorldMapNewsCaption(worldMapNews)) }
			</MenuItem>
			<MenuItem link={tracksLink} title="Seznam stop" onClick={sendOnClick} activeLink={tracksLink} />
		</> }
		{ global.loginData && <>
			<MenuItem link={ROUTES.SEARCH} title="Hledání" onClick={sendOnClick} />
		</> }
		{ (global.loginData && service === "makuderovi") && <>
			<MenuItem link={ROUTES.UPLOAD} title="Nahrávání" onClick={sendOnClick} />
			{ global.loginData.isAdmin && <>
				<MenuItem link={getDataContentLink()} title="Údaje" onClick={sendOnClick} />
				<MenuItem link={ROUTES.MESSENGER} title="Messenger" onClick={sendOnClick} />
				<MenuItem link={getDatabaseItemsLink()} title="Věci" onClick={sendOnClick} />
				<MenuItem link={ROUTES.OTHERS} title="Ostatní" onClick={sendOnClick} />
			</> }
		</> }
		{ global.loginData && <a href="#" onClick={logout}>
			<li className="menu__list__item">
				<span className="menu__listItemTitle">Odhlásit</span>
			</li>
		</a> }
		{ !global.loginData && <>
			<MenuItem link={ROUTES.LOGIN} title="Přihlásit" onClick={sendOnClick} />
		</> }
	</>;

	useEffect(() => {
		/* eslint-disable no-magic-numbers */
		if (!state.showMenu) {
			return () => {};
		}

		function docOnClick() {
			updateState({
				showMenu: false,
			});
		}

		setTimeout(() => {
			document.addEventListener("click", docOnClick);
		}, 100);

		return () => {
			document.removeEventListener("click", docOnClick);
		};
	}, [state.showMenu]);

	return <div className={getClassName(["menu", service === "roman" ? "menu-roman" : ""])}>
		<span style={{ display: "flex", width: "64px" }}></span>
		<ul className="menu__list">
			<MenuItem link={ROUTES.ROOT} title="Hlavní stránka" onClick={sendOnClick} />
			{ global.loginData && <>
				<MenuItem link={overviewLink} title="Galerie" onClick={sendOnClick} activeLink={overviewLink}>
					{ getNewsItem(noNews ? "" : "Nový obsah") }
				</MenuItem>
				<MenuItem link={ROUTES.CALENDAR} title="Kalendář" onClick={sendOnClick} activeLink={getEventDetailLink("")} />
				<MenuItem link={ROUTES.IN_THIS_DAY} title="V tento den" onClick={sendOnClick} />
			</> }
			{ mobileVersion && restContent }
		</ul>
		{ mobileVersion && <span></span> }
		{ !mobileVersion && <Button className="menu__restHamburgerBtn" onClick={() => toggleState("showMenu")}>
			<MenuIcon sx={{ fill: "#000" }} />
		</Button> }
		{ (!mobileVersion && state.showMenu) && <div className="menu__rest">
			{ restContent }
		</div> }
	</div>;
}
