import { myUseState } from "~/hooks/myUseState";
import MyButton from "~/my/MyButton";
import CloseBtn from "../CloseBtn";

import "./style.less";

interface IState {
	geometry: string;
}

interface ISetTracksDialog {
	onSave?: (geometry: string) => void;
	onClose?: () => void;
}

export default function SetTracksDialog({
	onSave = () => {},
	onClose = () => {},
}: ISetTracksDialog) {
	const { state, updateState } = myUseState<IState>({
		geometry: "",
	});

	function createTrack() {
		onSave(state.geometry);
	}

	return <div className="setTracksDialog map__dialog">
		<CloseBtn className="setTracksDialog__btnClose" onClick={onClose} />
		<div className="setTracksDialog__textarea">
			<textarea value={state.geometry} onChange={event => updateState({ geometry: event.target.value })} placeholder="Mirek string"></textarea>
		</div>
		<div className="setTracksDialog__btns">
			<MyButton text="Přidat stopu" onClick={createTrack} disabled={state.geometry.trim() === ""} variant="filled" />
		</div>
	</div>;
}
