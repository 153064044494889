import { DAYJS_FORMAT } from "~/const";
import { getCalendar } from "./php-api";
import { IGoogleCalendarItem } from "~/interfaces";
import { getCurrentMonday } from "~/utils/utils";

let googleCalendarEvents: Array<IGoogleCalendarItem> = [];

export async function loadCalendar(isAdmin: boolean) {
	if (isAdmin) {
		const monday = getCurrentMonday();
		const data = await getCalendar(monday.format(DAYJS_FORMAT));

		if (data.data) {
			googleCalendarEvents = data.data;
		}
	}

	return Promise.resolve({});
}

export function getGoogleCalendarEvents() {
	return googleCalendarEvents;
}
