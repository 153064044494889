import { useRef } from "react";

import { useImageLoad } from "~/hooks/useImageLoad";
import { ASSETS } from "~/utils/assets";
import { getClassName } from "~/utils/utils";

interface ILazyImage {
	src: string;
	alt: string;
	className?: string;
	xPos?: number;
}

export default function LazyImage({
	src,
	alt,
	className = "",
	xPos = undefined,
}: ILazyImage) {
	const imgRef = useRef<HTMLImageElement>(null);
	const hasXCor = typeof xPos === "number";
	const centerX = 50;

	useImageLoad(imgRef);

	return <img
		src={ASSETS.PlaceholderImg}
		data-src={src}
		data-load="false"
		alt={alt}
		className={getClassName([className, hasXCor ? "img-xcor" : ""])}
		style={{ ...hasXCor ? { transform: `translateX(${-centerX - xPos}%)` } : {} }}
		ref={imgRef}
	/>;
}
