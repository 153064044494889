import { notificationsStore } from "~/stores/notifications";
import NotificationItem from "../NotificationItem";

import "./style.less";

export default function Notifications() {
	const { notifications } = notificationsStore(storeState => ({
		notifications: storeState.notifications,
	}));

	return <div className="notifications" data-len={notifications.active.length}>
		<div className="notifications__list">
			{ notifications.active.map(item => <NotificationItem key={item.id} data={item} />)}
		</div>
	</div>;
}
