/* eslint-disable no-magic-numbers */
import { useEffect } from "react";
import dayjs from "dayjs";

import PageContent from "~/components/PageContent";
import MyCheckbox from "~/my/MyCheckbox";
import HeaderTitle from "~/components/HeaderTitle";
import { myUseState } from "~/hooks/myUseState";
import MyInputNumber from "~/my/MyInputNumber";
import { SEZNAM, SEZNAM_BONUS } from "~/data/salary";
import MyButton from "~/my/MyButton";
import { getSalary, getSallaryAllTaxPerc, updateSalaryDataByQuarters } from "~/utils/salary";
import { calcPriceBeforeDiscount, formatNumberToThousands } from "~/utils/utils";
import { getJSON } from "~/providers/php-api";
import { ISalary, ISalaryMonth, IYearsSalaryItem } from "~/interfaces";
import YearsSalaryTable from "~/components/YearsSalaryTable";

import "./style.less";

type TPerson = "roman" | "lenka";

interface IState {
	salary: number;
	incSallary: number;
	childrenCount: number;
	byod: boolean;
	emergency: number;
	premium: boolean;
	premiumPerc: string;
	salaryData: ReturnType<typeof getSalary>;
	compSalaryData: ReturnType<typeof getSalary>;
	selSallary: Array<IYearsSalaryItem>;
	simulSallary: Array<IYearsSalaryItem>;
	person: TPerson;
	romanSallary: ISalary;
	lenkaSallary: ISalary;
}

interface IStateDiscount {
	discPrice: number;
	discount: number;
	calcPrice: number;
}

interface ISettingsItem extends Pick<IState, "salary" | "childrenCount" | "emergency" | "byod" | "selSallary" | "person" | "simulSallary"> {}

export default function SalaryPage() {
	const { state, updateState, setState } = myUseState<IState>({
		salary: 0,
		incSallary: 0,
		childrenCount: 0,
		byod: true,
		emergency: 0,
		premium: false,
		premiumPerc: SEZNAM.premiumRatio.toString(),
		salaryData: null,
		compSalaryData: null,
		selSallary: [],
		simulSallary: [],
		person: "roman",
		romanSallary: null,
		lenkaSallary: null,
	});
	const { state: stateDisc, updateState: updateStateDisc } = myUseState<IStateDiscount>({
		discPrice: 1000,
		discount: 20,
		calcPrice: calcPriceBeforeDiscount(1000, 20),
	});

	function getLastItem(salary: ISalary): ISalaryMonth {
		const years = Object.keys(salary).map(item => parseFloat(item));

		years.sort((aItem, bItem) => aItem - bItem);

		const lastYear = years[years.length - 1];
		const months = Object.keys(salary[lastYear]);

		months.sort((aItem, bItem) => parseFloat(aItem) - parseFloat(bItem));

		const lastMonth = months[months.length - 1];

		return salary[lastYear][lastMonth];
	}

	function setPersonData(stateData: IState, person: TPerson): ISettingsItem {
		const sourceData = person === "roman"
			? stateData.romanSallary
			: stateData.lenkaSallary;
		const lastData = getLastItem(sourceData);

		return {
			...lastData,
			byod: "byod" in lastData,
			childrenCount: lastData.children || 0,
			emergency: lastData.emergency || 0,
			salary: lastData.base,
			selSallary: updateSalaryDataByQuarters({
				dataSalary: sourceData,
				reverseItems: true,
				useQuarters: true,
			}),
			person,
			simulSallary: [],
		};
	}

	async function loadData() {
		const [salaryRomanData, salaryLenkaData] = await Promise.all([
			getJSON("salaryroman"),
			getJSON("salarylenka"),
		]);
		const romanSallary = salaryRomanData.data;
		const lenkaSallary = salaryLenkaData.data;

		updateState({
			romanSallary,
			lenkaSallary,
			...setPersonData({
				...state,
				romanSallary,
				lenkaSallary,
			}, "roman"),
		});
	}

	function calcSalary(customState: IState = state) {
		// stravenky def
		let nettoBonus = SEZNAM_BONUS.food;

		if (customState.byod) {
			nettoBonus += SEZNAM_BONUS.byod;
		}

		const premiumPerc = parseFloat(state.premiumPerc);
		const salaryData = getSalary({
			salary: customState.salary,
			bonus: customState.emergency + (customState.premium ? customState.salary * premiumPerc : 0),
			children: customState.childrenCount,
			nettoBonus,
			taxPayer: true,
			debug: true,
		});
		let compSalaryData = null;

		if (customState.incSallary > 0) {
			const newSallary = customState.salary + customState.incSallary;

			compSalaryData = getSalary({
				salary: newSallary,
				bonus: customState.emergency + (customState.premium ? newSallary * premiumPerc : 0),
				children: customState.childrenCount,
				nettoBonus,
				taxPayer: true,
				debug: true,
			});
		}

		updateState({
			salaryData,
			compSalaryData,
		});
	}

	function setPerson(person: TPerson) {
		const newState: IState = {
			...state,
			emergency: 0,
			byod: false,
			childrenCount: 0,
			incSallary: 0,
			...setPersonData(state, person),
		};

		setState(newState);
		calcSalary(newState);
	}

	function calcDiscount() {
		updateStateDisc({
			calcPrice: calcPriceBeforeDiscount(stateDisc.discPrice, stateDisc.discount),
		});
	}

	function getSalaryMonth({ withInc = false, isPremium = false }: { withInc?: boolean; isPremium?: boolean; }) {
		const base = state.salary + (withInc ? state.incSallary : 0);
		const premium = base * parseFloat(state.premiumPerc);
		const oneMonth: ISalaryMonth = {
			base,
			byod: state.byod ? SEZNAM_BONUS.byod : 0,
			children: state.childrenCount || 0,
			emergency: state.emergency || 0,
			...isPremium
				? {
					premium,
				}
				: {},
		};

		return oneMonth;
	}

	function simulateSalary() {
		const selSallary: ISalary = {
			[dayjs().get("year") + 1]: {
				1: getSalaryMonth({}),
				2: getSalaryMonth({}),
				3: getSalaryMonth({ isPremium: true }),
				...state.incSallary > 0
					? {
						4: getSalaryMonth({ withInc: true }),
						5: getSalaryMonth({ withInc: true }),
						6: getSalaryMonth({ withInc: true, isPremium: true }),
					}
					: {},
			},
		};

		const simulSallary = updateSalaryDataByQuarters({
			dataSalary: selSallary,
			reverseItems: true,
			useQuarters: true,
		});

		updateState({
			simulSallary,
		});
	}

	useEffect(() => {
		loadData();
	}, []);

	return <PageContent className="salaryPage" adminContent={true}>
		<HeaderTitle title="Výpočet mzdy" />
		<div className="salaryPage__salaryLine">
			<div>
				Hrubá mzda:
			</div>
			<MyInputNumber value={state.salary} onChange={salary => updateState({ salary })} />
			<div>
				Přidání:
			</div>
			<MyInputNumber value={state.incSallary} onChange={incSallary => updateState({ incSallary })} />
			<div>
				Počet dětí:
			</div>
			<MyInputNumber value={state.childrenCount} onChange={childrenCount => updateState({ childrenCount })} />
			<div>
				Pohotovost:
			</div>
			<MyInputNumber value={state.emergency} onChange={emergency => updateState({ emergency })} />
			<div>
				Prémie [%]:
			</div>
			<div className="myInputNumber">
				<input type="text" value={state.premiumPerc.toString()} onChange={event => updateState({ premiumPerc: event.target.value })} />
			</div>
		</div>
		<div className="salaryPage__salaryBtnsLine">
			<span>
				<MyCheckbox value={state.byod} text={`BYOD (${SEZNAM_BONUS.byod} Kč)`} onChange={byod => updateState({ byod })} />
				<MyCheckbox value={state.premium} text="Prémie" onChange={premium => updateState({ premium })} />
			</span>
			<span>
				<MyButton text="Simulace mzdy" onClick={simulateSalary} variant="filled" />
				<MyButton text="Roman" onClick={() => setPerson("roman")} variant="filled" />
				<MyButton text="Lenka" onClick={() => setPerson("lenka")} variant="filled" />
				<MyButton text="Spočítat" onClick={calcSalary} variant="filled" />
			</span>
		</div>
		{ state.salaryData && <>
			<p>
				Čistá mzda: <strong>{ formatNumberToThousands(state.salaryData.netto) }</strong> Kč
				{ state.compSalaryData && <span className="margin-left">
					&lt;-&gt; <strong>{ formatNumberToThousands(state.compSalaryData.netto) }</strong> Kč (+ <strong>{formatNumberToThousands(state.compSalaryData.netto - state.salaryData.netto)}</strong> Kč)
				</span> }
			</p>
			<p className="salaryPage__salaryDesc">
				Celková hrubá mzda: <strong>{ formatNumberToThousands(state.salaryData.salary) }</strong> Kč
				{ state.compSalaryData && <span className="margin-left">
				&lt;-&gt; <strong>{ state.compSalaryData.salary }</strong> Kč (+ <strong>{formatNumberToThousands(state.compSalaryData.salary - state.salaryData.salary)}</strong> Kč)
				</span> }<br />
				Prémie: +<strong>{ formatNumberToThousands(state.salaryData.data.bonus) }</strong> Kč<br />
				Daně: -<strong>{ formatNumberToThousands(state.salaryData.data.allTaxes) }</strong> Kč
				{ state.compSalaryData && <span className="margin-left">
					&lt;-&gt; <strong>{ formatNumberToThousands(state.compSalaryData.data.allTaxes) }</strong> Kč (+ <strong>{formatNumberToThousands(state.compSalaryData.data.allTaxes - state.salaryData.data.allTaxes)}</strong> Kč)
				</span> }<br />
				Hrubá po zdanění: <strong>{ formatNumberToThousands(state.salaryData.data.beforeDiscount) }</strong> Kč
				{ state.compSalaryData && <span className="margin-left">
					&lt;-&gt; <strong>{ formatNumberToThousands(state.compSalaryData.data.beforeDiscount) }</strong> Kč (+ <strong>{formatNumberToThousands(state.compSalaryData.data.beforeDiscount - state.salaryData.data.beforeDiscount)}</strong> Kč)
				</span> }<br />
				Příspěvky seznamu: +<strong>{ formatNumberToThousands(state.salaryData.data.nettoBonus) }</strong> Kč<br />
				Sleva poplatníka: +<strong>{ formatNumberToThousands(state.salaryData.data.taxPayerDiscount) }</strong> Kč<br />
				Příspěvky za děti: +<strong>{ formatNumberToThousands(state.salaryData.data.childrenDiscount) }</strong> Kč<br />
			</p>
		</> }
		{ state.selSallary.length > 0 && <>
			<HeaderTitle title={`Výplaty - ${state.person === "roman" ? "Roman" : "Lenka"}`} className="salaryPage__title" />
			<YearsSalaryTable data={[...state.simulSallary, ...state.selSallary]} />
		</> }
		<HeaderTitle title="Spočítání slevy" className="salaryPage__title" />
		<div className="salaryPage__discountPrice">
			<div className="salaryPage__discountPrice__line1">
				<span>Cena po slevě</span>
				<MyInputNumber value={stateDisc.discPrice} onChange={discPrice => updateStateDisc({ discPrice })} />
				<span>Sleva v %</span>
				<MyInputNumber value={stateDisc.discount} onChange={discount => updateStateDisc({ discount })} />
				<strong onClick={() => updateStateDisc({ discount: getSallaryAllTaxPerc() })}>Výplata {getSallaryAllTaxPerc()}%</strong>
				<span>Původní cena <strong>{ stateDisc.calcPrice.toFixed(2) }</strong> Kč</span>
			</div>
			<div className="salaryPage__discountPrice__line2">
				<MyButton text="Spočítat" onClick={calcDiscount} variant="filled" />
			</div>
		</div>
	</PageContent>;
}
