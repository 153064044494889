import { IDatabaseItem } from "../interfaces";

export const COMMON_ITEMS: Array<IDatabaseItem> = [
	{
		title: "Test",
		desc: "Test popisek",
		images: [{
			src: "01.jpg",
			large: "01-large.jpg",
		}],
	},
];
