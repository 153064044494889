import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LayersIcon from '@mui/icons-material/Layers';
import TextsmsIcon from '@mui/icons-material/Textsms';
import AddIcon from '@mui/icons-material/Add';
import AdbIcon from '@mui/icons-material/Adb';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import SearchIcon from '@mui/icons-material/Search';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { SxProps } from "@mui/material";

import MyButton from "~/my/MyButton";
import { MAP_ACTIVE_SVG_FILL } from "~/const";
import { getClassName } from "~/utils/utils";

import "./style.less";

export type TMapControlsLeftContent = "search" | "ophoto" | "days" | "captions" | "debug" |"add" | "gps" | "searchApi" | "retina";

interface IMapControlsLeft {
	search?: boolean;
	searchApi?: boolean;
	days?: boolean;
	captions?: boolean;
	debug?: boolean;
	add?: boolean;
	active?: Array<TMapControlsLeftContent>;
	query?: string;
	onClick?: (type: TMapControlsLeftContent, state: boolean) => void;
	onSearch?: (query: string) => void;
}

export default function MapControlsLeft({
	search = false,
	searchApi = false,
	days = false,
	captions = false,
	debug = false,
	add = false,
	active = [],
	query = "",
	onClick = () => {},
	onSearch = () => {},
}: IMapControlsLeft) {
	function isActive(type: TMapControlsLeftContent) {
		return active.includes(type);
	}

	function getIconFill(type: TMapControlsLeftContent): SxProps {
		return {
			fill: isActive(type) ? "#fff" : MAP_ACTIVE_SVG_FILL,
		};
	}

	function getBtnClassName(type: TMapControlsLeftContent, customName = "") {
		return getClassName(["mapContainer__btn", isActive(type) ? "active" : "", customName]);
	}

	function updateSearch(newQuery: string) {
		onSearch(newQuery);
	}

	function btnClick(type: TMapControlsLeftContent) {
		onClick(type, isActive(type));
	}

	return <div className="mapContainer__mapControlsLeft">
		{ search && <>
			<input className="mapContainer__searchInput" placeholder="hledat galerii" type="text" value={query} onChange={event => updateSearch(event.target.value)} />
		</> }
		<MyButton text={<LayersIcon sx={getIconFill("ophoto")} />} onClick={() => btnClick("ophoto")} className={getBtnClassName("ophoto")} title="Zobrazit leteckou vrstvu" />
		<MyButton text={<AddPhotoAlternateIcon sx={getIconFill("retina")} />} onClick={() => btnClick("retina")} className={getBtnClassName("retina")} title="Použít retinu" />
		<MyButton text={<MyLocationIcon sx={getIconFill("gps")} />} onClick={() => btnClick("gps")} className={getBtnClassName("gps")} title="Moje poloha" />
		<span className="mapContainer__placeholder" />
		{ days && <MyButton text={<CalendarTodayIcon sx={getIconFill("days")} />} onClick={() => btnClick("days")}
			className={getBtnClassName("days")} title="Přehled dnů" /> }
		{ captions && <MyButton text={<TextsmsIcon sx={getIconFill("captions")} />} onClick={() => btnClick("captions")}
			className={getBtnClassName("captions")} title="Datumy markerů" /> }
		{ debug && <MyButton text={<AdbIcon sx={getIconFill("debug")} />} onClick={() => btnClick("debug")} className={getBtnClassName("debug")} title="Debug" /> }
		{ searchApi && <MyButton text={<SearchIcon sx={getIconFill("searchApi")} />} onClick={() => btnClick("searchApi")}
			className={getBtnClassName("searchApi")} title="Hledání" /> }
		{ add && <MyButton text={<AddIcon sx={getIconFill("add")} />} onClick={() => btnClick("add")}
			className={getBtnClassName("add")} title="Přidat markery" /> }
	</div>;
}
