import { Fragment } from "react";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import MyButton from "~/my/MyButton";
import { IRouteMarker, TRouteItemType } from "~/map-interfaces";
import { TRouteTypes, TTrackType } from "~/interfaces";
import { formatDistance } from "~/utils/utils";

import "./style.less";

export type TMapDebugType = "clearRouteNotLast" | "clearAllRoute" | "clearAllTracks" | "debug" | "saveTrack";

interface IInfoItem {
	title: string;
	value: string;
}

interface IMapDebug {
	items: Array<IRouteMarker>;
	geomCount?: number;
	debugCount?: number;
	distance?: number;
	tracksCount?: number;
	type?: TRouteItemType;
	trackType?: TTrackType;
	routeType?: TRouteTypes;
	onClick?: (type: TMapDebugType) => void;
	onAdd?: (id: string) => void;
	onRemove?: (id: string) => void;
	onRouteType?: (type: TRouteTypes) => void;
	onGlobalTypeChange?: (type: TRouteItemType) => void;
	onTypeChange?: (type: TRouteItemType, id: string) => void;
	onTrackTypeChange?: (type: TTrackType) => void;
}

export default function MapDebug({
	geomCount = 0,
	debugCount = 0,
	distance = 0,
	tracksCount = 0,
	items = [],
	type = "route",
	trackType = "walk",
	routeType = "bike_mountain",
	onClick = () => {},
	onAdd = () => {},
	onRemove = () => {},
	onRouteType = () => {},
	onGlobalTypeChange = () => {},
	onTypeChange = () => {},
	onTrackTypeChange = () => {},
}: IMapDebug) {
	const infoItems: IInfoItem[] = [];

	if (debugCount > 0) {
		infoItems.push({
			title: "debug",
			value: debugCount.toString(),
		});
	}

	if (geomCount > 0) {
		infoItems.push({
			title: "gpoints",
			value: geomCount.toString(),
		});
	}

	if (distance > 0) {
		infoItems.push({
			title: "dist",
			value: formatDistance(distance),
		});
	}

	if (tracksCount > 0) {
		infoItems.push({
			title: "tracks",
			value: tracksCount.toString(),
		});
	}

	if (infoItems.length) {
		infoItems[0].title = `${infoItems[0].title[0].toLocaleUpperCase()}${infoItems[0].title.slice(1)}`;
	} else {
		infoItems.push({
			title: "Info",
			value: "prázdné",
		});
	}

	/* eslint-disable react/no-array-index-key */
	return <div className="mapContainer__debug map__dialog">
		<div className="mapContainer__infoLine">
			{ infoItems.map((item, ind) => <Fragment key={`title_${ind}`}>
				{ `${item.title}: ` }
				<strong>{ item.value }</strong>
				{ ind === infoItems.length -1 ? "" : ", " }
			</Fragment>)}
		</div>
		{ items.length > 0 && <div className="mapContainer__routeCoordsList">
			{ items.map((item, ind) => <div className="mapContainer__routeCoordsListItem" key={`route-coord-${ind}`}>
				<span className="mapContainer__routeCoordsListItemBtns">
					{ `${item.ind}`}
					<select value={item.type} onChange={event => onTypeChange(event.target.value as IRouteMarker["type"], item.id)} className="mapContainer__typeSelect">
						<option value="route">Route</option>
						<option value="user">User</option>
					</select>
				</span>
				<span className="mapContainer__routeCoordsListItemBtns">
					{ items.length - 1 !== ind && <MyButton text={<AddIcon />} onClick={() => onAdd(item.id)} variant="filled" /> }
					<MyButton text={<DeleteIcon />} onClick={() => onRemove(item.id)} variant="filled" />
				</span>
			</div>)}
		</div> }
		{ items.length === 0 && <span>Seznam je prázdný</span> }
		<div className="mapContainer__2contrsPerRow">
			<select value={routeType} onChange={event => onRouteType(event.target.value as TRouteTypes)} className="mapContainer__select">
				<option value={"foot_fast" as TRouteTypes}>Pěšky - rychle</option>
				<option value={"car_fast" as TRouteTypes}>Auto - rychle</option>
				<option value={"car_fast_traffic" as TRouteTypes}>Auto - trafic</option>
				<option value={"car_short" as TRouteTypes}>Auto - krátká</option>
				<option value={"bike_mountain" as TRouteTypes}>Kolo - horské</option>
				<option value={"bike_road" as TRouteTypes}>Kolo - silniční</option>
			</select>
			<select value={type} onChange={event => onGlobalTypeChange(event.target.value as TRouteItemType)} className="mapContainer__select">
				<option value={"route" as TRouteItemType}>Route</option>
				<option value={"user" as TRouteItemType}>User</option>
			</select>
		</div>
		<div className="mapContainer__2contrsPerRow">
			<select value={trackType} onChange={event => onTrackTypeChange(event.target.value as TTrackType)} className="mapContainer__select">
				<option value={"walk" as TTrackType}>Pěšky</option>
				<option value={"car" as TTrackType}>Auto</option>
				<option value={"bike" as TTrackType}>Kolo</option>
				<option value={"pubt" as TTrackType}>MHD</option>
				<option value={"boat" as TTrackType}>Loď</option>
				<option value={"cable" as TTrackType}>Lanovka</option>
			</select>
			{ items.length > 0 && <MyButton text="Uložit track" onClick={() => onClick("saveTrack")} variant="filled" /> }
		</div>
		{ items.length > 0 && <div className="mapContainer__2contrsPerRow">
			<MyButton text="Smazat KP" onClick={() => onClick("clearRouteNotLast")} variant="filled" />
			<MyButton text="Smazat VŠE" onClick={() => onClick("clearAllRoute")} variant="filled" />
		</div> }
		<div className="mapContainer__2contrsPerRow">
			<MyButton text="Smazat stopy" onClick={() => onClick("clearAllTracks")} variant="filled" />
			<MyButton text="Zkopírovat" onClick={() => onClick("debug")} variant="filled" />
		</div>
	</div>;
}
