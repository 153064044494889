import { ICalendarLegendItem } from "~/interfaces";
import { getClassName } from "~/utils/utils";

import "./style.less";

interface ICalendarLegend {
	items: Array<ICalendarLegendItem>;
	active?: null | ICalendarLegendItem;
	onClick?: (item: ICalendarLegendItem) => void;
}

export default function CalendarLegend({
	items,
	active = null,
	onClick = null,
}: ICalendarLegend) {
	function isItemClickAble(item: ICalendarLegendItem) {
		return item.isDefault || item.permissions || item.isPublic || item.isAdmin;
	}

	function itemOnClick(item: ICalendarLegendItem) {
		if (onClick && isItemClickAble(item)) {
			onClick(item);
		}
	}

	function getItemClassName(item: ICalendarLegendItem) {
		return getClassName(["calendarLegend__item", "calendar-event", item.className, isItemClickAble(item) ? "clickable" : "", active && active.title === item.title ? "active" : ""]);
	}

	return <div className="calendarLegend">
		{ items.map(item => <span className={getItemClassName(item)} key={item.title} onClick={() => itemOnClick(item)}>
			{ item.title }
		</span>)}
	</div>;
}
