/* eslint-disable no-magic-numbers */
/* eslint-disable no-await-in-loop */
import { ChangeEvent, DragEvent, useRef } from "react";
import { Autocomplete, TextField } from "@mui/material";
import * as ExifReader from 'exifreader';
import dayjs from "dayjs";

import { DAYJS_WITH_TIME_FORMAT_FULL, DEFAULT_MAP_POSITION } from "~/const";
import { getCover } from "~/utils/uploadCovers";
import PageContent from "~/components/PageContent";
import HeaderTitle from "~/components/HeaderTitle";
import { myUseState } from "~/hooks/myUseState";
import { getClassName, imageToArrayBuffer } from "~/utils/utils";
import ImageTester from "~/components/ImageTesterItem";
import MyButton from "~/my/MyButton";
import Map from "~/components/Map";
import { IMapItem } from "~/map-interfaces";
import { IGalleryItem, TCoords } from "~/interfaces";
import Loader from "~/components/Loader";
import MyCheckbox from "~/my/MyCheckbox";
import { useScrollToTop } from "~/hooks/useScrollToTop";
import Scroller from "~/components/Scroller";
import { getAllGalleries, getGalleryBySeo } from "~/providers/events";

import "./style.less";

interface IGallerySeoItem {
	label: string;
	seo: string;
}

interface IItem extends IMapItem {
	id: number;
	size: number;
	same: boolean;
	testBatch: boolean;
	hash: string;
	subTitle: string;
}

interface IState {
	isActive: boolean;
	items: Array<IItem>;
	showMap: boolean;
	useHash: boolean;
	loading: number;
	seo: string;
	galleries: Array<IGallerySeoItem>;
}

interface IAddItem {
	tags: ExifReader.Tags;
	hash: string;
	fileName: string;
	src: string;
	size: number;
	testBatch?: boolean;
}

interface ILoadBySeo {
	seo?: string;
	clear?: boolean;
}

interface IGlobalData {
	seo: string;
}

export default function ImageTesterPage() {
	const { state, updateState, setState, toggleState } = myUseState<IState>({
		isActive: false,
		items: [],
		showMap: false,
		useHash: false,
		loading: 0,
		seo: "2022-prochazka-marianske-udoli",
		galleries: [],
	});
	const showScroller = useScrollToTop();
	const globalData = useRef<IGlobalData>({
		seo: "2022-prochazka-marianske-udoli",
	});

	function getDateFromExif(value: string) {
		const parts = value.split(" ");
		const date = parts[0].replaceAll(":", "-");

		return dayjs(`${date} ${parts[1]}`);
	}

	function addItem({
		fileName,
		tags,
		hash = "",
		src = "",
		size = 0,
		testBatch = false,
	}: IAddItem) {
		const title = fileName;
		let subTitle = "";
		let date = "";
		let coords: TCoords | null = null;

		if ("DateTime" in tags) {
			subTitle = getDateFromExif(tags.DateTime.value[0]).format(DAYJS_WITH_TIME_FORMAT_FULL);
			date = subTitle;
		}

		if ("GPSLatitude" in tags && "GPSLongitude" in tags) {
			coords = [tags.GPSLongitude.description, tags.GPSLatitude.description];
		}

		if (coords && coords[0] === 0 && coords[1] === 0) {
			coords = null;
		}

		setState(prev => {
			const items = prev.items.slice();
			const datesObj: { [key: string]: Array<IItem>; } = {};
			const hashObj: { [key: string]: Array<IItem>; } = {};

			items.push({
				id: Math.random(),
				coords,
				date,
				src,
				title,
				subTitle,
				name: title,
				captionTitle: title,
				popupTitle: title,
				size,
				same: false,
				testBatch,
				hash,
			});
			items.sort((aItem, bItem) => {
				if (aItem.date && !bItem.date) {
					return -1;
				} else if (!aItem.date && bItem.date) {
					return 1;
				}

				return dayjs(aItem.date).unix() - dayjs(bItem.date).unix();
			});

			for (const item of items) {
				if (!(item.date in datesObj)) {
					datesObj[item.date] = [];
				}

				if (!(item.hash in hashObj)) {
					hashObj[item.hash] = [];
				}

				datesObj[item.date].push(item);
				hashObj[item.hash].push(item);
			}

			if (state.useHash) {
				const allHashes = Object.keys(hashObj);

				for (const hashKey of allHashes) {
					const imgs = hashObj[hashKey] as Array<IItem>;

					if (imgs.length > 1) {
						for (const img of imgs) {
							if (img.testBatch) {
								img.same = true;
							}
						}
					}
				}
			} else {
				const allDates = Object.keys(datesObj);

				for (const dateKey of allDates) {
					const imgs = datesObj[dateKey] as Array<IItem>;

					if (imgs.length > 1) {
						for (const img of imgs) {
							if (img.testBatch) {
								img.same = true;
							}
						}
					}
				}
			}

			return {
				...prev,
				loading: Math.max(prev.loading - 1, 0),
				items,
			};
		});
	}

	async function loadFile(file: File) {
		const [coverData, tags, hashData] = await Promise.all([
			getCover(file),
			ExifReader.load(file),
			state.useHash ? window.pHash.hash(file) : Promise.resolve({ toHex: () => "" }),
		]);

		let src = "";
		const hash = hashData.toHex();

		if (coverData.data) {
			src = coverData.data;
		}

		addItem({
			hash,
			src,
			tags,
			fileName: file.name.replace(/[.].*$/u, ""),
			size: file.size,
			testBatch: true,
		});
	}

	async function loadGalleryItem(image: IGalleryItem) {
		const [imgBuffer] = await Promise.all([
			imageToArrayBuffer(image.largeSrc),
		]);
		const tags = await ExifReader.load(imgBuffer);

		addItem({
			hash: "",
			src: image.largeSrc,
			tags,
			fileName: image.name,
			size: imgBuffer.byteLength,
		});
	}

	async function processFiles(files: Array<File>, curSeo: string) {
		const todoFiles = files.filter(file => (file.name.includes("IMG") || file.name.includes("-large")) && file.name.toLocaleLowerCase().indexOf("jpg") !== -1);

		if (todoFiles.length > 0) {
			updateState({
				loading: todoFiles.length,
			});

			for (const file of todoFiles) {
				await loadFile(file);

				if (curSeo !== globalData.current.seo) {
					break;
				}
			}
		}
	}

	async function processImages(items: Array<IGalleryItem>, curSeo: string) {
		if (items.length > 0) {
			updateState({
				loading: items.length,
			});

			for (const image of items) {
				await loadGalleryItem(image);

				if (curSeo !== globalData.current.seo) {
					break;
				}
			}
		}
	}

	function onChange(event: ChangeEvent<HTMLInputElement>) {
		processFiles(Array.from(event.target.files), state.seo);
		event.target.value = null;
	}

	function onDragOver(event: DragEvent) {
		event.stopPropagation();
		event.preventDefault();
	}

	function onDrop(event: DragEvent) {
		event.stopPropagation();
		event.preventDefault();
		processFiles(Array.from(event.dataTransfer.files), state.seo);
	}

	function clearAll() {
		updateState({
			items: [],
			showMap: false,
			useHash: false,
		});
	}

	function loadBySeo({
		seo = state.seo,
		clear = false,
	}: ILoadBySeo) {
		if (!seo) {
			return;
		}

		const gallery = getGalleryBySeo(seo);

		updateState({
			seo,
			...clear
				? {
					items: [],
					showMap: false,
					testBatch: false,
					useHash: false,
				}
				: {},
		});
		globalData.current.seo = seo;

		if (gallery) {
			processImages(gallery.items.filter(item => !item.isVideo), seo);
		}
	}

	function onAutoCompleteChange(event: any, newValue: IGallerySeoItem | null) {
		const seo = newValue ? newValue.seo : "";

		updateState({
			seo,
		});
		globalData.current.seo = seo;
	}

	function setNextSeo() {
		const seo = state.seo;

		if (seo) {
			const ind = state.galleries.findIndex(item => item.seo === seo);

			if (ind !== -1 && state.galleries[ind + 1]) {
				loadBySeo({
					seo: state.galleries[ind + 1].seo,
					clear: true,
				});
			}
		}
	}

	function onPageLoad() {
		const script = document.createElement("script");

		script.src = "https://cdn.jsdelivr.net/npm/phash-js/dist/phash.js";
		document.head.append(script);

		updateState({
			galleries: getAllGalleries().map(item => ({
				seo: item.event.seo,
				label: item.event.seo,
			})),
		});
	}

	return <PageContent className="uploadPage" adminContent={true} onLoad={onPageLoad}>
		<HeaderTitle title="Tester obrázků" />
		{ state.showMap && <Map position={DEFAULT_MAP_POSITION} items={state.items.filter(item => item.coords)} /> }
		<div className="imageTesterPage__buttons">
			<MyCheckbox text="Použít hash" value={state.useHash} onChange={useHash => updateState({ useHash })} />
			<MyButton text="Zobrazit mapu" onClick={() => toggleState("showMap")} variant="filled" />
			<MyButton text="Smazat vše" onClick={clearAll} variant="filled" />
			<Autocomplete
				disablePortal
				options={state.galleries}
				sx={{ width: 300 }}
				renderInput={params => <TextField {...params} label="Movie" />}
				value={state.seo}
				onChange={onAutoCompleteChange}
			/>
			<MyButton text="Další" onClick={setNextSeo} variant="filled" />
			<MyButton text="Načíst" onClick={() => loadBySeo({})} variant="filled" />
		</div>
		{ state.loading > 0 && <Loader /> }
		{ state.loading > 0 && <div className="imageTesterPage__loadingCount">
			Zbývá: { state.loading }
		</div> }
		<label className={getClassName(["uploadPage__dad", state.isActive ? "active" : ""])} onDrop={onDrop} onDragOver={onDragOver} onDragEnter={() => updateState({ isActive: true })}>
			Otevřít / Přetáhnout soubory
			<input type="file" multiple={true} accept="video/*,image/*" onChange={onChange} />
		</label>
		<div className="uploadPage__items">
			{ state.items.map(item => <ImageTester key={item.id} title={item.title} subTitle={item.subTitle} coords={item.coords ? `[${item.coords[0].toFixed(5)}, ${item.coords[1].toFixed(5)}]` : "-"} imgSrc={item.src} size={item.size}
				background={item.same ? "red" : item.testBatch ? "green" : "white"} />) }
		</div>
		<Scroller visible={showScroller} className="galleryPage__sticky" />
	</PageContent>;
}
