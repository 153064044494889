import { getClassName, getDayVideoSubtitle, getFullDesc } from "~/utils/utils";
import { IEventDayItem, IGallery, IGalleryItem } from "~/interfaces";
import DescriptionDay from "../DescriptionDay";
import MyGallery from "~/my/MyGallery";
import YearGalleryVideoItem from "../YearGalleryVideoItem";
import YearGalleryImageItem from "../YearGalleryImageItem";

import "./style.less";

interface IGalleryDay {
	day: IEventDayItem;
	gallery: IGallery;
	className?: string;
	downloadMode?: boolean;
	selectMode?: boolean;
	onClick?: (item: IGalleryItem) => void;
	onMapClick?: (item: IGalleryItem) => void;
	selected?: Array<string>;
}

export default function GalleryDay({
	day,
	gallery,
	className = "",
	downloadMode = false,
	selectMode = false,
	onClick = () => {},
	onMapClick = () => {},
	selected = [],
}: IGalleryDay) {
	function getVideoData(item: IGalleryItem): IGalleryItem {
		return {
			...item,
			subTitle: day.type === "day"
				? getDayVideoSubtitle(item.date || "")
				: getFullDesc(item.date || ""),
		};
	}

	return <div className={getClassName(["galleryDay", className])}>
		<DescriptionDay day={day} />
		{ day.items.length > 0 && <MyGallery>
			{day.items.map(item => item.isVideo
				? <YearGalleryVideoItem key={item.name} data={getVideoData(item)} seo={gallery.event.seo} onMapClick={onMapClick} />
				: <YearGalleryImageItem key={item.name} data={item} gallery={gallery} onClick={() => onClick(item)} onMapClick={onMapClick} seo={gallery.event.seo}
					downloadMode={downloadMode} selectMode={selectMode} selected={selected.indexOf(item.name) !== -1} showOnlyHours={day.type === "day"} />)}
		</MyGallery> }
	</div>;
}
