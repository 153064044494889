import { create } from 'zustand';

import { INotificationItem } from "~/interfaces";
import { removeFromArray } from "~/utils/utils";

interface INotificationsStore {
	notifications: {
		lastId: number;
		active: Array<INotificationItem>;
	};
	addItem: (text: INotificationItem["text"]) => INotificationItem;
	updateItem: (id: INotificationItem["id"], newState: INotificationItem["state"]) => void;
	removeItem: (id: INotificationItem["id"]) => void;
}

const TIMEOUTS = {
	disapearing: 1000,
	removed: 1000,
};

export const notificationsStore = create<INotificationsStore>((set, get) => ({
	notifications: {
		lastId: 0,
		active: [],
	},
	addItem: text => {
		set(state => ({
			notifications: {
				...state.notifications,
				lastId: state.notifications.lastId + 1,
				active: [
					...state.notifications.active,
					{
						id: state.notifications.lastId + 1,
						text,
						state: "active",
					},
				],
			},
		}));

		const activeItems = get().notifications.active;

		return activeItems[activeItems.length - 1];
	},
	updateItem: (id, newState) => set(state => {
		const active = state.notifications.active.slice();
		const filtered = active.filter(item => item.id === id);

		if (filtered.length === 1) {
			filtered[0].state = newState;
		}

		return {
			notifications: {
				...state.notifications,
				active,
			},
		};
	}),
	removeItem: id => set(state => ({
		notifications: {
			...state.notifications,
			active: removeFromArray(state.notifications.active, item => item.id === id),
		},
	})),
}));

export function addNotification(text: INotificationItem["text"]) {
	const storeState = notificationsStore.getState();
	const addedItem = storeState.addItem(text);

	setTimeout(() => {
		storeState.updateItem(addedItem.id, "disapearing");
	}, TIMEOUTS.disapearing);

	setTimeout(() => {
		storeState.removeItem(addedItem.id);
	}, TIMEOUTS.disapearing + TIMEOUTS.removed);
}

