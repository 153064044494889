import { TTrackType } from "~/interfaces";

/* eslint-disable no-magic-numbers */
export interface ITrackTagItem {
	title: string;
	range: null | [number, number];
}

const M2KM = 1000;

export const WALK_DISTANCES: Array<ITrackTagItem> = [{
	title: "Vše",
	range: null,
}, {
	title: "0 - 5 km",
	range: [0, 5 * M2KM],
}, {
	title: "5 - 10 km",
	range: [5 * M2KM, 10 * M2KM],
}, {
	title: "10 - 15 km",
	range: [10 * M2KM, 15 * M2KM],
}, {
	title: "15 - 20 km",
	range: [15 * M2KM, 20 * M2KM],
}, {
	title: "20 km+",
	range: [20 * M2KM, Infinity],
}];

export const CAR_DISTANCES: Array<ITrackTagItem> = [{
	title: "Vše",
	range: null,
}, {
	title: "0 - 25 km",
	range: [0, 25 * M2KM],
}, {
	title: "25 - 50 km",
	range: [25 * M2KM, 50 * M2KM],
}, {
	title: "50 - 100 km",
	range: [50 * M2KM, 100 * M2KM],
}, {
	title: "100 km+",
	range: [100 * M2KM, Infinity],
}];

export const TYPES: Array<{ title: string; type: TTrackType; }> = [{
	title: "Chůze",
	type: "walk",
}, {
	title: "Auto",
	type: "car",
}, {
	title: "Lanovka",
	type: "cable",
}, {
	title: "Loď",
	type: "boat",
}, {
	title: "Kolo",
	type: "bike",
}, {
	title: "MHD",
	type: "pubt",
}];
