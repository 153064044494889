import { getClassName } from "~/utils/utils";

import "./style.less";

interface ICloseBtn {
	onClick: () => void;
	className?: string;
}

export default function CloseBtn({
	onClick,
	className = "",
}: ICloseBtn) {
	return <button type="button" onClick={() => onClick()} className={getClassName(["closeBtn", className])}>
		<svg x="0px" y="0px" viewBox="0 0 24 24" className="svg-icon close">
			<path d="M3.6,0.1L0.1,3.6L8.5,12l-8.4,8.4l3.5,3.5l8.4-8.4l8.4,8.4l3.5-3.5L15.5,12l8.4-8.4l-3.5-3.5L12,8.5L3.6,0.1L3.6,0.1z"/>
		</svg>
	</button>;
}
