import dayjs from "dayjs";
import maplibregl from "maplibre-gl";
import { KeyboardEvent as KeyboardEventReact } from "react";

import { ALL_OVERVIEW_DATA, DAYJS_FORMAT, DAYJS_NICE_FORMAT_DATE, DAYJS_NICE_FORMAT_TIME, DAYJS_WITH_TIME_FORMAT, DEFAULT_MAP_POSITION, DEFAULT_UPLOAD_PREVIEW_SIZE, DEFAULT_ZOOM, EMPTY_DATA_CONTENT_CATEGORY, GENERATIONS, KEYS, PERMS, ROMAN_PERMS, ROUTES, TRACK_COLORS_BY_TYPE } from "~/const";
import { IVisitJson, IEvent, IGalleryItem, IParseCoordsData, IBEVisitItem, ICalendarEvent, IAddressItem, IPersonItem, IGallery, TTrackType, TCoords, TService } from "~/interfaces";
import { ISearchItem } from "~/providers/search";
import { IMapPosition } from "~/map-interfaces";
import { ALL_DATABASE_ITEMS_CATEGORY } from "~/database-items/provider";

import { decode, isValid } from "./olc";
import { getCurrentWeekData } from "~/providers/month-data";
import { addNotification } from "~/stores/notifications";
import { IInfoLineItem } from "~/components/InfoLine";

export function isLocalhost() {
	return location.hostname.indexOf("localhost") !== -1;
}

/* eslint-disable no-magic-numbers */
export function timeToSeconds(value: string): number {
	const parts = value.split(":");

	if (parts.length === 2) {
		return (parseFloat(parts[0]) * 60) + parseFloat(parts[1]);
	}

	return 0;
}

export function getRandomHexHash(): string {
	return Math.random().toString(16).replace("0.", "");
}

export function getMonthDayDesc({
	date,
	month = true,
	weekDay = false,
}: {
	date: string;
	month?: boolean;
	weekDay?: boolean;
}): string {
	return new Intl.DateTimeFormat("cs", { ...month ? { month: 'long' } : {}, day: 'numeric', ...weekDay ? { weekday: 'long' } : {} }).format(dayjs(date).toDate());
}

export function getFullDesc(date: string): string {
	return new Intl.DateTimeFormat("cs", { year: 'numeric', month: 'long', day: 'numeric' }).format(dayjs(date).toDate());
}

export function getShortMonthDayDate(date: string): string {
	return new Intl.DateTimeFormat("cs", { month: 'short', day: 'numeric' }).format(dayjs(date).toDate());
}

export function getShortEventTitleDate(event: IEvent) {
	const datePart = [
		getShortMonthDayDate(event.date),
		...event.toDate
			? [
				getShortMonthDayDate(event.toDate),
			]
			: [],
	].join(" - ");

	return `${datePart} ${event.title}`;
}

export function getDateRange(dateStr: string, toDateStr: string, weekDay?: boolean): string {
	const date = dayjs(dateStr);
	const toDate = dayjs(toDateStr);
	const fromMonth = date.get("month");
	const toMonth = toDate.get("month");

	if (fromMonth === toMonth) {
		const toPart = getMonthDayDesc({
			date: toDateStr,
			weekDay,
		});

		if (weekDay) {
			const fromPart = getMonthDayDesc({
				date: dateStr,
				month: false,
				weekDay,
			});

			return `${fromPart} - ${toPart}`;
		}

		return `${dayjs(date).format("D.")} - ${toPart}`;
	} else if (date.get("year") === toDate.get("year")) {
		if (weekDay) {
			return `${getMonthDayDesc({
				date: dateStr,
				weekDay,
			})} - ${getMonthDayDesc({
				date: toDateStr,
				weekDay,
			})}`;
		}

		return `${dayjs(date).format("D. M.")} - ${dayjs(toDate).format("D. M.")}`;
	}

	return `${dayjs(date).format("M/YYYY")} - ${dayjs(toDate).format("M/YYYY")}`;
}

export function getDateFromEvent(event: IEvent, weekDay?: boolean) {
	return event.toDate
		? getDateRange(event.date, event.toDate, weekDay)
		: getMonthDayDesc({
			date: event.date,
			weekDay,
		});
}

export function getClassName(classes: Array<string>): string {
	const filtered = classes.filter(item => item.length > 0);

	return filtered.join(" ");
}

export function removeFromArray<T>(inputArray: Array<T>, compareFn: (value: T) => boolean): Array<T> {
	const ind = inputArray.findIndex(compareFn);

	if (ind !== -1) {
		const copiedArray = inputArray.slice();

		copiedArray.splice(ind, 1);

		return copiedArray;
	}

	return inputArray;
}

export function sortArrayNumbers(array: Array<number>): Array<number> {
	array.sort((aItem, bItem) => aItem - bItem);

	return array;
}

export function insertUnique<T>(inputArray: Array<T>, uniqueItem: T): Array<T> {
	if (inputArray.includes(uniqueItem)) {
		return inputArray;
	}

	return [
		...inputArray,
		uniqueItem,
	];
}

export function transVideosCount(count: number) {
	if (count >= 2 && count <= 4) {
		return `${count} videa`;
	} else if (count === 1) {
		return `${count} video`;
	}

	return `${count} videií`;
}

export function transPhotosCount(count: number) {
	if (count >= 2 && count <= 4) {
		return `${count} fotky`;
	} else if (count === 1) {
		return `${count} fotka`;
	}

	return `${count} fotek`;
}

export function transYearsBack(years: number) {
	if (years >= 2 && years <= 4) {
		return `-${years} roky`;
	} else if (years === 1) {
		return `-${years} rok`;
	}

	return `-${years} let`;
}

export function transTrackType(type: TTrackType) {
	switch (type) {
		case "bike":
			return "kolo";

		case "boat":
			return "loď";

		case "cable":
			return "lanovka";

		case "car":
			return "auto";

		case "pubt":
			return "mhd";

		case "walk":
			return "chůze";

		default:
	}

	return "chybí";
}

export function getYearsBack(eventYear = 0, curYear = 0, useText = false) {
	const yearsBack = curYear - eventYear;
	const yearsBackStr = yearsBack > 0
		? useText
			? ` (${transYearsBack(yearsBack)})`
			: ` (-${yearsBack})`
		: "";

	return `${eventYear}${yearsBackStr}`;
}

export function getLenDesc(photosCount: number, videosCount: number) {
	if (photosCount > 0 && videosCount > 0) {
		return `${transPhotosCount(photosCount)}, ${transVideosCount(videosCount)}`;
	}

	if (photosCount > 0) {
		return transPhotosCount(photosCount);
	}

	if (videosCount > 0) {
		return transVideosCount(videosCount);
	}

	return "";
}

export function replaceRoute(path: string, replaceObj: {[key: string]: string;}) {
	let output = path;
	const keys = Object.keys(replaceObj);

	for (const key of keys) {
		output = output.replace(key, replaceObj[key]);
	}

	return output;
}

export function getDataLink(folder?: string) {
	const source = ROUTES.OVERVIEW;
	const year = folder ?? dayjs().get("year").toString();

	return replaceRoute(source, {
		":year": year,
	});
}

export function valueSplitByDem(value = "", splitter = "-") {
	const ind = value.indexOf(splitter);

	return {
		leftPart: ind === -1 ? value : value.slice(0, ind),
		rightPart: ind === -1 ? "" : value.slice(ind + 1),
	};
}

export function parseSeo(seo: string) {
	const parts = valueSplitByDem(seo);

	return {
		folderSeo: parts.leftPart,
		nameSeo: parts.rightPart,
	};
}

function parseDates(folderName: string, fromDate?: string, toDate?: string) {
	if (fromDate) {
		const fromDateObj = dayjs(fromDate);
		const yearStr = fromDateObj.get("year").toString() === folderName
			? ""
			: `${fromDateObj.get("year")}-`;

		if (toDate) {
			const toDateObj = dayjs(toDate);
			const toYearStr = fromDateObj.get("year") === toDateObj.get("year")
				? ""
				: `${toDateObj.get("year")}-`;

			return `${yearStr}${fromDateObj.format("MM-DD")}-${toYearStr}${toDateObj.format("MM-DD")}`;
		}

		return `${yearStr}${fromDateObj.format("MM-DD")}`;
	}

	return "";
}

export function getEventDetailLink(seo: string, fromDate?: string, toDate?: string) {
	const parsed = parseSeo(seo);

	let folderSeo = parsed.folderSeo;
	const datesSeo = parseDates(parsed.folderSeo, fromDate, toDate);

	if (datesSeo) {
		folderSeo = `${folderSeo}-${datesSeo}`;
	}

	return replaceRoute(ROUTES.DETAIL, {
		":folderSeo": folderSeo,
		":nameSeo": parsed.nameSeo,
	});
}

export function getGalleryLink(seo: string, fromDate?: string, toDate?: string) {
	const parsed = parseSeo(seo);
	let folderSeo = parsed.folderSeo;
	const datesSeo = parseDates(parsed.folderSeo, fromDate, toDate);

	if (datesSeo) {
		folderSeo = `${folderSeo}-${datesSeo}`;
	}

	return replaceRoute(ROUTES.GALLERY, {
		":folderSeo": folderSeo,
		":nameSeo": parsed.nameSeo,
	});
}

export function getGalleryDetailLink(folderSeo: string, nameSeo: string, detailImage?: string) {
	return detailImage
		? replaceRoute(ROUTES.GALLERY_DETAIL, {
			":folderSeo": folderSeo,
			":nameSeo": nameSeo,
			":detailImage": detailImage,
		})
		: replaceRoute(ROUTES.GALLERY, {
			":folderSeo": folderSeo,
			":nameSeo": nameSeo,
		});
}

export function getCalendarDateLink(dateStr: string) {
	const date = dayjs(dateStr).format(DAYJS_FORMAT);

	return `${ROUTES.CALENDAR}?date=${date}`;
}

export function getDataContentLink(category?: string) {
	return replaceRoute(ROUTES.DATA_CONTENT, {
		":category": category || EMPTY_DATA_CONTENT_CATEGORY,
	});
}

export function getDatabaseItemsLink(category?: string) {
	return replaceRoute(ROUTES.DATABASE_ITEMS, {
		":category": category || ALL_DATABASE_ITEMS_CATEGORY,
	});
}

export function getTracksLink(galleryYear: string) {
	const source = ROUTES.TRACKS;
	const year = galleryYear ?? dayjs().get("year");
	const yearStr = year === "0"
		? ALL_OVERVIEW_DATA.URL
		: year.toString();

	return replaceRoute(source, {
		":year": yearStr,
	});
}

export function formatTime(seconds: number) {
	const minutes = seconds / 60 >>> 0;
	const restSeconds = seconds - (minutes * 60) >>> 0;
	const minStr = minutes.toString().padStart(2, "0");
	const secStr = restSeconds.toString().padStart(2, "0");

	return `${minStr}:${secStr}`;
}

export function debounce(func: (...rest: any[]) => void, wait: number) {
	let timeout = 0;

	return function debounced(...params: any[]) {
		function later() {
			timeout = 0;
			func(...params);
		}

		clearTimeout(timeout);
		timeout = window.setTimeout(later, wait);
	};
}

export function getWhiteImg() {
	return `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdj+P///38ACfsD/QVDRcoAAAAASUVORK5CYII=`;
}

export function getZoom(searchItem: ISearchItem) {
	return searchItem.type === "poi"
		? 17
		: 13;
}

export function numberToDec(number: number, dec = 5) {
	return parseFloat(number.toFixed(dec));
}

export function getPhotoByDirection(curName: IGalleryItem["name"], items: Array<IGalleryItem>, direction: number) {
	const ind = items.findIndex(item => item.name === curName);
	const maxInd = items.length - 1;
	let newInd = ind + direction;

	if (newInd < 0) {
		newInd = maxInd;
	} else if (newInd > maxInd) {
		newInd = 0;
	}

	return items[newInd];
}

export async function downloadImage(imageSrc: string, fileName: string) {
	const image = await fetch(imageSrc);
	const imageBlog = await image.blob();
	const imageURL = URL.createObjectURL(imageBlog);
	const link = document.createElement("a");

	link.href = imageURL;
	link.download = fileName;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
	URL.revokeObjectURL(imageURL);
}

export function getEventTitle(event: IEvent, customTitle?: string, weekDay?: boolean) {
	if (customTitle) {
		return customTitle;
	}

	const year = dayjs(event.date).get("year");

	return `${event.title} (${getDateFromEvent(event, weekDay)} ${year})`;
}

function getMapPosition(lon: number, lat: number): IMapPosition {
	return {
		x: lon,
		y: lat,
		z: DEFAULT_ZOOM,
	};
}

export function getMapPositionFromEvent(event: IEvent): IMapPosition {
	if (event.allTracks.length) {
		let zoomBounds: maplibregl.LngLatBounds = null;

		for (const track of event.allTracks) {
			if (zoomBounds) {
				zoomBounds.extend(track.bbox);
			} else {
				zoomBounds = new maplibregl.LngLatBounds(track.bbox);
			}
		}

		const center = zoomBounds.getCenter();

		return getMapPosition(center.lng, center.lat);
	} else if (event.coords) {
		return getMapPosition(event.coords[0], event.coords[1]);
	}

	return DEFAULT_MAP_POSITION;
}

export function getMapPositionFromYearItems(items: Array<IGallery>) {
	const filtered = items.filter(item => !!item.event.coords);

	if (filtered.length) {
		const coords = filtered[0].event.coords;

		return getMapPosition(coords[0], coords[1]);
	}

	return DEFAULT_MAP_POSITION;
}

export function getMapPositionFromYearGallery(gallery: IGallery) {
	const filtered = gallery.items.filter(item => !!item.coords);

	if (filtered.length) {
		const coords = filtered[0].coords;

		return getMapPosition(coords[0], coords[1]);
	}

	return getMapPositionFromEvent(gallery.event);
}

export function getTrackColorByType(type: TTrackType) {
	if (type in TRACK_COLORS_BY_TYPE) {
		return TRACK_COLORS_BY_TYPE[type];
	}

	// def
	return "#000";
}

export function formatDistance(distance: number) {
	if (distance < 1000) {
		return `${distance} m`;
	}

	const formatted = (distance / 1000).toFixed(1);

	return `${formatted} km`;
}

export function parseCoords(text: string, defZoom = DEFAULT_ZOOM): IParseCoordsData {
	let lon = 0;
	let lat = 0;
	let zoom = defZoom;
	let type: IParseCoordsData["type"] = "error";

	// souradnice
	const match = text.match(/\d+\.?\d*\s*,\s*\d+\.?\d*/u);

	if (match) {
		const parts = match[0].split(",").map(item => parseFloat(item));
		const hasBrackets = text.indexOf("[") !== -1 && text.indexOf("]") !== -1;

		lon = hasBrackets
			? parts[0]
			: parts[1];
		lat = hasBrackets
			? parts[1]
			: parts[0];
		type = "lon-lat";
	} else if (text.indexOf("+") !== -1) {
		const olcStr = text.trim().split(" ")[0];

		if (isValid(olcStr)) {
			try {
				const olc = decode(text.trim());

				lon = olc.longitudeLo;
				lat = olc.latitudeLo;
				type = "olc";
			} catch (exc) {
				/* eslint-disable-next-line */
				console.log(exc);
			}
		} else {
			/* eslint-disable-next-line */
			console.log(`Not valid str ${olcStr}`);
		}
	} else if (text.indexOf("mapy.cz") !== -1) {
		try {
			const url = new URL(text);

			lon = parseFloat(url.searchParams.get("x"));
			lat = parseFloat(url.searchParams.get("y"));
			zoom = parseFloat(url.searchParams.get("z"));
			type = "mapycz";
		} catch (exc) {
			/* eslint-disable-next-line */
			console.log(exc);
		}
	}

	return {
		type,
		coords: [lon, lat],
		zoom,
	};
}

export async function imgToArrayBuffer(url: string) {
	try {
		const request = await fetch(url);
		const data = await request.arrayBuffer();

		return data;
	} catch (err) {
		/* eslint-disable-next-line */
		console.log(err);
	}

	return null;
}

export function numberAtPercent(index: number, leftValue: number, rightValue: number) {
	const wholePoint = index >>> 0;
	const diff = index - wholePoint;

	if (diff < 0.05) {
		return leftValue;
	}

	const partA = leftValue * (1 - diff);
	const partB = rightValue * diff;
	const value = partA + partB;

	return value >>> 0;
}

export function loadImage(src: string, minTime?: number): Promise<HTMLImageElement> {
	return new Promise((resolve, reject) => {
		const img = new Image();
		const startTime = Date.now();

		img.addEventListener("load", () => {
			const diff = Date.now() - startTime;

			if (minTime > 0 && minTime > diff) {
				setTimeout(() => {
					resolve(img);
				}, minTime - diff);
			} else {
				resolve(img);
			}
		});
		img.addEventListener("error", () => reject());
		img.src = src;
	});
}

export async function imageToArrayBuffer(src: string): Promise<ArrayBuffer> {
	try {
		const request = await fetch(src);
		const data = await request.arrayBuffer();

		return data;
	} catch (exc) {
		/* eslint-disable-next-line */
		console.log(exc);

		return null;
	}
}

export function getAspectSize(size: number, imgWidth: number, imgHeight: number) {
	let width = 0;
	let height = 0;

	if (imgWidth > imgHeight) {
		width = imgWidth / imgHeight * size >>> 0;
		height = size;
	} else {
		width = size;
		height = imgHeight / imgWidth * size >>> 0;
	}

	return {
		width,
		height,
	};
}

export function getVideoCover(videoSrc: string, size = DEFAULT_UPLOAD_PREVIEW_SIZE): Promise<string> {
	return new Promise(resolve => {
		const video = document.createElement('video');

		function sendCover(cover: string) {
			resolve(cover);
		}

		function snapImage() {
			const canvas = document.createElement('canvas');
			const aspectSize = getAspectSize(size, video.videoWidth, video.videoHeight);

			canvas.width = aspectSize.width;
			canvas.height = aspectSize.height;
			canvas.getContext('2d')?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight, 0, 0, canvas.width, canvas.height);

			// 100x100
			const check = canvas.width * canvas.height > 10000;

			return {
				image: check
					? canvas.toDataURL()
					: "",
				check,
			};
		}

		function timeupdate() {
			const siDate = snapImage();

			if (siDate.check) {
				video.removeEventListener('timeupdate', timeupdate);
				video.pause();
				video.src = "";
				sendCover(siDate.image);
			}
		}

		video.addEventListener('loadeddata', () => {
			const siDate = snapImage();

			if (siDate.check) {
				video.removeEventListener('timeupdate', timeupdate);
				video.src = "";
				sendCover(siDate.image);
			}
		});
		video.addEventListener('timeupdate', timeupdate);
		video.preload = 'metadata';
		video.src = videoSrc;
		// Load video in Safari / IE11
		video.muted = true;
		video.playsInline = true;
		video.play();
	});
}

export function getFileCover(file: File, size = DEFAULT_UPLOAD_PREVIEW_SIZE): Promise<string> {
	/* eslint-disable no-inner-declarations */
	return new Promise((resolve, reject) => {
		const fileReader = new FileReader();

		if (file.type.match('image')) {
			fileReader.onload = async () => {
				try {
					const img = await loadImage(fileReader.result as string);
					const canvas = document.createElement('canvas');
					const aspectSize = getAspectSize(size, img.naturalWidth, img.naturalHeight);

					canvas.width = aspectSize.width;
					canvas.height = aspectSize.height;
					canvas.getContext('2d')?.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight, 0, 0, canvas.width, canvas.height);

					resolve(canvas.toDataURL());
				} catch (exc) {
					reject();
				}
			};
			fileReader.onerror = () => {
				reject();
			};
			fileReader.readAsDataURL(file);
		} else {
			fileReader.onload = () => {
				if (fileReader.result) {
					const blob = new Blob([fileReader.result], { type: file.type });
					const url = URL.createObjectURL(blob);

					getVideoCover(url).then(cover => {
						URL.revokeObjectURL(url);
						resolve(cover);
					});
				} else {
					reject();
				}
			};
			fileReader.onerror = () => {
				reject();
			};
			fileReader.readAsArrayBuffer(file);
		}
	});
}

export function deferPromise<T>() {
	let resolveCb: (data: T) => void = null;
	let rejectCb: (error: string) => void = null;
	const promise: Promise<{ data: T, error: string; }> = new Promise(resolve => {
		resolveCb = (data: T) => {
			resolve({
				data,
				error: "",
			});
		};
		rejectCb = (error: string) => {
			resolve({
				data: null,
				error,
			});
		};
	});

	return {
		promise,
		resolveCb,
		rejectCb,
	};
}

export function getVisitJsonItem(): IVisitJson {
	return {
		lang: navigator.language,
		agent: navigator.userAgent,
		screenWidth: screen.width,
		screenHeight: screen.height,
	};
}

export function compareVisitJsonToBrowser(browser: IVisitJson, jsonItem: IVisitJson) {
	return browser.lang === jsonItem.lang
		&& browser.agent === jsonItem.agent
		&& browser.screenWidth === jsonItem.screenWidth
		&& browser.screenHeight === jsonItem.screenHeight;
}

export function getVisitItem(admin: boolean): Omit<IBEVisitItem, "ip" | "id" | "client" | "sameClient"> {
	/* eslint-disable camelcase */
	return {
		date: dayjs().format(DAYJS_WITH_TIME_FORMAT),
		mobile: screen.orientation.type === "portrait-primary" || screen.orientation.type === "portrait-secondary",
		json: JSON.stringify(getVisitJsonItem()),
		admin,
	};
}

export function splitFile(file: File, chunkSizeMB: number) {
	const fileSize = file.size;
	const chunkSize = chunkSizeMB * Math.pow(1024, 2);

	if (fileSize < chunkSize) {
		return [file];
	}

	const chunksLen = Math.ceil(fileSize / chunkSize);
	let start = 0;
	let end = chunkSize;
	const chunks = [];

	for (let ind = 0; ind < chunksLen; ind++) {
		const chunk = file.slice(start, end);

		chunks.push(chunk);
		start = end;
		end = start + chunkSize;
	}

	return chunks;
}

export function formatSize(size: number) {
	if (typeof size !== "number") {
		return "";
	}

	let lv = size > 0
		? Math.floor(Math.log(size) / Math.log(1024))
		: 0;
	const sizes = ["", "K", "M", "G", "T"];

	lv = Math.min(sizes.length, lv);

	const value = lv > 0 ? (size / Math.pow(1024, lv)).toFixed(2) : size;

	return `${value} ${sizes[lv]}B`;
}

export function formatNumberToThousands(value: number, unit?: boolean) {
	const strValue = value.toString();
	const parts = strValue.split(".");

	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/ug, ' ');

	return `${parts.join(".")}${unit ? " Kč" : ""}`;
}

export function calcPriceBeforeDiscount(priceAfterDisc: number, discount: number) {
	const perPercent = priceAfterDisc / (100 - discount);

	return perPercent * 100;
}

export function getEasterSunday(year = dayjs().get("year")) {
	// konstanty pro gregoriansky kalendar
	const mVal = 24;
	const nVal = 5;
	// vypocet
	const aVal = year % 19;
	const bVal = year % 4;
	const cVal = year % 7;
	const dVal = ((19 * aVal) + mVal) % 30;
	const eVal = (nVal + (2 * bVal) + (4 * cVal) + (6 * dVal)) % 7;

	const date = dayjs(`${year}-03-22`).add(dVal + eVal, "days");

	return date.format(DAYJS_FORMAT);
}

export function getStateHolidays(year = dayjs().get("year")) {
	const events: Array<ICalendarEvent> = [];
	const event: ICalendarEvent = {
		title: "",
		date: "",
		extendedProps: {
			seo: "",
			public: true,
			groups: [],
			stateHoliday: true,
		},
		classNames: ["my-event", "calendar-event", "state-holiday"],
	};
	const easterSun = getEasterSunday(year);
	const easterMon = dayjs(easterSun).add(1, "days").format(DAYJS_FORMAT);
	const easterFriday = dayjs(easterSun).subtract(2, "days").format(DAYJS_FORMAT);

	events.push({
		...event,
		title: "Nový rok zav.",
		date: `${year}-01-01`,
	}, {
		...event,
		title: "Velký pátek otev.",
		date: easterFriday,
	}, {
		...event,
		title: "Velikonoční pondělí zav.",
		date: easterMon,
	}, {
		...event,
		title: "Svátek práce otev.",
		date: `${year}-05-01`,
	}, {
		...event,
		title: "Den vítězství zav.",
		date: `${year}-05-08`,
	}, {
		...event,
		title: "Cyril & Metoděj otev.",
		date: `${year}-07-05`,
	}, {
		...event,
		title: "Upálení Jana Husa otev.",
		date: `${year}-07-06`,
	}, {
		...event,
		title: "Den české státnosti zav.",
		date: `${year}-09-28`,
	}, {
		...event,
		title: "Den vzniku Československa zav.",
		date: `${year}-10-28`,
	}, {
		...event,
		title: "Den boje za svobodu a demokracii otev.",
		date: `${year}-11-17`,
	}, {
		...event,
		title: "Štědrý den otev. dop.",
		date: `${year}-12-24`,
	}, {
		...event,
		title: "1. svátek vánoční zav.",
		date: `${year}-12-25`,
	}, {
		...event,
		title: "2. svátek vánoční zav.",
		date: `${year}-12-26`,
	});

	return events;
}

export function getCurrentMonday() {
	const date = dayjs();
	const curDate = date.get("day");
	let days = curDate - 1;

	if (curDate === 1) {
		days = 0;
	} else if (curDate === 0) {
		days = 6;
	}

	return date.subtract(days, "days");
}

export function getInfoLine(messengerNews: boolean) {
	const allMsgs: Array<IInfoLineItem> = [];
	const monday = getCurrentMonday();
	const data = getCurrentWeekData(monday);

	if (data.length) {
		allMsgs.push({
			text: "Tento týden",
		});

		data.forEach(dataItem => {
			const items: Array<string> = [];
			const month = dataItem.diffMonth
				? ` ${dataItem.month + 1}.`
				: "";

			dataItem.items.forEach(oneItem => {
				switch (oneItem.type) {
					case "birthdate":
						items.push(`${oneItem.person} - nar. <b>${oneItem.age}</b>`);
						break;

					case "calendar":
						items.push(oneItem.text);
						break;

					case "holidate":
						items.push(`${oneItem.person} - svát.`);
						break;

					default:
				}
			});

			allMsgs.push({
				text: `<b>${dataItem.date}.${month}</b> ${items.join(", ")}`,
				active: dataItem.active,
				past: dataItem.past,
			});
		});
	} else {
		allMsgs.push({
			text: "Tento týden žádné události",
		});
	}

	if (messengerNews) {
		allMsgs.push({
			text: "nové zprávy v messengeru",
		});
	}

	return allMsgs;
}

export function copyToClipboard(text: string) {
	navigator.clipboard.writeText(text);
	addNotification("Zkopírováno do schránky");
}

export function getAddressString(address: IAddressItem) {
	if (!address.streetName || address.zipCode === 0) {
		return address.city;
	}

	const zipCodeStr = address.zipCode.toString();
	const zipCode = `${zipCodeStr.slice(0, 3)} ${zipCodeStr.slice(3, 5)}`;
	const commaItems = [
		address.firm || "",
		address.building || "",
		`${address.streetName} ${address.houseNumber}`,
		`${zipCode} ${address.city}`,
	].filter(item => item).join(", ");

	return commaItems;
}

export function getPersonsTitle(persons: Array<IPersonItem>) {
	const filtered = persons.filter(person => "familyName" in person);

	if (persons.length > 1 && filtered.length > 0) {
		const names = persons.map(person => person.fullname.split(" ")[0]).join(", ");

		return `${names} ${filtered[0].familyName}`;
	}

	return persons.map(person => person.fullname).join(", ");
}

export function hasTimeHours(time: string) {
	// vzorovy cas
	return time.length > "2024-10-10".length;
}

export function getGalleryItemTitle(galleryItem: IGalleryItem, gallery: IGallery, showOnlyHours = false) {
	if (!galleryItem.date || !gallery) {
		return "";
	}

	const dateObj = dayjs(galleryItem.date);
	const hasTime = hasTimeHours(galleryItem.date);

	if (showOnlyHours && !hasTime) {
		return "";
	}

	let format = "";

	if ((gallery.dateType === "same" && !gallery.event.toDate) || showOnlyHours) {
		format = hasTime
			? "H:mm"
			: "";
	} else if (gallery.dateType === "same-month") {
		format = hasTime
			? "D. H:mm"
			: "D.";
	} else if (gallery.dateType === "more-months" || (gallery.dateType === "same" && gallery.event.toDate)) {
		format = hasTime
			? "D. M. H:mm"
			: "D. M.";
	} else {
		// more years
		format = hasTime
			? DAYJS_NICE_FORMAT_TIME
			: DAYJS_NICE_FORMAT_DATE;
	}

	return format
		? dateObj.format(format)
		: "";
}

export function getDateWithHours(dateStr: string) {
	const hasTime = hasTimeHours(dateStr);
	const format = hasTime
		? "D. M. H:mm"
		: "D. M.";

	return dayjs(dateStr).format(format);
}

export function getDateHours(dateStr: string) {
	const hasTime = hasTimeHours(dateStr);

	return hasTime
		? dayjs(dateStr).format("H:mm")
		: "";
}

export function getFullDateWithHours(dateStr: string) {
	const hasTime = hasTimeHours(dateStr);
	const format = hasTime
		? DAYJS_NICE_FORMAT_TIME
		: DAYJS_NICE_FORMAT_DATE;

	return dayjs(dateStr).format(format);
}

export function getDayVideoSubtitle(dateStr: string) {
	const hasTime = hasTimeHours(dateStr);

	return hasTime
		? dayjs(dateStr).format("H:mm")
		: "";
}

export function formatDuration(duration: number) {
	let curTime = duration;
	const hours = Math.floor(curTime / 3600);

	curTime -= hours * 3600;

	const minutes = Math.floor(curTime / 60);

	curTime -= minutes * 60;

	const seconds = curTime;

	if (hours > 0) {
		return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
	}

	return `${minutes}:${seconds.toString().padStart(2, "0")}`;
}

export function updateFullscreenGalleryItem(data: IGalleryItem) {
	return {
		...data,
		subTitle: data.subTitle || data.date ? getFullDateWithHours(data.date) : "",
	};
}

export function getPersonGeneration(year: number) {
	const filtered = GENERATIONS.filter(item => year >= item.fromYear && year <= item.toYear);

	return filtered[0].title;
}

export function isKeyEnter(event: KeyboardEvent | KeyboardEventReact) {
	return event.code === KEYS.ENTER || event.code === KEYS.NUM_ENTER;
}

export function getDistanceBetweenPointsWGS84(wgs84a: TCoords, wgs84b: TCoords, altitude = 0) {
	const alt = 6371009 + altitude;
	let dx = Math.abs(wgs84a[0] - wgs84b[0]);
	let dy = Math.abs(wgs84a[1] - wgs84b[1]);

	dx = dx * Math.PI / 180;
	dy = dy * Math.PI / 180;

	const y1 = wgs84a[1] * Math.PI / 180;
	const y2 = wgs84b[1] * Math.PI / 180;
	const aVal = (Math.sin(dy/2) * Math.sin(dy/2)) + (Math.cos(y1) * Math.cos(y2) * Math.sin(dx/2) * Math.sin(dx/2));
	const cVal = 2 * Math.atan2(Math.sqrt(aVal), Math.sqrt(1-aVal));

	return alt * cVal;
}

export function getDistanceWGS84(wgs84Coords: Array<TCoords>) {
	let distance = 0;

	for (let ind = 0, max = wgs84Coords.length - 2; ind <= max; ind++) {
		distance += getDistanceBetweenPointsWGS84(wgs84Coords[ind], wgs84Coords[ind + 1]);
	}

	return distance >>> 0;
}

export function getGroupTitle(event: IEvent, service: TService) {
	if (event.public) {
		return "Veřejné";
	}

	if (event.groups) {
		const titles: Array<{ full: string; short: string; }> = [];

		if (service === "makuderovi") {
			if (event.groups.includes(PERMS.RODINA)) {
				titles.push({
					full: "Rodina",
					short: "ROD",
				});
			}

			if (event.groups.includes(PERMS.DETI)) {
				titles.push({
					full: "Děti",
					short: "DĚT",
				});
			}
		} else if (service === "roman") {
			if (event.groups.includes(ROMAN_PERMS.KAMOSI)) {
				titles.push({
					full: "Kámoši",
					short: "KÁM",
				});
			}

			if (event.groups.includes(ROMAN_PERMS.SEZNAM)) {
				titles.push({
					full: "Seznam",
					short: "SEZ",
				});
			}

			if (event.groups.includes(ROMAN_PERMS.RODINA)) {
				titles.push({
					full: "Rodina",
					short: "ROD",
				});
			}
		}

		return titles.length === 1
			? titles[0].full
			: titles.map(item => item.short).join(", ");
	}

	return "";
}

export function getImagePersons(persons: Array<string>) {
	const output: Array<string> = [];

	for (const person of persons) {
		const parts = person.split(" ");

		output.push(`${parts[0].slice(0, 3)} ${parts[1].slice(0, 3)}`);
	}

	return output.join(", ");
}
